import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import useUserInfo from "../../hooks/useUserInfo";
import { onClickChatRow } from "../../API/chats";
import ProfileAvatar from "../../assets/images/ProfileAvatar.jpg";
function ChatRow({
  userImage,
  userName,
  lastMessageAt,
  id,
  unreadCount,
  lastSenderId,
  isDisabled,
}) {
  const { lang } = useParams() || "no";
  const { currentUser } = useUserInfo();

  const { t } = useTranslation();
  return (
    <NavLink
      onClick={async () => {
        if (!isDisabled) {
          onClickChatRow(currentUser, lastSenderId, unreadCount, id);
        }
      }}
      to={!isDisabled ? `/${lang}/messages/${id}` : ``}
    >
      {({ isActive }) => (
        <div
          className={`${
            isActive && !isDisabled
              ? "md:bg-strokeColor md:text-kprimary hover:opacity-100"
              : ""
          } w-full px-3 lg:px-5  py-3 flex items-center text-kprimary text-white border-b md:border-b-0 border-border cursor-pointer hover:opacity-75 transition-colors`}
        >
          <Avatar
            size={{ xs: 44, sm: 44, md: 44, lg: 44, xl: 44, xxl: 44 }}
            src={userImage ? userImage : ProfileAvatar}
            alt={userName?.charAt(0)}
            className="shrink-0"
          />
          <div className="flex-grow overflow-hidden ml-2">
            <p className="text-sm truncate mr-4">{userName}</p>
            {!unreadCount && (
              <p
                className={`text-xs whitespace-nowrap ${
                  isActive && "font-semibold"
                }`}
              >
                {t("Lastmessage")}: {lastMessageAt}
              </p>
            )}
          </div>

          {!unreadCount && currentUser && lastSenderId !== currentUser.uid && (
            <div
              className={`
              h-6 w-6 rounded-full flex items-center justify-center bg-[#dee7e9] text-kprimary transition-colors text-sm
              ${unreadCount && "bg-kprimary text-white"}
              `}
            ></div>
          )}
        </div>
      )}
    </NavLink>
  );
}

export default ChatRow;
