import { useTranslation } from "react-i18next";
import ButtonDark from "../CommonComponents/ButtonDark";

const ChatAssetHeader = ({
  isOwnerChat,
  assetName,
  handleBookNowAndProposedDates,
  isBookingUpcomingOrOngoing,
  handleViewRental,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#E2F3F7]  justify-between  mb-2 w-full py-2 px-3 md:px-5 xl:px-8 text-sm flex items-center gap-3 text-kprimary">
      <h4 className=" font-roobert text-kprimary">{assetName}</h4>
      {!isBookingUpcomingOrOngoing && (
        <ButtonDark
          handleClick={() => handleBookNowAndProposedDates(isOwnerChat)}
          styleClasses={`!h-[40px] ${
            isOwnerChat ? "!max-w-[180px]" : "!max-w-[130px]"
          } !text-[18px] bg-dark-blue text-white  whitespace-nowrap `}
          text={t("sendSuggestion")}
        />
      )}
      {isBookingUpcomingOrOngoing && (
        <ButtonDark
          styleClasses={`!h-[40px] ${
            isOwnerChat ? "!max-w-[180px]" : "!max-w-[130px]"
          } !text-[18px] bg-dark-blue text-white whitespace-nowrap `}
          text={t("GotoRental")}
          handleClick={handleViewRental}
        ></ButtonDark>
      )}
    </div>
  );
};

export default ChatAssetHeader;
