import React from "react";

const Step2IconLight = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="22" stroke="#A5C5CC" />
      <path
        d="M19.3651 21.432C19.2851 19.144 20.7571 17.672 23.0611 17.672C25.1731 17.672 26.6931 18.984 26.6931 21.016C26.6931 22.472 25.7971 23.704 24.0851 24.552L22.4851 25.368C21.5731 25.832 20.5491 26.68 20.5491 27.768H26.7571V29H19.2211V28.024C19.2211 26.024 20.7891 24.792 21.9411 24.216L23.6531 23.336C24.8531 22.728 25.2851 21.96 25.2851 20.984C25.2851 19.704 24.3411 18.872 23.0611 18.872C21.6211 18.872 20.7091 19.896 20.7731 21.432H19.3651Z"
        fill="#A5C5CC"
      />
    </svg>
  );
};

export default Step2IconLight;
