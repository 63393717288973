import { Routes, Route, Navigate } from "react-router-dom";
import Signup from "../pages/Signup";
import Signin from "../pages/Signin";
import Dashboard from "../pages/Dashboard.jsx";
import Rentals from "../pages/Rentals";
import Assets from "../pages/Assets";
import Reports from "../pages/Reports";
import Map from "../pages/Map";
import ProtectedRoute from "../PrivateRoutes/ProtectedRoute";
import ClubMembers from "../pages/ClubMembers";
import WaitingList from "../pages/WaitingList";
import SeasonalDocks from "../pages/SeasonalDocks.jsx";
import Settings from "../pages/Settings.jsx";
import Support from "../pages/Support.jsx";
import AddItems from "../pages/AddItems.jsx";
import Export from "../pages/Export.jsx";
import Calendar from "../pages/Calendar.jsx";
import Subscription from "../pages/Subscription.jsx";
import { defaultLanguage } from "../constants/constants.js";
import Messages from "../pages/Messages.jsx";
import AddAsset from "../pages/AddAsset.jsx";

const PrivateRoutes = [
  {
    id: 1,
    path: "/",
    element: <Dashboard />,
  },
  {
    id: 2,
    path: "rentals/:rentalType",
    element: <Rentals />,
  },
  {
    id: 3,
    path: "assets/:assetsType",
    element: <Assets />,
  },
  {
    id: 4,
    path: "reports",
    element: <Reports />,
  },

  {
    id: 5,
    path: "map",
    element: <Map />,
  },
  {
    id: 6,
    path: "club-members",
    element: <ClubMembers />,
  },
  {
    id: 7,
    path: "wait-list",
    element: <WaitingList />,
  },
  {
    id: 8,
    path: "seasonaldocks",
    element: <SeasonalDocks />,
  },
  {
    id: 9,
    path: "seasonaldocks/:filter",
    element: <SeasonalDocks />,
  },
  {
    id: 10,
    path: "support",
    element: <Support />,
  },
  {
    id: 11,
    path: "settings",
    element: <Settings />,
  },
  {
    id: 12,
    path: "import/:data",
    element: <AddItems />,
  },
  {
    id: 13,
    path: "export/:data",
    element: <Export />,
  },
  {
    id: 14,
    path: "calendar",
    element: <Calendar />,
  },
  {
    id: 15,
    path: "messages",
    element: <Messages />,
  },
  {
    id: 16,
    path: "messages/:id",
    element: <Messages />,
  },
  {
    id: 17,
    path: "add-asset/:assetType",
    element: <AddAsset />,
  },
];
const filterRoutesByRole = (role, lang) => {
  // Filter out routes based on the role
  return PrivateRoutes.filter((route) => {
    if (
      role === "rental" &&
      (route.path === `/${defaultLanguage}/calendar` ||
        route.path === `/${defaultLanguage}/seasonaldocks` ||
        route.path === `/${defaultLanguage}/seasonaldocks/:filter` ||
        route.path === `/${defaultLanguage}/club-members` ||
        route.path === `/${defaultLanguage}/wait-list`)
    ) {
      return false;
    }
    return true;
  });
};

const ReactRouter = () => {
  const userRole = localStorage.getItem("role");
  const filteredRoutes = filterRoutesByRole(userRole, defaultLanguage);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to={`/${defaultLanguage ? defaultLanguage : "no"}/`} />
          }
        />
        <Route path=":lang/signup" element={<Signup />} />
        <Route path=":lang/signin" element={<Signin />} />
        <Route path=":lang/subscription" element={<Subscription />} />
        <Route key={userRole} element={<ProtectedRoute />}>
          {filteredRoutes.map((route) => (
            <Route
              key={route.id}
              exact
              path={`/:lang/${route.path}`}
              element={route.element}
            />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default ReactRouter;
