import React from "react";
import { Table } from "antd";
import { useSearchParams } from "react-router-dom";
import "../../../styles/antd-table.css";
import { useTranslation } from "react-i18next";
const AntdTable = ({
  columns,
  data,
  selection,
  selectedRowKeys,
  onSelectChange,
  onRow,
  pagination,
  totalPages,
  pageSize,
  loading,
  error,
}) => {
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRows) => onSelectChange(selectedRows),
  };
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const current = Number(params.get("page")) || 1;
  const onChangePage = (page) => {
    const paramsObject = Object.fromEntries(params);
    setParams({ ...paramsObject, page });
  };
  if (error) {
    return error?.message;
  } else {
    return (
      <Table
        columns={columns}
        dataSource={data}
        rowSelection={selection ? rowSelection : ""}
        rowClassName={() => "rowClassName1"}
        className="max-lg:w-full overflow-x-auto"
        onRow={onRow}
        locale={{
          emptyText: t("noData"),
        }}
        pagination={
          pagination
            ? {
                current,
                pageSize: pageSize,
                total: totalPages,
                onChange: onChangePage,
              }
            : false
        }
        loading={loading}
      />
    );
  }
};

export default AntdTable;
