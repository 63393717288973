import { Tag } from "antd";
import ViewDetails from "../components/CommonComponents/ViewDetails";

export const dropDownProps = (t, setParams, paramsObject) => {
  return {
    options: [
      { value: "all", label: t("all") + " " + t("seasonalDocks") },
      { value: "a_z", label: t("pier") + " " + t("A-Z") },
      { value: "z_a", label: t("pier") + " " + t("Z-A") },
      {
        value: "width_highest",
        label: t("dock") + " " + t("width") + " " + t("highest_lowest"),
      },
      {
        value: "width_lowest",
        label: t("dock") + " " + t("width") + " " + t("lowest_highest"),
      },
      {
        value: "length_highest",
        label: t("dock") + " " + t("length") + " " + t("highest_lowest"),
      },
      {
        value: "length_lowest",
        label: t("dock") + " " + t("length") + " " + t("lowest_highest"),
      },
    ],
    defaultValue: "all",
    handleChange: (option) => setParams({ ...paramsObject, type: option }),
  };
};

export const getSeasonDocksColumns = (
  t,
  pierColumn,
  location,
  renterColumn,
  priceColumn,
  viewDockDetails
) => {
  return [
    {
      title: t("dock"),
      dataIndex: "dock_number",
    },
    pierColumn,
    ...(location === "/seasonaldocks/member-requests"
      ? [
          renterColumn,
          {
            title: t("vacancyPeriod"),
            dataIndex: "period",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_, { request_status }) => {
              let color =
                request_status === "approved"
                  ? "#D9F2DD"
                  : request_status === "request"
                  ? "yellow"
                  : "'#FFC4B7'";
              return (
                <Tag
                  color={color}
                  key={request_status}
                  className="!rounded-full  leading-[28px]  text-center  w-[98px]"
                >
                  <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                    {request_status === "approved"
                      ? "Approved"
                      : request_status === "request"
                      ? "Request"
                      : "Rejected"}
                  </div>
                </Tag>
              );
            },
          },
        ]
      : [
          {
            title: t("name"),
            dataIndex: "customer_name",
          },
          {
            title: t("email"),
            dataIndex: "customer_email",
          },
          {
            title: t("Phonenumber"),
            dataIndex: "customer_contact",
          },
          {
            title: t("boatType"),
            dataIndex: "boat_type",
          },
          priceColumn,
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            // width: '25%',
            render: (_, { status, customer_id }) => {
              let color = !customer_id ? "#D9F2DD" : "#FFC4B7";
              return (
                <Tag
                  color={color}
                  key={status}
                  className="!rounded-full my-1 leading-[28px] text-center  w-[98px]"
                >
                  <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                    {status}
                  </div>
                </Tag>
              );
            },
          },
          {
            title: t("details"),
            dataIndex: "4",
            render: (_, { id }) => {
              return (
                <div className="flex items-center justify-center">
                  <ViewDetails handleClick={() => viewDockDetails(id)} />
                </div>
              );
            },
          },
        ]),
  ];
};
