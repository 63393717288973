import React from "react";

const SupportIcon = ({ location, path }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M14.0994 6.89474C14.0994 5.33136 13.5094 3.83201 12.4592 2.72653C11.409 1.62105 9.98463 1 8.49941 1C7.0142 1 5.58982 1.62105 4.53962 2.72653C3.48941 3.83201 2.89941 5.33136 2.89941 6.89474"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1 12.0535V12.4219C14.1 12.8127 13.9525 13.1876 13.6899 13.464C13.4274 13.7403 13.0713 13.8956 12.7 13.8956H10.25M1.5 10.1657V8.78337C1.50005 8.45475 1.60444 8.13557 1.79659 7.87656C1.98874 7.61756 2.25762 7.43359 2.5605 7.3539L3.7785 7.03264C3.84039 7.01641 3.90498 7.01524 3.96737 7.0292C4.02975 7.04317 4.0883 7.07191 4.13856 7.11324C4.18882 7.15457 4.22948 7.20741 4.25746 7.26775C4.28543 7.32809 4.29998 7.39434 4.3 7.46148V11.4876C4.3 11.5548 4.28545 11.6211 4.25743 11.6815C4.22942 11.7419 4.18869 11.7948 4.13835 11.8362C4.088 11.8775 4.02936 11.9062 3.96688 11.9202C3.9044 11.9341 3.83974 11.9328 3.7778 11.9164L2.5598 11.5959C2.25705 11.5161 1.98833 11.332 1.79632 11.073C1.6043 10.8141 1.50001 10.495 1.5 10.1664V10.1657ZM15.5 10.1657V8.78337C15.5 8.45475 15.3956 8.13557 15.2034 7.87656C15.0113 7.61756 14.7424 7.43359 14.4395 7.3539L13.2215 7.03264C13.1596 7.01641 13.095 7.01524 13.0326 7.0292C12.9702 7.04317 12.9117 7.07191 12.8614 7.11324C12.8112 7.15457 12.7705 7.20741 12.7425 7.26775C12.7146 7.32809 12.7 7.39434 12.7 7.46148V11.4876C12.7 11.5547 12.7146 11.621 12.7425 11.6813C12.7705 11.7416 12.8112 11.7945 12.8614 11.8358C12.9117 11.8772 12.9702 11.9059 13.0326 11.9199C13.095 11.9338 13.1596 11.9326 13.2215 11.9164L14.4395 11.5959C14.7424 11.5162 15.0113 11.3322 15.2034 11.0732C15.3956 10.8142 15.5 10.4951 15.5 10.1664V10.1657Z"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeWidth="1.2"
      />
      <path
        d="M9.54941 14.9996H7.44941C7.17094 14.9996 6.90387 14.8831 6.70695 14.6759C6.51004 14.4686 6.39941 14.1875 6.39941 13.8943C6.39941 13.6012 6.51004 13.3201 6.70695 13.1128C6.90387 12.9055 7.17094 12.7891 7.44941 12.7891H9.54941C9.82789 12.7891 10.095 12.9055 10.2919 13.1128C10.4888 13.3201 10.5994 13.6012 10.5994 13.8943C10.5994 14.1875 10.4888 14.4686 10.2919 14.6759C10.095 14.8831 9.82789 14.9996 9.54941 14.9996Z"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default SupportIcon;
