import React from "react";
import ButtonDark from "../CommonComponents/ButtonDark";
import SuggestNewTimePermission from "./SuggestNewTimePermission";
import Calendar from "../Chats/Calendar";
import Spinner from "../CommonComponents/Spinner";
import { declineOptions } from "../../constants/constants";
import useGetDataForAcceptOrRejectRental from "../../hooks/getData/useGetDataForAcceptOrRejectRental";
import { getDisabledDates } from "../../utils/getDisabledDates";
// import { getDisabledDates } from '../../utils/getDisabledDates';

const AcceptOrRejectRentalModal = ({
  showModal,
  onCLickAcceptOrReject,
  onClickAccept,
  onClickReject,
  loading,
  modalScreen,
  setModalScreen,
  rentalItem,
  t,
}) => {
  const {
    dateRange,
    setDateRange,
    handleNo,
    handleYes,
    unavailbleDates,
    selectReason,
    isLoading,
    handleClick,
    sendProposedDates,
    data,
  } = useGetDataForAcceptOrRejectRental({
    modalScreen,
    setModalScreen,
    rentalItem,
    onClickReject,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {showModal.type === "reject" && modalScreen === 1 && (
        <div className="h-[70vh] bg-[#E2F3F7] gap-y-[25px] flex flex-col justify-center items-center rounded-[10px]">
          <h1 className="leading-[50px] text-dark-blue 2xl:text-[35px] text-[28px]">
            {t("SelectReasonToRejectRentalRequest")}
          </h1>
          {declineOptions.map(({ text, id }) => {
            return (
              <ButtonDark
                text={t(text)}
                styleClasses={`${
                  selectReason === id ? "light-Btn" : "white-Btn"
                } w-[340px] h-[40px]`}
                handleClick={() => {
                  handleClick(id);
                }}
                loading={loading && selectReason === id}
              />
            );
          })}
        </div>
      )}
      {showModal.type === "reject" && modalScreen === 2 && (
        <SuggestNewTimePermission
          isPending={loading}
          handleYes={handleYes}
          handleNo={handleNo}
        />
      )}
      {showModal.type === "reject" && modalScreen === 3 && (
        <div className="bg-[#E2F3F7] rounded-[10px] pb-[20px]">
          <Calendar
            btnText="continue"
            dateRange={dateRange}
            setDateRange={setDateRange}
            startTime={dateRange.startDate}
            endTime={dateRange.endDate}
            isLoading={loading}
            handleContinue={sendProposedDates}
            disableDates={[
              ...getDisabledDates(data?.bookings),
              ...unavailbleDates,
            ]}
          />
        </div>
      )}
      {showModal.type === "accept" && (
        <div className="h-[50vh] bg-[#E2F3F7] gap-y-[25px] flex flex-col justify-center items-center rounded-[10px]">
          <h1 className="leading-[50px] 2xl:text-[35px] text-[28px] font-roobert-rg">
            {t("AreyousureyouwanttoacceptrentalRequest")}
          </h1>
          <div className="flex items-center gap-x-[15px]">
            <ButtonDark
              text={t("yes")}
              styleClasses={"light-Btn h-[40px] w-[90px]"}
              handleClick={onClickAccept}
              loading={loading}
            />
            <ButtonDark
              text={t("no")}
              styleClasses={"white-Btn h-[40px] w-[90px]"}
              handleClick={() => onCLickAcceptOrReject(false, null)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AcceptOrRejectRentalModal;
