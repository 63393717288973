import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { onDownloadEmptyCSV } from "../../utils/downloadCsv";
import { useTranslation } from "react-i18next";
const useConstants = () => {
  const { data: enabled } = useParams();
  const downloadCSVDemo = () => {
    onDownloadEmptyCSV(headers, "Dummy");
  };
  const location = useLocation().pathname;
  const [params, setParams] = useSearchParams();
  const userId = localStorage.getItem("userId");
  const page = params.get("page") ? params.get("page") : 1;
  const search = params.get("search") ? params.get("search") : "";
  const stringify = JSON.stringify;
  const parse = JSON.parse;
  const { t } = useTranslation();
  const headers =
    enabled === "rentals"
      ? [
          "ID",
          "Rental Object",
          "Asset Type",
          "Tenant",
          "Period",
          "Status",
          "Revenue",
          "Ship O Hos fees",
          "Profit excluding tax",
          "Profit including tax (25%)",
        ]
      : enabled === "club-members"
      ? [
          "Member Number",
          "Name",
          "Email",
          "Phone Number",
          "Dock",
          "Pier",
          "Member Since",
        ]
      : enabled === "season-dock"
      ? [
          "Member Number",
          "Name",
          "Email",
          "Phone Number",
          "Pier",
          "Dock Number",
          "Dock Length",
          "Dock Width",
          "Boat Type",
          "Boat Length",
          "Boat Width",
          "Price",
          "Status",
        ]
      : enabled === "waiting-list"
      ? [
          "Boat Type",
          "Width",
          "Length",
          "Customer Id",
          "Name",
          "Email",
          "Phone Number",
          "Reported",
        ]
      : enabled === "guest-docks"
      ? [
          "Dock Number",
          "Pier",
          "Dock Width",
          "Dock Length",
          "Status",
          "Renter",
          "Email",
          "Phone Number",
        ]
      : enabled === "boat" || enabled === "jet_ski"
      ? [
          "Name",
          "Available",
          "Size",
          "Horse Power",
          "Daily Rate",
          "Hourly Rate",
        ]
      : enabled === "kayak"
      ? ["Name", "Available", "Size", "Daily Rate", "Hourly Rate"]
      : enabled === "boat_trailer" || enabled === "boat-trailer"
      ? ["Name", "Available", "Size", "Daily Rate", "Hourly Rate", "Weight"]
      : [];

  const keyMapping =
    enabled === "club-members"
      ? {
          Dock: "dock_number",
          Email: "customer_email",
          "Member Number": "customer_id",
          "Member Since": "join_date",
          Name: "customer_name",
          "Phone Number": "customer_contact",
          Pier: "pier_number",
        }
      : enabled === "waiting-list"
      ? {
          "Boat Type": "boat_type",
          Width: "boat_width",
          Length: "boat_length",
          "Customer Id": "customer_id",
          Name: "customer_name",
          Email: "customer_email",
          "Phone Number": "customer_contact",
          Reported: "join_date",
        }
      : enabled === "season-dock"
      ? {
          "Member Number": "customer_id",
          Name: "customer_name",
          Email: "customer_email",
          "Phone Number": "customer_contact",
          Pier: "pier_number",
          "Dock Number": "dock_number",
          "Dock Length": "dock_length",
          "Dock Width": "dock_width",
          "Boat Type": "boat_type",
          "Boat Length": "boat_length",
          "Boat Width": "boat_width",
          Price: "price",
          Status: "status",
        }
      : enabled === "guest-docks"
      ? {
          "Dock Number": "dock_number",
          Pier: "pier_number",
          "Dock Width": "dock_width",
          "Dock Length": "dock_length",
          Status: "status",
          "Marina Name": "marina_name",
          Renter: "renter",
          Email: "email",
          "Phone Number": "phone",
        }
      : enabled === "boat" || enabled === "jet_ski"
      ? {
          Name: "name",
          Available: "available",
          Size: "size_in_feet",
          "Horse Power": "horse_power",
          "Hourly Rate": "rate_per_hour",
          "Daily Rate": "rate_per_day",
        }
      : enabled === "kayak"
      ? {
          Name: "name",
          Available: "available",
          Size: "size_in_feet",
          "Hourly Rate": "rate_per_hour",
          "Daily Rate": "rate_per_day",
        }
      : {};

  const emailColumn = {
    title: t("email"),
    dataIndex: "email",
  };
  const sizeInFeet = {
    title: t("size"),
    dataIndex: "size_in_feet",
  };
  const horsePowerColumn = {
    title: t("horse_power"),
    dataIndex: "horse_power",
  };
  const priceColumn = {
    title: t("price"),
    dataIndex: "rate_per_hour",
  };
  const pierColumn = {
    title: t("pier"),
    dataIndex: "pier_number",
  };
  const renterColumn = {
    title: t("renter"),
    dataIndex: "renter",
  };

  const assetsDropdownOption = [
    { value: "all", label: t("all") + " " + t("guestDocks") },
    { value: "a_z", label: t("pier") + " " + t("A-Z") },
    { value: "z_a", label: t("pier") + " " + t("Z-A") },
    {
      value: "width_highest",
      label: t("dock") + " " + t("width") + " " + t("highest_lowest"),
    },
    {
      value: "width_lowest",
      label: t("dock") + " " + t("width") + " " + t("lowest_highest"),
    },
    {
      value: "length_highest",
      label: t("dock") + " " + t("length") + " " + t("highest_lowest"),
    },
    {
      value: "length_lowest",
      label: t("dock") + " " + t("length") + " " + t("lowest_highest"),
    },
  ];
  return {
    headers,
    keyMapping,
    downloadCSVDemo,
    stringify,
    parse,
    page,
    search,
    sizeInFeet,
    emailColumn,
    horsePowerColumn,
    priceColumn,
    pierColumn,
    renterColumn,
    t,
    assetsDropdownOption,
    location,
    userId,
  };
};

export default useConstants;
