import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useMediaQuery } from "../hooks/useMediaQuery";
import ChatSidebar from "../components/Chats/ChatSidebar";
import useUserInfo from "../hooks/useUserInfo";
import { or, query, where } from "firebase/firestore";
import { getChats } from "../API/chats";
import { chatsCollection } from "../constants/chatsConstants";
import useChatsStore from "../store/chatStore";
import Spinner from "../components/CommonComponents/Spinner";

function ChatLayout({ children }) {
  const pathname = useLocation().pathname;
  const isMatches = useMediaQuery("(max-width:768px)");
  const [chats, setChats] = useState();

  const isMobileChatsList = pathname === "/messages" && isMatches;
  const isMobileSingleChat =
    !!matchPath("/messages/:chatId", pathname) && isMatches;
  const [loading, setLoading] = useState(false);
  const { currentUser } = useUserInfo();
  useEffect(() => {
    setLoading(true);
    let unsubscribe;
    setChats([]);
    if (currentUser) {
      const ownerID = currentUser.uid;
      const q = query(
        chatsCollection,
        or(where("owner_id", "==", ownerID), where("tenant_id", "==", ownerID))
      );
      unsubscribe = getChats(q, setLoading, setChats);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);
  const { notExistedChat } = useChatsStore();
  if (loading) {
    return (
      <div className="h-[100vh] w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  // +++++++++++++++++
  // return
  // +++++++++++++++++

  return (
    <div>
      {!isMobileSingleChat && (
        <ChatSidebar
          chats={
            notExistedChat.length === 1
              ? [...(chats ?? []), ...notExistedChat]
              : chats
          }
        />
      )}

      {!isMobileChatsList && (
        <main className="md:ml-[16rem] lg:ml-[20rem] h-[100vh]">
          {children}
        </main>
      )}
    </div>
  );
}

export default ChatLayout;
