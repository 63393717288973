import React from "react";
import ViewMore from "../CommonComponents/ViewMore";
import Table from "../CommonComponents/Table/Table";
import ButtonDark from "../CommonComponents/ButtonDark";
import Drag from "../../assets/icons/Drag";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useGetDataForAssets from "../../hooks/getData/useGetDataForAssets";
const AllAssets = ({ orderedTableCardsData, isLoading, error }) => {
  const { typeOfAsset, handleDragEnd } = useGetDataForAssets();

  return (
    <div className="">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="rental-objects">
          {(provided) => (
            <div
              className="rental-objects"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {orderedTableCardsData.map((item, index) => {
                const { id, heading, addAsset, columns, type, viewMore } = item;
                return (
                  <Draggable
                    key={id.toString()}
                    draggableId={id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        className="rounded-[12px] flex 2xl:mt-[60px] mt-[40px]  bg-[#FFFFFF] my-table"
                      >
                        <div
                          {...provided.dragHandleProps}
                          className="bg-[#E2F3F7] shadow-drag-btn rounded-l-[12px] h-auto flex justify-center items-center w-[21px]"
                        >
                          <Drag />
                        </div>
                        <div className="w-full pt-[33px] pb-[10px]">
                          <div className="px-[30px] pb-[30px] flex items-center justify-between">
                            <h1 className="text-dark-blue text-[28px] 2xl:text-[35px]">
                              {heading}
                            </h1>
                            <ButtonDark
                              text={item.buttonText}
                              styleClasses="h-[40px] light-Btn"
                              handleClick={addAsset}
                            />
                          </div>
                          <Table
                            columns={columns}
                            data={typeOfAsset(type)}
                            loading={isLoading}
                            error={error}
                          />
                          <ViewMore handleClick={viewMore} />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default AllAssets;
