import React from "react";

const ArrowDownDark = ({ arrowLight }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
    >
      <path
        d="M0.638672 1.24857L5.99379 7.25868C6.12616 7.40807 6.33074 7.48851 6.51125 7.5C6.70379 7.5 6.89634 7.41956 7.02871 7.25868L12.3838 1.24857C12.7208 0.869348 12.2635 0.31775 11.7942 0.559074L6.49921 2.90336L1.20427 0.559073C0.746978 0.317749 0.277653 0.869347 0.614604 1.24857L0.638672 1.24857Z"
        fill={arrowLight ? "#FFFFFF" : "#18374F"}
      />
    </svg>
  );
};

export default ArrowDownDark;
