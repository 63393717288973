import create from "zustand";
import { persist } from "zustand/middleware";

export const useAssetEditStore = create(
  persist(
    (set) => ({
      screen: 1,
      setScreen: (screen) => set(() => ({ screen })),
      isOpen: false,
      setIsOpen: (bool) => set(() => ({ isOpen: bool })),
      thumbnailImages: [],
      setThumbnailImages: (data) => set(() => ({ thumbnailImages: data })),
      images: [],
      setImages: (data) => set(() => ({ images: data })),
    }),
    {
      name: "useEditAsset",
      getStorage: () => localStorage,
    }
  )
);
