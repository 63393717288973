import React from "react";
import ArrowDownDark from "../../assets/icons/ArrowDownDark";
import { useTranslation } from "react-i18next";
const ViewMore = ({ handleClick }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-[25px] flex justify-center  items-center  font-roobert-sb mt-[25px]">
      <span
        onClick={handleClick}
        className="flex items-center gap-x-[15px] cursor-pointer"
      >
        {t("more")} <ArrowDownDark />
      </span>
    </div>
  );
};

export default ViewMore;
