import { useState } from "react";
import { message } from "antd";
import useQueryHook from "../useQueryHook";
import {
  acceptOrRejectRequests,
  getGuestDocksRequests,
  getSeasonsDocks,
} from "../../API/marina";
import { useParams } from "react-router-dom";
import { useLocationStore } from "../../store/store";
import { deleteParam } from "../../utils/deleteParam";
import useMutationHook from "../useMutationHook";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import useConstants from "./useConstants";
import { getSeasonDocksColumns } from "../../constants/seasonDockConstants";
const useGetDataForSeasonalDocks = ({ enabled }) => {
  const { isModalOpen, setIsModalOpen, setAddRenter, setEdit } =
    useLocationStore();

  const { params, setParams, paramsObject, t, location, userId } =
    useUserDataAndNavigation();
  const { page, search, pierColumn, priceColumn, renterColumn } =
    useConstants();
  const [requestModalLoading, setRequestModalLoading] = useState(false);
  const [requestModal, setRequestModal] = useState({
    active: false,
    type: null,
  });

  const viewDockDetails = (id) => {
    setParams({ ...paramsObject, id });
    setIsModalOpen(true);
  };
  const type = params.get("type") || "all";
  const { filter } = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [requestedMember, setRequestedMember] = useState(false);
  const onSelectChange = (newSelectedRowKeys) => {
    const lastSelectedKey = newSelectedRowKeys[newSelectedRowKeys.length - 1];
    const selectedRental = filterData.find(
      (rental) => rental?.id === lastSelectedKey
    );
    if (selectedRental && selectedRental.request_status === "request") {
      setRequestedMember(true);
    } else {
      setRequestedMember(false);
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleRequestModal = (bool, type) => {
    setRequestModal({
      active: bool,
      type,
    });
  };

  const { data, error, refetch, isLoading, isFetching } = useQueryHook(
    ["get-season-docks", page, filter, type, search],
    async () => {
      return await getSeasonsDocks(userId, page, filter, type, search);
    },
    enabled && location !== "/seasonaldocks/member-requests"
  );

  const {
    data: guestDocksRequest,
    isLoading: guestDocksRequestLoading,
    isFetching: guestDocksRequestFetching,
    error: guestDocksRequestError,
    refetch: guestDocksRefetch,
  } = useQueryHook(
    ["guest-dock-requests"],
    async () => {
      return await getGuestDocksRequests(userId);
    },
    location === "/seasonaldocks/member-requests"
  );
  let filterData = [];

  if (data?.members && location !== "/seasonaldocks/member-requests") {
    filterData = data.members.map((obj) => ({ key: obj.id, ...obj }));
  }

  if (guestDocksRequest && location === "/seasonaldocks/member-requests") {
    filterData = guestDocksRequest?.map((obj) => ({ key: obj.id, ...obj }));
  }
  const onSucces = (Data) => {
    guestDocksRefetch();
    setRequestModalLoading(false);
    message.success(Data.message);
    setSelectedRowKeys([]);
    handleRequestModal(false, null);
  };
  const onError = (error) => {
    const { data } = error.response;
    message.error(data.message);
    setRequestModalLoading(false);
  };
  const { mutate } = useMutationHook(
    ["accept-or-reject-requests"],
    async (Data) => {
      return await acceptOrRejectRequests(userId, Data);
    },
    onSucces,
    onError
  );
  const onClickAcceptOrReject = (type) => {
    setRequestModalLoading(true);
    mutate({
      requestIds: selectedRowKeys,
      requestStatus: type === "accept" ? "approved" : type,
    });
  };

  const columns = getSeasonDocksColumns(
    t,
    pierColumn,
    location,
    renterColumn,
    priceColumn,
    viewDockDetails
  );

  const memberRequests = location === "/seasonaldocks/member-requests";
  const handleClose = async () => {
    setIsModalOpen(false);
    setEdit(false);
    setAddRenter(false);
    setParams((prevParams) => {
      deleteParam(prevParams, "id");
    });
  };
  return {
    columns,
    data,
    isModalOpen,
    memberRequests,
    setIsModalOpen,
    location,
    filter,
    filterData,
    setEdit,
    isLoading,
    isFetching,
    handleClose,
    refetch,
    onSelectChange,
    selectedRowKeys,
    requestedMember,
    handleRequestModal,
    requestModal,
    onClickAcceptOrReject,
    requestModalLoading,
    guestDocksRequestLoading,
    params,
    setParams,
    error,
    guestDocksRequestFetching,
    guestDocksRequestError,
    paramsObject,
  };
};

export default useGetDataForSeasonalDocks;
