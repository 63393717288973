import { useNavigate, useParams } from "react-router-dom";
import {
  checkSubscription,
  getSubscriptionInfo,
  handleSignin,
} from "../../API/marina";
import { useAuth } from "../../context/authContext";
import useMutationHook from "../useMutationHook";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { message } from "antd";
import { useState } from "react";
const useGetDataForSignin = () => {
  const { loginAuth } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const auth = getAuth();
  const { t } = useTranslation();
  const { lang } = useParams();
  const [loading, setLoading] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [instruction, setInstructions] = useState(false);
  const onSuccess = async (Data) => {
    loginAuth(Data.data);
    navigate(`/${lang}/`);
  };
  const onError = (error) => {
    const { data } = error?.response;
    message.error(data.message);
  };
  const { mutate } = useMutationHook(
    ["sign-in"],
    async (Data) => {
      return await handleSignin(Data);
    },
    onSuccess,
    onError
  );

  const handleSignIn = async (formData) => {
    const { email, password } = formData;
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      if (res?.user) {
        mutate({
          email: email,
          userId: res.user.uid,
        });
      }
    } catch (err) {
      const currentValues = form.getFieldsValue();
      if (err.code === "auth/user-not-found") {
        form.setFields([
          {
            name: "password",
            value: currentValues.password,
            errors: [t("invalidcredentials")],
          },
          {
            name: "email",
            value: currentValues.email,
            errors: [""],
          },
        ]);
      } else if (err.code === "auth/wrong-password") {
        form.setFields([
          {
            name: "password",
            value: currentValues.password,
            errors: [t("invalidcredentials")],
          },
          {
            name: "email",
            value: currentValues.email,
            errors: [""],
          },
        ]);
      } else if (err.code === "auth/too-many-requests") {
        message.error(
          "Too many requests error Please try again after 30 seconds."
        );
      } else if (err.code === "auth/invalid-email") {
        form.setFields([
          {
            name: "password",
            value: currentValues.password,
            errors: [t("invalidcredentials")],
          },
          {
            name: "email",
            value: currentValues.email,
            errors: [""],
          },
        ]);
      } else {
        message.error("An error occurred. Please try again later.");
      }
    } finally {
      setTimeout(() => {}, 2000);
    }
  };

  const handleCancelForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
    setInstructions(false);
  };
  const onSubmit = ({ email }) => {
    setLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setInstructions(true);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };
  return {
    mutate,
    handleSignIn,
    form,
    handleCancelForgotPasswordModal,
    onSubmit,
    instruction,
    setInstructions,
    openForgotPasswordModal,
    setOpenForgotPasswordModal,
    loading,
  };
};

export default useGetDataForSignin;
