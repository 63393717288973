import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarChartComponent = ({ data }) => {
  function convertAverageToTotalRentals(data) {
    return data?.map((item) => {
      const newItem = { ...item };
      if ("average" in newItem) {
        newItem.total_rentals = newItem.average;
        const { average, ...rest } = newItem;
        return rest;
      }
      return newItem;
    });
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={convertAverageToTotalRentals(data)}
        barGap={0}
        barSize={20}
      >
        <CartesianGrid stroke="rgba(204, 204, 204, 0.39)" />
        <XAxis
          stroke="#A5C5CC"
          tickLine={{ display: "none", fontSize: "10px" }}
          dataKey={"period"}
        />
        <YAxis stroke="#D7EEF3" tickLine={{ display: "none" }} />
        <Tooltip
          formatter={(value, name, props) => [`Total Rentals: ${value}`]}
        />
        <Legend />
        <Bar dataKey="total_rentals" fill="#7BB9D3" radius={[30, 30, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
