import React from "react";

const CancelCircle = () => {
  return (
    <svg
      width="29"
      height="25"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.2422"
        cy="14.5"
        r="13.2422"
        stroke="#18374F"
        strokeWidth="2"
      />
      <path
        d="M10.0449 19.3494L18.4397 9.65273"
        stroke="#18374F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18.4395 19.3494L10.0447 9.65273"
        stroke="#18374F"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CancelCircle;
