import React from "react";

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="178"
      height="38"
      viewBox="0 0 198 38"
      fill="none"
    >
      <g clipPath="url(#clip0_2289_19343)">
        <path
          d="M1.96624 29.4118C2.01414 29.2403 2.20324 29.1527 2.36209 29.2327C3.56476 29.8409 5.26413 30.2904 7.00763 30.2904C9.48105 30.3958 11.5334 29.1235 11.5334 26.6857C11.5334 24.512 10.2702 23.3998 8.06028 21.2794C5.21749 18.5762 3.90261 15.7131 3.90261 12.6925C3.90261 5.58858 8.84945 0.818359 16.1651 0.818359C18.9448 0.818359 20.6404 1.31862 21.5443 1.8138C21.6628 1.87855 21.717 2.01822 21.678 2.14772L19.7882 8.34634C19.7378 8.51013 19.555 8.59393 19.4012 8.52156C18.4242 8.0594 17.3375 7.70896 15.9016 7.70896C13.4282 7.70896 11.638 9.19323 11.638 11.631C11.638 12.9566 12.0062 14.0168 15.0595 16.6666C18.1645 19.3698 19.323 22.497 19.323 25.1481C19.323 32.9402 14.0068 37.181 7.21816 37.181C3.55845 37.181 1.34599 36.4979 0.371499 36.0179C0.249214 35.9583 0.189963 35.8186 0.226523 35.6866L1.96498 29.4105L1.96624 29.4118Z"
          fill="white"
        />
        <path
          d="M34.7084 1.47446L32.153 14.9522C32.1202 15.1274 32.2539 15.2899 32.4304 15.2899H37.855C37.9911 15.2899 38.1071 15.1934 38.1323 15.0589L40.7293 1.3678C40.7545 1.23322 40.8718 1.13672 41.0066 1.13672H48.2202C48.3979 1.13672 48.5315 1.29924 48.4975 1.47446L41.816 36.6334C41.7908 36.768 41.6735 36.8645 41.5386 36.8645H34.3226C34.1461 36.8645 34.0125 36.7032 34.0453 36.528L36.554 22.7824C36.5855 22.6072 36.4531 22.4459 36.2766 22.4459H30.9554C30.8192 22.4459 30.7033 22.5424 30.678 22.677L28.0294 36.6334C28.0042 36.768 27.8869 36.8645 27.752 36.8645H20.5385C20.3608 36.8645 20.2271 36.702 20.2612 36.5268L26.9389 1.3678C26.9641 1.23322 27.0814 1.13672 27.2163 1.13672H34.431C34.6088 1.13672 34.7424 1.29924 34.7084 1.47446Z"
          fill="white"
        />
        <path
          d="M61.7724 1.47446L55.0909 36.6334C55.0657 36.768 54.9485 36.8645 54.8136 36.8645H47.6C47.4223 36.8645 47.2887 36.702 47.3227 36.5268L54.003 1.3678C54.0282 1.23322 54.1454 1.13672 54.2803 1.13672H61.4938C61.6716 1.13672 61.8052 1.29924 61.7712 1.47446H61.7724Z"
          fill="white"
        />
        <path
          d="M67.3759 1.86095C67.3986 1.74414 67.4881 1.65399 67.6041 1.63368C69.9439 1.22991 72.8976 0.978516 75.2021 0.978516C77.6226 0.978516 81.518 1.40259 83.9385 4.00038C85.5168 5.69668 86.2014 8.45317 86.2014 10.3082C86.2014 15.7145 83.5704 19.5845 81.2016 21.4929C78.6752 23.5066 75.0432 24.4614 72.0429 24.4614H70.9083C70.7734 24.4614 70.6561 24.5579 70.6309 24.6925L68.3504 36.6352C68.3252 36.7698 68.2079 36.8663 68.073 36.8663H61.0688C60.891 36.8663 60.7574 36.7038 60.7915 36.5285L67.3759 1.86095ZM72.0025 17.7092C71.9697 17.8844 72.1034 18.0469 72.2799 18.0469H72.8333C77.3591 18.0469 78.4117 13.5408 78.4117 10.8377C78.4117 8.7706 77.412 7.33839 75.3597 7.33839C74.8831 7.33839 74.4419 7.33839 74.1255 7.39806C74.012 7.41965 73.9263 7.51107 73.9049 7.62407L72.0025 17.7092Z"
          fill="white"
        />
        <path
          d="M126.709 16.5946C126.709 22.7107 124.509 28.0053 120.632 31.7978C116.962 35.3695 111.854 37.5 105.789 37.5C96.0978 37.5 89.5234 31.3052 89.5234 21.5311C89.5234 15.363 91.8721 9.96934 95.8041 6.18821C99.5117 2.63181 104.719 0.5 110.476 0.5C120.405 0.5 126.71 6.60593 126.71 16.5946H126.709ZM102.227 10.2563C99.5608 12.9417 98.0253 16.9768 98.0253 20.9737C98.0253 27.0009 100.951 30.9484 106.943 30.9484C109.66 30.9484 112.176 29.7727 114.109 27.8161C116.658 25.2488 118.205 21.2886 118.205 17.0656C118.205 11.8675 116.171 7.05159 109.565 7.05159C106.691 7.05159 104.177 8.29081 102.226 10.2563H102.227Z"
          fill="white"
        />
        <path
          d="M144.212 1.47446L141.657 14.9522C141.624 15.1274 141.758 15.2899 141.934 15.2899H147.359C147.495 15.2899 147.611 15.1934 147.636 15.0589L150.233 1.3678C150.258 1.23322 150.376 1.13672 150.511 1.13672H157.724C157.902 1.13672 158.035 1.29924 158.001 1.47446L151.32 36.6334C151.295 36.768 151.177 36.8645 151.043 36.8645H143.827C143.65 36.8645 143.516 36.7032 143.549 36.528L146.058 22.7824C146.089 22.6072 145.957 22.4459 145.781 22.4459H140.459C140.323 22.4459 140.207 22.5424 140.182 22.677L137.533 36.6334C137.508 36.768 137.391 36.8645 137.256 36.8645H130.042C129.865 36.8645 129.731 36.702 129.765 36.5268L136.443 1.3678C136.468 1.23322 136.585 1.13672 136.72 1.13672H143.935C144.113 1.13672 144.246 1.29924 144.212 1.47446Z"
          fill="white"
        />
        <path
          d="M182.803 12.0042C182.803 19.955 180.382 28.9673 175.645 33.7375C173.329 36.0699 170.645 37.2888 167.276 37.2888C159.171 37.2888 157.96 29.3901 157.96 25.468C157.96 17.9934 160.38 8.98237 165.329 4.15883C167.487 2.03845 170.171 0.712891 173.592 0.712891C181.697 0.712891 182.803 7.86886 182.803 12.0042ZM170.645 8.50624C167.644 11.5281 165.96 21.1752 165.96 26.4761C165.96 28.0137 166.224 30.4515 168.434 30.4515C169.012 30.4515 169.644 30.1328 170.171 29.6034C173.382 26.3695 174.802 14.9728 174.802 11.1561C174.802 9.77847 174.486 7.55143 172.592 7.55143C171.908 7.55143 171.276 7.87013 170.645 8.50624Z"
          fill="white"
        />
        <path
          d="M197.212 1.47446L190.53 36.6334C190.505 36.768 190.388 36.8645 190.253 36.8645H183.039C182.862 36.8645 182.728 36.702 182.762 36.5268L189.442 1.3678C189.468 1.23322 189.585 1.13672 189.72 1.13672H196.933C197.111 1.13672 197.245 1.29924 197.211 1.47446H197.212Z"
          fill="white"
        />
        <path
          d="M128.398 7.52946L129.401 1.6673C129.426 1.51748 129.383 1.37147 129.297 1.26481C129.209 1.15943 129.073 1.09087 128.923 1.0896L123.018 1.02738C122.644 1.0223 122.543 1.54414 122.891 1.68254L126.661 3.48042L127.738 7.53961C127.81 7.90909 128.338 7.90275 128.4 7.53073L128.398 7.52946Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2289_19343">
          <rect
            width="197"
            height="37"
            fill="white"
            transform="translate(0.216797 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
