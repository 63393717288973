import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ButtonDark from "../CommonComponents/ButtonDark";

const BookingBanner = ({ item }) => {
  const { t } = useTranslation();
  const buttonText =
    item?.payment_status === "paid" ? (
      <div className="flex flex-col text-start">
        {t("TenantPaid")}{" "}
        <div>
          {dayjs(item.payment_date * 1000).format("DD. MM")} {t("at")}{" "}
          {dayjs(item.payment_date * 1000).format("HH:mm")}
        </div>
      </div>
    ) : (
      `${t("Mustbepaidby")}
            ${dayjs(item.payment_deadline_date * 1000).format("DD. MM")}
            ${t("at")} ${dayjs(item.payment_deadline_date * 1000).format(
        "HH:mm"
      )}`
    );
  return (
    <div>
      <div className=" py-3 mx-10  flex justify-between">
        <div className="">
          {t("Payment Status")}:
          <h1 className="text-[18px] font-roobert-m mt-1">
            {t(item?.payment_status)}
          </h1>
        </div>
        <div className="w-[30%] flex flex-col items-end">
          <ButtonDark
            styleClasses={`${
              item?.payment_status === "paid"
                ? "!bg-transparent text-kprimary border border-kprimary"
                : "bg-dark-blue text-[#FFF]"
            } !text-[14px] h-[60px] !p-2 !rounded-[15px]`}
            text={buttonText}
          />
          {item?.payment_status !== "paid" && (
            <p className="mt-2">*{t("handOverKey")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingBanner;
