import React from "react";

const Bell = () => {
  return (
    <svg
      className="h-[27px] lg:h-[30px] 2xl:h-[35px]"
      width="32"
      viewBox="0 0 32 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 8.42858C32 11.8291 29.2089 14.5952 25.7778 14.5952C22.3467 14.5952 19.5556 11.8291 19.5556 8.42858C19.5556 5.0281 22.3467 2.26191 25.7778 2.26191C29.2089 2.26191 32 5.0281 32 8.42858ZM28.4444 17.7491C27.5556 17.9781 26.6667 18.1191 25.7778 18.1191C23.186 18.1144 20.7017 17.0919 18.869 15.2756C17.0364 13.4593 16.0047 10.9972 16 8.42858C16 5.83858 17.0311 3.49525 18.6667 1.75096C18.344 1.35901 17.9372 1.04333 17.4759 0.82692C17.0146 0.610512 16.5104 0.498829 16 0.500009C14.0444 0.500009 12.4444 2.08572 12.4444 4.02382V4.53477C7.16444 6.08525 3.55556 10.8952 3.55556 16.3571V26.9286L0 30.4524V32.2143H32V30.4524L28.4444 26.9286V17.7491ZM16 37.5C17.9733 37.5 19.5556 35.9319 19.5556 33.9762H12.4444C12.4444 34.9108 12.819 35.8071 13.4858 36.4679C14.1526 37.1287 15.057 37.5 16 37.5Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default Bell;
