import {
  Timestamp,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import axiosInstance from "./axiosConfig";
import { db } from "../config/firebaseConfig";
import { v4 as uuidv4 } from "uuid";
export const getChats = (query, setLoading, setChats) => {
  return onSnapshot(
    query,
    (snapshot) => {
      const chats = [];
      snapshot.forEach((doc) => {
        const chatData = doc.data();
        chats.push(chatData);
      });
      setChats(chats);
      setLoading(false);
    },
    (error) => {
      console.log(error);
      setLoading(false);
    }
  );
};

export const getFcmToken = async (userId, Data) => {
  try {
    const { data } = await axiosInstance.get(
      `${userId}/receiverNotificationData?firebase_id=${Data.receiverId}&asset_id=${Data.assetId}&owner_firebase_id=${Data.ownerId}&tenant_firebase_id=${Data.tenantId}&asset_type=${Data.assetType}&owner_flow=${Data.ownerFlow}`
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getNestedCollection = (
  chatId,
  parentCollection,
  childCollection
) => {
  return collection(db, parentCollection, chatId, childCollection);
};

export const getChat = async (chatId, setChat) => {
  if (chatId) {
    const chatRef = doc(db, "chats", chatId);
    const getChat = await getDoc(chatRef);
    if (getChat.exists()) {
      setChat(getChat.data());
    }
  }
};

export const getMessages = (query, setMessages, setLoading) => {
  const unsubscribe = onSnapshot(query, (querySnapshot) => {
    const messagesData = [];
    querySnapshot.forEach((doc) => {
      const messageData = {
        id: doc.id,
        createdAt: doc.data().createdAt.seconds,
        ...doc.data(),
      };
      messagesData.push(messageData);
    });
    setMessages(messagesData);
    setLoading(false);
  });

  return unsubscribe;
};

export const sendMessage = async (
  currentUser,
  chatId,
  message,
  senderId,
  isExisting,
  setIsChatExisted,
  chatInfo,
  setNotExistedChat,
  reset,
  metaData
) => {
  try {
    if (!chatId || !currentUser) return;
    const batch = writeBatch(db);
    if (!isExisting) {
      const docRef = doc(db, "chats", chatId);
      setNotExistedChat?.([]);
      setIsChatExisted(true);
      console.log(chatInfo, "++++");
      await setDoc(docRef, {
        ...chatInfo,
        asset_id: chatInfo?.asset_id?.toString(),
        tenant_pic: chatInfo?.tenant_pic ?? "",
        owner_pic: chatInfo?.owner_pic ?? "",
        is_read: false,
        last_message_sender: senderId,
        last_message_timestamp: Timestamp.now(),
      });
    }
    const messageRef = collection(db, "chats", chatId, "messages");
    const receiverFcm = localStorage.getItem("receiverFcm");
    const messageData = {
      text: message,
      id: uuidv4(),
      author: {
        id: currentUser.uid,
        firstName: localStorage.getItem("username") ?? "",
        imageUrl: localStorage.getItem("userProfile") || "",
      },
      receiverFcmToken: receiverFcm === null ? "" : receiverFcm,
      createdAt: Timestamp.now(),
      type: "text",
    };
    if (metaData) {
      messageData.metadata = metaData;
    }
    batch.set(doc(messageRef), messageData);
    if (reset) {
      reset();
    }
    if (isExisting) {
      const chatRef = doc(db, "chats", chatId);
      batch.update(chatRef, {
        is_read: false,
        last_message_sender: senderId,
        last_message_timestamp: Timestamp.now(),
      });
    }
    await batch.commit();
  } catch (error) {
    console.error("Error adding message:", error);
  }
};

export const contactOwnerOrTenant = async (
  tenantId,
  ownerId,
  propertyId,
  tenantName,
  tenantProfileImage,
  assetName,
  ownerName,
  ownerProfileImage,
  assetType,
  asset_pic,
  setIsChatExisted,
  setNotExistedChat,
  fcmToken,
  navigate,
  hideNames,
  lang
) => {
  if (ownerId) {
    try {
      const combinedId = propertyId + "_" + ownerId + "_" + tenantId;
      localStorage.setItem("receiverFcm", fcmToken);
      const docRef = doc(db, "chats", combinedId);
      const res = await getDoc(docRef);
      if (!res.exists()) {
        setIsChatExisted(false);
        setNotExistedChat([
          {
            tenant_id: tenantId,
            tenant_name: tenantName,
            tenant_pic: tenantProfileImage,
            is_read: true,
            asset_name: assetName,
            owner_id: ownerId,
            owner_name: ownerName,
            owner_pic: ownerProfileImage,
            asset_id: propertyId,
            asset_type: assetType,
            asset_pic,
            is_disable: false,
            hide_names: hideNames,
          },
        ]);
        navigate(`/${lang}/messages/${combinedId}`);
      } else {
        setNotExistedChat([]);
        navigate(`/${lang}/messages/${combinedId}`);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export const onClickChatRow = async (
  currentUser,
  lastSenderId,
  unreadCount,
  id
) => {
  if (currentUser && currentUser.uid !== lastSenderId && !unreadCount) {
    try {
      await updateDoc(doc(db, "chats", id), {
        is_read: true,
      });
    } catch (error) {
      console.log(error);
    }
  }
};
