import marineImage from "../assets/images/marine.png";

const FormSideImage = ({ TextComponent }) => {
  return (
    <div>
      <div
        className=" w-full  flex justify-center 2xl:pt-[150px] pt-[70px]  h-screen  "
        style={{
          backgroundImage: `url(${marineImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        {TextComponent}
      </div>
    </div>
  );
};

export default FormSideImage;
