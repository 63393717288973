import React from "react";

const Step3Icon = () => {
  return (
    <svg
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="22.5" r="22.5" fill="#ACDCE7" />
      <path
        d="M23.1542 28.628C20.8982 28.628 19.1382 27.172 19.1382 24.98H20.4822C20.4822 26.436 21.6342 27.396 23.1542 27.396C24.7062 27.396 25.6982 26.644 25.6982 25.316C25.6982 24.004 24.7062 23.3 23.1542 23.3H22.2582V22.1H23.1382C24.4662 22.1 25.2662 21.284 25.2662 20.164C25.2662 19.06 24.4022 18.404 23.0582 18.404C21.7782 18.404 20.9462 19.156 20.8662 20.484H19.5382C19.6182 18.452 21.0742 17.172 23.0742 17.172C25.1542 17.172 26.6582 18.404 26.6582 20.116C26.6582 21.252 25.9382 22.212 25.0422 22.612C26.2102 22.996 27.1062 23.988 27.1062 25.332C27.1062 27.476 25.2502 28.628 23.1542 28.628Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default Step3Icon;
