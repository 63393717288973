import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonDark from "./ButtonDark";
import SelectDropdown from "./Select";
import { useTranslation } from "react-i18next";

const PageHeaders = ({
  heading,
  exportData,
  importData,
  sendInvoice,
  dropDown,
  dropDownProps,
  exportUrl,
  importUrl,
}) => {
  const { t } = useTranslation();
  const naviagte = useNavigate();
  const { options, styleClasses, defaultValue, handleChange } =
    dropDownProps ?? "";
  return (
    <div className="mt-[20px] flex justify-between items-center">
      <h1 className="text-[35px] text-dark-blue max-2xl:text-[28px] font-roobert-rg">
        {heading}
      </h1>
      <div className="flex gap-x-[20px]">
        {dropDown && (
          <SelectDropdown
            options={options}
            stylesClasses={`${styleClasses} !w-[300px]`}
            defaultValue={defaultValue}
            handleChange={handleChange}
          />
        )}
        {sendInvoice && (
          <ButtonDark
            text={t("sendInvoice")}
            styleClasses={"dark-Btn  px-[21px]  h-[40px]"}
          />
        )}
        {importData && (
          <ButtonDark
            text={t("import")}
            styleClasses={"dark-Btn px-[21px] h-[40px]"}
            handleClick={() => {
              naviagte(importUrl);
            }}
          />
        )}
        {exportData && (
          <ButtonDark
            text={t("export")}
            styleClasses={"dark-Btn ]  px-[21px] h-[40px]"}
            handleClick={() => {
              naviagte(exportUrl);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PageHeaders;
