export const getRangeForOccupancy = (assetType) => {
  return assetType === "boat"
    ? 100
    : assetType === "jet_ski"
    ? 5
    : assetType === "kayak"
    ? 10
    : 10;
};

export const getRangeForHorsePower = (assetType) => {
  return assetType === "boat" ? 100000 : assetType === "jet_ski" ? 1500 : 10;
};
export const getRangeForSizeInFeet = (assetType) => {
  return assetType === "boat"
    ? 1500
    : assetType === "jet_ski"
    ? 50
    : assetType === "kayak"
    ? 100
    : assetType === "boat_trailer"
    ? 150
    : 10;
};
