import React from "react";
import Search from "../../assets/icons/Search";

const SearchBar = ({
  placeholder,
  handleSearchChange,
  value,
  handleSubmit,
  width,
}) => {
  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <div
      className={` ${
        width ? width : "w-[265px]"
      } border  h-[40px]   flex items-center pl-[8px] 2xl:pl-[12px] pr-[10px] gap-x-[5px] 2xl:gap-x-[10px] rounded-full border-[#385E62] bg-[#FFFFFF] `}
    >
      <div className="max-2xl:scale-75 origin-left">
        <Search />
      </div>
      <input
        onKeyUp={(event) => onKeyUp(event)}
        type="text"
        className="  w-full  leading-[15px] font-roobert-rg text-[16px] 2xl:text-[18px] text-[#9F9D9D]   outline-none placeholder:text-[22]   placeholder:text-[#9F9D9D]"
        placeholder={placeholder}
        value={value ?? ""}
        onChange={(e) => handleSearchChange(e)}
      />
    </div>
  );
};

export default SearchBar;
