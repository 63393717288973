import React from "react";

const BoatIcon = () => {
  return (
    <svg
      width="23"
      height="10"
      viewBox="0 0 23 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54183 9.82716C6.05046 9.82716 1.51262 8.76741 0.266932 4.81741C0.0889776 4.23936 0 3.66132 0 2.98693V2.60156L22.3333 5.29912L21.0876 9.82716H8.54183ZM0.71182 3.37229C0.71182 3.75766 0.800796 4.14302 0.978751 4.52839C2.04648 8.09302 6.40637 9.05643 8.63081 9.05643H20.6428L21.5325 5.87717L0.71182 3.37229Z"
        fill="#18374F"
      />
      <path
        d="M8.54183 9.82716C6.05046 9.82716 1.51262 8.76741 0.266932 4.81741C0.0889776 4.23936 0 3.66132 0 2.98693V2.60156L22.3333 5.29912L21.0876 9.82716H8.54183ZM0.71182 3.37229C0.71182 3.75766 0.800796 4.14302 0.978751 4.52839C2.04648 8.09302 6.40637 9.05643 8.63081 9.05643H20.6428L21.5325 5.87717L0.71182 3.37229Z"
        fill="#18374F"
      />
      <path
        d="M8.5427 9.73037C6.05133 9.73037 1.51348 8.67062 0.267799 4.72062L0.0898438 4.14258L21.8003 6.84013L21.0885 9.73037H8.5427ZM1.15757 5.00965C2.49223 8.18891 6.49622 9.05598 8.5427 9.05598H20.5546L20.9995 7.51452L1.15757 5.00965Z"
        fill="#18374F"
      />
      <path
        d="M16.3713 4.62438H15.7485V2.02317L16.1933 1.44512L8.63026 0.770732L6.4948 3.94999L5.96094 3.46829L8.27435 0L17.528 0.867071L16.3713 2.21585V4.62438Z"
        fill="#18374F"
      />
      <path
        d="M11.9053 0.83307L11.4092 4.06445L12.1114 4.19085L12.6075 0.959469L11.9053 0.83307Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default BoatIcon;
