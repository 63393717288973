import React from "react";
import SelectDropdown from "../CommonComponents/Select";
import { useSearchParams } from "react-router-dom";

const DashboardCardHeader = ({ item }) => {
  const { options, option, defaultValue, handleFilter } = item;
  const available = option !== "disabled";
  const [params] = useSearchParams();
  const assetType = params.get("asset");
  return (
    <div className="flex items-center justify-between">
      <h1 className="text-dark-blue pl-[10px] leading-[50px] font-roobert-rg font-light text-[28px] 2xl:text-[35px]">
        {item.heading}
      </h1>
      {available && (
        <SelectDropdown
          options={options}
          defaultValue={defaultValue}
          handleChange={handleFilter}
          key={assetType}
        />
      )}
    </div>
  );
};

export default DashboardCardHeader;
