import CustomCarousel from "../CommonComponents/CustomCarousel";
import BookingBanner from "./BookingBanner";

const ChatBookingsHeader = ({ bookings }) => {
  return (
    <div className="w-full bg-[#E2F3F7] px-8">
      <CustomCarousel
        children={bookings?.map((item) => (
          <BookingBanner item={item} />
        ))}
      />
    </div>
  );
};

export default ChatBookingsHeader;
