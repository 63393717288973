import React from "react";
import AntdTable from "../CommonComponents/Table/Table";
import ButtonDark from "../CommonComponents/ButtonDark";

const UserAccounts = ({
  userAccountsColumns,
  users,
  isLoading,
  isFetching,
  handleAddUserModal,
  onSelectChange,
  selectedRowKeys,
  setType,
  t,
  usersError,
}) => {
  return (
    <div className="bg-[#FFFFFF] rounded-[20px] mt-[35px] pt-[24px] px-[40px] 2xl:mt-[53px]">
      <div className="flex justify-between items-center">
        <h1 className="text-[28px] font-roobert-rg 2xl:text-[35px] text-dark-blue leading-[50px]">
          {t("userAccounts")}
        </h1>
        <div className="flex gap-x-[25px]">
          <ButtonDark
            styleClasses={"light-Btn h-[40px]"}
            text={t("addNewUser")}
            handleClick={() => {
              setType("");
              handleAddUserModal(true);
            }}
          />
          <ButtonDark
            styleClasses={"light-Btn h-[40px]"}
            text={t("deleteUser")}
            handleClick={() => {
              handleAddUserModal(true);
              setType("delete");
            }}
          />
        </div>
      </div>
      <div className="my-table">
        <AntdTable
          selection={true}
          data={users}
          columns={userAccountsColumns}
          loading={isLoading || isFetching}
          selectedRowKeys={selectedRowKeys}
          onSelectChange={onSelectChange}
          error={usersError}
        />
      </div>
    </div>
  );
};

export default UserAccounts;
