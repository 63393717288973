import React from "react";
import Navbar from "../components/CommonComponents/Navbar";
import RoundedCheckbox from "../components/CommonComponents/RoundedCheckbox";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import CopyAndPaste from "../components/CommonComponents/Imports/CopyAndPaste";
import { useTranslation } from "react-i18next";
import useGetDataForImports from "../hooks/getData/useGetDataForImports";

const AddItems = () => {
  const { t } = useTranslation();
  const {
    activeCopyAndPaste,
    param,
    importType,
    downloadCSVDemo,
    changeImportType,
    handleFileChange,
    handleImportType,
    selectedFile,
    handleProceed,
  } = useGetDataForImports({ onDeleteRow: "" });

  return (
    <div className="h-screen overflow-y-auto">
      <Navbar backToDashboard={false} />
      {!activeCopyAndPaste && (
        <div className="  h-[89vh] font-roobert-rg  flex flex-col items-center  justify-center ">
          <div className="">
            <h1 className="2xl:text-[35px] text-left text-[28px] leading-[50px]">
              {param === "season-dock"
                ? t("howWouldYouLikeToAddANewSeasonalDock")
                : t("howWouldYouLikeToAddANewMember")}
            </h1>
            <div className="grid grid-cols-2 mt-[30px] 2xl:mt-[60px] !gap-x-[60px]">
              <div
                className={`${
                  importType === "importWithCsv"
                    ? "bg-[#D7EEF3]"
                    : "bg-[#FFFFFF]"
                } h-[200px] w-[110%] relative flex flex-col justify-center items-center pl-[32px] pr-[18px] rounded-[20px] cursor-pointer`}
                onClick={() => handleImportType("importWithCsv")}
              >
                <div className="absolute top-2 right-2 ">
                  <RoundedCheckbox
                    onChange={changeImportType}
                    value={"importWithCsv"}
                    checked={importType === "importWithCsv" ? true : false}
                  />
                </div>
                <h1 className="2xl:text-[35px]  text-[28px] text-center">
                  {t("importWithCsv")}
                </h1>
                {importType === "importWithCsv" && (
                  <div>
                    <input
                      type="file"
                      id="csvFile"
                      name="csvFile"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <label htmlFor="csvFile" className="cursor-pointer">
                      {selectedFile
                        ? `Selected file: ${selectedFile.name}`
                        : t("chooseCSV")}
                    </label>
                  </div>
                )}
              </div>
              <div
                className={` ${
                  importType === "copyAndPaste"
                    ? "bg-[#D7EEF3]"
                    : "bg-[#FFFFFF]"
                } h-[200px] relative flex  w-[110%]  justify-center items-center pl-[32px] pr-[18px] rounded-[20px] cursor-pointer`}
                onClick={() => handleImportType("copyAndPaste")}
              >
                <div className="absolute top-2 right-2 ">
                  <RoundedCheckbox
                    onChange={changeImportType}
                    value={"copyAndPaste"}
                    checked={importType === "copyAndPaste" ?? false}
                  />
                </div>
                <h1 className="2xl:text-[35px]  text-[28px] text-center">
                  {t("copy&paste")}
                </h1>
              </div>
            </div>
            <div className="2xl:mt-[40px] mt-[20px]">
              <ButtonDark
                text={t("downloadStandard")}
                styleClasses={"light-Btn h-[40px]"}
                handleClick={downloadCSVDemo}
              />
            </div>
            <div className="2xl:mt-[50px] mt-[30px]">
              <ButtonDark
                handleClick={handleProceed}
                text={t("proceed")}
                styleClasses={"dark-Btn h-[50px] !w-[160px]"}
              />
            </div>
          </div>
        </div>
      )}

      {activeCopyAndPaste && <CopyAndPaste t={t} />}
    </div>
  );
};

export default AddItems;
