import { Button } from "antd";
import React from "react";
import Spinner from "../CommonComponents/Spinner";
const ButtonDark = ({
  styleClasses,
  text,
  handleClick,
  type,
  loading,
  disabled,
}) => {
  return (
    <Button
      shape="round"
      type={type ? type : "button"}
      htmlType={type}
      onClick={handleClick}
      disabled={disabled}
      className={`${styleClasses} ${
        disabled ? " cursor-not-allowed" : ""
      } font-roobert-rg flex gap-x-[10px] items-center justify-center  3xl:text-[22px] text-[20px]`}
    >
      {text} {loading ? <Spinner /> : ""}
    </Button>
  );
};

export default ButtonDark;
