import { useTranslation } from "react-i18next";
// import usePropertyDetails from '@/hooks/usePropertyDetails';
import { getDisabledDates } from "../../utils/getDisabledDates";
import useAssetDetails from "../../hooks/getData/useAssetDetails";
import Calendar from "./Calendar";
import Spinner from "../CommonComponents/Spinner";

const ProposedDatesModal = ({
  dateRange,
  setDateRange,
  sendProposedDates,
  propertyId,
  propertyType,
  messageLoading,
}) => {
  const { t } = useTranslation();

  const { data, isLoading, isFetching, error } = useAssetDetails({
    enabled: true,
    id: propertyId,
    assetType: propertyType,
  });

  const rentalDates = getDisabledDates(data?.bookedProperty);
  if (isLoading || isFetching) {
    return (
      <div className="max-h-[600px] pb-4  overflow-auto flex jc items-center">
        <Spinner />
      </div>
    );
  }
  if (error) {
    return (
      <div className="max-h-[600px] pb-4  overflow-auto flex jc items-center">
        {error}
      </div>
    );
  }
  return (
    <div className="max-h-[600px] pb-4  overflow-auto">
      <Calendar
        btnText={t("continue")}
        dateRange={dateRange}
        setDateRange={setDateRange}
        startTime={dateRange.startDate}
        endTime={dateRange.endDate}
        disableDates={[
          ...rentalDates,
          ...(data?.property?.unavailable_dates ?? []),
        ]}
        handleContinue={() => sendProposedDates()}
        isLoading={messageLoading}
      />
    </div>
  );
};

export default ProposedDatesModal;
