import { useNavigate, useParams } from "react-router-dom";
import { useRef, useState } from "react";
import dayjs from "dayjs";
import { convertToTimeStamps, getMonths } from "../utils/dateUtils";
import { useTranslation } from "react-i18next";
import useChatsStore from "../store/chatStore";
import { getFcmToken, sendMessage } from "../API/chats";
import useUserInfo from "./useUserInfo";
import { useQuery } from "react-query";

const useGetDataForChats = () => {
  const { currentUser } = useUserInfo();
  const { id: chatId, lang } = useParams() || { lang: "no" };
  const [loading, setLoading] = useState(false);
  const [chat, setChat] = useState();
  const [messages, setMessages] = useState([]);
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().toDate(),
    endDate: dayjs().toDate(),
  });
  const [openProposedDates, setOpenProposedDates] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const navigate = useNavigate();
  const { isChatExisted, notExistedChat, setIsChatExisted, setNotExistedChat } =
    useChatsStore();
  const sectionRef = useRef(null);
  const userId = localStorage.getItem("userId");
  const assetId = chatId?.split("_")[0] ?? "";
  const ownerId = chatId?.split("_")[1] ?? "";
  const tenantId = chatId?.split("_")[2] ?? "";
  const { data, isLoading } = useQuery({
    queryKey: ["get-fcm-token", assetId],
    queryFn: () => {
      const receiverId = currentUser?.uid === ownerId ? tenantId : ownerId;
      return getFcmToken(userId, {
        assetId: Number(assetId),
        ownerId,
        tenantId: tenantId,
        ownerFlow: currentUser?.uid === ownerId,
        receiverId: receiverId,
        assetType: chat?.asset_type,
      });
    },
    enabled: chat?.asset_type ? true : false,
  });

  const isNamesHidden = data?.chatMetaData?.hide_names;
  const isOwnerChat = chat?.owner_id === currentUser?.uid;
  const userName = isOwnerChat
    ? isNamesHidden
      ? t("Tenant")
      : chat?.tenant_name
    : isNamesHidden
    ? t("Owner")
    : chat?.owner_name;

  const handleViewRental = () => {
    const url = `/${lang}/rentals/all-rentals`;
    navigate(url);
  };
  const isBookingUpcomingOrOngoing =
    data?.chatMetaData?.booking_state === "upcoming" ||
    data?.chatMetaData?.booking_state === "ongoing";
  const handleCloseProposedDatesModal = () => {
    setOpenProposedDates(false);
  };

  const sendProposedDates = async () => {
    setMessageLoading(true);
    const metadata = {
      suggestedFromDate: convertToTimeStamps(dateRange.startDate),
      suggestedTillDate: convertToTimeStamps(dateRange.endDate),
    };
    const message = `Proposal for rental period ${dateRange.startDate.getDate()}. ${getMonths(
      dateRange.startDate.getMonth(),
      "long"
    )} ${
      dateRange.startDate.getHours() === 0
        ? "00"
        : dateRange.startDate.getHours()
    }:${
      dateRange.startDate.getMinutes() === 0
        ? "00"
        : dateRange.startDate.getMinutes()
    }
        - ${dateRange.endDate.getDate()}. ${getMonths(
      dateRange.endDate.getMonth(),
      "long"
    )} ${
      dateRange.endDate.getHours() === 0 ? "00" : dateRange.endDate.getHours()
    }:${
      dateRange.endDate.getMinutes() === 0
        ? "00"
        : dateRange.endDate.getMinutes()
    }`;

    return await sendMessage(
      currentUser,
      chatId,
      message,
      currentUser ? currentUser.uid : null,
      isChatExisted,
      setIsChatExisted,
      notExistedChat[0],
      setNotExistedChat,
      () => {
        setOpenProposedDates(false);
        setMessageLoading(false);
        setDateRange({
          startDate: dayjs().toDate(),
          endDate: dayjs().toDate(),
        });
      },
      metadata
    );
  };

  const handleBookNowAndProposedDates = (isOwnerChat) => {
    if (isOwnerChat) {
      setOpenProposedDates(true);
    } else {
      navigate(
        `/${lang}/book-asset/${chat?.asset_id}?assetType=${chat?.asset_type}`
      );
    }
  };
  return {
    data,
    isLoading,
    loading,
    messageLoading,
    setLoading,
    isChatExisted,
    notExistedChat,
    setIsChatExisted,
    setNotExistedChat,
    messages,
    setMessages,
    chatId,
    chat,
    setChat,
    currentUser,
    sectionRef,
    dateRange,
    setDateRange,
    isNamesHidden,
    handleViewRental,
    isBookingUpcomingOrOngoing,
    isOwnerChat,
    openProposedDates,
    handleCloseProposedDatesModal,
    sendProposedDates,
    handleBookNowAndProposedDates,
    userName,
  };
};

export default useGetDataForChats;
