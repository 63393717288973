import React from "react";
import ButtonDark from "../CommonComponents/ButtonDark";
import useGetDataForSignup from "../../hooks/getData/useGetDataForSignup";
import { useSignupStore } from "../../store/store";

const ThirdStep = ({ activeProgress }) => {
  const { setParams, paramsObject, t } = useGetDataForSignup();
  const { subscriptionData } = useSignupStore();
  const { monthly_price, yearly_price } = subscriptionData;
  const planAvailable =
    yearly_price && monthly_price ? "" : t("noPlanAvailable");
  return (
    <div>
      <div className="bg-[#D7EEF3] mt-[16px]  py-[10px] px-[20px] rounded-[30px] border-[#6DB9D0] max-w-[350px] border-[4px]">
        <h1 className="text-center 2xl:text-[35px] text-[#18374F] text-[28px] leading-[50px] font-roobert-rg ">
          {t("pricing")}
        </h1>
        {activeProgress === "marina" && (
          <p className="text-[22px] text-center font-roobert-rg 2xl:text-[18px]">
            {monthly_price} NOK {t("perMonth")}
          </p>
        )}
        {activeProgress === "rental" && (
          <p className="text-[22px] text-center font-roobert-rg 2xl:text-[18px]">
            {yearly_price} NOK {t("perYear")}
          </p>
        )}
        <h3 className="text-center mt-[5px] text-[17px] text-[#18374F] leading-[35px] font-roobert-l">
          *{t("billedAnnually")}
        </h3>
        <p className="text-center">{planAvailable} </p>
        <p className="text-center">{t("feeForShortTerm")}</p>
      </div>
      <p className="mt-[20px] text-[18px] text-dark-blue font-roobert-rg">
        {t("get7daytrial")}.
      </p>
      <p className=" text-[18px] text-dark-BLUE font-roobert-rg">
        {t("willNotBeBilled")}.
      </p>
      <ButtonDark
        styleClasses={
          "bg-dark-blue text-[#FFFFFF] h-[50px] mt-[20px] 2xl:mt-[30px] 2xl:h-[60px] w-[150px] px-[50px] py-[10px] !rounded-[50px]"
        }
        text={t("next")}
        handleClick={() => {
          setParams({ ...paramsObject, type: "password-reset" });
        }}
      />
    </div>
  );
};

export default ThirdStep;
