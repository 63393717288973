import React from "react";
import BackToPosition from "../../assets/images/BackToPosition.png";

const BackAndChangeMapType = ({ backToStart }) => {
  return (
    <div className=" h-[40px]  gap-y-[5px] bg-dark-blue flex flex-col items-center justify-center  text-white w-[40px] rounded-full">
      <img
        height={30}
        width={30}
        className="hover:scale-[1.2] cursor-pointer"
        src={BackToPosition}
        onClick={backToStart}
        alt="back-to-position"
      />
    </div>
  );
};

export default BackAndChangeMapType;
