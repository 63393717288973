import React from "react";
import Cancel from "../../assets/icons/Cancel";
import ButtonDark from "../CommonComponents/ButtonDark";
import { useTranslation } from "react-i18next";
const SDSDetailsHeader = ({
  handleClose,
  handleEdit,
  edit,
  showEdit,
  onDone,
  onDeleteUser,
  addRenter,
  newInvoice,
  isLoading,
  isFetching,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-end items-center">
      {!edit && (
        <>
          {" "}
          {newInvoice && (
            <ButtonDark
              styleClasses={
                "light-Btn mr-[20px] h-[40px] 2xl:h-[50px]  w-[150px] px-[46px]"
              }
              text={t("newInvoice")}
            />
          )}
          {showEdit && (
            <ButtonDark
              styleClasses={
                "light-Btn h-[40px] 2xl:h-[50px] w-[100px] mr-[30px] px-[46px]"
              }
              text={t("edit")}
              handleClick={handleEdit}
            />
          )}
        </>
      )}
      {edit && (
        <>
          {" "}
          <ButtonDark
            styleClasses={
              "light-Btn mr-[20px] h-[40px] 2xl:h-[50px] w-[100px]   px-[46px]"
            }
            text={t("save")}
            handleClick={onDone}
            loading={isLoading || isFetching}
            type={"submit"}
          />
          {!addRenter && (
            <ButtonDark
              styleClasses={
                "light-Btn h-[40px] 2xl:h-[50px] w-[150px]   mr-[30px] px-[46px]"
              }
              text={t("deleteUser")}
              handleClick={onDeleteUser}
            />
          )}
        </>
      )}
      <Cancel handleClose={handleClose} />
    </div>
  );
};

export default SDSDetailsHeader;
