import React from "react";
import { Modal } from "antd";
const Dialog = ({
  isModalOpen,
  component,
  styles,
  onCancelModal,
  width,
  background,
}) => {
  return (
    <Modal
      open={isModalOpen}
      onCancel={onCancelModal}
      footer={null}
      styles={{
        content: {
          background,
        },
      }}
      className={`${styles}`}
      width={width ?? 1000}
      centered={true}
    >
      {component}
    </Modal>
  );
};

export default Dialog;
