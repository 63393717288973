import { formatTimeAgo } from "../../utils/dateUtils";
import { Avatar } from "antd";
import ProfileAvatar from "../../assets/images/ProfileAvatar.jpg";

function ChatBubble({ text, isMe, time, userImage, metadata, isOwnerChat }) {
  return (
    <div
      className={`flex gap-2 font-roobert-rg md:gap-4 ${
        isMe ? "self-end" : "self-start"
      }`}
    >
      {!metadata && (
        <>
          {!isMe && (
            <Avatar
              src={userImage ? userImage : ProfileAvatar}
              className="self-end"
            />
          )}
          <div
            className={`${
              isMe ? "bg-lightBlue" : "bg-white"
            } px-3 lg:px-4 pt-3 lg:pt-4 pb-2 overflow-hidden !h-full flex flex-col rounded-2xl max-w-[250px] sm:max-w-xs lg:max-w-md 2xl:max-w-lg`}
          >
            <div className="text-kprimary overflow-hidden !h-full w-full text-sm xl:text-base ">
              {text}
            </div>
            <p className="ml-auto text-[10px] text-[#A3A3A3]">
              {formatTimeAgo(time)}
            </p>
          </div>
          {isMe && (
            <Avatar
              src={userImage ? userImage : ProfileAvatar}
              className="self-end"
            />
          )}
        </>
      )}
      {metadata && isOwnerChat && (
        <>
          {!isMe && (
            <Avatar
              src={userImage ? userImage : ProfileAvatar}
              className="self-end"
            />
          )}
          <div
            className={`${
              isMe ? "bg-lightBlue" : "bg-white"
            } px-3 lg:px-4 pt-3 lg:pt-4 pb-2 overflow-hidden !h-full flex flex-col rounded-2xl max-w-[250px] sm:max-w-xs lg:max-w-md 2xl:max-w-lg`}
          >
            <div className="text-kprimary overflow-hidden !h-full w-full text-sm xl:text-base ">
              {text}
            </div>
            <p className="ml-auto text-[10px] text-[#A3A3A3]">
              {formatTimeAgo(time)}
            </p>
          </div>
          {isMe && (
            <Avatar
              src={userImage ? userImage : ProfileAvatar}
              className="self-end"
            />
          )}
        </>
      )}
    </div>
  );
}

export default ChatBubble;
