import React from "react";
import ChatLayout from "../Layout/ChatLayout";
import ChatScreen from "../components/Chats/ChatScreen";
import ChatsList from "../components/Chats/ChatsList";
import { useLocation, useParams } from "react-router-dom";

const Messages = () => {
  const location = useLocation().pathname;
  const { lang } = useParams();
  return (
    <ChatLayout>
      {location === `/${lang}/messages` ? <ChatsList /> : <ChatScreen />}
    </ChatLayout>
  );
};

export default Messages;
