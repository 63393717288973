import React from "react";
import FormInput from "../CommonComponents/FormInput";
import Label from "../CommonComponents/Label";

const EditOtherGuestDockDetails = ({ t }) => {
  return (
    <div className="text-dark-blue">
      <h1 className="text-[22px] text-center 2xl:text-[24px] font-roobert-rg text-dark-blue'>">
        Other Details
      </h1>
      <div className="grid grid-cols-2 w-full gap-x-[20px] gap-y-[40px] mt-[10px]">
        <div className="">
          <Label
            text={t("dockNumber")}
            styleClasses={"!text-[16px]  !leading-[28px]"}
          />
          <FormInput
            name={"dock_number"}
            placeHolder={t("dockNumber") + "*"}
            type={"text"}
            styleClasses={"rounded-full  font-roobert-rg pl-[20px]"}
          />
        </div>
        <div className="">
          <Label
            text={t("pier")}
            styleClasses={"!text-[16px]  !leading-[28px]"}
          />
          <FormInput
            name={"pier_number"}
            type={"text"}
            placeHolder={t("pier")}
            styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
          />
        </div>
        <div className="">
          <Label
            text={t("dockWidth")}
            styleClasses={"!text-[16px]  !leading-[28px]"}
          />
          <FormInput
            name={"dock_width"}
            type={"text"}
            placeHolder={t("dockWidth") + "*"}
            styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
          />
        </div>
        <div className="">
          <Label
            styleClasses={"!text-[16px]  !leading-[28px]"}
            text={t("dockLength")}
          />
          <FormInput
            name={"dock_length"}
            type={"text"}
            placeHolder={t("dockLength") + "*"}
            styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
          />
        </div>
      </div>
    </div>
  );
};

export default EditOtherGuestDockDetails;
