import React, { useState } from "react";
import ButtonDark from "../CommonComponents/ButtonDark";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
import { addGuestDocks } from "../../API/marina";
import { message } from "antd";
import { addImagesToFirebase } from "../../utils/addImages";
import getValueFromLocalStorage from "../../utils/getValueFromLocalStorage";
import { addAssetStore } from "../../store/store";
import { useMutation } from "react-query";

const GuestDocksAddedSuccessful = () => {
  const {
    navigate,
    t,
    params,
    paramsObject,
    lang,
    parse,
    setParams,
    role,
    userId,
  } = useUserDataAndNavigation();
  const { docksLocation } = addAssetStore();
  const handleClick = () => {
    navigate(`/${lang}/assets/guest-docks`);
  };
  const [loading, setLoading] = useState(false);
  const onSuccess = (Data) => {
    const { data } = Data;
    message.success(data.message);
    setLoading(false);
    localStorage.removeItem("values");
    localStorage.removeItem("my-images");
    localStorage.removeItem("thumbnail-images");
    setParams({ ...paramsObject, step: 8 });
  };
  const onError = (error) => {
    const { response } = error;
    const { message: errorResponse } = response.data;
    setLoading(false);
    message.error(errorResponse);
  };
  const { mutate, isLoading } = useMutation(
    ["add-guest-docks"],
    async (Data) => {
      return await addGuestDocks(userId, Data);
    },
    { onSuccess, onError }
  );

  const addGuestDockDetails = async () => {
    setLoading(true);
    const images = parse(getValueFromLocalStorage("my-images"));
    const thumbnailImages = parse(getValueFromLocalStorage("thumbnail-images"));
    const imagesUrls = await addImagesToFirebase(images.map((img) => img.file));
    const thumbnailUrls = await addImagesToFirebase(
      thumbnailImages.map((img) => img.file)
    );
    const myValues = parse(localStorage.getItem("values"));
    mutate({
      ...myValues,
      dock_details: docksLocation,
      images: imagesUrls,
      thumbnail_images: thumbnailUrls,
    });
  };
  return (
    <>
      {params.get("step") === "7" ? (
        <div className='bg-[#E2F3F7] h-[300px] px-[50px] shadow-custom-lg overflow-y-auto font-roobert-rg text-dark-blue"  z-20 w-[50%]  py-[20px] rounded-[20px] flex flex-col justify-center'>
          <h1 className="text-[28px] text-center 2xl:text-[35px] font-roobert-rg text-dark-blue">
            {t("sureToAddGuestDocks")}?
          </h1>
          <div className="flex justify-center mt-[30px] gap-x-[10px]">
            <ButtonDark
              text={t("yes")}
              styleClasses={"light-Btn h-[40px] !px-[60px]"}
              handleClick={addGuestDockDetails}
              loading={loading}
              disabled={loading}
            />
            <ButtonDark
              text={t("no")}
              handleClick={() => {
                navigate(`/${lang}/assets/guest-docks`);
              }}
              styleClasses={"white-Btn h-[40px] !px-[60px]"}
            />
          </div>
        </div>
      ) : (
        <div className='bg-[#E2F3F7]  overflow-y-auto  xl:px-[50px] px-[20px]  shadow-custom-lg font-roobert-rg text-dark-blue"  z-20 xl:w-[45%] w-[60%]  py-[20px] rounded-[20px] flex flex-col justify-center'>
          <h1 className="2xl:text-[35px] text-center max-lg:text-[22px] text-[28px]">
            {t("allguestdocksadded")}
          </h1>
          <div className="2xl:mt-[20px] xl:mt-[10px]">
            <h2 className="text-[24px] text-center max-lg:text-[20px]">
              {t("importantDetails")}
            </h2>
            <ol className="text-[16px] max-lg:text-[12px] leading-[30px] mt-[10px] 2xl:mt-[20px]">
              <li>1. {t("list1")}</li>
              <li className="">2. {t("list2")}</li>
              <li>3. {t("list3")}</li>
            </ol>
          </div>
          <div className="flex justify-center mt-[30px]">
            <ButtonDark
              text={t("proceed")}
              styleClasses={"dark-Btn h-[40px] w-[100px]"}
              handleClick={() => handleClick()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GuestDocksAddedSuccessful;
