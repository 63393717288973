import React from "react";
import BankIconLarge from "../../assets/icons/BankIconLarge";
import ButtonDark from "../CommonComponents/ButtonDark";
const ConnectionSuccessful = ({ t, handleProceed }) => {
  return (
    <div className="bg-[#E2F3F7]   text-dark-blue shadow-custom-lg z-20 w-full   2xl:max-h-[550px]  py-[20px] rounded-[20px]">
      <h1 className="text-[20px] 2xl:text-[22px] font-roobert-rg px-[50px] text-center 2xl:mt-[30px] leading-[35px]">
        {t("registrationSucceeded")}
      </h1>
      <div className="flex justify-center my-[10px]">
        <BankIconLarge />
      </div>
      <div className="flex justify-center  flex-col items-center">
        <h1 className="text-[28px] 2xl:text-[35px] font-roobert-rg">
          {t("bankConnected")}
        </h1>
        <p className="text-center font-roobert-rg px-[60px] text-[20px] 2xl:text-[22px]">
          {t("bankConnected2")}
        </p>
        <ButtonDark
          text={t("proceed")}
          styleClasses={"dark-Btn mt-[15px] h-[50px]"}
          handleClick={handleProceed}
        />
      </div>
    </div>
  );
};

export default ConnectionSuccessful;
