import React from "react";

const Cancel = ({ handleClose }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      onClick={handleClose}
      className="h-3 w-3"
    >
      <path
        d="M2 40L40.5547 2"
        stroke="#134357"
        strokeWidth="4"
        strokeLinecap="round"
      />

      <path
        d="M2 2L40.5547 40"
        stroke="#134357"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Cancel;
