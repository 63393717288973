import React, { useEffect, useRef, useState } from "react";
import Globe from "../../assets/icons/Globe";
import ArrowDown from "../../assets/icons/ArrowDown";

import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
import { useTranslation } from "react-i18next";
import useChangeLanguage from "../../utils/changeLanguage";
import Bell from "../../assets/icons/Bell";
import NotificationDropDown from "./Notifications/NotificationDropDown";
import { useQuery } from "react-query";
import axios from "axios";
import NotificationBadge from "./Notifications/NotificationBadge";
import ButtonDark from "./ButtonDark";
import Notification from "./Notifications/Notification";
import Select from "./Select";
import BackToDashboardButton from "./BackToDashboardButton";
import { defaultLanguage, langOptions } from "../../constants/constants";
import i18n from "../../languages/config";
const Navbar = ({ backToDashboard, page }) => {
  const { changeLanguage } = useChangeLanguage();
  const handleChange = (value) => {
    changeLanguage(value);
  };
  const { t, lang } = useUserDataAndNavigation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <>
      <div className="pt-[29px]  w-full gap-x-[24px] !outline-none  !pr-[24px] flex justify-end items-center">
        <ButtonDark
          styleClasses={"dark-Btn h-[40px] w-[250px]"}
          text={t("Videoguides")}
        />
        <Bell />
        <Select
          options={langOptions}
          handleChange={handleChange}
          defaultValue={lang ?? "en"}
        />
      </div>
      {backToDashboard && <BackToDashboardButton page={page} />}
    </>
  );
};

export default Navbar;
