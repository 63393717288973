export const organizeDataByMonthYear = (data) => {
  const organizedData = data.reduce((acc, transaction) => {
    const transferDate = new Date(transaction.transfer_date * 1000);
    const monthYearKey = `${transferDate.toLocaleString("en-US", {
      month: "short",
    })}-${transferDate.getFullYear()}`;

    if (!acc[monthYearKey]) {
      acc[monthYearKey] = {
        month: transferDate.toLocaleString("en-US", { month: "long" }),
        year: transferDate.getFullYear(),
        data: [transaction],
      };
    } else {
      acc[monthYearKey].data.push(transaction);
    }

    return acc;
  }, {});

  const resultArray = Object.values(organizedData);

  return resultArray;
};
