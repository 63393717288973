import React from "react";
import { Draggable } from "react-beautiful-dnd";
import Drag from "../../assets/icons/Drag";
import DashboardCardHeader from "./DashboardCardHeader";
import Averagecards from "./AverageCard";
import Graph from "./Graph";
import { useSearchParams } from "react-router-dom";
const GraphCard = ({ item, index, graphData, averageData }) => {
  const { id } = item;
  const [params] = useSearchParams();
  const { total_average, currency } = averageData ?? {};
  return (
    <Draggable draggableId={id.toString()} index={index}>
      {(provided) => {
        return (
          <div
            {...provided.draggableProps}
            ref={provided.innerRef}
            className="mt-[30px] dashboard    h-auto   bg-light-blue flex rounded-[12px] shadow-custom"
          >
            <div
              {...provided.dragHandleProps}
              className="h-auto w-[21px] shadow-drag-btn rounded-l-[12px] flex justify-center items-center bg-transparent"
            >
              <Drag />
            </div>
            <div className="w-full pt-[24px] pb-[25px] 2xl:pb-[50px]  bg-[#FFFFFF] rounded-[12px]">
              <div className="pl-[32px] pr-[42px]">
                <DashboardCardHeader item={item} />
              </div>
              <div className="2xl:mt-[25px] mt-[10px] pl-[70px]">
                <Averagecards average={total_average} currency={currency} />
              </div>
              <div className="pl-[25px] 2xl:mt-[40px] mt-[20px] w-full h-[350px] 3xl:h-[500px]">
                <Graph
                  data={graphData ?? []}
                  left={-22}
                  type={params.get("graph") || "monthly"}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default GraphCard;
