import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomPrevButton from "./CustomPrevButton";
import CustomNextButton from "./CustomNextButton";
import ImageCard from "./ImageCard";
const Carousel = ({ data }) => {
  const [settings] = useState({
    speed: 500,
    slidesToShow: data.length === 1 ? 1 : 2,
    slidesToScroll: 1,
    nextArrow: <CustomNextButton />,
    prevArrow: <CustomPrevButton />,
  });

  return (
    <div>
      <Slider {...settings}>
        {data.map((img) => {
          return <ImageCard key={img?.images_id} src={img?.image} />;
        })}
      </Slider>
    </div>
  );
};

export default Carousel;
