import { useLocation, useNavigate, useParams } from "react-router-dom";
import useUserInfo from "../../hooks/useUserInfo";
import { convertTimeStamps } from "../../utils/dateUtils";
import ChatRow from "./ChatRow";
import { useTranslation } from "react-i18next";
import BackIcon from "../../assets/icons/BackIcon";

function ChatSidebar({ chats }) {
  const { currentUser } = useUserInfo();
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const isActive = pathname === "/en/messages";
  const sideBarStyle = `flex flex-col  ${
    isActive ? "" : "max-md:hidden"
  }  fixed   md:bg-kprimary md:w-[16rem] 
      lg:w-[20rem] w-full`;
  const navigate = useNavigate();
  const { lang } = useParams() || "en";
  return (
    <aside
      className={`flex flex-col fixed  bg-dark-blue md:bg-kprimary md:w-[16rem] 
      lg:w-[20rem] w-full h-full overflow-y-auto ${sideBarStyle}`}
    >
      <div
        className="ml-4 mt-4 cursor-pointer"
        onClick={() => navigate(`/${lang}`)}
      >
        <BackIcon color="white" />
      </div>
      <h1 className=" text-[#FFFFFF] text-[28px] text-center my-5  font-roobert-sb">
        {t("Chats")}
      </h1>
      <div className="flex-1 space-y-2 font-roobert-sb  mx-2 md:mx-0 flex flex-col mb-4">
        {currentUser &&
          chats?.map((chat) => {
            const isOwner = currentUser?.uid === chat.owner_id;
            const receiverFirebaseId = isOwner
              ? chat?.tenant_id
              : chat?.owner_id;
            const userImage = isOwner ? chat?.tenant_pic : chat?.owner_pic;
            const getUsername = isOwner
              ? chat.hide_names === true
                ? t("Tenant")
                : chat?.tenant_name
              : chat.hide_names === true
              ? t("Owner")
              : chat?.owner_name;

            return (
              <ChatRow
                key={chat.last_message_sender}
                isDisabled={chat.is_disable}
                id={`${chat?.asset_id}_${chat?.owner_id}_${chat?.tenant_id}`}
                userName={getUsername}
                userImage={userImage}
                lastMessageAt={
                  chat.last_message_timestamp
                    ? convertTimeStamps(
                        chat?.last_message_timestamp?.seconds,
                        t
                      )
                    : null
                }
                unreadCount={chat?.is_read}
                lastSenderId={chat?.last_message_sender}
                receiverFirebaseId={receiverFirebaseId}
              />
            );
          })}
      </div>
    </aside>
  );
}

export default ChatSidebar;
