import React from "react";
import MapInstructions from "../../assets/images/MapInstruction.png";
import MapPlus from "../../assets/icons/MapPlus";
import CircleCutMap from "../../assets/icons/CircleCutMap";
import HandMap from "../../assets/icons/HandMap";
import ButtonDark from "../CommonComponents/ButtonDark";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
// import { getAssetTypeName } from '../../utils/assetType';

const Instructions = ({ onHandleInstructions }) => {
  const { t, params, setParams, paramsObject } = useUserDataAndNavigation();
  const handleOk = () => {
    onHandleInstructions(false);
  };
  const isNotGuestDock = localStorage.getItem("addAssetType") !== "guestDock";
  return (
    <div className=" bg-[#E2F3F7] z-10  shadow-custom-lg     overflow-y-hidden py-[40px] rounded-[20px] flex flex-col justify-center items-center">
      <div className="relative">
        <img src={MapInstructions} alt="" />
        <div className="absolute top-4 right-4">
          <MapPlus />
        </div>
        <div className="absolute top-[65px] right-5">
          <CircleCutMap />
        </div>
        <div className="absolute top-[95px] right-[25px]">
          <HandMap />
        </div>
      </div>

      <h1 className="text-[28px] 2xl:text-[35px] font-roobert-rg px-[50px] text-center mt-[40px] leading-[50px]">
        {t("mapInstruction1")}
      </h1>

      <p className="text-[18px] 2xl:text-[22px] font-roobert-rg text-center px-[50px] mt-[30px]">
        {isNotGuestDock
          ? t("mapInstruction2")
          : t("mapInstructionForGuestDocks")}
      </p>

      <div className="">
        <ButtonDark
          text={"Ok"}
          handleClick={handleOk}
          styleClasses={"dark-Btn h-[50px] w-[100px] mt-[30px]"}
        />
      </div>
    </div>
  );
};

export default Instructions;
