import useQueryHook from "../useQueryHook";
import { checkPayoutAccount, goToPayouts } from "../../API/marina";
import useMutationHook from "../useMutationHook";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
const useCheckBalance = ({ enabled, setIsLoading, type }) => {
  const { setParams, paramsObject, userId } = useUserDataAndNavigation();
  const { data, isLoading, isFetching, refetch, error } = useQueryHook(
    ["check-account-status"],
    async () => {
      return checkPayoutAccount(userId);
    },
    enabled
  );

  const onSuccess = (Data) => {
    localStorage.setItem("payoutUrl", Data?.accountLink?.url);
    window.location.href = Data?.accountLink?.url;
    setIsLoading(false);
  };

  const onError = (error) => {
    setIsLoading(false);
  };

  const { mutate, isLoading: mutateLoading } = useMutationHook(
    ["create-Account"],
    async (Data) => {
      return await goToPayouts(Data, Data?.flow ?? true);
    },
    onSuccess,
    onError
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
    mutate,
    error,
    mutateLoading,
  };
};

export default useCheckBalance;
