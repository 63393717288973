import React from "react";
import { Form, Select } from "antd";
import ArrowDownDark from "../../assets/icons/ArrowDownDark";
const SelectInput = ({
  options,
  defaultValue,
  handleChange,
  disabled,
  name,
}) => {
  return (
    <div className="relative">
      <Form.Item name={name}>
        <Select
          defaultValue={defaultValue}
          className="w-full h-[40px] !border-none !text-black outline-none"
          onChange={handleChange}
          suffixIcon={<ArrowDownDark />}
          options={options}
          disabled={disabled}
        />
      </Form.Item>
    </div>
  );
};

export default SelectInput;
