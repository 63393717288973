import React from "react";

const ReportIcon = ({ location, path }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.83333 14.5H10.1667M5.83333 14.5V10.8889M5.83333 14.5H1.93333C1.81841 14.5 1.70819 14.4543 1.62692 14.3731C1.54565 14.2918 1.5 14.1816 1.5 14.0667V11.3222C1.5 11.2073 1.54565 11.0971 1.62692 11.0158C1.70819 10.9345 1.81841 10.8889 1.93333 10.8889H5.83333M10.1667 14.5V5.83333M10.1667 14.5H14.0667C14.1816 14.5 14.2918 14.4543 14.3731 14.3731C14.4543 14.2918 14.5 14.1816 14.5 14.0667V1.93333C14.5 1.81841 14.4543 1.70819 14.3731 1.62692C14.2918 1.54565 14.1816 1.5 14.0667 1.5H10.6C10.4851 1.5 10.3749 1.54565 10.2936 1.62692C10.2123 1.70819 10.1667 1.81841 10.1667 1.93333V5.83333M5.83333 10.8889V6.26667C5.83333 6.15174 5.87899 6.04152 5.96025 5.96025C6.04152 5.87899 6.15174 5.83333 6.26667 5.83333H10.1667"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ReportIcon;
