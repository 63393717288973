import React from "react";
import { Checkbox } from "antd";

const Facilities = ({ checkedList, setCheckedList, t }) => {
  const handleCheckboxChange = (checkedValues) => {
    if (checkedValues === "water") {
      setCheckedList({ ...checkedList, water: !checkedList.water });
    }
    if (checkedValues === "electricity") {
      setCheckedList({ ...checkedList, electricity: !checkedList.electricity });
    }
  };
  return (
    <div className="w-full flex flex-col items-center mt-[40px]">
      <h2 className="text-[22px] 2xl:text-[24px] font-roobert-rg text-dark-blue">
        {t("facilitiesInMarina")}?
      </h2>
      <div className="grid grid-cols-2 mt-[10px] gap-x-[10px] text-[16px] text-dark-blue font-roobert-rg">
        <label
          className={` ${
            checkedList.electricity === true ? "bg-[#D7EEF3]" : "bg-[#FFFFFF]"
          } rounded-[50px] cursor-pointer h-[40px] justify-between gap-x-[20px] border border-[#A5C5CC] px-[32px] flex items-center `}
        >
          <span>{t("electricity")}</span>
          <Checkbox
            className="rounded-checkbox"
            value="electricity"
            onChange={(e) => handleCheckboxChange(e.target.value)}
            checked={checkedList.electricity}
          />
        </label>
        <label
          className={` ${
            checkedList.water === true ? "bg-[#D7EEF3]" : "bg-[#FFFFFF]"
          } rounded-[50px] cursor-pointer h-[40px] justify-between gap-x-[20px] border border-[#A5C5CC] px-[32px] flex items-center `}
        >
          <span>{t("water")}</span>
          <Checkbox
            className="rounded-checkbox"
            value="water"
            checked={checkedList.water}
            onChange={(e) => handleCheckboxChange(e.target.value)}
          />
        </label>
      </div>
    </div>
  );
};

export default Facilities;
