import React, { useState } from "react";
import { getAutoValues, getCurrency } from "../../API/marina";
import { useTranslation } from "react-i18next";
import { useQueries } from "react-query";

const useGetDataForRentalPrice = ({
  enabled,
  sizeInfeet,
  propertyType,
  form,
}) => {
  const { t } = useTranslation();
  const [autoFill, setAutoFill] = useState(false);
  const onClickAutoFill = (autoFill) => {
    if (!autoFill) {
      form.setFieldsValue({
        rate_per_hour: Number(autofillValues?.hourly),
        rate_per_day: autofillValues?.daily,
        rate_per_week: autofillValues?.weekly,
        rate_per_month: autofillValues?.monthly,
        rate_per_season: autofillValues?.season,
      });
    } else {
      form.resetFields([
        "rate_per_hour",
        "rate_per_day",
        "rate_per_week",
        "rate_per_month",
        "rate_per_season",
      ]);
    }
    setAutoFill(!autoFill);
  };
  const userId = localStorage.getItem("userId");
  const [AutoFill, CurrencyData] = useQueries([
    {
      queryKey: ["autofill-values"],
      queryFn: () => getAutoValues(userId, propertyType, sizeInfeet),
      enabled: enabled,
    },
    {
      queryKey: ["get-currency"],
      queryFn: () => getCurrency(userId),
      enabled: enabled,
    },
  ]);
  const { data: autofillValues, isLoading, error } = AutoFill;
  const {
    data: currency,
    isLoading: currencyLoading,
    error: currencyError,
  } = CurrencyData;

  return {
    isLoading,
    error,
    t,
    autoFill,
    onClickAutoFill,
    currency,
    currencyLoading,
    currencyError,
  };
};

export default useGetDataForRentalPrice;
