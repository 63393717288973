import { useState, useEffect, createRef } from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import BoatIcon from "../../assets/icons/BoatIcon";
import SelectDropdown from "../CommonComponents/Select";
import PrevIcon from "../../assets/icons/PrevIcon";
import NextIcon from "../../assets/icons/NextIcon";
import DateRangePicker from "../CommonComponents/DateRange";
import dayjs from "dayjs";

const getResources = (campaigns, mode) =>
  campaigns?.map((campaign) => ({
    ...campaign,
    children: campaign?.steps?.map((step) => {
      return {
        id: step.id,
        name: step.name,
        type: "steps",
      };
    }),
  }));

const getEvents = (campaigns) =>
  campaigns
    ?.map(
      ({ id, name: campaignName, from: campaignFrom, to: campaignTo, steps }) =>
        steps
          .map(({ id: eventId, name, from, to, phase, type }) => ({
            resourceId: eventId,
            // start: dayjs(from * 1000).toDate(),
            // end: dayjs(to * 1000).toDate(),
            start: "2024-06-01",
            end: dayjs().toDate(),
            allDay: true,
            color: type === "rental" ? "#ACDCE7" : "#FFCC9D",
            type,
            campaignId: id,
            name,
            phase,
            eventId,
          }))
          .concat([
            {
              resourceId: id,
              start: new Date(campaignFrom),
              end: new Date(campaignTo),
              allDay: true,
              color: "#2C3E50",
              type: "campaign",
              name: campaignName,
              campaignId: id,
            },
          ])
    )
    .flat();

const CampaignResource = ({ resource }) => {
  const { name, type, steps } = resource.extendedProps;
  return (
    <div
      style={{
        display: "inline-flex",
        width: "100%",
      }}
      className={`${
        type === "steps" ? "px-[30px] py-[10px]" : "py-[12px]"
      } !h-full ${
        type === "steps" && resource.id % 2 === 0 ? "bg-[#F5F5F5]" : ""
      }`}
    >
      <div
        className={`${
          type === "step" ? "text-[10px]" : "text-[16px]"
        } flex px-[20px]  items-center gap-x-[17px] text-dark-blue font-roobert-rg`}
      >
        {type === "boat" && <BoatIcon />}
        <div className="flex flex-col">
          <div className="">{name}</div>
          {type !== "steps" && <div className="">Enheter: {steps?.length}</div>}
        </div>
      </div>
    </div>
  );
};

const ModeSelector = ({ current, onChange }) => {
  const options = [
    { value: "month", label: "Month" },
    { value: "year", label: "Year" },
    { value: "week", label: "Week" },
  ];
  return (
    <>
      <SelectDropdown
        handleChange={(value) => onChange(value)}
        options={options}
        defaultValue={"month"}
        stylesClasses={"w-[150px]"}
      />
    </>
  );
};

const StepEvent = ({ name, phase }) => {
  return <div title={`${name} (${phase})`}>Leieforhold</div>;
};

const CampaignEvent = ({ name, owner, integrator }) => {
  return (
    <div
      style={{
        height: "30px",
        padding: 5,
      }}
    >
      <div style={{ fontWeight: 600 }}>{name}</div>
      <div
        style={{
          display: "flex",
          marginTop: 5,
        }}
      ></div>
    </div>
  );
};
const BlockEvent = ({ name, type, owner, integrator }) => {
  return <div>Blokkert</div>;
};

const Event = ({ event }) => {
  const { type, name, phase, owner, integrator } = event.extendedProps;
  const style = { border: "none", width: "100%", padding: "5px" };
  return (
    <div className="fc-event-title fc-sticky" style={style}>
      {type === "campaign" && (
        <CampaignEvent {...{ name, owner, integrator }} />
      )}
      {type === "rental" && <StepEvent {...{ name, phase }} />}
      {type === "block" && <BlockEvent {...{ name, phase }} />}
    </div>
  );
};

const computeDurations = (mode) => {
  switch (mode) {
    case "week":
      return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{ month: "long" }, { day: "2-digit" }],
        duration: { weeks: 1 },
      };
    case "2weeks":
      return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{ month: "long" }, { day: "2-digit" }],
        duration: { weeks: 2 },
      };
    case "month":
      return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{ day: "2-digit" }, { weekday: "short" }],
        duration: { months: 1 },
      };
    case "year":
      return {
        slotDuration: { months: 1 },
        slotLabelFormat: [{ month: "short" }, { day: "2-digit" }],
        duration: { years: 1 },
      };
    default:
      return {};
  }
};

const handleEventChange =
  (calendarRef, isResize) =>
  ({ event, oldEvent, revert }) => {
    const { type, campaignId } = event.extendedProps;
    if (isResize && type === "campaign") return revert();
    if (type === "campaign") {
      const diff = event.start.getTime() - oldEvent.start.getTime();
      calendarRef.current
        .getApi()
        .getEvents()
        .filter((currentEvent) => {
          const { type: currEventType, campaignId: currEventCampaignId } =
            currentEvent.extendedProps;
          return currEventType === "step" && currEventCampaignId === campaignId;
        })
        .forEach((campaignEvent) => {
          const start = campaignEvent.start;
          const end = campaignEvent.end;
          campaignEvent.setDates(
            new Date(start.getTime() + diff),
            new Date(end.getTime() + diff)
          );
        });
    } else if (type === "step") {
      const campaignEvent = calendarRef.current
        .getApi()
        .getEvents()
        .find(
          ({ extendedProps: evt }) =>
            evt.type === "campaign" && evt.campaignId === campaignId
        );
      const { first, last } = calendarRef.current
        .getApi()
        .getEvents()
        .filter((currentEvent) => {
          const { type: currEventType, campaignId: currEventCampaignId } =
            currentEvent.extendedProps;
          return currEventType === "step" && currEventCampaignId === campaignId;
        })
        .reduce(
          (acc, evt) => {
            return {
              first:
                acc.first === null ||
                acc.first.start.getTime() > evt.start.getTime()
                  ? evt
                  : acc.first,
              last:
                acc.last === null || acc.last.end.getTime() < evt.end.getTime()
                  ? evt
                  : acc.last,
            };
          },
          { first: null, last: null }
        );
      campaignEvent.setDates(first.start, last.end);
    }
  };

const CalendarComponent = ({ campaigns }) => {
  const [mode, setMode] = useState("month");
  const [resources, setResources] = useState([]);
  const [visibleDateRange, setVisibleDateRange] = useState(null);
  const calendarRef = createRef();
  const handleNextDate = () => {
    calendarRef.current.getApi().next();
  };

  const handlePrevDate = () => {
    calendarRef.current.getApi().prev();
  };
  const { slotDuration, slotLabelFormat } = computeDurations(mode);
  const isOdd = (num) => num % 2 !== 0;

  const getSlotLaneClassNames = ({ date }) => {
    const day = date.getDate();
    return isOdd(day) ? "odd-date" : "even-date";
  };

  useEffect(() => {
    if (calendarRef.current && calendarRef.current.getApi) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi && calendarApi.view && calendarApi.view.activeEnd) {
        setVisibleDateRange({
          startDate: calendarApi.view.activeStart,
          endDate: calendarApi.view.activeEnd,
        });
      }
    }
  }, []);

  const handleDateChange = (dates, dateStrings) => {
    setVisibleDateRange({
      startDate: dayjs(dateStrings[0]).toDate(),
      endDate: dayjs(dateStrings[1]).toDate(),
    });
  };
  return (
    <>
      <div className="flex items-center gap-x-[20px]">
        <div>
          <button onClick={handlePrevDate}>
            <PrevIcon />
          </button>
          <button onClick={handleNextDate}>
            <NextIcon />
          </button>
        </div>
        <DateRangePicker
          visibleDateRange={visibleDateRange}
          handleDateChange={handleDateChange}
        />

        <ModeSelector current={mode} onChange={setMode} />
      </div>

      <FullCalendar
        ref={calendarRef}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimeline"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "",
        }}
        resourceAreaHeaderContent=" "
        resourcesInitiallyExpanded={true}
        slotDuration={slotDuration}
        slotLabelFormat={slotLabelFormat}
        resources={getResources(campaigns, "")}
        events={getEvents(campaigns)}
        resourceLabelContent={CampaignResource}
        eventContent={Event}
        eventResourceEditable={false}
        eventResize={handleEventChange(calendarRef, true)}
        eventDrop={handleEventChange(calendarRef, false)}
        slotLaneClassNames={getSlotLaneClassNames}
        visibleRange={() => {
          return {
            start:
              visibleDateRange?.startDate || dayjs().startOf("month").toDate(),
            end: visibleDateRange?.endDate || dayjs().endOf("month").toDate(),
          };
        }}
      />
    </>
  );
};

export default CalendarComponent;
