import { Form, message } from "antd";
import React, { useState } from "react";
import FormInput from "../CommonComponents/FormInput";
import ButtonDark from "../CommonComponents/ButtonDark";
import { addAssetStore } from "../../store/store";
import useAccountStatus from "../../hooks/getData/useAccountStatus";

import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
import { searchPlaceName } from "../../utils/searchPlaceName";
import { disabled, pending } from "../../constants/constants";
import { useMutation } from "react-query";
import { addGuestDocks } from "../../API/marina";
import getValueFromLocalStorage from "../../utils/getValueFromLocalStorage";
import { addImagesToFirebase } from "../../utils/addImages";

const AddDockDetail = ({ total, t, activeDockNumber }) => {
  const { setDocksLocation, docksLocation } = addAssetStore();
  const { paramsObject, params, setParams, userId, parse, navigate, lang } =
    useUserDataAndNavigation();
  const [loading, setLoading] = useState(false);
  const onSuccess = (Data) => {
    const { data } = Data;
    message.success(data.message);
    setLoading(false);
    localStorage.removeItem("values");
    localStorage.removeItem("my-images");
    localStorage.removeItem("thumbnail-images");
    navigate(`/${lang}/assets/guest-docks`);
  };
  const onError = (error) => {
    const { response } = error;
    const { message: errorResponse } = response.data;
    setLoading(false);
    message.error(errorResponse);
  };
  const { mutate, isLoading } = useMutation(
    ["add-guest-docks"],
    async (Data) => {
      return await addGuestDocks(userId, Data);
    },
    { onSuccess, onError }
  );

  const addGuestDockDetails = async (updatedDocksLocation) => {
    setLoading(true);
    const images = parse(getValueFromLocalStorage("my-images"));
    const thumbnailImages = parse(getValueFromLocalStorage("thumbnail-images"));
    const imagesUrls = await addImagesToFirebase(images.map((img) => img.file));
    const thumbnailUrls = await addImagesToFirebase(
      thumbnailImages.map((img) => img.file)
    );
    const myValues = parse(localStorage.getItem("values"));
    mutate({
      ...myValues,
      dock_details: updatedDocksLocation,
      images: imagesUrls,
      thumbnail_images: thumbnailUrls,
    });
  };
  const onFinish = async (values) => {
    const indexToRemove = docksLocation.findIndex(
      (dock) => dock.activeDock === activeDockNumber
    );

    if (indexToRemove !== -1) {
      docksLocation.splice(indexToRemove, 1);
    }
    setLoading(true);
    const place_name = await searchPlaceName(
      params.get("lat"),
      params.get("lng")
    );
    if (place_name) {
      const updatedDocksLocation = [
        ...docksLocation,
        {
          ...values,
          lat: params.get("lat"),
          activeDock: activeDockNumber,
          lng: params.get("lng"),
          place_name,
        },
      ];
      if (activeDockNumber === total) {
        setDocksLocation(updatedDocksLocation);
        return await addGuestDockDetails(updatedDocksLocation);
      } else {
        setDocksLocation(updatedDocksLocation);
        setParams({
          ...paramsObject,
          active: false,
          activeDock: activeDockNumber + 1,
        });
      }
    }
  };

  return (
    <div className="text-dark-blue ">
      <h1 className="text-[28px] 2xl:text-[35px] font-roobert-rg px-[50px] text-center mt-[40px] leading-[50px]">
        {t("addDetailsForDock")} #{activeDockNumber}
      </h1>

      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        className="px-[18%] mt-[20px] flex flex-col gap-y-16"
      >
        <FormInput
          name={"dock_number"}
          label={t("dockNumber")}
          type={"text"}
          styleClasses={"rounded-full w-full font-roobert-rg pl-[20px]"}
          rules={[
            {
              required: true,
              message: t("enterDockNum"),
            },
          ]}
        />
        <FormInput
          name={"pier_number"}
          label={t("pier")}
          type={"text"}
          styleClasses={"rounded-full w-full font-roobert-rg pl-[20px]"}
          rules={[
            {
              required: true,
              message: t("enterPierNum"),
            },
          ]}
        />
        <FormInput
          name={"dock_width"}
          label={t("dockWidth")}
          step="any"
          type={"number"}
          styleClasses={"rounded-full w-full font-roobert-rg pl-[20px]"}
          rules={[
            {
              required: true,
              message: t("enterDockWidth"),
            },
          ]}
        />
        <FormInput
          name={"dock_length"}
          step="any"
          label={t("dockLength")}
          styleClasses={"rounded-full w-full font-roobert-rg pl-[20px]"}
          type={"number"}
          rules={[
            {
              required: true,
              message: t("enterDockLength"),
            },
          ]}
        />
        <div className="flex justify-center">
          <ButtonDark
            text={t("proceed")}
            styleClasses={"dark-Btn h-[50px]"}
            type={"submit"}
            loading={loading || isLoading}
          />
        </div>
      </Form>
    </div>
  );
};

export default AddDockDetail;
