import Layout from "../Layout/Layout";

import Table from "../components/CommonComponents/Table/Table";
import useGetDataForRentals from "../hooks/getData/useGetDataForRentals";
import PageHeaders from "../components/CommonComponents/PageHeaders";
import IconBtn from "../components/CommonComponents/IconBtn";
import CancelCircle from "../assets/icons/CancelCircle";
import AcceptCircle from "../assets/icons/AcceptCircle";

import AcceptOrRejectRentalModal from "../components/Rentals/AcceptOrRejectRentalModal";
import SearchButton from "../components/CommonComponents/SearchButton";
import { handleSearchChange, handleSearchClick } from "../utils/handleSearch";
import SearchBar from "../components/CommonComponents/SearchBar";
import PayoutAlertModal from "../components/Rentals/PayoutAlertModal";
import ConnectToBank from "../components/Maps/ConnectToBank";
import ConnectionSuccessful from "../components/Maps/ConnectionSuccessful";
import Dialog from "../components/CommonComponents/Modal/Dialog";

const Rentals = () => {
  const {
    columns,
    params,
    setParams,
    type,
    pageHeading,
    t,
    filterRentals,
    rentalsData,
    selectedRowKeys,
    onSelectChange,
    requestedAsset,
    onCLickAcceptOrReject,
    showModal,
    onClickAccept,
    onClickReject,
    location,
    loading,
    error,
    isLoading,
    isFetching,
    lang,
    openPayout,
    setOpenPayout,
    openLinkBankModal,
    setOpenLinkBankModal,
    checkAccountError,
    checkAccountLoading,
    linking,
    searchValue,
    setSearchValue,
    handleProceddBankingSuccessConnnection,
    upcomingAsset,
    modalScreen,
    setModalScreen,
  } = useGetDataForRentals();

  // *********************
  // return
  // *********************
  return (
    <Layout page={"Rental"}>
      <Dialog
        isModalOpen={linking === "success"}
        onCancelModal={handleProceddBankingSuccessConnnection}
        component={
          <ConnectionSuccessful
            handleProceed={handleProceddBankingSuccessConnnection}
            t={t}
          />
        }
      />
      <Dialog
        isModalOpen={openLinkBankModal}
        onCancelModal={() => setOpenLinkBankModal(false)}
        component={<ConnectToBank t={t} />}
      />
      <Dialog
        isModalOpen={openPayout}
        onCancelModal={() => setOpenPayout(false)}
        component={
          <PayoutAlertModal
            handleProceed={() => {
              setOpenLinkBankModal(true);
              setOpenPayout(false);
            }}
            handleCancelModal={() => setOpenPayout(false)}
          />
        }
      />
      <Dialog
        isModalOpen={showModal.active}
        onCancelModal={() => onCLickAcceptOrReject(false, null)}
        component={
          <AcceptOrRejectRentalModal
            rentalItem={filterRentals?.filter(
              (rental) => rental.id === selectedRowKeys[0]
            )}
            showModal={showModal}
            onCLickAcceptOrReject={onCLickAcceptOrReject}
            onClickAccept={onClickAccept}
            onClickReject={onClickReject}
            loading={loading}
            modalScreen={modalScreen}
            setModalScreen={setModalScreen}
            t={t}
          />
        }
      />
      <div className="px-[25px]">
        <PageHeaders
          heading={pageHeading + " " + t("rentals")}
          exportUrl={`/${lang}/export/rentals?filter=${type}`}
          exportData={location === `/${lang}/rentals/all-rentals`}
        />

        <div className="  mt-[25px] bg-[#FFFFFF] pb-[108px] rounded-[40px] my-table">
          <div className="w-full pt-[30px] pb-[40px]">
            <div className="flex items-center justify-between">
              <div className="pl-[20px]">
                <div className="flex gap-x-[10px] pl-[25px]">
                  <SearchBar
                    value={searchValue}
                    placeholder={`${t("search")} ${
                      pageHeading === "" ? t("rentals") : pageHeading
                    }`}
                    handleSearchChange={(e) =>
                      handleSearchChange(e, setSearchValue, params, setParams)
                    }
                    handleSubmit={() =>
                      handleSearchClick(searchValue, setParams, params)
                    }
                  />
                  <SearchButton
                    text={t("search")}
                    handleSearchClick={() =>
                      handleSearchClick(searchValue, setParams, params)
                    }
                  />
                </div>
              </div>
              {selectedRowKeys.length === 1 && (
                <div className="w-full flex justify-end gap-x-[20px] pr-[20px]  items-center">
                  {requestedAsset && (
                    <IconBtn
                      text={t("acceptRequest")}
                      styleClasses={"!bg-[#D9F2DD] !border-none text-dark-blue"}
                      icon={<AcceptCircle />}
                      handleClick={() => onCLickAcceptOrReject(true, "accept")}
                    />
                  )}
                  {(requestedAsset || upcomingAsset) && (
                    <IconBtn
                      text={
                        upcomingAsset ? "Reject Rental" : t("rejectRequest")
                      }
                      styleClasses={"!bg-[#FFC4B7] !border-none text-dark-blue"}
                      icon={<CancelCircle />}
                      handleClick={() => onCLickAcceptOrReject(true, "reject")}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <Table
            data={filterRentals}
            columns={columns}
            setSearchParams={setParams}
            searchParams={params}
            selection={true}
            selectedRowKeys={selectedRowKeys}
            onSelectChange={onSelectChange}
            pagination={true}
            totalPages={rentalsData?.total_pages * 20}
            pageSize={20}
            loading={isLoading || isFetching || checkAccountLoading}
            error={error || checkAccountError}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Rentals;
