import { Checkbox } from "antd";
import React from "react";

const RoundedCheckbox = ({ onChange, value, checked }) => {
  return (
    <Checkbox
      className="rounded-checkbox"
      value={value}
      onChange={onChange}
      checked={checked}
    ></Checkbox>
  );
};

export default RoundedCheckbox;
