import React from "react";

const SignupLogo = () => {
  return (
    <svg
      className="xl:h-[90px] h-[70px] mt-5"
      viewBox="0 0 541 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2020_65750)">
        <path
          d="M4.80698 79.7039C4.93854 79.2314 5.45784 78.9899 5.89406 79.2104C9.19683 80.887 13.8636 82.1261 18.6516 82.1261C25.4441 82.4166 31.0803 78.9094 31.0803 72.189C31.0803 66.1966 27.6113 63.1304 21.5424 57.285C13.7355 49.833 10.1247 41.94 10.1247 33.613C10.1247 14.0292 23.7096 0.878906 43.7997 0.878906C51.4335 0.878906 56.0899 2.25799 58.5722 3.62308C58.8976 3.80159 59.0465 4.18662 58.9392 4.54364L53.7496 21.6317C53.6111 22.0833 53.1091 22.3143 52.6868 22.1148C50.0037 20.8407 47.0194 19.8746 43.0762 19.8746C36.2837 19.8746 31.3676 23.9664 31.3676 30.6868C31.3676 34.341 32.3785 37.2637 40.7636 44.5687C49.2905 52.0207 52.4721 60.6417 52.4721 67.9502C52.4721 89.4311 37.8728 101.122 19.2298 101.122C9.17952 101.122 3.10367 99.2387 0.427523 97.9156C0.0917068 97.7511 -0.0710083 97.3661 0.0293904 97.002L4.80352 79.7004L4.80698 79.7039Z"
          fill="white"
        />
        <path
          d="M94.7206 2.68497L87.7031 39.8398C87.6131 40.3229 87.9801 40.7709 88.4648 40.7709H103.362C103.736 40.7709 104.054 40.5049 104.124 40.1339L111.255 2.39095C111.325 2.01992 111.646 1.75391 112.017 1.75391H131.827C132.315 1.75391 132.682 2.20193 132.588 2.68497L114.24 99.6096C114.17 99.9807 113.848 100.247 113.478 100.247H93.6613C93.1766 100.247 92.8096 99.8022 92.8996 99.3191L99.789 61.4257C99.8756 60.9427 99.5121 60.4982 99.0274 60.4982H84.4142C84.0403 60.4982 83.7218 60.7642 83.6526 61.1352L76.3788 99.6096C76.3096 99.9807 75.9876 100.247 75.6172 100.247H55.8075C55.3194 100.247 54.9524 99.7987 55.0459 99.3156L73.3842 2.39095C73.4534 2.01992 73.7754 1.75391 74.1458 1.75391H93.959C94.4471 1.75391 94.8141 2.20193 94.7206 2.68497Z"
          fill="white"
        />
        <path
          d="M169.044 2.68497L150.695 99.6096C150.626 99.9807 150.304 100.247 149.934 100.247H130.124C129.636 100.247 129.269 99.7987 129.362 99.3156L147.708 2.39095C147.777 2.01992 148.099 1.75391 148.469 1.75391H168.279C168.767 1.75391 169.134 2.20193 169.041 2.68497H169.044Z"
          fill="white"
        />
        <path
          d="M184.433 3.74906C184.495 3.42704 184.741 3.17853 185.059 3.12252C191.485 2.00945 199.596 1.31641 205.925 1.31641C212.572 1.31641 223.27 2.48548 229.917 9.64694C234.251 14.3232 236.131 21.9222 236.131 27.036C236.131 41.94 228.906 52.6087 222.401 57.8695C215.463 63.4209 205.489 66.053 197.249 66.053H194.133C193.763 66.053 193.441 66.3191 193.372 66.6901L187.109 99.6132C187.04 99.9842 186.718 100.25 186.347 100.25H167.112C166.624 100.25 166.257 99.8022 166.351 99.3192L184.433 3.74906ZM197.138 47.4389C197.048 47.9219 197.415 48.3699 197.9 48.3699H199.42C211.848 48.3699 214.739 35.9476 214.739 28.4956C214.739 22.7973 211.994 18.849 206.358 18.849C205.049 18.849 203.837 18.849 202.968 19.0135C202.657 19.073 202.421 19.3251 202.363 19.6366L197.138 47.4389Z"
          fill="white"
        />
        <path
          d="M347.373 44.3688C347.373 61.2294 341.332 75.8253 330.683 86.2805C320.605 96.1266 306.577 102 289.921 102C263.308 102 245.254 84.9224 245.254 57.9777C245.254 40.9736 251.704 26.1047 262.502 15.681C272.684 5.87687 286.985 0 302.793 0C330.06 0 347.377 16.8326 347.377 44.3688H347.373ZM280.141 26.8957C272.819 34.2987 268.602 45.4224 268.602 56.4411C268.602 73.0566 276.637 83.9388 293.092 83.9388C300.553 83.9388 307.463 80.6976 312.77 75.3038C319.771 68.2263 324.018 57.3091 324.018 45.6674C324.018 31.3375 318.434 18.0611 300.293 18.0611C292.4 18.0611 285.496 21.4774 280.137 26.8957H280.141Z"
          fill="white"
        />
        <path
          d="M395.439 2.68497L388.422 39.8398C388.332 40.3229 388.699 40.7709 389.184 40.7709H404.081C404.455 40.7709 404.773 40.5049 404.842 40.1339L411.974 2.39095C412.043 2.01992 412.365 1.75391 412.736 1.75391H432.545C433.034 1.75391 433.4 2.20193 433.307 2.68497L414.958 99.6096C414.889 99.9807 414.567 100.247 414.197 100.247H394.38C393.895 100.247 393.528 99.8022 393.618 99.3191L400.508 61.4257C400.594 60.9427 400.231 60.4982 399.746 60.4982H385.133C384.759 60.4982 384.441 60.7642 384.371 61.1352L377.098 99.6096C377.028 99.9807 376.706 100.247 376.336 100.247H356.526C356.038 100.247 355.671 99.7987 355.765 99.3156L374.103 2.39095C374.172 2.01992 374.494 1.75391 374.865 1.75391H394.678C395.166 1.75391 395.533 2.20193 395.439 2.68497Z"
          fill="white"
        />
        <path
          d="M501.414 31.7115C501.414 53.6299 494.767 78.4744 481.757 91.6248C475.397 98.0547 468.026 101.415 458.776 101.415C436.518 101.415 433.191 79.64 433.191 68.8278C433.191 48.222 439.838 23.3809 453.427 10.0836C459.354 4.23822 466.725 0.583984 476.12 0.583984C498.378 0.583984 501.414 20.3112 501.414 31.7115ZM468.026 22.0684C459.787 30.3989 455.161 56.9936 455.161 71.607C455.161 75.8458 455.885 82.5662 461.954 82.5662C463.543 82.5662 465.277 81.6877 466.725 80.2281C475.542 71.313 479.444 39.895 479.444 29.3733C479.444 25.5756 478.575 19.4362 473.375 19.4362C471.495 19.4362 469.761 20.3147 468.026 22.0684Z"
          fill="white"
        />
        <path
          d="M540.985 2.68497L522.637 99.6096C522.567 99.9807 522.245 100.247 521.875 100.247H502.065C501.577 100.247 501.21 99.7987 501.304 99.3156L519.649 2.39095C519.718 2.01992 520.04 1.75391 520.411 1.75391H540.22C540.708 1.75391 541.075 2.20193 540.982 2.68497H540.985Z"
          fill="white"
        />
        <path
          d="M352.01 19.3798L354.762 3.21929C354.831 2.80627 354.714 2.40374 354.478 2.10972C354.236 1.8192 353.862 1.63019 353.45 1.62669L337.234 1.45518C336.206 1.44118 335.929 2.87977 336.884 3.26129L347.239 8.21761L350.196 19.4078C350.393 20.4264 351.844 20.4089 352.013 19.3833L352.01 19.3798Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2020_65750">
          <rect width="541" height="102" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignupLogo;
