import { Form, message } from "antd";
import React, { useRef, useState } from "react";
import FormInput from "../CommonComponents/FormInput";
import TextArea from "../CommonComponents/TextArea";
import ButtonDark from "../CommonComponents/ButtonDark";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
import useGetDataForAddingAssets from "../../hooks/getData/useGetDataForAddingAssets";
import DiscountOffer from "./DiscountOffer";
import UploadImages from "./UploadImages";
import RentalPrice from "./RentalPrice";
import { useForm } from "antd/es/form/Form";
const AddJetski = ({ t }) => {
  const inputRef = useRef(null);
  const { initialValues, parse, stringify } = useUserDataAndNavigation();
  const isKayak = localStorage.getItem("addAssetType") === "kayak";
  const myThumbnailImages = localStorage.getItem("thumbnail-images");
  const [thumbnailImages, setThumbnailImages] = useState(
    myThumbnailImages ? parse(myThumbnailImages) : []
  );

  const {
    onFinish,
    loading,
    imagesUploadError,
    setImagesUploadError,
    screen,
    setScreen,
    navigate,
  } = useGetDataForAddingAssets();
  const [removeImage, setRemoveImage] = useState(false);
  const isDiscounted = localStorage.getItem("values")
    ? parse(localStorage.getItem("values"))
    : "no";
  const [isDiscount, setIsDiscount] = useState(
    isDiscounted.is_discount || false
  );

  const handleUploadClick = () => {
    if (selectedImages.length >= 1) {
      setImagesUploadError("");
    }
    if (selectedImages.length > 3) {
      message.error(t("maximumLimitExceeded"));
    } else {
      inputRef.current.click();
    }
  };

  const myImages = localStorage.getItem("my-images");
  const addAssetType = localStorage.getItem("addAssetType");
  const [selectedImages, setSelectedImages] = useState(
    myImages ? parse(myImages) : []
  );

  let heading;
  let formHeading;
  let placeHolder;

  switch (addAssetType) {
    case "jet_ski":
      heading = t("addJetskiDetails");
      formHeading = t("jetskiDetails");
      placeHolder = t("singleJetski") + " " + t("model") + "*";
      break;
    case "boat":
      heading = t("addBoatDetails");
      formHeading = t("boatDetails");
      placeHolder = t("boat") + " " + t("model") + "*";
      break;
    case "boat_trailer":
      heading = t("add") + " " + t("BoatTrailer") + " " + t("details");
      formHeading = t("BoatTrailer") + " " + t("details");
      placeHolder = t("BoatTrailer") + " " + t("name") + "*";
      break;
    case "kayak":
      heading = t("addKayakDetails");
      formHeading = t("kayakDetails");
      placeHolder = t("singleKayak") + " " + t("model") + "*";
      break;
    default:
      break;
  }
  const isBoatTrailer = addAssetType === "boat_trailer";
  const [form] = useForm();
  return (
    <div className="bg-[#E2F3F7]  shadow-custom-lg text-dark-blue font-roobert-rg z-20 w-[60%] 2xl:w-[45%] h-[65vh] overflow-y-auto py-[40px] rounded-[20px]">
      <h1 className="text-[28px] mb-[5px] 2xl:text-[35px] font-roobert-rg px-[50px] text-center  leading-[50px]">
        {heading}
      </h1>
      <Form
        name="basic"
        onFinish={(values) => onFinish(values, isDiscount)}
        autoComplete="off"
        initialValues={initialValues}
        form={form}
      >
        {screen === 1 && (
          <div className={` w-full  px-[50px] mt-[10px]`}>
            <h1 className="text-[22px] mb-[5px] 2xl:text-[24px] font-roobert-rg">
              {formHeading}
            </h1>
            <div className="flex w-full gap-x-[20px]">
              <div className="w-full">
                <FormInput
                  name={"name"}
                  placeHolder={placeHolder}
                  rules={[
                    {
                      required: true,
                      message: t("enterYourModel"),
                    },
                  ]}
                  type={"text"}
                  styleClasses={"rounded-full  font-roobert-rg pl-[20px]"}
                />
              </div>

              <div className="w-full">
                {isBoatTrailer ? (
                  <FormInput
                    name={"weight_in_kg"}
                    step={"any"}
                    type={"number"}
                    rules={[
                      {
                        required: true,
                        message: t("enterweightinKG"),
                      },
                    ]}
                    placeHolder={t("weightinKG") + "*"}
                    styleClasses={"rounded-full  font-roobert-rg pl-[20px]"}
                  />
                ) : (
                  <FormInput
                    name={"occupancy"}
                    type={"number"}
                    rules={[
                      {
                        required: true,
                        message: t("eneterNumberOfPeople"),
                      },
                      {
                        validator: (_, value) => {
                          const range =
                            addAssetType === "boat"
                              ? 100
                              : addAssetType === "jet_ski"
                              ? 5
                              : addAssetType === "kayak"
                              ? 10
                              : 10;
                          if (value > range) {
                            return Promise.reject(
                              new Error(t("maximumLimitExceeded"))
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    placeHolder={t("noofpeople")}
                    styleClasses={"rounded-full   font-roobert-rg pl-[20px]"}
                  />
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-[20px]">
              {!isKayak && !isBoatTrailer && (
                <div className="">
                  <h1 className="text-[22px] 2xl:text-[24px] font-roobert-rg">
                    {t("speed")}
                  </h1>
                  <FormInput
                    name={"horse_power"}
                    placeHolder={t("horse_power") + "*"}
                    step={"any"}
                    styleClasses={
                      "rounded-full mt-[5px] font-roobert-rg pl-[20px]"
                    }
                    type={"number"}
                    rules={[
                      {
                        required: true,
                        message: t("pleaseEnter") + " " + t("speed") + "!",
                      },
                      {
                        validator: (_, value) => {
                          const range =
                            addAssetType === "boat"
                              ? 100000
                              : addAssetType === "jet_ski"
                              ? 1500
                              : 10;
                          if (value > range) {
                            return Promise.reject(
                              new Error(t("maximumLimitExceeded"))
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  />
                </div>
              )}
              <div className="">
                <h1 className="text-[22px] 2xl:text-[24px] font-roobert-rg">
                  {t("foot")}
                </h1>
                <FormInput
                  name={"size_in_feet"}
                  placeHolder={t("foot") + "*"}
                  type={"number"}
                  step={"any"}
                  styleClasses={
                    "rounded-full font-roobert-rg mt-[5px] pl-[20px]"
                  }
                  rules={[
                    {
                      required: true,
                      message: t("enterSize"),
                    },
                    {
                      validator: (_, value) => {
                        const range =
                          addAssetType === "boat"
                            ? 1500
                            : addAssetType === "jet_ski"
                            ? 50
                            : addAssetType === "kayak"
                            ? 100
                            : addAssetType === "boat_trailer"
                            ? 150
                            : 10;
                        if (value > range) {
                          return Promise.reject(
                            new Error(t("maximumLimitExceeded"))
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                />
              </div>
            </div>
            <TextArea
              rows={5}
              placeHolder={t("writeDesrciption")}
              name="description"
              styleClasses={
                "!rounded-[20px]  font-roobert-rg pt-[10px] pl-[20px]"
              }
            />

            <UploadImages
              t={t}
              selectedImages={selectedImages}
              removeImage={removeImage}
              stringify={stringify}
              parse={parse}
              myImages={myImages}
              inputRef={inputRef}
              setRemoveImage={setRemoveImage}
              setSelectedImages={setSelectedImages}
              handleUploadClick={handleUploadClick}
              error={imagesUploadError}
              thumbnailImages={thumbnailImages}
              setThumbnailImages={setThumbnailImages}
            />
          </div>
        )}

        {screen === 2 && (
          <div className={`  w-full  px-[50px] mt-[10px]`}>
            <RentalPrice
              step={screen}
              propertyType={addAssetType}
              sizeInfeet={form.getFieldValue("size_in_feet")}
              form={form}
            />
            <DiscountOffer
              setIsDiscount={setIsDiscount}
              isDiscount={isDiscount}
              t={t}
            />
          </div>
        )}

        <div className="text-white  mt-[30px] gap-x-4 flex justify-center">
          <ButtonDark
            styleClasses={"dark-Btn h-[50px] !w-[140px] !px-[20px]"}
            text={"Back"}
            handleClick={() => {
              if (screen === 1) {
                navigate(-1);
              } else {
                setScreen(1);
              }
            }}
          />
          <ButtonDark
            styleClasses={"dark-Btn h-[50px] !w-[140px] !px-[20px]"}
            text={t("proceed")}
            type="submit"
            loading={loading}
            disabled={loading}
          />
        </div>
      </Form>
    </div>
  );
};

export default AddJetski;
