import React, { useEffect } from "react";
import {
  deleteSeasonDock,
  editSeasonDock,
  seasonDockDetails,
} from "../../API/marina";
import { useLocationStore } from "../../store/store";
import useQueryHook from "../useQueryHook";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";
import useMutationHook from "../useMutationHook";
import { addImagesToFirebase } from "../../utils/addImages";
const useGetDataForEditSeasonalDock = ({ form, refetch }) => {
  const onDone = () => {
    form.submit();
  };
  const userId = localStorage.getItem("userId");
  const [params] = useSearchParams();
  const { isModalOpen, setEdit, setAddRenter, addRenter, edit } =
    useLocationStore();
  const {
    data: dockData,
    isLoading: dockLoading,
    isFetching: dockFetching,
    refetch: dockRefetch,
    error: dockError,
  } = useQueryHook(
    ["fetch-seasondock"],
    async () => {
      return await seasonDockDetails(userId, params.get("id"));
    },
    isModalOpen
  );

  const onSuccess = (Data) => {
    const { message: response } = Data.data ? Data.data : Data;
    message.success(response);
    dockRefetch();
    refetch();
    setEdit(false);
    setAddRenter(false);
  };

  const onError = ({ response }) => {
    const { message: errMessage } = response?.data;
    message.error(errMessage);
  };

  const { mutate } = useMutationHook(
    ["edit-season-dock"],
    async (Data) => {
      return await editSeasonDock(userId, Data, params.get("id"));
    },
    onSuccess,
    onError
  );
  const { mutate: deleteDock } = useMutationHook(
    ["delete-renter"],
    async () => {
      return await deleteSeasonDock(userId, params.get("id"));
    },
    onSuccess,
    onError
  );
  const onDeleteUser = () => {
    deleteDock();
  };

  const onFinish = async (values, addImages) => {
    const images = (addImages || []).filter((image) => !image?.firebase);
    const imageUrls = images.map((image) => image.image);
    const newImagesUrls = await addImagesToFirebase(imageUrls);
    const previousImages = (addImages || []).filter((image) => image?.firebase);
    const previousImagesUrls = previousImages.map((image) => image.image);

    const { boat_length, boat_width, ...otherValues } = values;
    const boatWidth = boat_width === "" ? 0 : boat_width;
    const boatLength = boat_length === "" ? 0 : boat_length;
    mutate({
      renterDetails: {
        ...otherValues,
        boat_length: boatLength,
        boat_width: boatWidth,
      },
      images: [...newImagesUrls, ...previousImagesUrls],
    });
  };

  const handleEdit = () => {
    setEdit(true);
  };

  useEffect(() => {
    if (dockData && !addRenter) {
      const {
        customer_name,
        customer_email,
        customer_contact,
        customer_id,
        boat_type,
        boat_length,
        boat_width,
      } = dockData;
      const formattedBoatLength = boat_length > 0 ? boat_length : "";
      const formattedBoatWidth = boat_width > 0 ? boat_width : "";
      form.setFieldsValue({
        customer_name,
        customer_email,
        customer_contact,
        customer_id,
        boat_type,
        boat_length: formattedBoatLength,
        boat_width: formattedBoatWidth,
      });
    }
  }, [dockData, form, addRenter]);

  return {
    onDone,
    dockData,
    dockError,
    dockLoading,
    dockFetching,
    dockRefetch,
    onFinish,
    mutate,
    handleEdit,
    onDeleteUser,
  };
};

export default useGetDataForEditSeasonalDock;
