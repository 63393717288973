export const transformedData = (inputData) => {
  const arrayObj = Object.entries(inputData)
    .filter(([key, value]) => !isNaN(Number(key.match(/\d+/))))
    .reduce((result, [key, value]) => {
      const id = parseInt(key.match(/\d+/), 10);
      const prefix = key.match(/[a-zA-Z_]+/);
      const existingObject = result.find((obj) => obj.id === id);
      if (existingObject) {
        if (prefix) {
          existingObject[prefix[0]] = value;
        }
      } else {
        const newObj = {
          id,
        };
        if (prefix) {
          newObj[prefix[0]] = value;
        } else {
          newObj["unknown"] = value;
        }

        result.push(newObj);
      }

      return result;
    }, []);
  return arrayObj;
};
