import { useEffect, useState } from "react";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { useMutation, useQuery } from "react-query";
import {
  getInvoicedInformation,
  updateInvoicedInformation,
} from "../../API/marina";
import { Form } from "antd";

const useGetDataForInvoiceInformation = () => {
  const { t, countryOptions, userId } = useUserDataAndNavigation();
  const [form] = Form.useForm();

  const emailOptions = [
    {
      label: t("email"),
      value: "email",
    },
    {
      label: "EHF",
      value: "ehf",
    },
  ];

  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryKey: ["get-invoice-information"],
    queryFn: () => getInvoicedInformation(userId),
  });
  const [activeNum, setActiveNum] = useState("");
  useEffect(() => {
    if (data && data.phone) {
      const extractedNum = data.phone.substring(0, 3);
      setActiveNum(extractedNum);
    }
  }, [data]);

  const initialValues = {
    organization_name: data?.organization_name,
    organization_number: data?.organization_number,
    contact_person: data?.contact_person,
    marina_name: data?.marina_name,
    address: data?.address,
    email: data?.email,
    billing_email: data?.billing_email,
    phone: data?.phone.substring(3),
    billing_type: data?.billing_type,
  };
  const { mutate, isLoading: mutateLoading } = useMutation({
    mutationKey: ["update-invoice-information"],
    mutationFn: (Data) => updateInvoicedInformation(userId, Data),
    onSuccess: (Data) => {
      refetch();
      localStorage.setItem("organizationName", Data?.organization_name);
    },
  });
  const onFinish = (values) => {
    mutate({
      ...values,
      phone: activeNum + values?.phone,
    });
  };

  return {
    emailOptions,
    countryOptions,
    data,
    isLoading,
    isFetching,
    error,
    activeNum,
    setActiveNum,
    initialValues,
    form,
    mutateLoading,
    onFinish,
  };
};

export default useGetDataForInvoiceInformation;
