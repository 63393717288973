import React from "react";
import { Link } from "react-router-dom";
import ArrowDownDark from "../../assets/icons/ArrowDownDark";
import ArrowDark from "../../assets/icons/ArrowDark";
import Arrow from "../../assets/icons/Arrow";

const MenuItems = ({
  location,
  menu,
  lang,
  setCloseActiveMenuChilds,
  closeActiveMenuChilds,
}) => {
  const isActive =
    location === `/${lang}/${menu.path}`
      ? "text-dark-blue bg-[#ACDCE7]"
      : location.split("/")[2] === menu.nameId
      ? `bg-[#E2F3F7]  text-dark-blue`
      : "";
  return (
    <div
      key={menu.id}
      className={`${isActive}  mt-[3px]  font-normal  cursor-pointer   leading-[24.52px]  `}
    >
      <Link to={`/${lang}/${menu.path}`}>
        <div className="flex items-center pl-[20px] justify-between py-[10px] font-roobert-sb  leading-[28px] text-[16px] 2xl:text-[20px]  pr-[15px] lg:pr-[13px]">
          <div className="flex gap-x-[8px] items-center ">
            <div className="">{menu.icon}</div> {menu.name}
          </div>
          {menu.children && !menu.variableColor && <Arrow />}
          {menu.children && menu.variableColor && !closeActiveMenuChilds && (
            <div onClick={() => setCloseActiveMenuChilds(true)}>
              <ArrowDownDark />
            </div>
          )}
          {menu.children && menu.variableColor && closeActiveMenuChilds && (
            <div onClick={() => setCloseActiveMenuChilds(false)}>
              <ArrowDark />{" "}
            </div>
          )}
        </div>
      </Link>
      {menu.children && menu.variableColor && !closeActiveMenuChilds && (
        <div className="2xl:text-[16px] text-[14px]    bg-[#E2F3F7]  w-full  flex flex-col 2xl:gap-y-[5px] ">
          {menu?.children?.map((menuChild) => {
            return (
              <Link to={`/${lang}/${menuChild.path}`} key={menuChild.id}>
                <div
                  className={`${
                    location.split("/")[3] === menuChild.nameId
                      ? "bg-[#ACDCE7]"
                      : ""
                  } cursor-pointer font-roobert-rg  pl-[52px] py-[5px] text-center  flex items-center  `}
                >
                  {menuChild.name}
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MenuItems;
