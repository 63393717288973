import React, { useState } from "react";

import { Form, Input } from "antd";

const FormInput = ({
  name,
  rules,
  type,
  dependencies,
  placeHolder,
  addonBefore,
  value,
  styleClasses,
  onChange,
  disabled,
  outlineClasses,
  step,
  suffix,
  label,
  required,
  requiredMessage,
}) => {
  const onHandleWheel = (event) => {
    event.target.blur();
    event.stopPropagation();
  };
  const requiredRule = [
    {
      required: required,
      message: requiredMessage,
    },
  ];

  if (type === "password") {
    return (
      <Form.Item
        name={name}
        rules={requiredRule.concat(rules)}
        dependencies={dependencies}
        className={`  border-b border-dark-blue  !outline-none ${
          outlineClasses ? outlineClasses : ""
        }`}
      >
        <Input.Password
          type={type}
          onChange={onChange}
          className={`h-[40px] !bg-transparent  !outline-none !shadow-none !placeholder:text-[#A3A3A3] text-[14px] font-roobert-rg text-black ${styleClasses}  border-none shadow-input`}
          placeholder={placeHolder}
          addonBefore={addonBefore}
          onWheel={onHandleWheel}
          value={value}
          styles={{
            input: {
              background: "transparent",
            },
          }}
          min={0}
          step={step}
          disabled={disabled ?? false}
        />
      </Form.Item>
    );
  } else {
    return (
      <Form.Item
        name={name}
        rules={rules ? requiredRule.concat(rules) : requiredRule}
        dependencies={dependencies}
        label={label ?? ""}
        className={`!rounded-[30px] ${outlineClasses ? outlineClasses : ""}`}
      >
        <Input
          type={type}
          onChange={onChange}
          className={`h-[40px] !placeholder:text-[#A3A3A3] text-[14px] font-roobert-rg text-black ${styleClasses}  border-none !outline-none shadow-input`}
          placeholder={placeHolder}
          addonBefore={addonBefore}
          onWheel={onHandleWheel}
          value={value}
          min={0}
          step={step}
          disabled={disabled ?? false}
          suffix={suffix}
        />
      </Form.Item>
    );
  }
};

export default FormInput;
