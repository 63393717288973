import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import "../../App.css";
import CustomTooltip from "../CommonComponents/CustomTooltip";

import { filterData, sortData } from "../../utils/dataManipulation.js";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";

const Graph = ({ left, data, type }) => {
  const { monthNames, t } = useUserDataAndNavigation();

  const translateAverage = t("average");

  const sortedData = sortData(data);

  const newSortData = sortedData?.map((data) => {
    return {
      ...data,
      [translateAverage]: data?.average || data?.average_cost || 0,
    };
  });

  const newSortedData = filterData(
    newSortData,
    type,
    translateAverage,
    monthNames
  );
  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={12}
          textAnchor="middle"
          fill="#046997"
          fontSize="10px"
        >
          {payload.value}
        </text>
      </g>
    );
  };
  return (
    <ResponsiveContainer width="99%" className="h-full ">
      <AreaChart
        data={newSortedData ?? []}
        margin={{
          right: 40,
          left: left,
        }}
        // width={1000}
        height={4500}
        interval={0}
      >
        <CartesianGrid stroke="#F0F0F0" />
        <XAxis
          dataKey="period"
          isAnimationActive={false}
          interval={0}
          stroke="#F0F0F0"
          tickLine={{ display: "none" }}
          tick={{
            fill: "#046997",
            fontSize: "2px", // Adjust the font size as needed
          }}
        />
        <YAxis
          stroke="#F0F0F0"
          tickLine={{ display: "none" }}
          tick={{ fill: "#046997" }}
          tickCount={11}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Area
          type="monotone"
          dataKey="average"
          stroke="#6DB9D0"
          strokeWidth="3px"
          fill="url(#colorUv)"
          activeDot={{ r: 8, stroke: "#FFFFFF", fill: "#18374F" }}
        />
        <defs>
          <linearGradient
            id="colorUv"
            x1="515"
            y1="0.0516357"
            x2="515"
            y2="298.948"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6DB9D0" />
            <stop offset="1" stopColor="#6DB9D0" stopOpacity="0.01" />
          </linearGradient>
        </defs>
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Graph;
