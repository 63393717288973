import "./App.css";
import ReactRouter from "./router";

function App() {
  return (
    <>
      <div className="overlay">
        <ReactRouter />
      </div>
    </>
  );
}

export default App;
