import React from "react";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import RoundedCheckbox from "../components/CommonComponents/RoundedCheckbox";
import Navbar from "../components/CommonComponents/Navbar";
import useExportCalls from "../hooks/useExportCalls";
import useGetDataForExports from "../hooks/getData/useGetDataForExports";
import useConstants from "../hooks/getData/useConstants";
import { useTranslation } from "react-i18next";
const Export = () => {
  const {
    importType,
    changeImportType,
    handleImportType,
    handleProceedExport,
  } = useGetDataForExports();

  const { data } = useExportCalls();
  const { headers } = useConstants();
  const { t } = useTranslation();
  return (
    <div className="h-screen  w-full overflow-y-hidden">
      <Navbar backToDashboard={false} />
      <div className="h-full  font-roobert-rg flex flex-col items-center  justify-center ">
        <div className="w-[60%]">
          <h1 className="2xl:text-[35px] text-left text-[28px] leading-[50px]">
            {t("exportScreen")}
          </h1>
          <div className="flex gap-x-[36px] mt-[30px] 2xl:mt-[60px] ">
            <div
              className={`${
                importType === "csv" ? "bg-[#D7EEF3]" : "bg-[#FFFFFF]"
              } flex justify-center w-[110%]   relative items-center h-[200px] pl-[32px] pr-[18px] rounded-[20px] cursor-pointer`}
              onClick={() => handleImportType("csv")}
            >
              <div className="absolute top-2 right-2 ">
                <RoundedCheckbox
                  onChange={changeImportType}
                  value={"csv"}
                  checked={importType === "csv" ? true : false}
                />
              </div>
              <h1 className="2xl:text-[35px]  text-[28px] text-center">
                {t("exportascsv")}
              </h1>
            </div>
            <div
              className={` ${
                importType === "pdf" ? "bg-[#D7EEF3]" : "bg-[#FFFFFF]"
              }  h-[200px] pl-[32px] w-[110%] relative flex justify-center items-center pr-[18px] rounded-[20px] cursor-pointer`}
              onClick={() => handleImportType("pdf")}
            >
              <div className="absolute top-2 right-2 ">
                <RoundedCheckbox
                  onChange={changeImportType}
                  value={"pdf"}
                  checked={importType === "pdf" ?? false}
                />
              </div>
              <h1 className="2xl:text-[35px]  text-[28px] text-center">
                {t("exportaspdf")}
              </h1>
            </div>
          </div>
          <div className="2xl:mt-[50px] mt-[30px]">
            <ButtonDark
              handleClick={() => handleProceedExport(importType, data, headers)}
              text={t("proceed")}
              styleClasses={"dark-Btn h-[50px] !w-[160px]"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Export;
