import React from "react";
import { Image } from "antd";
const ImageCard = ({ src }) => {
  return (
    <div className=" w-full carousel-img rounded-[10px]">
      <Image
        src={src}
        className="overflow-y-auto rounded-[10px]"
        height={200}
        width={"100%"}
        alt="carousel-img"
        style={{
          borderRadius: "10px",
        }}
      />
    </div>
  );
};

export default ImageCard;
