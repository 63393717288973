import React from "react";

const Upload = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 4908">
        <rect
          id="Rectangle 4502"
          width="45"
          height="45"
          rx="10"
          fill="#6DB9D0"
        />
        <path
          id="Vector"
          d="M27.1429 22.8571H22.8571V27.1429C22.8571 27.3702 22.7668 27.5882 22.6061 27.7489C22.4453 27.9097 22.2273 28 22 28C21.7727 28 21.5547 27.9097 21.3939 27.7489C21.2332 27.5882 21.1429 27.3702 21.1429 27.1429V22.8571H16.8571C16.6298 22.8571 16.4118 22.7668 16.2511 22.6061C16.0903 22.4453 16 22.2273 16 22C16 21.7727 16.0903 21.5547 16.2511 21.3939C16.4118 21.2332 16.6298 21.1429 16.8571 21.1429H21.1429V16.8571C21.1429 16.6298 21.2332 16.4118 21.3939 16.2511C21.5547 16.0903 21.7727 16 22 16C22.2273 16 22.4453 16.0903 22.6061 16.2511C22.7668 16.4118 22.8571 16.6298 22.8571 16.8571V21.1429H27.1429C27.3702 21.1429 27.5882 21.2332 27.7489 21.3939C27.9097 21.5547 28 21.7727 28 22C28 22.2273 27.9097 22.4453 27.7489 22.6061C27.5882 22.7668 27.3702 22.8571 27.1429 22.8571Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default Upload;
