import FormSideImage from "../components/FormSideImage";
import app from "../config/firebaseConfig";
import { Form } from "antd";
import FormInput from "../components/CommonComponents/FormInput";
import Label from "../components/CommonComponents/Label";
import useChangeLanguage from "../utils/changeLanguage";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import DarkLogo from "../assets/icons/DarkLogo";
import SelectDropdown from "../components/CommonComponents/Select";
import useGetDataForSignin from "../hooks/getData/useGetDataForSignin";
import { langOptions } from "../constants/constants";
import { useUserDataAndNavigation } from "../hooks/useUserDataAndNavigation";
import { Link } from "react-router-dom";
import { useState } from "react";
import Dialog from "../components/CommonComponents/Modal/Dialog";
import ForgotPasswordModal from "../components/CommonComponents/Modal/ForgotPasswordModal";
const Signin = () => {
  const {
    handleSignIn,
    form,
    handleCancelForgotPasswordModal,
    onSubmit,
    instruction,
    setInstructions,
    openForgotPasswordModal,
    setOpenForgotPasswordModal,
    loading,
  } = useGetDataForSignin();
  const { changeLanguage } = useChangeLanguage();
  const { lang, t } = useUserDataAndNavigation();

  // *********************
  // return
  // *********************
  return (
    <div className="h-screen grid grid-cols-2">
      <Dialog
        isModalOpen={openForgotPasswordModal}
        onCancelModal={handleCancelForgotPasswordModal}
        width={600}
        component={
          <ForgotPasswordModal
            onCancel={handleCancelForgotPasswordModal}
            instruction={instruction}
            setInstructions={setInstructions}
            onSubmit={onSubmit}
            loading={loading}
            t={t}
          />
        }
      />
      <section className="">
        <FormSideImage />
      </section>
      <section className="pb-3 pt-6 lg:pt-8 lg:pb-5 form-wrap   flex flex-col items-center  signin ">
        <div className="flex items-center justify-end w-full pr-[26px]">
          <SelectDropdown
            options={langOptions}
            defaultValue={lang || "no"}
            handleChange={(value) => {
              changeLanguage(value);
            }}
          />
        </div>
        <div className="flex  scale-90  mt-[40px]  ">
          <DarkLogo />
        </div>
        <div className="w-[50%] mt-[60px]  flex flex-col h-full  2xl:items-start ">
          <h2 className=" text-dark-blue mb-5  text-[18px] 2xl:text-[28px] tracking-wider font-roobert-rg ">
            {t("helloCaptain")}!
          </h2>

          <Form
            form={form}
            onFinish={async (values) => {
              await handleSignIn(values);
            }}
            className="w-full h-auto  flex flex-col justify-center items-center   2xl:gap-y-[30px] gap-y-[10px]"
          >
            <div className="w-full">
              <Label text={t("email")} />
              <FormInput
                name={"email"}
                styleClasses={"!bg-transparent mt-[-10px]"}
                type={"email"}
                rules={[
                  {
                    required: true,
                    message: t("emailisrequired"),
                  },
                ]}
              />
            </div>
            <div className="w-full">
              <Label text={t("password")} />
              <FormInput
                name={"password"}
                type={"password"}
                styleClasses={"!bg-transparent mt-[-10px]"}
                rules={[
                  {
                    required: true,
                    message: t("passwordisrequired"),
                  },
                ]}
              />
              <p
                onClick={() => setOpenForgotPasswordModal(true)}
                className="mt-[-15px] text-right underline cursor-pointer"
              >
                {t("forgotPassword")}?
              </p>
            </div>
            <div className="flex justify-center">
              <ButtonDark
                styleClasses={"dark-Btn h-[50px] w-[150px] "}
                text={t("login")}
                type={"submit"}
              />
            </div>
          </Form>
          <p className="tracking-widest w-full 2xl:pt-[50px] pt-[30px] text-center font-roobert-l text-[16px] text-dark-blue">
            {t("noAccount")}
            <Link
              to={`/${lang}/signup`}
              className="underline cursor-pointer font-roobert-sb"
            >
              {t("registerSingle")}
            </Link>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Signin;
