import React, { useState } from "react";
import Layout from "../Layout/Layout";
import PageHeaders from "../components/CommonComponents/PageHeaders";
import useGetDataForSeasonalDocks from "../hooks/getData/useGetDataForSeasonalDocks";
import Table from "../components/CommonComponents/Table/Table";
import SDSDetails from "../components/SeasonalDockSpots/SDSDetails";
import { useTranslation } from "react-i18next";
import WaitingList from "./WaitingList";
import { Form } from "antd";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import { useNavigate, useParams } from "react-router-dom";
import AcceptOrRejectRequest from "../components/SeasonalDockSpots/AcceptOrRejectRequest";
import SearchBar from "../components/CommonComponents/SearchBar";
import { handleSearchChange, handleSearchClick } from "../utils/handleSearch";
import SearchButton from "../components/CommonComponents/SearchButton";
import Dialog from "../components/CommonComponents/Modal/Dialog";
import ClubMembers from "./ClubMembers";
import { dropDownProps } from "../constants/seasonDockConstants";
const SeasonalDocks = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const {
    columns,
    data,
    isModalOpen,
    memberRequests,
    filter,
    filterData,
    handleClose,
    refetch,
    onSelectChange,
    selectedRowKeys,
    handleRequestModal,
    requestModal,
    onClickAcceptOrReject,
    requestModalLoading,
    guestDocksRequestLoading,
    params,
    setParams,
    error,
    isLoading,
    isFetching,
    guestDocksRequestFetching,
    guestDocksRequestError,
    paramsObject,
  } = useGetDataForSeasonalDocks({ enabled: true });
  const [searchValue, setSearchValue] = useState("");
  const { lang } = useParams();
  if (filter !== "wait-list" && filter !== "club-member") {
    return (
      <Layout page={t("seasonalDocks")}>
        <Dialog
          isModalOpen={requestModal.active}
          onCancelModal={() => handleRequestModal(false)}
          component={
            <AcceptOrRejectRequest
              showModal={requestModal}
              onClickAcceptOrReject={onClickAcceptOrReject}
              handleRequestModal={handleRequestModal}
              loading={requestModalLoading}
            />
          }
        />
        <Dialog
          isModalOpen={isModalOpen}
          onCancelModal={handleClose}
          component={
            <SDSDetails
              form={form}
              handleClose={handleClose}
              refetch={refetch}
            />
          }
          styles={"seasonaldocks"}
        />
        <div className="px-[25px] text-dark-blue pb-[33px]">
          {!memberRequests && (
            <PageHeaders
              heading={t("seasonalDocks")}
              exportData={true}
              importData={true}
              sendInvoice={true}
              importUrl={`/${lang}/import/season-dock`}
              exportUrl={`/${lang}/export/season-dock?type=${filter ?? ""}`}
              dropDown={true}
              dropDownProps={dropDownProps(t, setParams, paramsObject)}
            />
          )}
          {memberRequests && (
            <>
              <h1 className="mt-[10px] text-[28px] 2xl:text-[35px] leading-[50px] font-roobert-rg">
                {t("requestFromclubmember")}
              </h1>
              <p className="2xl:text-[22px] mt-[20px] text-[20px] w-[65%] font-roobert-rg leading-[35px]">
                {t("requestText1")}
              </p>
              <p className="2xl:text-[22px] mt-[20px] w-[65%] text-[20px] font-roobert-rg leading-[35px]">
                {t("requestText2")}
              </p>
            </>
          )}

          {/* <div className='h-[40px]'>
            {selectedRowKeys.length === 1 &&
              requestedMember &&
              location === `/${lang}/seasonaldocks/member-requests` && (
                <div className='w-full flex justify-end gap-x-[20px] pr-[20px]  items-center'>
                  <IconBtn
                    text={t('acceptRequest')}
                    styleClasses={'!bg-[#D9F2DD] !border-none text-dark-blue'}
                    icon={<AcceptCircle />}
                    handleClick={() => handleRequestModal(true, 'accept')}
                  />
                  <IconBtn
                    text={t('rejectRequest')}
                    styleClasses={'!bg-[#FFC4B7] !border-none text-dark-blue'}
                    icon={<CancelCircle />}
                    handleClick={() => handleRequestModal(true, 'reject')}
                  />
                </div>
              )}
          </div> */}
          <div className=" py-[30px]  mt-[25px] bg-[#FFFFFF] pb-[108px] rounded-[40px] my-table">
            <div className=" flex items-center justify-between pb-[20px] pr-[20px]">
              <div className="flex gap-x-[10px] pl-[45px]">
                <SearchBar
                  value={searchValue}
                  placeholder={t("search") + " " + t("seasonalDocks")}
                  handleSearchChange={(e) =>
                    handleSearchChange(e, setSearchValue, params, setParams)
                  }
                  handleSubmit={() =>
                    handleSearchClick(searchValue, setParams, params)
                  }
                />
                <SearchButton
                  text={t("search")}
                  handleSearchClick={() =>
                    handleSearchClick(searchValue, setParams, params)
                  }
                />
              </div>
              <ButtonDark
                handleClick={() => {
                  navigate(`/${lang}/import/season-dock`);
                }}
                styleClasses={"light-Btn h-[40px]"}
                text={t("addSeasonalDock")}
              />
            </div>
            <div className="pt-[20px]">
              <Table
                data={filterData}
                columns={columns}
                selection={true}
                selectedRowKeys={selectedRowKeys}
                onSelectChange={onSelectChange}
                totalPages={data?.total_pages * 20}
                pagination={true}
                pageSize={20}
                loading={
                  guestDocksRequestLoading ||
                  isLoading ||
                  isFetching ||
                  guestDocksRequestFetching
                }
                error={error || guestDocksRequestError}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
  if (filter === "wait-list") {
    return <WaitingList />;
  }
  if (filter === "club-member") {
    return <ClubMembers />;
  }
};

export default SeasonalDocks;
