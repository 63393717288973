import React from "react";
import { chatListDescription } from "../../constants/chatsConstants";

const ChatsList = () => {
  return (
    <div className="hidden md:flex h-full items-center justify-center">
      <h1 className="text-lg 2xl:text-xl text-center max-w-xl 2xl:max-w-2xl mx-5 text-kprimary">
        {chatListDescription}
      </h1>
    </div>
  );
};

export default ChatsList;
