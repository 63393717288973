import { useEffect } from "react";
import useUserInfo from "../../hooks/useUserInfo";
import { useQuery } from "react-query";
import { getMyProfile } from "../../API/marina";
import { generateHMAC } from "../../utils/createHMACSHA256";
import Intercom from "@intercom/messenger-js-sdk";

const IntercomComponent = () => {
  const userId = localStorage.getItem("userId");
  const { currentUser } = useUserInfo();

  const { data: profileData, isLoading } = useQuery({
    queryKey: ["get-my-profile-data"],
    queryFn: () => {
      if (userId) {
        return getMyProfile(userId);
      }
      return { myProfile: {} };
    },
  });

  useEffect(() => {
    try {
      if (currentUser && !isLoading) {
        Intercom({
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          api_base: process.env.REACT_APP_INTERCOM_API_BASE,
          email: currentUser.email,
          name: profileData?.myProfile?.full_name,
          user_hash: generateHMAC(currentUser.email),
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [userId, profileData, currentUser, isLoading]);
  return <></>;
};

export default IntercomComponent;
