import create from "zustand";
import { persist } from "zustand/middleware";
import { defaultLanguage } from "../constants/constants";

export const useLocationStore = create(
  persist(
    (set) => ({
      position: {},
      newPosition: {},
      center: {},
      isLoading: false,
      deleteModal: false,
      edit: false,
      addRenter: false,
      lang: defaultLanguage,
      setLanguage: (lang) => set(() => ({ lang })),
      isModalOpen: false,
      organizedKeys: ["0", "1", "2", "3", "4", "5"],
      organizedAssetKeys: ["1", "2", "3", "4", "5", "6"],
      setOrganizedAssetKeys: (array) =>
        set(() => ({ organizedAssetKeys: array })),
      setOrganizedKeys: (array) => set(() => ({ organizedKeys: array })),
      setAddRenter: (bool) => set(() => ({ addRenter: bool })),
      setIsModalOpen: (bool) => set(() => ({ isModalOpen: bool })),
      setEdit: (bool) => set(() => ({ edit: bool })),
      addPosition: (location) => set(() => ({ position: location })),
      addCenter: (location) => set(() => ({ center: location })),
      addNewPosition: (location) => set(() => ({ newPosition: location })),
      openOrCloseDeleteModal: (bool, memberId) =>
        set(() => ({ deleteModal: bool, memberId })),
    }),
    {
      name: "locationStore", // name for the persisted data in local storage
      getStorage: () => localStorage, // specify the storage type
      onRehydrate: (state, setState) => {
        setState({ organizedKeys: state.organizedKeys });
      },
    }
  )
);

export const useSignupStore = create(
  persist(
    (set) => ({
      subscriptionData: {
        code: null,
        description: null,
        name: null,
        period: null,
        price: null,
      },
      signupValues: {},
      addSubscriptionData: (Data) => set(() => ({ subscriptionData: Data })),
      addSignupValues: (Data) => set(() => ({ signupValues: Data })),
    }),
    {
      name: "signupStore",
      getStorage: () => localStorage,
    }
  )
);

export const addAssetStore = create(
  persist(
    (set) => ({
      docksLocation: [],
      setDocksLocation: (data) => set(() => ({ docksLocation: data })),
      active: false,
      setActive: (bool) => set(() => ({ active: bool })),
      activeDockNumber: 1,
      setActiveDockNumber: (number) =>
        set(() => ({ activeDockNumber: number })),
      isConnectionEnabled: false,
      setConnectionEnabled: (bool) =>
        set(() => ({ isConnectionEnabled: bool })),
      resetToInitialValues: () => {
        set(() => ({
          docksLocation: [],
          active: false,
          activeDockNumber: 1,
          isConnectionEnabled: false,
        }));
        localStorage.removeItem("addAssetsStore");
      },
    }),
    {
      name: "addAssetsStore",
      getStorage: () => localStorage,
    }
  )
);
