import React from "react";
import FormInput from "../CommonComponents/FormInput";
import ButtonDark from "../CommonComponents/ButtonDark";
import Spinner from "../CommonComponents/Spinner";
import useGetDataForRentalPrice from "../../hooks/getData/useGetDataForRentalPrice";

const RentalPrice = ({ step, sizeInfeet, propertyType, form }) => {
  const {
    isLoading,
    error,
    t,
    autoFill,
    onClickAutoFill,
    currency,
    currencyLoading,
    currencyError,
  } = useGetDataForRentalPrice({
    enabled: step === 2 && propertyType !== "guest_dock",
    sizeInfeet,
    propertyType,
    form,
  });
  if (isLoading || currencyLoading) {
    return <Spinner />;
  }
  if (error || currencyError) {
    return error;
  }
  return (
    <div className="">
      <h1 className="text-[22px] text-center 2xl:text-[24px] font-roobert-rg">
        {t("price")}
      </h1>
      {propertyType !== "guest_dock" && (
        <>
          <p className="text-[14px]  text-center 2xl:text-[16px] mt-[10px]">
            {t("rentalPriceDescription")}.
          </p>
          <div className="my-5  flex justify-center">
            <ButtonDark
              text="Autofill"
              styleClasses={` ${
                autoFill
                  ? " bg-[#6DB9D0] text-[#FFFFFF] "
                  : "bg-[#FFFFFF] border-dark-blue text-dark-blue border"
              } !text-[18px]  `}
              handleClick={() => onClickAutoFill(autoFill)}
            />
          </div>
        </>
      )}
      <div className=" grid grid-cols-2 gap-y-[50px] mt-[5px] gap-x-[20px]">
        <FormInput
          name={"rate_per_hour"}
          step={"any"}
          type={"number"}
          label={t("hour")}
          styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
          suffix={currency}
          rules={[
            {
              required: true,
              message: t("pleaseEnter") + " " + t("rate_per_hour") + "!",
            },
          ]}
        />
        <FormInput
          name={"rate_per_day"}
          type={"number"}
          step={"any"}
          suffix={currency}
          label={t("day")}
          styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
          rules={[
            {
              required: true,
              message: t("pleaseEnter") + " " + t("rate_per_day") + "!",
            },
          ]}
        />
        {propertyType !== "guest_dock" && (
          <>
            <FormInput
              name={"rate_per_week"}
              label={t("week")}
              step={"any"}
              suffix={currency}
              type={"number"}
              styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
            />

            <FormInput
              name={"rate_per_month"}
              label={t("month")}
              step={"any"}
              type={"number"}
              suffix={currency}
              styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
            />
            <FormInput
              name={"rate_per_season"}
              step={"any"}
              type={"number"}
              suffix={currency}
              label={t("season")}
              styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RentalPrice;
