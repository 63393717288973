import React from "react";
import { useTranslation } from "react-i18next";
import Cancel from "../../assets/icons/Cancel";
import ButtonDark from "../CommonComponents/ButtonDark";

const VideoModal = ({ onCloseVideoModal, iframeRef }) => {
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");
  const youtubeVideoSrc =
    language === "nor"
      ? "https://www.youtube.com/embed/o618BKdI49k"
      : `https://www.youtube.com/embed/_n1zdYgHNWc`;

  return (
    <div className="h-[80vh]  relative rounded-[10px] flex text-dark-blue font-roobert-rg flex-col items-center justify-center">
      <div className="absolute top-1.5 px-[20px] pt-[20px] right-3">
        <Cancel handleClose={onCloseVideoModal} />
      </div>
      <h1 className="text-[28px] 2xl:text-[35px] ">{t("hithere")}!</h1>
      <h3 className="text-[22px] text-center">{t("checkoutText")}</h3>
      <div className="bg-black h-[40vh] mt-[20px] rounded-[20px] w-[70%]">
        <iframe
          title="YouTube Video"
          width="100%"
          height="100%"
          className="rounded-[20px]"
          src={youtubeVideoSrc}
          frameBorder="0"
          allowFullScreen
          ref={iframeRef}
        ></iframe>
      </div>
      <ButtonDark
        handleClick={onCloseVideoModal}
        styleClasses={"white-Btn h-[40px] mt-[20px]"}
        text={t("continue")}
      />
    </div>
  );
};

export default VideoModal;
