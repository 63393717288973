import React from "react";
import UploadImages from "../Maps/UploadImages";
import FormInput from "../CommonComponents/FormInput";
import TextArea from "../CommonComponents/TextArea";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
import { getAssetModelLabel } from "../../utils/addAssetUtils";
import {
  getRangeForHorsePower,
  getRangeForOccupancy,
  getRangeForSizeInFeet,
} from "../../utils/getAssetRanges";
import { validatorFnc } from "../../utils/getRequiredFn";

const AddAssetScreenOne = ({
  isBoatTrailer,
  assetType,
  isKayak,
  selectedImages,
  removeImage,
  myImages,
  inputRef,
  thumbnailImages,
  setThumbnailImages,
  imagesUploadError,
  handleUploadClick,
  setSelectedImages,
  setRemoveImage,
}) => {
  const { t, stringify, parse } = useUserDataAndNavigation();

  return (
    <>
      <div className="flex w-full gap-[20px]">
        <div className="w-full">
          <FormInput
            name={"name"}
            label={getAssetModelLabel(assetType, t)}
            required={true}
            requiredMessage={t("enterYourModel")}
            type={"text"}
            styleClasses={"rounded-full  font-roobert-rg pl-[20px]"}
          />
        </div>

        <div className="w-full">
          {isBoatTrailer ? (
            <FormInput
              name={"weight_in_kg"}
              step={"any"}
              type={"number"}
              required={true}
              requiredMessage={t("enterweightinKG")}
              label={t("weightinKG") + "*"}
              styleClasses={"rounded-full  font-roobert-rg pl-[20px]"}
            />
          ) : (
            <FormInput
              name={"occupancy"}
              type={"number"}
              required={true}
              requiredMessage={t("eneterNumberOfPeople")}
              rules={[
                {
                  validator: (_, value) => {
                    const range = getRangeForOccupancy(assetType);
                    return validatorFnc(
                      value,
                      range,
                      "maximumLimitExceeded",
                      t
                    );
                  },
                },
              ]}
              label={t("noofpeople")}
              styleClasses={"rounded-full   font-roobert-rg pl-[20px]"}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 !mt-[60px] gap-x-[20px]">
        {!isKayak && !isBoatTrailer && (
          <div className="">
            <FormInput
              name={"horse_power"}
              label={t("horse_power") + "*"}
              step={"any"}
              styleClasses={"rounded-full mt-[5px] font-roobert-rg pl-[20px]"}
              type={"number"}
              required={true}
              requiredMessage={t("pleaseEnter") + " " + t("speed") + "!"}
              rules={[
                {
                  validator: (_, value) => {
                    const range = getRangeForHorsePower(assetType);
                    return validatorFnc(
                      value,
                      range,
                      "maximumLimitExceeded",
                      t
                    );
                  },
                },
              ]}
            />
          </div>
        )}
        <div className="">
          <FormInput
            name={"size_in_feet"}
            label={t("foot") + "*"}
            type={"number"}
            step={"any"}
            styleClasses={"rounded-full font-roobert-rg mt-[5px] pl-[20px]"}
            required={true}
            requiredMessage={t("enterSize")}
            rules={[
              {
                validator: (_, value) => {
                  const range = getRangeForSizeInFeet(assetType);
                  return validatorFnc(value, range, "maximumLimitExceeded", t);
                },
              },
            ]}
          />
        </div>
      </div>
      <div className=" h-[200px] mt-[80px]">
        <h1 className=" font-roobert-sb text-dark-blue text-[26px] text-center">
          {t("description")}
        </h1>
        <TextArea
          rows={5}
          name="description"
          styleClasses={
            "!rounded-[20px] mt-[20px]  font-roobert-rg pt-[10px] pl-[20px]"
          }
        />
      </div>

      <div className="!mt-[40px]">
        <UploadImages
          t={t}
          selectedImages={selectedImages}
          removeImage={removeImage}
          stringify={stringify}
          parse={parse}
          myImages={myImages}
          inputRef={inputRef}
          setRemoveImage={setRemoveImage}
          setSelectedImages={setSelectedImages}
          handleUploadClick={handleUploadClick}
          error={imagesUploadError}
          thumbnailImages={thumbnailImages}
          setThumbnailImages={setThumbnailImages}
        />
      </div>
    </>
  );
};

export default AddAssetScreenOne;
