import React from "react";
import FormSideImage from "../components/FormSideImage";
import SignupLogo from "../assets/icons/SignupLogo";
import SelectDropdown from "../components/CommonComponents/Select";
import TabBtn from "../components/CommonComponents/TabBtn";
import ProgressSteps from "../components/Signup/ProgressSteps";
import useChangeLanguage from "../utils/changeLanguage";
import AddPassword from "../components/Signup/AddPassword";
import useGetDataForSignup from "../hooks/getData/useGetDataForSignup";
import { TABS, langOptions } from "../constants/constants";

const TextInImage = () => {
  return (
    <>
      <div className="">
        <SignupLogo />
      </div>
    </>
  );
};

const Signup = () => {
  const { params, t, handleTab, activeTab, lang } = useGetDataForSignup();
  const { changeLanguage } = useChangeLanguage();
  const { marina, rental } = TABS;
  // *********************
  // return
  // *********************
  return (
    <div className="h-screen overflow-y-auto signup flex">
      <section className="w-[50%] max-md:hidden">
        <FormSideImage TextComponent={<TextInImage />} />
      </section>
      <section className="signup-bg w-[50%] max-md:w-full  flex h-[100vh] overflow-y-auto max-2xl:pb-[20px] pb-[30px] flex-col">
        <div className="w-full pr-[26px] pt-[15px] 2xl:pt-[20px] flex justify-end text-dark-blue">
          <SelectDropdown
            options={langOptions}
            defaultValue={lang || "no"}
            handleChange={(value) => {
              changeLanguage(value);
            }}
          />
        </div>
        <div className="2xl:pt-[40px] flex flex-col 2xl:h-[90vh]  2xl:justify-center xl:mr-[100px] mr-[40px]  pt-[25px] pl-[40px] xl:pl-[120px]">
          <h1 className="text-[28px] text-dark-blue 2xl:text-[35px] font-roobert-rg leading-[50px]">
            {t("registeraccount")}
          </h1>
          <p className=" text-dark-blue text-[14px] font-roobert-rg leading-[19px] mt-[6px] 2xl:mt-[15px]">
            {params.get("type") === "password-reset"
              ? t("passwordText1")
              : `${t("Which description suits you best")}?`}{" "}
          </p>
          {params.get("type") === "password-reset" && (
            <p className="mt-[15px] text-dark-blue text-[14px] font-roobert-rg leading-[19px]">
              {t("passswordInstruction")}
            </p>
          )}
          {params.get("type") !== "password-reset" && (
            <>
              <div className="signup text-dark-blue mt-[10px] 2xl:mt-[20px] flex gap-x-[10px]">
                <TabBtn
                  active={activeTab === marina ?? false}
                  text={"Marina"}
                  hanldeClick={handleTab}
                  option={marina}
                />
                <TabBtn
                  active={activeTab === rental ?? false}
                  text={t("rntlcmpnyndreseller")}
                  hanldeClick={handleTab}
                  option={rental}
                />
              </div>
              <div className="mt-[50px] pb-[40px] ">
                <ProgressSteps t={t} params={params} />
              </div>
            </>
          )}
          {params.get("type") === "password-reset" && <AddPassword />}
        </div>
      </section>
    </div>
  );
};

export default Signup;
