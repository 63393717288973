import React, { useEffect, useState } from "react";
import { Tag, message } from "antd";
import { dateFormat } from "../../utils/dateFormat";
import useQueryHook from "../useQueryHook";
import { resolvedFlattened } from "../../utils/flatObj";
import {
  accceptRentalRequest,
  fetchRentals,
  rejectRentalRequest,
} from "../../API/marina";
import useAccountStatus from "./useAccountStatus";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { useNavigate, useParams } from "react-router-dom";
import useMutationHook from "../useMutationHook";
import SendMessage from "../../assets/icons/SendMessage";
import { contactOwnerOrTenant, getFcmToken } from "../../API/chats";
import useChatsStore from "../../store/chatStore";
import { getPageHeading, getType } from "../../utils/rentalsUtils";
const GetDataForRentals = () => {
  const { rentalType } = useParams();
  const userId = localStorage.getItem("userId");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { location, params, setParams, lang, t } = useUserDataAndNavigation();
  let page = params.get("page") ? params.get("page") : 1;
  let newSearch = params.get("search") ? params.get("search") : "";
  const linking = params.get("linking");
  const [requestedAsset, setRequestedAsset] = useState(false);
  const [upcomingAsset, setUpcomingAsset] = useState(false);
  const [showModal, setShowModal] = useState({
    active: false,
    type: null,
  });
  const [modalScreen, setModalScreen] = useState(1);

  const [loading, setLoading] = useState(false);
  const [openPayout, setOpenPayout] = useState(false);
  const [openLinkBankModal, setOpenLinkBankModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const onCLickAcceptOrReject = (bool, type) => {
    setShowModal({
      active: bool,
      type,
    });
    setModalScreen(1);
  };

  useEffect(() => {
    setSelectedRowKeys([]);
    setRequestedAsset(false);
  }, [location]);

  const pageHeading = getPageHeading(rentalType, t);

  const handleProceddBankingSuccessConnnection = () => {
    setParams({});
  };
  let type = getType(rentalType, params);

  const {
    isLoading,
    error,
    refetch,
    isFetching,
    data: rentalsData,
  } = useQueryHook(["gettingRentals", params.toString(), type, newSearch], () =>
    fetchRentals(userId, page, newSearch, type)
  );

  const { setIsChatExisted, setNotExistedChat } = useChatsStore();
  const navigate = useNavigate();
  const backToDefaultStates = () => {
    setSelectedRowKeys([]);
    refetch();
    onCLickAcceptOrReject(false, null);
  };
  const {
    data: checkAccount,
    isLoading: checkAccountLoading,
    error: checkAccountError,
  } = useAccountStatus({
    enabled: true,
    setIsLoading: setLoading,
  });
  const onSuccess = (Data) => {
    setLoading(false);
    const { data } = Data;
    if (showModal.type === "accept") {
      const { status, data: paymentData } = checkAccount;
      if (
        status === "disabled" ||
        status === null ||
        (status === "pending" && paymentData.length === 0)
      ) {
        setOpenPayout(true);
        backToDefaultStates();
      } else {
        message.success(data);
        backToDefaultStates();
      }
    } else {
      message.success(data);
      backToDefaultStates();
    }
  };
  const onError = (error) => {
    setLoading(false);
    const { data } = error?.response;
    message.error(data?.message);
    onCLickAcceptOrReject(false, null);
  };

  const { mutate } = useMutationHook(
    ["accept-reject-request"],
    async (Data) => {
      if (showModal.type === "accept") {
        return await accceptRentalRequest(userId, Data.id);
      }
      if (showModal.type === "reject") {
        return await rejectRentalRequest(userId, Data);
      }
    },
    onSuccess,
    onError
  );

  const onClickAccept = () => {
    setLoading(true);
    const id = selectedRowKeys[selectedRowKeys.length - 1];
    mutate({
      id,
    });
  };
  const onClickReject = (reason, suggested_from_date, suggested_to_date) => {
    setLoading(true);
    const id = selectedRowKeys[selectedRowKeys.length - 1];
    let cancelReason;
    if (reason === 1) {
      cancelReason = "The timing doesn't fit";
    }
    if (reason === 2) {
      cancelReason = "The asset is being repaired";
    }
    if (reason === 3) {
      cancelReason = "Give a suggestion for new time";
    }
    if (reason === 4) {
      cancelReason = "Not put on the sea";
    }
    if (reason === 5) {
      cancelReason = "Other reason";
    }
    const fullDateString = new Date().toISOString();
    const dateObject = new Date(fullDateString);
    const utcTimestamp = dateObject.getTime();
    const utcTimestampInSeconds = Math.floor(utcTimestamp / 1000);

    let mutationObject = {
      id,
      date_of_cancellation: utcTimestampInSeconds,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      declined_reason: cancelReason,
    };
    if (suggested_from_date && suggested_to_date) {
      mutationObject.suggested_from_date = suggested_from_date;
      mutationObject.suggested_to_date = suggested_to_date;
    }
    mutate(mutationObject);
  };

  const getFlattenedObj = rentalsData?.rentals?.map((rental) => {
    return resolvedFlattened(rental);
  });

  const filterRentals = getFlattenedObj?.map((rental) => {
    return { key: rental.id, ...rental };
  });

  const onSelectChange = (newSelectedRowKeys) => {
    const lastSelectedKey = newSelectedRowKeys[newSelectedRowKeys.length - 1];
    const selectedRental = getFlattenedObj.find(
      (rental) => rental?.id === lastSelectedKey
    );
    if (selectedRental && selectedRental.state === "request") {
      setRequestedAsset(true);
      setUpcomingAsset(false);
    } else if (selectedRental && selectedRental.state === "upcoming") {
      setUpcomingAsset(true);
      setRequestedAsset(false);
    } else {
      setRequestedAsset(false);
      setUpcomingAsset(false);
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // table headings
  const columns = [
    {
      title: t("rentalObjects"),
      dataIndex: "property.name",
      key: "id",
    },
    {
      title: t("renter"),
      dataIndex: "user.tenantName",
    },
    {
      title: t("email"),
      dataIndex: "user.tenantEmail",
    },
    {
      title: t("Phonenumber"),
      dataIndex: "user.tenantPhone",
    },
    {
      title: t("period"),
      render: (_, data) => {
        const date1 = dateFormat(data?.booked_from);
        const date2 = dateFormat(data?.booked_till);
        return (
          <div className="">
            {date1}-{date2}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // width: '25%',
      render: (_, { state }) => {
        let color =
          state === "ongoing"
            ? "#D9F2DD"
            : state === "request"
            ? "#F8F5DA"
            : state === "upcoming"
            ? "#CF9FFF"
            : "red";
        return (
          <Tag
            color={color}
            key={state}
            className="!rounded-full  leading-[28px]  text-center  w-[98px]"
          >
            <div className="text-[16px]  h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
              {state === "ongoing"
                ? t("ongoing")
                : state === "request"
                ? t("request")
                : state === "upcoming"
                ? t("upcoming")
                : state === "archived"
                ? t("archive")
                : state === "ended"
                ? t("ended")
                : ""}
            </div>
          </Tag>
        );
      },
    },
    ...(rentalType !== "archive"
      ? [
          {
            title: "Chat",
            render: (_, data) => {
              return (
                <div className="flex mt-3 justify-center items-center">
                  <div
                    onClick={async () => {
                      const tokenData = await getFcmToken(userId, {
                        receiverId: data["user.tenantFirebaseId"],
                        assetId: data["property.id"],
                        ownerId: rentalsData?.ownerFirebaseId,
                        assetType: data["property.type"],
                        ownerFlow: true,
                        tenantId: data["user.tenantFirebaseId"],
                      });
                      contactOwnerOrTenant(
                        data["user.tenantFirebaseId"],
                        rentalsData?.ownerFirebaseId,
                        data["property.id"],
                        data["user.tenantName"],
                        data["user.tenantProfileImage"],
                        data["property.name"],
                        rentalsData?.ownerName,
                        rentalsData?.ownerProfileImage,
                        data["property.type"],
                        "",
                        setIsChatExisted,
                        setNotExistedChat,
                        tokenData?.data?.fcm_token,
                        navigate,
                        tokenData?.chatMetaData?.hide_names,
                        lang
                      );
                    }}
                    className="cursor-pointer "
                  >
                    <SendMessage />
                  </div>
                </div>
              );
            },
          },
        ]
      : []),
  ];

  return {
    columns,
    setParams,
    params,
    type,
    t,
    pageHeading,
    newSearch,
    page,
    filterRentals,
    selectedRowKeys,
    setSelectedRowKeys,
    onSelectChange,
    location,
    requestedAsset,
    onCLickAcceptOrReject,
    showModal,
    onClickAccept,
    onClickReject,
    loading,
    error,
    isLoading,
    isFetching,
    rentalsData,
    lang,
    openPayout,
    setOpenPayout,
    openLinkBankModal,
    setOpenLinkBankModal,
    checkAccountError,
    checkAccountLoading,
    linking,
    searchValue,
    setSearchValue,
    handleProceddBankingSuccessConnnection,
    upcomingAsset,
    modalScreen,
    setModalScreen,
  };
};
export default GetDataForRentals;
