import React from "react";
import { Button } from "antd";
const TabBtn = ({ active, text, hanldeClick, option }) => {
  return (
    <Button
      onClick={() => hanldeClick(option)}
      className={`  ${
        active
          ? "shadow-active bg-[#ACDCE7] border-none "
          : "!border-[#A5C5CC] shadow-unactive"
      }  text-dark-blue text-[16px] ${
        text === "Marina" ? "px-[46px]" : ""
      } leading-[28px] font-roobert-rg focus:text-dark-blue hover:text-dark-blue  rounded-[30px] h-[40px]`}
    >
      {text}
    </Button>
  );
};

export default TabBtn;
