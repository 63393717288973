import React from "react";

const AlertCircle = () => {
  return (
    <svg
      width="100"
      height="101"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5 35.2222V53M52.5 70.7778H52.5444M12.5 53C12.5 58.2529 13.5346 63.4543 15.5448 68.3073C17.555 73.1604 20.5014 77.5699 24.2157 81.2843C27.9301 84.9986 32.3396 87.945 37.1927 89.9552C42.0457 91.9654 47.2471 93 52.5 93C57.7529 93 62.9543 91.9654 67.8073 89.9552C72.6604 87.945 77.0699 84.9986 80.7843 81.2843C84.4986 77.5699 87.445 73.1604 89.4552 68.3073C91.4654 63.4543 92.5 58.2529 92.5 53C92.5 42.3913 88.2857 32.2172 80.7843 24.7157C73.2828 17.2143 63.1087 13 52.5 13C41.8913 13 31.7172 17.2143 24.2157 24.7157C16.7143 32.2172 12.5 42.3913 12.5 53Z"
        stroke="#046997"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlertCircle;
