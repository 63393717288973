import React, { useState } from "react";
import {
  handleImageChange,
  handleRemoveImage,
} from "../../utils/handleImageChangeAndRemove";
import Cancel from "../../assets/icons/Cancel";
import Upload from "../../assets/icons/Upload";
import CropImageModal from "../CommonComponents/CropImageModal";

const UploadImages = ({
  handleUploadClick,
  inputRef,
  setSelectedImages,
  selectedImages,
  stringify,
  setRemoveImage,
  removeImage,
  parse,
  myImages,
  error,
  t,
  thumbnailImages,
  setThumbnailImages,
  type,
}) => {
  const [openCropImageModal, setOpenCropImageModal] = useState(false);
  const [image, setImage] = useState(null);
  return (
    <>
      <CropImageModal
        isOpen={openCropImageModal}
        onCancel={() => {
          setImage(null);
          setOpenCropImageModal(false);
        }}
        image={image}
        setSelectedImages={setSelectedImages}
        setThumbnailImages={setThumbnailImages}
        selectedImages={selectedImages}
        thumbnailImages={thumbnailImages}
        type={type}
      />
      <h1 className="2xl:text-[24px] text-center text-[22px] mt-[30px] font-roobert-rg">
        {t("addPhotos")}
      </h1>
      <p className="2xl:text-[24px] text-center text-[18px] mt-[10px] font-roobert-rg">
        {t("AddPhotosDescription")}
      </p>
      <input
        type="file"
        multiple
        onChange={(e) =>
          handleImageChange(e, setImage, setOpenCropImageModal, type)
        }
        className="invisible"
        ref={inputRef}
      />
      <div className="flex  flex-col-reverse justify-center  items-center">
        <div className="flex  gap-x-[10px]">
          {selectedImages?.length ? (
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-8">
              {selectedImages.map(({ file, id }, index) => (
                <div
                  key={id}
                  className={"relative h-40 w-full overflow-hidden rounded-xl"}
                >
                  <img
                    src={file}
                    className="h-full w-full object-cover"
                    alt="images"
                  />

                  <button
                    type="button"
                    className="absolute top-2 right-2 h-6 w-6 rounded-full flex items-center 
                  justify-center bg-white/50 active:opacity-70 disabled:pointer-events-none disabled:opacity-50"
                  >
                    <Cancel
                      handleClose={() =>
                        handleRemoveImage(
                          index,
                          selectedImages,
                          stringify,
                          parse,
                          myImages,
                          setSelectedImages,
                          id
                        )
                      }
                    />
                  </button>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            handleUploadClick();
          }}
        >
          <Upload />
        </div>
      </div>
      <div className="flex justify-center text-red-400">{error}</div>
    </>
  );
};

export default UploadImages;
