import { Button } from "antd";
import React from "react";

const IconBtn = ({ text, icon }) => {
  return (
    <Button
      icon={icon}
      className="dark-Btn h-[50px] font-roobert-l rounded-[50px] flex justify-center w-full gap-x-[15px] items-center "
    >
      {text}
    </Button>
  );
};

export default IconBtn;
