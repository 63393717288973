import React from "react";
import { Form, Input } from "antd";
const TextArea = ({
  styleClasses,
  rows,
  placeHolder,
  name,
  rules,
  dependencies,
  label,
}) => {
  const { TextArea } = Input;
  return (
    <Form.Item
      name={name}
      rules={rules}
      dependencies={dependencies}
      className=" !rounded-[30px]"
      label={label}
    >
      <TextArea
        rows={rows}
        placeholder={placeHolder}
        className={`${styleClasses} !pt-[15px] !pl-[15px] rounded-[30px] font-roobert-rg`}
      />
    </Form.Item>
  );
};

export default TextArea;
