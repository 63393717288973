import React from "react";
import Cancel from "../../assets/icons/Cancel";
import { useTranslation } from "react-i18next";
import ButtonDark from "./ButtonDark";
const ApprovePopup = ({ handleCloseModal, onApprove, isLoading }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#ACDCE7] rounded-[10px] p-[20px]">
      <div className="flex justify-end">
        <Cancel handleClose={handleCloseModal} />
      </div>
      <div className="flex flex-col gap-y-[20px] justify-center items-center text-[25px] my-[50px] text-dark-blue font-roobert-rg">
        <h1 className="">{t("assignDockStatement")}</h1>
        <div className="flex justify-center items-center gap-x-[20px]">
          <ButtonDark
            text={t("no")}
            styleClasses={"white-Btn h-[40px] w-[120px]"}
            handleClick={handleCloseModal}
          />
          <ButtonDark
            text={t("yes")}
            styleClasses={"dark-Btn h-[40px] w-[120px]"}
            handleClick={onApprove}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovePopup;
