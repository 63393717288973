import React from "react";

const CircleCutMap = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="67"
      viewBox="0 0 51 67"
      fill="none"
    >
      <g filter="url(#filter0_bd_2020_73939)">
        <path
          d="M25.4809 8.6796C28.6571 9.1209 31.5274 10.8101 33.4602 13.3755C35.3931 15.941 36.2302 19.1726 35.7874 22.3594C35.1614 26.8654 32.2361 30.3477 28.3554 31.9213L28.8645 28.2564C30.9204 26.858 32.422 24.617 32.7934 21.9435C33.1255 19.5534 32.4977 17.1296 31.048 15.2055C29.5984 13.2814 27.4457 12.0146 25.0635 11.6836C22.6813 11.3526 20.2648 11.9847 18.3455 13.4407C16.4263 14.8967 15.1615 17.0575 14.8294 19.4476C14.4579 22.1212 15.2917 24.6866 16.8885 26.5925L16.3793 30.2574C13.0745 27.6855 11.2094 23.5376 11.8354 19.0316C12.2782 15.8448 13.9645 12.9638 16.5236 11.0224C19.0826 9.08105 22.3046 8.23831 25.4809 8.6796ZM26.3156 2.67163C31.0799 3.33358 35.3853 5.86735 38.2846 9.71555C41.1839 13.5638 42.4396 18.4112 41.7754 23.1914C41.0659 28.2982 38.3016 32.5989 34.4593 35.4333L31.6531 33.6655C33.5698 32.4916 35.2034 30.9059 36.4361 29.0228C37.6688 27.1396 38.4698 25.0059 38.7814 22.7754C39.3349 18.7919 38.2885 14.7524 35.8724 11.5455C33.4563 8.33871 29.8685 6.22724 25.8982 5.67562C21.9279 5.124 17.9004 6.17743 14.7016 8.60416C11.5028 11.0309 9.39485 14.6321 8.84139 18.6157C7.9858 24.7738 10.9339 30.5725 15.9119 33.6218L15.4611 36.8661C8.83152 33.4036 4.75807 26.0401 5.84739 18.1997C6.51154 13.4195 9.04112 9.09796 12.8796 6.18588C16.7182 3.27381 21.5512 2.00969 26.3156 2.67163Z"
          fill="#18374F"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_2020_73939"
          x="1.67188"
          y="-1.5"
          width="44.2773"
          height="44.3662"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2020_73939"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.74375 0 0 0 0 0.799 0 0 0 0 0.85 0 0 0 0.38 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_2020_73939"
            result="effect2_dropShadow_2020_73939"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2020_73939"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CircleCutMap;
