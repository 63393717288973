import { useQuery } from "react-query";

const useQueryHook = (key, queryFtn, enabled, onSuccess) => {
  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryKey: key,
    queryFn: queryFtn,
    enabled: enabled,
    onSuccess: onSuccess,
  });
  return {
    data,
    isLoading,
    isFetching,
    error,
    refetch,
  };
};

export default useQueryHook;
