import React from "react";

const NextIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.5" cy="8.5" r="8.5" fill="#FFFDF6" />
      <path
        d="M6.78038 13.1953L11.8605 8.9706C11.9867 8.86617 12.0547 8.70477 12.0645 8.56237C12.0645 8.41047 11.9965 8.25857 11.8605 8.15414L6.78038 3.92945C6.45984 3.66362 5.99359 4.02438 6.19758 4.39464L8.1791 8.57186L6.19758 12.7491C5.9936 13.1098 6.45984 13.4801 6.78038 13.2143L6.78038 13.1953Z"
        fill="#134357"
      />
    </svg>
  );
};

export default NextIcon;
