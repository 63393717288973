import React from "react";
import { useTranslation } from "react-i18next";
const AverageCard = ({ average, currency }) => {
  const { t } = useTranslation();
  const { average_income } = average ?? {};
  return (
    <div className="2xl:py-[20px] py-[15px] bg-[#FFFFFF] w-[300px] pl-[30px] border border-[#A5C5CC] rounded-[12px]">
      <h1 className="text-[22px] font-roobert-rg leading-[35px] text-dark-blue">
        {t("averagerevenue")}
      </h1>
      <div className="mt-[8px] text-dark-blue flex gap-x-[7px]">
        <span className="text-[16px] font-roobert-sb 0]">
          {currency} {average_income ?? 0}
        </span>
      </div>
    </div>
  );
};

export default AverageCard;
