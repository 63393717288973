import React, { useRef, useState } from "react";
import Layout from "../Layout/Layout";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "../styles/antd-table.css";
import TableCard from "../components/Dashboard/TableCard";
import useGetDataForDashboard from "../hooks/getData/useGetDataForDashboard";
import GraphCard from "../components/Dashboard/GraphCard";
import DocksList from "../components/CommonComponents/DocksList";
import useGetDataForMarinaMembers from "../hooks/getData/useGetDataForMarinaMembers";
import MembersDetails from "../components/MarinaMember/MembersDetails";
import Drag from "../assets/icons/Drag";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import VideoModal from "../components/Dashboard/VideoModal";
import Dialog from "../components/CommonComponents/Modal/Dialog";
const Dashboard = () => {
  const {
    viewClubMemberModal,
    setViewClubMemberModal,
    mutateEdit,
    form,
    hanldeCloseModal,
    handleDeleteWaitingMember,
  } = useGetDataForMarinaMembers({ dashboard: true });
  const [showVideo, setShowVideo] = useState(
    localStorage.getItem("onBoarding") === "true"
  );
  const iframeRef = useRef(null);

  const stopYouTubeVideo = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const currentSrc = iframe.src;
      iframe.src = currentSrc.replace(/\?autoplay=1/g, "") + "?autoplay=0";
    }
  };
  const onCloseVideoModal = () => {
    setShowVideo(false);
    stopYouTubeVideo();
    localStorage.setItem("onBoarding", false);
  };
  let {
    orderedTableCardsData,
    onDragEnd,
    getTopValues,
    financeGraph,
    showCountedData,
    showLoading,
    filterDocksData,
    isModalOpen,
    handleCloseModal,
    docksLoading,
    refetchWaitingList,
    t,
    assignName,
    getDisabledValue,
    getTotalTasks,
    tasksDone,
    docksFetching,
    showError,
  } = useGetDataForDashboard({
    setViewClubMemberModal: setViewClubMemberModal,
    viewClubMemberModal: viewClubMemberModal,
  });

  return (
    <Layout backToDashboard={false}>
      <Dialog
        isModalOpen={showVideo}
        onCancelModal={onCloseVideoModal}
        component={
          <VideoModal
            onCloseVideoModal={onCloseVideoModal}
            iframeRef={iframeRef}
          />
        }
        styles={"club-members !w-[70%]"}
      />
      <Dialog
        isModalOpen={viewClubMemberModal}
        setIsModalOpen={setViewClubMemberModal}
        onCancelModal={hanldeCloseModal}
        component={
          <MembersDetails
            mutateEdit={mutateEdit}
            handleClose={hanldeCloseModal}
            form={form}
            t={t}
            handleDeleteWaitingMember={handleDeleteWaitingMember}
          />
        }
        styles={"club-members"}
      />
      <Dialog
        isModalOpen={isModalOpen}
        onCancelModal={handleCloseModal}
        component={
          <DocksList
            data={filterDocksData}
            handleCloseModal={handleCloseModal}
            docksLoading={docksLoading || docksFetching}
            assignName={assignName}
            refetch={refetchWaitingList}
            t={t}
          />
        }
      />
      <div className="3xl:pt-[75px] pb-[100px] mt-[50px] px-[25px]">
        <h1 className="pl-[10px] text-[28px] 2xl:text-[35px] font-roobert-rg leading-[50px]">
          {localStorage.getItem("organizationName")}
        </h1>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {orderedTableCardsData.map((item, index) => {
                  if (item.id === 0) {
                    return (
                      <Draggable
                        draggableId={item.id.toString()}
                        index={index}
                        key={item.id}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            key={item?.id}
                            className="mt-[30px] h-auto min-h-[300px]  bg-light-blue flex rounded-[12px] shadow-custom"
                          >
                            <div
                              {...provided.dragHandleProps}
                              className="h-auto w-[21px] shadow-drag-btn rounded-l-[12px] flex justify-center items-center bg-transparent"
                            >
                              <Drag />
                            </div>
                            <div className="pl-[25px] w-full  py-[20px]">
                              <div className="flex justify-between items-center">
                                <h1 className="text-[28px] 2xl:text-[35px] font-roobert-rg font-normal text-dark-blue leading-[51px]">
                                  {t("tasks")}
                                </h1>
                                <p className="text-[22px] mr-[30px] 2xl:text-[24px] text-darkblue">
                                  {t("youhavedone")} {tasksDone}/{getTotalTasks}
                                </p>
                              </div>
                              <div className=" pt-[20px]  flex flex-wrap  gap-y-[30px] gap-x-[10px]">
                                {item.buttons.map((button, i) => {
                                  return (
                                    <ButtonDark
                                      key={i}
                                      text={button.text}
                                      disabled={getDisabledValue(button.type)}
                                      handleClick={button.handleClick}
                                      styleClasses={` ${
                                        getDisabledValue(button.type)
                                          ? "dark-Btn"
                                          : "light-Btn text-dark-blue"
                                      }  !text-[15px]  font-roobert-rg h-[50px]`}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                  if (item.id >= 1 && item.id <= 4) {
                    return (
                      <TableCard
                        item={item}
                        index={index}
                        key={`${item.id}-${index}`}
                        data={getTopValues(item.type)}
                        showCountedData={showCountedData}
                        showLoading={showLoading}
                        showError={showError}
                      />
                    );
                  } else {
                    return (
                      <GraphCard
                        item={item}
                        key={item.id}
                        graphData={financeGraph?.data?.graphData}
                        index={index}
                        averageData={financeGraph?.data}
                      />
                    );
                  }
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Layout>
  );
};

export default Dashboard;
