import { Input } from "antd";
import React, { useState } from "react";
import { checkUniqueInputs } from "../../utils/uniqueInputs";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { useParams } from "react-router-dom";
import Papa from "papaparse";
import useConstants from "./useConstants";
import useImportDataCalls from "./useImportDataCalls";

const useGetDataForImports = ({ onDeleteRow }) => {
  const { t } = useTranslation();
  const { keyMapping, downloadCSVDemo } = useConstants();
  const { mutate } = useImportDataCalls();

  const param = useParams().data;
  const [importType, setImportType] = useState("copyAndPaste");
  const [activeCopyAndPaste, setActiveCopyAndPaste] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvData, setCsvData] = useState([]);

  const changeImportType = (e) => {
    const { value } = e.target;
    setImportType(value);
    setSelectedFile(null);
    setCsvData([]); // Reset CSV data when changing import type
  };

  const handleImportType = (type) => {
    setImportType(type);
    setSelectedFile(null);
    setCsvData([]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name.toLowerCase();
      if (fileName.endsWith(".csv")) {
        setSelectedFile(file);
        Papa.parse(file, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (result) => {
            setCsvData(result.data);
          },
        });
      } else {
        alert("Please select a valid CSV file.");
      }
    }
  };

  const handleProceed = () => {
    if (importType === "copyAndPaste") {
      setActiveCopyAndPaste(true);
    } else if (importType === "importWithCsv") {
      const updateKeys = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).map(([oldKey, value]) => [
            keyMapping[oldKey] || oldKey,
            value,
          ])
        );
      };
      const updatedCsvData = csvData.map(updateKeys);
      if (updatedCsvData) {
        mutate(updatedCsvData);
      }
    }
  };

  let defaultColumns;
  switch (param) {
    case "waiting-list":
      defaultColumns = [
        {
          title: t("boatType"),
          dataIndex: "boat_type",
          key: "boat_type",
          rules: [
            {
              required: true,
              message: "Please input your username!",
            },
          ],
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("width"),
          dataIndex: "boat_width",
          key: "boat_width",
          editable: "true",
          rules: [
            {
              required: true,
              message: "Please input your username!",
            },
          ],
          render: (_, record) => {
            return <Input type="number" name={`width${record.id}`} />;
          },
        },
        {
          title: t("length"),
          dataIndex: "boat_length",
          key: "boat_length",
          editable: "true",
          rules: [
            {
              required: true,
              message: "Please input your username!",
            },
          ],
          render: (_, record) => {
            return <Input type="number" name={`length${record.id}`} />;
          },
        },
        {
          title: t("customerId"),
          dataIndex: "customer_id",
          key: "customer_id",
          rules: [
            {
              required: true,
              message: "Please input your username!",
            },
            ({ getFieldsValue, setFields }) => ({
              async validator(_) {
                return await checkUniqueInputs(getFieldsValue, setFields);
              },
            }),
          ],
          render: (_, record) => {
            return (
              <Input
                type="text"
                name={`customer_id_${record.id}`} // Append record ID to the name
              />
            );
          },
        },

        {
          title: t("name"),
          dataIndex: "customer_name",
          key: "customer_name",
          editable: "true",
          rules: [
            {
              required: true,
              message: "Please input your username!",
            },
          ],
          render: (_, record) => {
            return <Input type="text" name={`name${record.id}`} />;
          },
        },
        {
          title: t("email"),
          dataIndex: "customer_email",
          key: "customer_email",
          editable: "true",
          rules: [
            {
              required: true,
              message: "Please input your email!",
            },
          ],
          render: (_, record) => {
            return <Input type="email" name={`email${record.id}`} />;
          },
        },
        {
          title: t("Phonenumber"),
          dataIndex: "customer_contact",
          key: "customer_contact",
          editable: "true",
          rules: [
            {
              required: true,
              message: "Please input your username!",
            },
          ],
          render: (_, record) => {
            return <Input type="number" name={`contact${record.id}`} />;
          },
        },
        {
          title: t("reported"),
          dataIndex: "join_date",
          key: "join_date",
          editable: "true",
          rules: [
            {
              required: true,
              message: "Please input your username!",
            },
          ],
          render: (_, record) => {
            return <Input type="date" name={`reported${record.id}`} />;
          },
        },
        {
          title: t("actions"),
          dataIndex: "actions",
          render: (_, { id }) => {
            return (
              <div className="flex items-center h-[32px] border rounded-[7px] bg-white  justify-center">
                <DeleteIcon handleClick={() => onDeleteRow(id)} />
              </div>
            );
          },
        },
      ];
      break;
    case "club-members":
      defaultColumns = [
        {
          title: t("customerId"),
          dataIndex: "customer_id",
          rules: [
            {
              required: true,
              message: "Please input your Customer Id!",
            },
          ],
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("Name"),
          dataIndex: "customer_name",
          rules: [
            {
              required: true,
              message: "Please input your Customer Name!",
            },
          ],
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("email"),
          dataIndex: "customer_email",
          rules: [
            {
              required: true,
              message: "Please input your Customer Email!",
            },
          ],
          render: (_, record) => {
            return <Input type="email" name={`type${record.id}`} />;
          },
        },
        {
          title: t("Phonenumber"),
          dataIndex: "customer_contact",
          rules: [
            {
              required: true,
              message: "Please input your Customer Contact!",
            },
          ],
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("dockNumber"),
          dataIndex: "dock_number",
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("pier"),
          dataIndex: "pier_number",
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("memberSince"),
          dataIndex: "join_date",
          rules: [
            {
              required: true,
              message: "Please input your Joined Date!",
            },
          ],
          render: (_, record) => {
            return <Input type="date" name={`type${record.id}`} />;
          },
        },
        {
          title: t("actions"),
          dataIndex: "actions",
          render: (_, { id }) => {
            return (
              <div className="flex items-center h-[32px] border rounded-[7px] bg-white  justify-center">
                <DeleteIcon handleClick={() => onDeleteRow(id)} />
              </div>
            );
          },
        },
      ];
      break;

    case "season-dock":
      defaultColumns = [
        {
          title: "Dock Number",
          dataIndex: "dock_number",
          rules: [
            {
              required: true,
              message: "Please input your Dock Number!",
            },
          ],
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("pier"),
          dataIndex: "pier_number",
          rules: [
            {
              required: true,
              message: "Please input your Pier!",
            },
          ],
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("customerId"),
          dataIndex: "customer_id",
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("name"),
          dataIndex: "customer_name",
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("email"),
          dataIndex: "customer_email",
          render: (_, record) => {
            return <Input type="email" name={`type${record.id}`} />;
          },
        },
        {
          title: t("PhoneNumber"),
          dataIndex: "customer_contact",
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("boatType"),
          dataIndex: "boat_type",
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("price"),
          dataIndex: "price",
          rules: [
            {
              required: true,
              message: "Please input your Price!",
            },
          ],
          render: (_, record) => {
            return <Input type="number" name={`type${record.id}`} />;
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          rules: [
            {
              required: true,
              message: "Please input your status!",
            },
          ],
          render: (_, record) => {
            return <Input type="text" name={`type${record.id}`} />;
          },
        },
        {
          title: t("dockWidth"),
          dataIndex: "dock_width",
          rules: [
            {
              required: true,
              message: "Please input your Dock Width!",
            },
          ],
          render: (_, record) => {
            return <Input type="number" name={`type${record.id}`} />;
          },
        },
        {
          title: t("dockLength"),
          dataIndex: "dock_length",
          rules: [
            {
              required: true,
              message: "Please input your Dock Length!",
            },
          ],
          render: (_, record) => {
            return <Input type="number" name={`type${record.id}`} />;
          },
        },
        {
          title: t("boatWidth"),
          dataIndex: "boat_width",
          render: (_, record) => {
            return <Input type="number" name={`type${record.id}`} />;
          },
        },
        {
          title: t("boatLength"),
          dataIndex: "boat_length",
          render: (_, record) => {
            return <Input type="number" name={`type${record.id}`} />;
          },
        },
        {
          title: t("actions"),
          dataIndex: "actions",
          render: (_, { id }) => {
            return (
              <div className="flex items-center h-[32px] border rounded-[7px] bg-white  justify-center">
                <DeleteIcon handleClick={() => onDeleteRow(id)} />
              </div>
            );
          },
        },
      ];
      break;
    default:
      break;
  }
  return {
    defaultColumns,
    activeCopyAndPaste,
    param,
    importType,
    downloadCSVDemo,
    changeImportType,
    handleFileChange,
    handleImportType,
    selectedFile,
    handleProceed,
    mutate,
  };
};

export default useGetDataForImports;
