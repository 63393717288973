function BackIcon({ className, color }) {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18 33.5698C25.325 33.5698 31.2631 27.6891 31.2631 20.4348C31.2631 13.1805 25.325 7.2998 18 7.2998C10.6749 7.2998 4.73682 13.1805 4.73682 20.4348C4.73682 27.6891 10.6749 33.5698 18 33.5698Z"
        stroke={color ?? "#134357"}
        strokeWidth="1.4"
        stroke-miterlimit="10"
      />
      <path
        d="M20.7764 13.1032L12.6424 19.8022C12.4402 19.9678 12.3313 20.2237 12.3158 20.4495C12.3158 20.6903 12.4247 20.9312 12.6424 21.0968L20.7764 27.7958C21.2896 28.2173 22.0361 27.6453 21.7095 27.0582L18.5368 20.4344L21.7095 13.8107C22.0361 13.2386 21.2896 12.6515 20.7764 13.0731V13.1032Z"
        fill={color ?? "#134357"}
      />
    </svg>
  );
}

export default BackIcon;
