import { Button } from "antd";
import React from "react";
import Eye from "../../assets/icons/Eye";
import { useTranslation } from "react-i18next";

const ViewDetails = ({ handleClick, text }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={handleClick}
      className="h-[30px] border my-1  transparent-btn border-[#A5C5CC] bg-transparent text-center   rounded-[30px] "
    >
      <div className="flex items-center text-[14px] h-full font-roobert_l justify-center gap-x-[10px]">
        <Eye />
        <div className="">{text ? text : t("Seedetails")}</div>
      </div>
    </Button>
  );
};

export default ViewDetails;
