import React from "react";

const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M25.1333 0.868019L25.9306 1.20949C25.9992 1.05016 26.0185 0.873907 25.9858 0.703534C25.9532 0.53316 25.8701 0.376506 25.7475 0.253841C25.6248 0.131176 25.4681 0.0481456 25.2978 0.0154923C25.1274 -0.0171609 24.9511 0.00206607 24.7918 0.0706856L25.1333 0.868019ZM0.866619 11.268L0.525152 10.4707C0.37817 10.5335 0.25142 10.6357 0.158915 10.7661C0.0664097 10.8964 0.0117541 11.0498 0.000990908 11.2093C-0.00977231 11.3688 0.0237761 11.5281 0.097927 11.6697C0.172078 11.8113 0.283942 11.9296 0.421152 12.0116L0.866619 11.268ZM14.7333 25.1347L13.9897 25.5802C14.0717 25.7174 14.19 25.8292 14.3316 25.9034C14.4732 25.9775 14.6325 26.0111 14.792 26.0003C14.9515 25.9896 15.1049 25.9349 15.2352 25.8424C15.3656 25.7499 15.4678 25.6231 15.5306 25.4762L14.7333 25.1347ZM24.7918 0.0706856L0.525152 10.4707L1.20809 12.0654L25.4748 1.66535L24.7918 0.0706856ZM0.421152 12.0116L9.08782 17.2134L9.97875 15.7262L1.31209 10.5262L0.421152 12.0116ZM8.78795 16.9135L13.988 25.5802L15.4752 24.6892L10.2752 16.0226L8.78795 16.9135ZM15.5289 25.4762L25.9289 1.20949L24.3342 0.526552L13.9342 24.7932L15.5289 25.4762ZM24.5197 0.256152L8.91969 15.8562L10.1469 17.0816L25.7469 1.48162L24.5197 0.254419V0.256152Z"
        fill="#134357"
      />
    </svg>
  );
};

export default SendIcon;
