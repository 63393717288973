import jsPDF from "jspdf";
import "jspdf-autotable";
import LogoDark from "../assets/images/LogoDark.png";

export const downloadPdfForRental = (data, headers, title) => {
  const pdf = new jsPDF({ orientation: "landscape" });
  pdf.setFontSize(10);

  const logoWidth = 80; // Adjust the width of the logo as needed
  const logoHeight = 17; // Adjust the height of the logo as needed
  const pageWidth = pdf.internal.pageSize.width;

  const logoX = (pageWidth - logoWidth) / 2;

  pdf.addImage(LogoDark, "JPEG", logoX, 10, logoWidth, logoHeight);

  pdf.text(title, 14, 37);

  const headerStyles = {
    fillColor: "#134357", // Header background color
    textColor: "#ffffff", // Header text color
    fontStyle: "bold", // Header text style
    halign: "center",
  };

  const lastRowStyles = {
    fontStyle: "bold", // Apply bold font style to the last row
    halign: "center",
    // valign: 'center',
    cellPadding: { top: 3 },
  };

  pdf.autoTable({
    head: [headers],
    body: [...data], // Add the total row at the end of the data array
    startY: 40,
    showHead: "firstPage",
    didDrawPage: (data) => {
      const pageSize = pdf.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageCount = pdf.internal.getNumberOfPages();
      pdf.text(`Page ${data.pageNumber} of ${pageCount}`, 14, pageHeight - 10);
    },
    margin: { top: 50 },
    headStyles: headerStyles,
    didParseCell: (data) => {
      if (data.row.index === data.table.body.length - 1) {
        // Check if it's the last row
        data.cell.styles = lastRowStyles; // Apply styles to the last row
      } else {
        // Align content horizontally center for all other rows
        data.cell.styles.halign = "center";
      }
    },
  });

  // Save the PDF
  pdf.save(`${title}.pdf`);
};
const downloadPdf = (data, headers, title) => {
  const pdf = new jsPDF({ orientation: "landscape" });
  pdf.setFontSize(10);

  const logoWidth = 80; // Adjust the width of the logo as needed
  const logoHeight = 17; // Adjust the height of the logo as needed
  const pageWidth = pdf.internal.pageSize.width;

  const logoX = (pageWidth - logoWidth) / 2;

  pdf.addImage(LogoDark, "JPEG", logoX, 10, logoWidth, logoHeight);

  pdf.text(title, 14, 37);

  const headerStyles = {
    fillColor: "#134357", // Header background color
    textColor: "#ffffff", // Header text color
    fontStyle: "bold", // Header text style
  };

  pdf.autoTable({
    head: [headers],
    body: data,
    startY: 40,
    showHead: "firstPage",
    didDrawPage: (data) => {
      const pageSize = pdf.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageCount = pdf.internal.getNumberOfPages();
      pdf.text(`Page ${data.pageNumber} of ${pageCount}`, 14, pageHeight - 10);
    },
    margin: { top: 50 },
    headStyles: headerStyles,
  });
  pdf.save(`${title}.pdf`);
};
export default downloadPdf;
