export const onDrag = (result, orderedTableCardsData, setOrganizedKeys) => {
  if (!result.destination) {
    return;
  }
  const reorderedItems = Array.from(orderedTableCardsData);
  const [removed] = reorderedItems.splice(result.source.index, 1);
  reorderedItems.splice(result.destination.index, 0, removed);
  orderedTableCardsData = reorderedItems;
  const organizedKeys = reorderedItems.map((item) => item.id);
  setOrganizedKeys(organizedKeys);
};
