import React from "react";
import ButtonDark from "../CommonComponents/ButtonDark";
import Bank from "../../assets/icons/Bank";
import Spinner from "../CommonComponents/Spinner";
import { useMutation } from "react-query";
import { changeBankAccount } from "../../API/marina";
import PayoutCard from "./PayoutCard";
import useGetDataForPayouts from "../../hooks/getData/useGetDataForPayouts";
import ViewPaymentOrEarnedList from "./ViewPaymentOrEarnedList";
import Dialog from "../CommonComponents/Modal/Dialog";

const YourBank = ({
  t,
  lastDigits,
  payouts,
  isLoading,
  isFetching,
  error,
  bankName,
  userId,
  bankKey,
}) => {
  const {
    earnedOrPending,
    setEarnedOrPending,
    data,
    isLoading: transationDataLoading,
    isFetching: transationDataFetching,
    error: transationDataError,
  } = useGetDataForPayouts();

  const handleClickPayout = (type, bool) => {
    setEarnedOrPending({
      type,
      open: bool,
    });
  };

  const onSuccess = (data) => {
    window.location.href = data?.accountLink?.url;
  };
  const onError = (error) => {
    console.log(error);
  };

  const { mutate, isLoading: changeAccountLoading } = useMutation({
    mutationKey: ["change-account"],
    mutationFn: async () => {
      return await changeBankAccount(userId, bankKey);
    },
    onSuccess,
    onError,
  });

  if (isFetching || isLoading) {
    return <Spinner />;
  }
  if (error) {
    return error;
  }

  const handleChangeAccount = () => {
    mutate();
  };
  const heading =
    earnedOrPending?.type === "pending" ? t("pendingBalance") : t("earned");

  return (
    <>
      <Dialog
        isModalOpen={earnedOrPending.open}
        onCancelModal={() => handleClickPayout(null, false)}
        width={600}
        component={
          <ViewPaymentOrEarnedList
            type={earnedOrPending.type}
            heading={heading}
            data={data}
            isLoading={transationDataLoading}
            isFetching={transationDataFetching}
            error={transationDataError}
          />
        }
      />
      <div className="bg-[#FFFFFF] rounded-[20px] mt-[35px] py-[24px] px-[40px] 2xl:mt-[53px]">
        <div className="flex justify-between items-center">
          <h1 className="text-[28px] font-roobert-rg 2xl:text-[35px] text-dark-blue leading-[50px]">
            {t("yourBank")}
          </h1>
          <div className="flex gap-x-[25px]">
            {/* <ButtonDark
            styleClasses={"light-Btn h-[40px]"}
            text={t("addAccount")}
          /> */}
            <ButtonDark
              styleClasses="light-Btn h-[40px]"
              text={t("changeAccount")}
              handleClick={handleChangeAccount}
              loading={changeAccountLoading}
            />
          </div>
        </div>
        <div className="2xl:mt-[45px] mt-[20px] w-full grid grid-cols-2">
          <div className="">
            <div className="rounded-[20px] w-[65%] border mb-[37px] px-[20px] py-[30px] border-[#A5C5CC] text-dark-blue">
              <h6 className="2xl:text-[24px] text-[22px] leading-[30px] font-roobert-rg">
                {t("amountAvailable")}
              </h6>
              <h1 className="2xl:text-[50px] mt-[20px] text-[40px] leading-[30px] font-roobert-rg">
                {payouts?.available ?? ""} {payouts?.currency ?? ""}
              </h1>
              <p className="text-[16px]  mt-[20px] font-roobert-rg leading-[28px] ">
                {t("amountTransfer1")}
              </p>
            </div>
            {/* <ButtonDark
            styleClasses={"dark-Btn h-[40px]"}
            text={t("transferAmountToAccount")}
          /> */}
          </div>
          <div className="flex  text-dark-blue flex-col">
            <p className="2xl:text-[24px] text-[22px] mt-[20px] font-roobert-rg leading-[31px] ">
              {t("amountTransfer2")}
            </p>
            <div className="2xl:mt-[20px] mt-[15px] shadow-2xl  flex items-center  pl-[23px] py-[15px] gap-x-[30px] rounded-[20px] bg-[#FFFFFF]">
              <Bank />
              <div className="">
                <h1 className="2xl:text-[24px] text-[22px] font-roobert-rg leading-[31px]">
                  {bankName ?? ""}
                </h1>
                <h1 className="2xl:text-[24px] text-[22px] font-roobert-rg leading-[31px]">
                  **********{lastDigits}
                </h1>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-[46px] mt-[46px]">
              <PayoutCard
                payouts={payouts}
                text={t("earned")}
                styleClasses="bg-[#6DB9D0]"
                amount={payouts?.earning}
                type="earning"
                handleClickPayout={handleClickPayout}
              />
              <PayoutCard
                payouts={payouts}
                text={t("pendingBalance")}
                styleClasses="bg-dark-blue"
                amount={payouts?.pending}
                type="pending"
                handleClickPayout={handleClickPayout}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourBank;
