import i18next from "i18next";
import ViewDetails from "../components/CommonComponents/ViewDetails";
import { dateFormat } from "../utils/dateFormat";
import ButtonDark from "../components/CommonComponents/ButtonDark";

const t = i18next.t;
export const getClubMembersColumns = (
  handleModal,
  pierColumn,
  assignDockId
) => {
  return [
    {
      title: t("CustomerNumber"),
      dataIndex: "customer_id",
    },
    {
      title: t("name"),
      dataIndex: "customer_name",
    },
    {
      title: t("email"),
      dataIndex: "customer_email",
    },
    {
      title: t("Phonenumber"),
      dataIndex: "customer_contact",
    },
    {
      title: t("seasonalDocks"),
      dataIndex: "dock_number",
    },
    pierColumn,
    {
      title: t("memberSince"),
      render: (_, { join_date }) => {
        const date = dateFormat(join_date);
        return <div className="">{date}</div>;
      },
    },
    {
      title: t("assignDock"),
      dataIndex: "4",

      render: (_, { id, customer_name }) => {
        return (
          <div className="flex items-center justify-center my-1 ">
            <ButtonDark
              styleClasses={"white-Btn !text-[12px]"}
              text={t("assignDock")}
              handleClick={() => assignDockId(id, customer_name)}
            />
          </div>
        );
      },
    },
    {
      title: t("details"),
      dataIndex: "5",
      // width: '25%',
      render: (_, { id }) => {
        return (
          <ViewDetails
            handleClick={() => {
              handleModal(true, id);
            }}
          />
        );
      },
    },
  ];
};

export const assignUserFromClubMemberListOrWaitlist = (
  updateDock,
  searchParams
) => {
  updateDock({
    dockId: [searchParams.get("dockId")],
    waitListId: searchParams.get("id"),
  });
};

export const handleCloseModal = (
  setIsModalOpen,
  setSearchParams,
  paramsObject
) => {
  setIsModalOpen(false);
  setSearchParams({ ...paramsObject, id: "" });
};
