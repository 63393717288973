import React from "react";
import SettingHeader from "./SettingHeader";
import { Form } from "antd";
import Label from "../CommonComponents/Label";
import FormInput from "../CommonComponents/FormInput";
import SelectInput from "../CommonComponents/SelectInput";
import Spinner from "../CommonComponents/Spinner";
import PhoneNumberInput from "../CommonComponents/PhoneNumberInput";
import useGetDataForInvoiceInformation from "../../hooks/getData/useGetDataForInvoiceInformation";
const InvoiceInformation = ({ t }) => {
  const {
    emailOptions,
    countryOptions,
    data,
    isLoading,
    isFetching,
    error,
    activeNum,
    setActiveNum,
    initialValues,
    form,
    onFinish,
    mutateLoading,
  } = useGetDataForInvoiceInformation();

  return (
    <div className="mt-[20px] 2xl:pb-[80px] pb-[30px]  bg-[#E2F3F7] rounded-[20px]">
      <SettingHeader
        isLoading={mutateLoading}
        t={t}
        heading={t("invoiceInformation")}
        form={form}
      />
      {(isLoading || isFetching) && <Spinner />}
      {error && <div>{error}</div>}
      {data && (
        <Form
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialValues}
          form={form}
        >
          <div className="grid xl:grid-cols-4 grid-cols-3 pl-[40px] mr-[80px] gap-x-[20px] mt-[5px]">
            <div>
              <Label text={t("organizationName") + "*"} />
              <FormInput
                styleClasses={"rounded-full"}
                name="organization_name"
              />
            </div>
            <div>
              <Label text={t("organizationNumber") + "*"} />
              <FormInput
                styleClasses={"rounded-full"}
                name="organization_number"
              />
            </div>
            <div>
              <Label text={t("Contactperson") + "*"} />
              <FormInput styleClasses={"rounded-full"} name="contact_person" />
            </div>
            <div className="h-full">
              <Label text={t("country") + "*"} />
              <SelectInput
                options={countryOptions}
                defaultValue={data?.country_name}
                disabled={true}
              />
            </div>
            <div>
              <Label text={t("MarinaName") + "*"} />
              <FormInput styleClasses={"rounded-full"} name="marina_name" />
            </div>
            <div>
              <Label text={t("marinaAddress") + "*"} />
              <FormInput styleClasses={"rounded-full"} name="address" />
            </div>
            <div>
              <Label text={t("Phonenumber") + "*"} />
              <PhoneNumberInput
                t={t}
                activeNum={activeNum}
                name="phone"
                setActiveNum={setActiveNum}
              />
            </div>

            <div>
              <Label text={t("email") + "*"} />
              <FormInput
                styleClasses={"rounded-full"}
                name="email"
                disabled={true}
              />
            </div>
            <div>
              <Label text={t("invoiceEmail") + "*"} />
              <FormInput name="billing_email" styleClasses={"rounded-full"} />
            </div>
            <div className="email-select">
              <Label text={t("emailOrEhf")} />
              <SelectInput options={emailOptions} name="billing_type" />
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default InvoiceInformation;
