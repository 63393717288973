import { useParams } from "react-router-dom";
import {
  importClubMembers,
  importSeasonDocks,
  importWaitingList,
} from "../../API/marina";
import useMutationHook from "../useMutationHook";
import { message } from "antd";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { useQueryClient } from "react-query";
const useImportDataCalls = () => {
  const { userId, navigate, role, lang } = useUserDataAndNavigation();
  const queryClient = useQueryClient();
  const onSuccess = ({ data }) => {
    if (params === "season-dock") {
      const { is_exceeded, number_of_dock_or_rental, number_of_guest_dock } =
        data?.data ?? {};
      if (is_exceeded) {
        navigate(`/${lang}/subscription`, {
          state: {
            number_of_dock_or_rental,
            number_of_guest_dock,
            company_type: role,

            type: "asset",
            returnUrl: `/${lang}seasonaldocks`,
          },
        });
      } else {
        message.success(data.message);
        navigate(`/${lang}/seasonaldocks`);
      }
    }
    if (params === "waiting-list") {
      message.success(data.message);
      queryClient.invalidateQueries({ queryKey: ["waiting-list"] });
      navigate(`/${lang}/wait-list`);
    }
    if (params === "club-members") {
      message.success(data.message);
      queryClient.invalidateQueries({ queryKey: ["fetch-member"] });
      navigate(`/${lang}/club-members`);
    }
  };
  const params = useParams().data;

  const onError = (error) => {
    const { data } = error.response;
    message.error(data.message);
  };

  const { mutate: addWaitlist } = useMutationHook(
    ["add-waitlist"],
    async (Data) => {
      return await importWaitingList(userId, Data);
    },
    onSuccess,
    onError
  );

  const { mutate: addClubMembers } = useMutationHook(
    ["add-club-members"],
    async (Data) => {
      return await importClubMembers(userId, Data);
    },
    onSuccess,
    onError
  );
  const { mutate: addSeasonDocks } = useMutationHook(
    ["add-club-members"],
    async (Data) => {
      return await importSeasonDocks(userId, Data);
    },
    onSuccess,
    onError
  );

  switch (params) {
    case "waiting-list":
      return {
        mutate: addWaitlist,
      };
    case "club-members":
      return {
        mutate: addClubMembers,
      };
    case "season-dock":
      return {
        mutate: addSeasonDocks,
      };
    default:
      break;
  }
};

export default useImportDataCalls;
