import { Tag } from "antd";
import { dateFormat } from "./dateFormat";

export const getPageHeading = (rentalType, t) => {
  switch (rentalType) {
    case "all-rentals":
      return "";
    case "ongoing":
      return t("ongoing");
    case "upcoming":
      return t("upcoming");
    case "requests":
      return t("requests");
    case "archive":
      return t("archive");
    default:
      return "";
  }
};

export const getType = (rentalType, params) => {
  return rentalType === "all-rentals"
    ? "all"
    : rentalType === "ongoing"
    ? "ongoing"
    : rentalType === "archive"
    ? "archived"
    : rentalType === "requests" && params.get("type") === "pending"
    ? "request"
    : "upcoming";
};

export const getColumns = (t) => {
  return [
    {
      title: t("rentalObjects"),
      dataIndex: "property.name",
      key: "id",
    },
    {
      title: t("renter"),
      dataIndex: "user.full_name",
    },
    {
      title: t("email"),
      dataIndex: "user.email",
    },
    {
      title: t("Phonenumber"),
      dataIndex: "user.phone",
    },
    {
      title: t("period"),
      render: (_, data) => {
        const date1 = dateFormat(data?.booked_from);
        const date2 = dateFormat(data?.booked_till);
        return (
          <div className="">
            {date1}-{date2}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // width: '25%',
      render: (_, { state }) => {
        let color =
          state === "ongoing"
            ? "#D9F2DD"
            : state === "request"
            ? "#F8F5DA"
            : state === "upcoming"
            ? "#CF9FFF"
            : "red";
        return (
          <Tag
            color={color}
            key={state}
            className="!rounded-full  leading-[28px]  text-center  w-[98px]"
          >
            <div className="text-[16px]  h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
              {state === "ongoing"
                ? t("ongoing")
                : state === "request"
                ? t("request")
                : state === "upcoming"
                ? t("upcoming")
                : state === "archived"
                ? t("archive")
                : state === "ended"
                ? t("ended")
                : ""}
            </div>
          </Tag>
        );
      },
    },
  ];
};
