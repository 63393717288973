import { useState } from "react";
import { downloadCSV } from "../../utils/downloadCsv";
import { dateFormat } from "../../utils/dateFormat";
import downloadPdf, { downloadPdfForRental } from "../../utils/downloadPdf";
import { useParams } from "react-router-dom";
const useGetDataForExports = () => {
  const [importType, setImportType] = useState("");
  const params = useParams().data;

  const changeImportType = (e) => {
    const { value } = e.target;
    setImportType(value);
  };

  const handleImportType = (type) => {
    setImportType(type);
  };

  const handleDownload = (importType, headers, newData, fileName) => {
    if (importType === "csv") {
      downloadCSV([headers, ...newData], fileName);
    }
    if (importType === "pdf") {
      downloadPdf(newData, headers, fileName);
    }
  };
  const handleDownloadForRental = (importType, headers, newData, fileName) => {
    if (importType === "csv") {
      downloadCSV([headers, ...newData], fileName);
    }
    if (importType === "pdf") {
      downloadPdfForRental(newData, headers, fileName);
    }
  };
  const handleProceedExportForGuestDocks = (importType, data, headers) => {
    const newData = data.map((subData) => {
      const {
        name,
        pier_number,
        dock_width,
        dock_length,
        status,
        renter,
        email,
        phone,
      } = subData;
      return [
        name,
        pier_number,
        dock_width,
        dock_length,
        status,
        renter,
        email,
        phone,
      ];
    });
    handleDownload(importType, headers, newData, "Guest Docks");
  };

  const handleProceedExportForRentals = (importType, data, headers) => {
    let totalCost = 0;
    let totalClientProfit = 0;
    let totalClientProfitWithoutTax = 0;
    let totalShipOHoiProfit = 0;
    const newData = data.map((subData) => {
      const {
        id,
        booked_from,
        booked_till,
        state,
        customer_name,
        asst_type,
        cost,
        client_profit,
        client_profit_without_tax,
        ship_o_hoi_profit,
        asset_name,
      } = subData;
      const bookTill = dateFormat(booked_till);
      const bookFrom = dateFormat(booked_from);
      totalCost += cost;
      totalClientProfit += client_profit;
      totalClientProfitWithoutTax += client_profit_without_tax;
      totalShipOHoiProfit += ship_o_hoi_profit;
      return [
        id,
        asset_name,
        asst_type,
        customer_name,
        `${bookFrom}-${bookTill}`,
        state,
        cost,
        ship_o_hoi_profit,
        client_profit_without_tax,
        client_profit,
      ];
    });

    handleDownloadForRental(
      importType,
      headers,
      [
        ...newData,
        ["", "", "", "", "", "", "", "", "", ""],
        [
          "",
          "",
          "",
          "",
          "",
          "Total",
          totalCost + " " + "NOK",
          totalShipOHoiProfit + " " + "NOK",
          totalClientProfitWithoutTax + " " + "NOK",
          totalClientProfit + " " + "NOK",
        ],
      ],
      "Rentals"
    );
  };

  const handleProceedExportForClubMembers = (importType, data, headers) => {
    const newData = data.map((subData) => {
      const {
        customer_id,
        customer_name,
        customer_email,
        customer_contact,
        dock_number,
        pier_number,
        join_date,
      } = subData;
      const joinedDate = dateFormat(join_date);
      return [
        customer_id,
        customer_name,
        customer_email,
        customer_contact,
        dock_number,
        pier_number,
        joinedDate,
      ];
    });
    handleDownload(importType, headers, newData, "Club Members");
  };
  const handleProceedExportForSeasonalDocks = (importType, data, headers) => {
    const newData = data.map((subData) => {
      const {
        customer_id,
        customer_name,
        customer_email,
        customer_contact,
        dock_number,
        pier_number,
        dock_length,
        dock_width,
        boat_type,
        boat_length,
        boat_width,
        price,
        status,
      } = subData;
      return [
        customer_id,
        customer_name,
        customer_email,
        customer_contact,
        pier_number,
        dock_number,
        dock_length,
        dock_width,
        boat_type,
        boat_length,
        boat_width,
        price,
        status,
      ];
    });

    handleDownload(importType, headers, newData, "Seasonal Docks");
  };
  const handleProceedExportForWaitingList = (importType, data, headers) => {
    const newData = data.map((subData) => {
      const {
        boat_type,
        boat_width,
        boat_length,
        customer_id,
        customer_name,
        customer_email,
        customer_contact,
        join_date,
      } = subData;
      return [
        boat_type,
        boat_width,
        boat_length,
        customer_id ? customer_id : "0",
        customer_name,
        customer_email,
        customer_contact,
        dateFormat(join_date),
      ];
    });
    handleDownload(importType, headers, newData, "Waiting List");
  };

  const handleProceedExportForAssets = (importType, data, headers) => {
    const newData = data.map((subData) => {
      const {
        name,
        available,
        size_in_feet,
        horse_power,
        rate_per_hour,
        rate_per_day,
        weight_in_kg,
      } = subData;
      let exportedData;
      if (params === "kayak") {
        exportedData = [
          name,
          available,
          size_in_feet,
          rate_per_hour,
          rate_per_day,
        ];
      }
      if (params === "boat_trailer" || params === "boat-trailer") {
        exportedData = [
          name,
          available,
          size_in_feet,
          rate_per_hour,
          rate_per_day,
          weight_in_kg,
        ];
      } else {
        exportedData = [
          name,
          available,
          size_in_feet,
          horse_power,
          rate_per_hour,
          rate_per_day,
        ];
      }
      return exportedData;
    });
    handleDownload(importType, headers, newData, params + "s");
  };

  switch (params) {
    case "rentals":
      return {
        importType,
        setImportType,
        changeImportType,
        handleImportType,
        handleProceedExport: handleProceedExportForRentals,
      };
    case "club-members":
      return {
        importType,
        setImportType,
        changeImportType,
        handleImportType,
        handleProceedExport: handleProceedExportForClubMembers,
      };
    case "season-dock":
      return {
        importType,
        setImportType,
        changeImportType,
        handleImportType,
        handleProceedExport: handleProceedExportForSeasonalDocks,
      };
    case "waiting-list":
      return {
        importType,
        setImportType,
        changeImportType,
        handleImportType,
        handleProceedExport: handleProceedExportForWaitingList,
      };
    case "guest-docks":
      return {
        importType,
        setImportType,
        changeImportType,
        handleImportType,
        handleProceedExport: handleProceedExportForGuestDocks,
      };
    case "boat":
    case "jet_ski":
    case "kayak":
    case "boat_trailer":
    case "boat-trailer":
      return {
        importType,
        setImportType,
        changeImportType,
        handleImportType,
        handleProceedExport: handleProceedExportForAssets,
      };
    default:
      break;
  }
};

export default useGetDataForExports;
