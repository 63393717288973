import { Form } from "antd";
import React from "react";
import ButtonDark from "../ButtonDark";
import FormInput from "../FormInput";

const ForgotPasswordModal = ({
  onCancel,
  instruction,
  setInstructions,
  onSubmit,
  loading,
  t,
}) => {
  return (
    <>
      {!instruction && (
        <Form
          onFinish={(values) => onSubmit(values)}
          id="forgot-password"
          className="flex flex-col gap-y-5 p-5 items-center mt-14"
        >
          <div className="text-[25px] text-secondary font-medium font-roobert">
            {t("Forgotyourpassword")}?
          </div>
          <p className="text-[16px] font-roobert font-normal text-secondary text-center">
            {t("ForgotyourpasswordDesc1")}.
          </p>
          <div className="flex flex-col w-full ">
            <div className="pl-1">{t("email")}</div>
            <FormInput
              name="email"
              type="email"
              styleClasses={
                "!bg-transparent !shadow-none !border-b !rounded-none !outline-none"
              }
              rules={[
                {
                  required: true,
                  message: t("emailisrequired"),
                },
              ]}
            />
          </div>
          <ButtonDark
            type="submit"
            loading={loading}
            styleClasses="w-[40%] bg-[#134357] text-[#FFFFFF] h-[50px]"
            text={t("continue")}
          />
        </Form>
      )}
      {instruction && (
        <div className=" font-roobert flex flex-col items-center justify-center gap-y-[10px] p-[20px]">
          <h2 className=" text-[22px] text-center font-medium">
            {t("PasswordResetInstructionsSent")}
          </h2>
          <p className="text-[14px] text-center font-normal">
            {t("PasswordResetInstructionsSentDesc1")}
          </p>
          <ButtonDark
            handleClick={() => {
              onCancel();
              setInstructions(false);
            }}
            styleClasses="bg-[#FFF] w-[40%] text-secondary border mt-3 border-[#6DB9D0]"
            text={t("Ok")}
          />
        </div>
      )}
    </>
  );
};

export default ForgotPasswordModal;
