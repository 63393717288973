import React from "react";
import Layout from "../Layout/Layout";
import CalendarComponent from "../components/Calendar/CalendarComponent";
import rentals from "../components/Calendar/rentals.json";
import { useQuery } from "react-query";
import { getRentalsForCalendar } from "../API/calendar";
import { useUserDataAndNavigation } from "../hooks/useUserDataAndNavigation";
import Spinner from "../components/CommonComponents/Spinner";

const Calendar = () => {
  const { userId } = useUserDataAndNavigation();
  const { data, isLoading, refetch, error, isFetching } = useQuery({
    queryKey: ["get-rentals"],
    queryFn: () => getRentalsForCalendar(userId),
  });

  return (
    <Layout page={"Calendar Overview"}>
      {(isLoading || isFetching) && <Spinner />}
      <div className="px-[26px]">
        {data && <CalendarComponent campaigns={data?.data} />}
      </div>
    </Layout>
  );
};

export default Calendar;
