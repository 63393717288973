import React from "react";

const ArrowNext = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
    >
      <path
        d="M1.39957 16.7361L9.70486 9.43123C9.9176 9.24468 10.0247 8.98413 10.0233 8.72991C10.0162 8.47632 9.89938 8.21778 9.68192 8.03901L1.15369 1.08078C0.615057 0.640442 -0.144603 1.27359 0.204719 1.87787L3.6005 8.86038L0.430271 15.9778C0.105894 16.5969 0.880346 17.1997 1.40526 16.7355L1.39957 16.7361Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default ArrowNext;
