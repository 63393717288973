import React from "react";
import ButtonDark from "../CommonComponents/ButtonDark";

const AskAutoApproval = ({
  isAutoApproveRequest,
  setIsAutoApproveRequest,
  t,
}) => {
  return (
    <div className="text-center font-roobert-rg mt-[20px]">
      <h1 className="text-[22px] 2xl:text-[24px]">{t("autoApproveText1")}</h1>
      <p className="text-[14px] 2xl:text-[16px] mt-[10px]">
        {t("autoApproveText2")}
      </p>
      <div className="flex gap-x-[12px] mt-[20px] justify-center items-center">
        <ButtonDark
          text={t("yes")}
          styleClasses={`${
            isAutoApproveRequest ? "bg-[#ACDCE7]" : ""
          } white-Btn h-[40px] w-[100px]`}
          handleClick={() => {
            setIsAutoApproveRequest(true);
          }}
        />
        <ButtonDark
          text={t("no")}
          styleClasses={`${
            !isAutoApproveRequest ? "bg-[#ACDCE7]" : ""
          } white-Btn h-[40px]  w-[100px]`}
          handleClick={() => {
            setIsAutoApproveRequest(false);
          }}
        />
      </div>
    </div>
  );
};

export default AskAutoApproval;
