import React from "react";
import ButtonDark from "../CommonComponents/ButtonDark";

const SettingHeader = ({ heading, t, subscription, form, isLoading }) => {
  return (
    <div className="w-full flex px-[40px] pt-[30px] items-center justify-between">
      <h1 className="text-[28px] 2xl:text-[35px] text-dark-blue leading-[50px] font-roobert-rg">
        {heading}
      </h1>
      {subscription ? (
        <ButtonDark
          text={t("changeSubscription")}
          type="submit"
          styleClasses="light-Btn h-[40px]"
        />
      ) : (
        <ButtonDark
          text={t("save")}
          type="submit"
          styleClasses="light-Btn h-[40px]"
          handleClick={() => form.submit()}
          loading={isLoading}
        />
      )}
    </div>
  );
};

export default SettingHeader;
