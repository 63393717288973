import dayjs from "dayjs";
import { useState } from "react";
import useAssetDetails from "./useAssetDetails";
import { message } from "antd";
import { convertToTimeStamps } from "../../utils/dateUtils";

const useGetDataForAcceptOrRejectRental = ({
  modalScreen,
  setModalScreen,
  rentalItem,
  onClickReject,
}) => {
  const [selectReason, setSelectReason] = useState(0);
  const handleClick = (reason) => {
    setSelectReason(reason);
    setModalScreen(2);
  };

  const { data, isLoading } = useAssetDetails({
    enabled: modalScreen === 3,
    id: rentalItem[0]["property.id"],
    assetType: rentalItem[0]["property.type"],
  });

  const unavailbleDates =
    data?.property?.unavailable_dates?.map((dates) => dates * 1000) ?? [];
  const handleYes = () => {
    setModalScreen(3);
  };
  const handleNo = () => {
    onClickReject(selectReason);
  };
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().toDate(),
    endDate: dayjs().toDate(),
  });

  const sendProposedDates = () => {
    if (dateRange.startDate && dateRange.endDate) {
      let startDate = dayjs.unix(dateRange.startDate);
      let endDate = dayjs.unix(dateRange.endDate);
      let differenceInHours = endDate.diff(startDate, "hour");
      if (differenceInHours < 1) {
        message.error(
          "The difference between start and end date should be at least 1 hour."
        );
      } else {
        onClickReject(
          selectReason,
          convertToTimeStamps(dateRange.startDate),
          convertToTimeStamps(dateRange.endDate)
        );
      }
    }
  };
  return {
    dateRange,
    setDateRange,
    handleNo,
    handleYes,
    unavailbleDates,
    selectReason,
    setSelectReason,
    data,
    isLoading,
    handleClick,
    sendProposedDates,
  };
};

export default useGetDataForAcceptOrRejectRental;
