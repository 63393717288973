import { useTranslation } from "react-i18next";
import ButtonDark from "../CommonComponents/ButtonDark";

const SuggestNewTimePermission = ({ isPending, handleNo, handleYes }) => {
  const { t } = useTranslation();
  return (
    <div className="h-[40vh] bg-[#E2F3F7] rounded-[10px] flex flex-col justify-center items-center">
      <h1 className="!text-[22px]">{t("Wouldyouliketosuggestanewtime")}?</h1>
      <div className="flex items-center gap-x-3 mt-3">
        <ButtonDark
          handleClick={handleYes}
          styleClasses="capitalize h-[45px] bg-white border-dark-blue  rounded-full min-w-[100px]"
          text={t("yes")}
        />

        <ButtonDark
          handleClick={handleNo}
          loading={isPending}
          text={t("no")}
          styleClasses="capitalize h-[45px] bg-white border-dark-blue rounded-full min-w-[100px]"
        />
      </div>
    </div>
  );
};

export default SuggestNewTimePermission;
