import { Carousel } from "antd";

export default function CustomCarousel({ children }) {
  return (
    <div id="chatBookings" className="bg-[#E2F3F7] ">
      <Carousel adaptiveHeight dots={false} arrows infinite={false}>
        {children}
      </Carousel>
    </div>
  );
}
