import { useQuery } from "react-query";
import { editGuestDock, getAssetDetails } from "../../API/marina";
import useConstants from "./useConstants";

const useAssetDetails = ({ enabled, id, assetType }) => {
  const { userId, location } = useConstants();
  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: ["get-asset", location, id],
    queryFn: async () => {
      if (assetType !== "guest_dock") {
        return await getAssetDetails(userId, id, assetType);
      } else {
        return await editGuestDock(userId, id);
      }
    },
    enabled: enabled,
  });
  return { data, isLoading, isFetching, refetch, error };
};

export default useAssetDetails;
