import React from "react";

const CustomNextButton = ({ onClick, style, className }) => {
  return (
    <svg
      width="69"
      height="77"
      viewBox="0 0 69 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ ...style }}
      className={className}
    >
      <path
        d="M34.5008 12.3775C20.4612 12.3775 9.07978 23.6979 9.07978 37.6624C9.07977 51.6268 20.4612 62.9473 34.5008 62.9473C48.5405 62.9473 59.9219 51.6268 59.9219 37.6624C59.9219 23.6979 48.5405 12.3775 34.5008 12.3775Z"
        stroke="#18374F"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M29.1784 51.7774L44.7685 38.8818C45.1561 38.5631 45.3647 38.0704 45.3945 37.6357C45.3945 37.1721 45.1859 36.7084 44.7685 36.3896L29.1784 23.4941C28.1947 22.6827 26.7639 23.7838 27.3899 24.914L33.4709 37.6647L27.3899 50.4154C26.7639 51.5166 28.1947 52.6468 29.1784 51.8354L29.1784 51.7774Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default CustomNextButton;
