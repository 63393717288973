import React from "react";
import { Cell, Pie, PieChart } from "recharts";

const PieChartGraph = ({ data }) => {
  return (
    <PieChart height={300} width={300}>
      <Pie
        cx="50%" // Center x-coordinate
        cy="50%" // Center y-coordinate
        innerRadius={30}
        outerRadius={110}
        startAngle={270}
        endAngle={-360}
        paddingAngle={5}
        data={[
          {
            value: data?.guestDocks,
            name: "guestPercentage",
          },
          {
            value: data?.seasonDocks,
            name: "seasonPercentage",
          },
        ]}
      >
        <Cell
          fill="#7BB9D3"
          // seasonaldocks
          strokeWidth={5}
        />
        <Cell
          fill="#D7EEF3"
          strokeWidth="12"
          // guestdocks
        />
      </Pie>
    </PieChart>
  );
};

export default PieChartGraph;
