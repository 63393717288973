/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import SDSDetailsHeader from "../SeasonalDockSpots/SDSDetailsHeader";
import ButtonDark from "../CommonComponents/ButtonDark";
import FormInput from "../CommonComponents/FormInput";
import TextArea from "../CommonComponents/TextArea";
import { Form, message } from "antd";
import DiscountOffer from "../Maps/DiscountOffer";
import EditImages from "../CommonComponents/EditImages";
import Spinner from "../CommonComponents/Spinner";
import useEditAsset from "../../hooks/getData/useEditAsset";
// import Facilities from "../Maps/Facilities";
import EditOtherGuestDockDetails from "./EditOtherGuestDockDetails";
import RentalPrice from "../Maps/RentalPrice";
import EditFacilities from "./EditFacilities";
import {
  getRangeForHorsePower,
  getRangeForOccupancy,
  getRangeForSizeInFeet,
} from "../../utils/getAssetRanges";
import { validatorFnc } from "../../utils/getRequiredFn";
import AskAutoApproval from "../Maps/AskAutoApproval";

const EditAssets = ({ onCancelModal, refetch, refetchTopAssets }) => {
  const {
    handleClick,
    isDiscount,
    setIsDiscount,
    type,
    fileInputRef,
    onFinish,
    images,
    setImages,
    t,
    loading,
    checkedList,
    setCheckedList,
    isBoatTrailer,
    isGuestDock,
    isKayak,
    isFetching,
    isLoading,
    error,
    form,
    removeImages,
    setRemoveImages,
    screen,
    setScreen,
    isBoat,
    assetType,
    isJetSki,
    assetId,
    isAutoApproveRequest,
    setIsAutoApproveRequest,
  } = useEditAsset({
    refetch,
    onCancelModal,
    refetchTopAssets,
  });

  useEffect(() => {
    if (!assetId) {
      onCancelModal();
    }
  }, [onCancelModal]);

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  if (error) {
    return message.error(error.message);
  }
  return (
    <div className="px-[30px]  add-asset h-[80vh] rounded-[20px] overflow-auto  bg-[#E2F3F7] pt-[26px]">
      <SDSDetailsHeader
        handleClose={() => {
          onCancelModal();
        }}
        newInvoice={false}
        addRenter={true}
      />
      <h1 className="w-full px-[50px] font-roobert-rg mt-[10px] text-center text-[28px] 2xl:text-[35px]">
        {t("edit")} {type} {t("details")}
      </h1>
      <div className="pb-[20px]">
        <Form
          name="basic"
          onFinish={(values) => onFinish(values, isDiscount)}
          autoComplete="off"
          form={form}
        >
          <div className="w-full px-[50px] mt-[10px]">
            {screen === 1 && (
              <h1 className="text-[22px] mb-[5px] 2xl:text-[24px] font-roobert-rg">
                {type} {t("details")}
              </h1>
            )}

            {screen === 1 && (
              <>
                {!isGuestDock && (
                  <div className="flex gap-[20px] w-full">
                    <div className="w-full">
                      <FormInput
                        name={"name"}
                        required={true}
                        requiredMessage="Please enter your Model!"
                        label={`${type} model`}
                        type={"text"}
                        styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                      />
                    </div>
                    {!isBoatTrailer && (
                      <div className="w-full">
                        <FormInput
                          name={"occupancy"}
                          type={"number"}
                          required={true}
                          requiredMessage="Please enter Number of people!"
                          rules={[
                            {
                              validator: (_, value) => {
                                const range = getRangeForOccupancy(assetType);
                                return validatorFnc(
                                  value,
                                  range,
                                  "maximumLimitExceeded",
                                  t
                                );
                              },
                            },
                          ]}
                          label={"Number of people"}
                          styleClasses={
                            "rounded-full font-roobert-rg pl-[20px]"
                          }
                        />
                      </div>
                    )}
                    {isBoatTrailer && (
                      <div className="w-full">
                        <FormInput
                          name="weight_in_kg"
                          type="number"
                          step={"any"}
                          required={true}
                          requiredMessage="Please enter Weight!"
                          label={"Size in KG"}
                          styleClasses={
                            "rounded-full font-roobert-rg pl-[20px]"
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
                {isGuestDock && (
                  <>
                    <FormInput
                      name={"marina_name"}
                      type={"text"}
                      required={true}
                      requiredMessage="Please enter marina name"
                      label={"Marina Name"}
                      styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                    />
                    <div className="mt-[60px]">
                      <FormInput
                        name={"number_of_guest_dock"}
                        type={"text"}
                        disabled={true}
                        label={"Number Of Guest Dock"}
                        styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {screen === 1 && !isGuestDock && (
              <div className="grid grid-cols-2 mt-[20px] gap-[20px]">
                {!isKayak && !isBoatTrailer && (
                  <div className="">
                    <FormInput
                      name={"horse_power"}
                      label={"Speed"}
                      styleClasses={
                        "rounded-full mt-[5px] font-roobert-rg pl-[20px]"
                      }
                      step="any"
                      type={"number"}
                      required={true}
                      requiredMessage="Please enter Speed!"
                      rules={[
                        {
                          validator: (_, value) => {
                            const range = getRangeForHorsePower(assetType);
                            return validatorFnc(
                              value,
                              range,
                              "maximumLimitExceeded",
                              t
                            );
                          },
                        },
                      ]}
                    />
                  </div>
                )}
                <div className="">
                  <FormInput
                    name={"size_in_feet"}
                    label={"Foot"}
                    type={"number"}
                    step="any"
                    styleClasses={
                      "rounded-full font-roobert-rg mt-[5px] pl-[20px]"
                    }
                    required={true}
                    requiredMessage="Please enter Size!"
                    rules={[
                      {
                        validator: (_, value) => {
                          const range = getRangeForSizeInFeet(assetType);
                          return validatorFnc(
                            value,
                            range,
                            "maximumLimitExceeded",
                            t
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            )}
            {screen === 2 && (
              <>
                <RentalPrice
                  step={screen}
                  propertyType={assetType.split("-").join("_")}
                  sizeInfeet={form.getFieldValue("size_in_feet")}
                  form={form}
                />
                {isGuestDock && (
                  <>
                    <EditFacilities
                      checkedList={checkedList}
                      setCheckedList={setCheckedList}
                    />
                    <div className="mt-[50px]">
                      <EditOtherGuestDockDetails t={t} />
                    </div>
                  </>
                )}
              </>
            )}

            {screen === 2 && (
              <div className="mt-[50px]">
                <DiscountOffer
                  setIsDiscount={setIsDiscount}
                  isDiscount={isDiscount}
                  t={t}
                />
              </div>
            )}
            {screen === 2 && (
              <div className="my-[50px]">
                <AskAutoApproval
                  setIsAutoApproveRequest={setIsAutoApproveRequest}
                  isAutoApproveRequest={isAutoApproveRequest}
                  t={t}
                />
              </div>
            )}
            {screen === 1 && (
              <div className="h-[200px] mt-[50px]">
                <div className=" font-roobert-sb text-[25px] text-dark-blue text-center mb-[10px]">
                  {t("description")}
                </div>
                <TextArea
                  rows={5}
                  placeHolder={"Write a description of your asset"}
                  name="description"
                  styleClasses={
                    "!rounded-[20px] font-roobert-rg pt-[10px] pl-[20px]"
                  }
                />
              </div>
            )}
            <div className="mt-[10px]">
              {screen === 1 && (
                <EditImages
                  addImages={images}
                  handleClick={handleClick}
                  t={t}
                  fileInputRef={fileInputRef}
                  editAsset={true}
                  setAddImages={setImages}
                  removeImages={removeImages}
                  setRemoveImages={setRemoveImages}
                />
              )}
            </div>
          </div>

          <div className="text-white   flex gap-x-3 mt-[30px] justify-center">
            <ButtonDark
              styleClasses={"dark-Btn h-[50px] w-[150px] !px-[20px]"}
              text={"Back"}
              handleClick={() => {
                if (screen === 1) {
                  onCancelModal();
                } else {
                  setScreen(1);
                }
              }}
            />
            <ButtonDark
              styleClasses={"dark-Btn h-[50px] w-[150px] !px-[20px]"}
              text={screen === 1 ? t("continue") : t("save")}
              type="submit"
              loading={loading}
              disabled={loading}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditAssets;
