import React from "react";
import ButtonDark from "../CommonComponents/ButtonDark";

const AcceptOrRejectRequest = ({
  showModal,
  onClickAcceptOrReject,
  handleRequestModal,
  loading,
}) => {
  return (
    <div className="h-[50vh] bg-[#E2F3F7] gap-y-[25px] flex flex-col justify-center items-center rounded-[10px]">
      <h1 className="leading-[50px] 2xl:text-[35px] text-[28px] font-roobert-rg">
        Are you sure you want to{" "}
        {showModal.type === "accept" ? "accept" : "reject"} rental Request?
      </h1>
      <div className="flex items-center gap-x-[15px]">
        <ButtonDark
          text={"Yes"}
          styleClasses={"light-Btn h-[40px] w-[90px]"}
          handleClick={() => onClickAcceptOrReject(showModal.type)}
          loading={loading}
        />
        <ButtonDark
          text={"No"}
          styleClasses={"white-Btn h-[40px] w-[90px]"}
          handleClick={() => handleRequestModal(false, null)}
        />
      </div>
    </div>
  );
};

export default AcceptOrRejectRequest;
