import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { addImagesToFirebase } from "../../utils/addImages";
import useMutationHook from "../useMutationHook";
import { editAsset, editGuestDocks } from "../../API/marina";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import useAssetDetails from "./useAssetDetails";
import { useForm } from "antd/es/form/Form";
import { useAssetEditStore } from "../../store/useAssetEditStore";
import { removeImageByUrl } from "../../utils/removeImagesFromFirebase";

const useEditAsset = ({ refetch, onCancelModal, refetchTopAssets }) => {
  const { userId, params, t } = useUserDataAndNavigation();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const {
    images,
    setImages,
    thumbnailImages,
    setThumbnailImages,
    isOpen,
    screen,
    setScreen,
  } = useAssetEditStore();
  const [removeImages, setRemoveImages] = useState([]);
  const [firstStepFormFields, setFirstStepFormFields] = useState({});
  const [isDiscount, setIsDiscount] = useState(false);
  const [isAutoApproveRequest, setIsAutoApproveRequest] = useState(false);

  const [checkedList, setCheckedList] = useState({});
  const fileInputRef = useRef(null);
  const assetType = params.get("type");
  const assetId = params.get("id");
  const isGuestDock = assetType === "guest-dock";
  const isBoat = assetType === "boat";
  const isJetSki = assetType === "jet_ski";
  const isKayak = assetType === "kayak";
  const isBoatTrailer = assetType === "boat_trailer";
  const type = isBoat
    ? t("boat")
    : isJetSki
    ? t("singleJetski")
    : isKayak
    ? t("singleKayak")
    : isGuestDock
    ? t("guestDock")
    : isBoatTrailer
    ? t("BoatTrailer")
    : "";

  const [addImages, setAddImages] = useState([]);
  const {
    data: assetData,
    isFetching,
    isLoading,
    error,
  } = useAssetDetails({
    enabled: !!(isOpen || assetId),
    id: params.get("id"),
    assetType: params.get("type"),
  });
  const {
    property,
    images: assetImages,
    thumbnailImages: thumbnail_images,
  } = assetData ? assetData : { property: {}, images: [] };
  const handleClick = () => {
    if (images.length > 3) {
      message.error(t("maximumLimitExceeded"));
    } else {
      fileInputRef.current.click();
    }
  };
  useEffect(() => {
    if (assetData && assetType !== "guest-dock") {
      const { property } = assetData;
      form.setFieldsValue({
        name: property?.name,
        weight_in_kg: property?.weight_in_kg,
        occupancy: property?.occupancy === 0 ? "" : property?.occupancy,
        description: property?.description,
        horse_power: property?.horse_power === 0 ? "" : property?.horse_power,
        size_in_feet:
          property?.size_in_feet === 0 ? "" : property?.size_in_feet,
        rate_per_hour:
          property?.rate_per_hour === 0 ? "" : property?.rate_per_hour,
        rate_per_day:
          property?.rate_per_day === 0 ? "" : property?.rate_per_day,
        rate_per_month:
          property?.rate_per_month === 0 ? "" : property?.rate_per_month,
        rate_per_season:
          property?.rate_per_season === 0 ? "" : property?.rate_per_season,
        rate_per_week:
          property?.rate_per_week === 0 ? "" : property?.rate_per_week,
      });
    }
    if (assetType === "guest-dock" && assetData) {
      const { property } = assetData;
      form.setFieldsValue({
        dock_width: property?.dock_width,
        dock_length: property?.dock_length,
        pier_number: property?.pier_number,
        dock_number: property?.dock_number ?? "",
        description: property?.description,
        rate_per_day:
          property?.rate_per_day === 0 ? "" : property?.rate_per_day,
        rate_per_hour:
          property?.rate_per_hour === 0 ? "" : property?.rate_per_hour,
        water_rate_per_day:
          property?.water_rate_per_day === 0
            ? ""
            : property?.water_rate_per_day,
        water_rate_per_hour:
          property?.water_rate_per_hour === 0
            ? ""
            : property?.water_rate_per_hour,
        electricity_rate_per_day:
          property?.electricity_rate_per_day === 0
            ? ""
            : property?.electricity_rate_per_day,
        electricity_rate_per_hour:
          property?.electricity_rate_per_hour === 0
            ? ""
            : property?.electricity_rate_per_hour,
        marina_name: property?.name,
        number_of_guest_dock:
          property?.number_of_guest_dock === 0
            ? ""
            : property?.number_of_guest_dock,
      });
    }
  }, [assetData, assetType, form]);
  useEffect(() => {
    setIsDiscount(property?.is_discount || property?.is_discount || false);
    setCheckedList({
      water: property?.is_water || false,
      electricity: property?.is_electricity || false,
    });
  }, [
    params,
    assetData,
    property?.is_discount,
    property?.is_water,
    property?.is_electricity,
  ]);
  useEffect(() => {
    if (assetData) {
      setImages(
        assetImages?.map((img) => {
          return {
            file: img?.image,
            id: img?.image_id,
            isFirebase: true,
          };
        })
      );
      setThumbnailImages(
        thumbnail_images?.map((img) => {
          return {
            file: img?.image,
            id: img?.image_id,
            isFirebase: true,
            parentId: img?.fk_full_image_id,
          };
        })
      );
    }
  }, [assetData, assetImages, setImages, setThumbnailImages, thumbnail_images]);

  const onSuccess = (Data) => {
    refetchTopAssets();
    if (!isGuestDock) {
      setLoading(false);
      message.success(Data.data.data);
    } else {
      message.success(Data.data.message);
      setLoading(false);
    }
    refetch();
    onCancelModal();
  };
  const onError = (error) => {
    const { data } = error.response;
    message.error(data?.message);
    setLoading(false);
  };
  const { mutate } = useMutationHook(
    "[edit-asset]",
    async (Data) => {
      if (!isGuestDock) {
        return await editAsset(
          userId,
          params.get("id"),
          params.get("type"),
          Data
        );
      }
      if (isGuestDock) {
        return await editGuestDocks(userId, params.get("id"), Data);
      }
    },
    onSuccess,
    onError
  );

  const onFinish = async (values, isDiscount) => {
    if (screen === 1) {
      if (images.length < 1) {
        setLoading(false);
        return message.error("Please enter at least one image");
      }
      setFirstStepFormFields({
        ...firstStepFormFields,
        ...values,
      });
      return setScreen(2);
    }
    setLoading(true);
    const existingImages = images.filter((image) => image.isFirebase);
    const newImages = images.filter((image) => !image.isFirebase);
    const existingThubnailImages = thumbnailImages.filter(
      (image) => image.isFirebase
    );
    const newThumbnailImages = thumbnailImages.filter((img) => !img.isFirebase);
    const imageUrls = newImages.map((image) => image.file);
    const thumbnailUrls = newThumbnailImages.map((image) => image.file);
    const existingImagesUrl = existingImages.map((image) => image.file);
    const existingThumbnailUrls = existingThubnailImages.map(
      (image) => image.file
    );

    const newAddedImages =
      imageUrls.length >= 1 ? await addImagesToFirebase(imageUrls) : [];
    const newAddedThumbnailImages =
      newThumbnailImages.length >= 1
        ? await addImagesToFirebase(thumbnailUrls)
        : [];

    if (!isGuestDock) {
      mutate({
        ...values,
        ...firstStepFormFields,
        rate_per_week: values?.rate_per_week === "" ? 0 : values?.rate_per_week,
        rate_per_month:
          values?.rate_per_month === "" ? 0 : values?.rate_per_month,
        rate_per_season:
          values?.rate_per_season === "" ? 0 : values?.rate_per_season,
        images: [...existingImagesUrl, ...newAddedImages],
        thumbnail_images: [
          ...existingThumbnailUrls,
          ...newAddedThumbnailImages,
        ],
        is_discount: isDiscount,
        type: params.get("type"),
        lat: Number(property?.lat),
        lng: Number(property?.lng),
      });
    }
    if (isGuestDock) {
      mutate({
        details: {
          dock_number: values?.dock_number,
          pier_number: values?.pier_number,
          dock_length: values?.dock_length,
          dock_width: values?.dock_width,
        },
        ...firstStepFormFields,
        guestDock: {
          water_rate_per_day:
            values.water_rate_per_day === "" ? 0 : values.water_rate_per_day,
          water_rate_per_hour:
            values.water_rate_per_hour === "" ? 0 : values.water_rate_per_hour,
          electricity_rate_per_day:
            values.electricity_rate_per_day === ""
              ? 0
              : values.electricity_rate_per_day,
          electricity_rate_per_hour:
            values.electricity_rate_hour === 0
              ? ""
              : values.electricity_rate_per_hour,
          rate_per_hour: values.rate_per_hour === "" ? 0 : values.rate_per_hour,
          rate_per_day: values.rate_per_day === "" ? 0 : values.rate_per_day,
          is_discount: isDiscount,
          is_electricity: checkedList.electricity,
          is_water: checkedList.water,
          marina_name: firstStepFormFields.marina_name,
          description: firstStepFormFields.description,
        },
        images: [...existingImagesUrl, ...newAddedImages],
        thumbnail_images: [
          ...existingThumbnailUrls,
          ...newAddedThumbnailImages,
        ],
      });
    }
  };
  return {
    handleClick,
    isDiscount,
    setIsDiscount,
    type,
    t,
    fileInputRef,
    onFinish,
    addImages,
    setAddImages,
    loading,
    params,
    checkedList,
    setCheckedList,
    isBoatTrailer,
    isGuestDock,
    isKayak,
    isFetching,
    isLoading,
    error,
    form,
    images,
    setImages,
    thumbnailImages,
    setThumbnailImages,
    removeImages,
    setRemoveImages,
    screen,
    setScreen,
    isBoat,
    isJetSki,
    assetType,
    assetId,
    isAutoApproveRequest,
    setIsAutoApproveRequest,
  };
};

export default useEditAsset;
