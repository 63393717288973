import NorwegianLocale from "date-fns/locale/nb";
import GermanLocale from "date-fns/locale/de";
import SweLocale from "date-fns/locale/sv";
import enLocale from "date-fns/locale/en-US";
import esNO from "antd/lib/locale/nb_NO";
import enUS from "antd/lib/locale/en_US";
import enSE from "antd/lib/locale/sv_SE";
export const getLocale = (lang) => {
  return lang === "no"
    ? NorwegianLocale
    : lang === "se"
    ? SweLocale
    : lang === "de"
    ? GermanLocale
    : enLocale;
};

export const getAntdLocales = (lang) => {
  return lang === "no" ? esNO : lang === "se" ? enSE : enUS;
};
