import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import MyPosition from "../assets/images/myposition.png";
import DocksPosition from "../assets/images/DocksPosition.png";
import BoatsPosition from "../assets/images/BoatsPosition.png";
import JetSkisPosition from "../assets/images/JetSkisPosition.png";
import KayakPosition from "../assets/images/KayakPosition.png";
import BoatTrailerPosition from "../assets/images/BoatTrailer.png";
import Marina from "../assets/images/Marina.png";
import "@reach/combobox/styles.css";
import { useLocationStore } from "../store/store";
import ZoomInZoomOut from "./CommonComponents/ZoomInZoomOut";
import BackAndChangeMapType from "./Maps/BackAndChangeMapType";
import {
  customMapStyles,
  mapStyles,
  placesLibrary,
} from "../constants/constants";
import useGetDataForMap from "../hooks/getData/useGetDataForMap";
import SearchLocation from "./Maps/SearchLocation";
import Dialog from "./CommonComponents/Modal/Dialog";
import Instructions from "./Maps/Instructions";

const GoogleMapContainer = ({
  setActive,
  isGuestDock,
  notGuestDock,
  t,
  assetType,
  activeDockNumber,
  dockNumber,
}) => {
  const {
    acceptDocksLocation,
    acceptLocation,
    handleLoad,
    onHandleInstructions,
    showInstruction,
  } = useGetDataForMap();
  const { isLoaded: isGoogleMapsLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
    libraries: placesLibrary,
  });

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    newPosition({ lat, lng });
  }, []);

  const isMapsApiLoaded = isGoogleMapsLoaded && !loadError;
  const [isValidCenter, setIsValidCenter] = useState(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState(null);
  const mapRef = useRef(null);
  const {
    addPosition,
    position,
    center,
    addCenter,
    newPosition,
    addNewPosition,
  } = useLocationStore();
  const getUserPosition = React.useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        addPosition({ lat: latitude, lng: longitude });
        addCenter({ lat: latitude, lng: longitude });
        addNewPosition({ lat: latitude, lng: longitude });
      },
      (error) => {
        setError(error.message);
      }
    );
  }, [addCenter, addNewPosition, addPosition]);

  useEffect(() => {
    getUserPosition();
  }, [getUserPosition]);

  useEffect(() => {
    if (
      center &&
      center.lat &&
      center.lng &&
      Number.isFinite(center.lat) &&
      Number.isFinite(center.lng)
    ) {
      setIsValidCenter(true);
    } else {
      setIsValidCenter(false);
    }
  }, [center]);

  const assignIcon = (type) => {
    switch (type) {
      case "dock":
        return DocksPosition;
      case "boat":
        return BoatsPosition;
      case "kayak":
        return KayakPosition;
      case "boat_trailer":
        return BoatTrailerPosition;
      case "jet_ski":
        return JetSkisPosition;
      case "guest_dock":
        return Marina;
      default:
        return MyPosition;
    }
  };

  const handleZoomIn = () => {
    const { current } = mapRef;
    if (current) {
      const newZoomLevel = current.getZoom() + 1;
      mapRef.current.setZoom(newZoomLevel);
    }
  };

  const handleCenterChanged = () => {
    const { current } = mapRef;
    if (!current) return;
    const newPos = current.getCenter().toJSON();
    addNewPosition(newPos);
  };

  const handleZoomOut = () => {
    const { current } = mapRef;
    if (current) {
      const currentZoomLevel = current.getZoom();
      const newZoomLevel = Math.max(currentZoomLevel - 1, 1);
      current.setZoom(newZoomLevel);
    }
  };

  return (
    <>
      <Dialog
        isModalOpen={showInstruction}
        onCancelModal={() => onHandleInstructions(false)}
        component={<Instructions onHandleInstructions={onHandleInstructions} />}
      />
      <div className="flex justify-between items-center">
        <p className=" my-[10px] text-dark-blue font-roobert-sb text-[22px]">
          {t("Moveonthemaptoselectyourlocation")}
        </p>
        {isGuestDock && (
          <p className=" my-[10px] text-dark-blue font-roobert-sb text-[22px]">
            {activeDockNumber} / {dockNumber}
          </p>
        )}
      </div>

      <div className="absolute z-10 right-7 bottom-[25%]">
        <BackAndChangeMapType backToStart={getUserPosition} />
      </div>
      <div className="absolute z-10 right-7 bottom-[10%]">
        <ZoomInZoomOut
          handleZoomOut={handleZoomOut}
          handleZoomIn={handleZoomIn}
          mainMap="main"
        />
      </div>

      {isMapsApiLoaded && position && (
        <>
          <SearchLocation
            isGuestDock={isGuestDock}
            panTo={panTo}
            notGuestDock={notGuestDock}
            acceptDocksLocation={acceptDocksLocation}
            acceptLocation={acceptLocation}
            t={t}
            newPosition={newPosition}
            setActive={setActive}
          />
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={17}
            center={center}
            onLoad={(map) => handleLoad(map, mapRef, setOptions)}
            options={options}
            onCenterChanged={handleCenterChanged}
          >
            <Marker
              position={newPosition}
              icon={{
                url: isGuestDock ? DocksPosition : assignIcon(assetType),
                scaledSize: new window.google.maps.Size(55, 55),
              }}
            />
          </GoogleMap>
          <style>{customMapStyles}</style>
        </>
      )}
    </>
  );
};

export default GoogleMapContainer;
