import React from "react";

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
    >
      <path
        d="M1.24857 11.8613L7.25868 6.50621C7.40807 6.37384 7.48851 6.16926 7.5 5.98875C7.5 5.79621 7.41956 5.60366 7.25868 5.47129L1.24857 0.116174C0.869348 -0.220777 0.31775 0.236513 0.559074 0.705838L2.90336 6.00079L0.559073 11.2957C0.317749 11.753 0.869347 12.2223 1.24857 11.8854L1.24857 11.8613Z"
        fill="white"
      />
    </svg>
  );
};

export default Arrow;
