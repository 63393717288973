import { Tag } from "antd";
import ViewDetails from "../../components/CommonComponents/ViewDetails";
import DockBtnIcon from "../../assets/icons/DockBtnIcon";
import MembersOverview from "../../assets/icons/MembersOverview";
import WaitlistOverview from "../../assets/icons/WaitlistOverview";
import RentalsOverview from "../../assets/icons/RentalsOverview";
import { addAssetStore, useLocationStore } from "../../store/store";
import { useQueries } from "react-query";
import {
  fetchDocks,
  fetchMembers,
  fetchRentals,
  fetchWaitingList,
  financeGraphData,
  getAssetCount,
  getAssets,
  getSeasonsDocks,
  getTasks,
} from "../../API/marina";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { resolvedFlattened } from "../../utils/flatObj";
import { onDrag } from "../../utils/onDrag";
import ButtonDark from "../../components/CommonComponents/ButtonDark";
import { useState } from "react";
const useGetDataForDashboard = ({
  setViewClubMemberModal,
  viewClubMemberModal,
}) => {
  const { organizedKeys, setOrganizedKeys, setIsModalOpen, isModalOpen } =
    useLocationStore();
  const { resetToInitialValues } = addAssetStore();
  const [assignName, setAssignName] = useState("");
  const [assignDockModal, setAssignDockModal] = useState(false);
  const handleAssignDockModal = (bool) => {
    setAssignDockModal(bool);
  };

  const {
    userId,
    params,
    paramsObject,
    lang,
    setParams,
    handleNavigate,
    role,
    t,
  } = useUserDataAndNavigation();

  const graphType = params.get("graph") || "monthly";
  const rentalType = params.get("rental") || "all";
  const isRoleMarina = role === "marina";
  const isRoleRental = role === "rental";
  const assetType = params.get("asset")
    ? params.get("asset")
    : isRoleMarina
    ? "guestDocks"
    : "boat";
  const isSeasonalDocks = assetType === "seasonaldocks";
  const isJetski = assetType === "jet_ski";
  const isBoat = assetType === "boat";
  const [
    SeasonalDocks,
    Rentals,
    ClubMembers,
    WaitingList,
    financeGraph,
    countAsset,
    docksList,
    Tasks,
    Assets,
  ] = useQueries([
    {
      queryKey: ["get-top-seasonal-docks", assetType],
      queryFn: async () => {
        if (isSeasonalDocks) {
          const data = await getSeasonsDocks(userId, 1, "", "all", "");
          return data?.members;
        } else {
          const data = await getAssets(userId, "guest_dock", "1", "");
          return data?.myAssets;
        }
      },
      enabled: isRoleMarina,
    },
    {
      queryKey: ["get-top-rentals", rentalType],
      queryFn: async () => {
        return await fetchRentals(
          userId,
          1,
          "",
          rentalType === "archive" ? "archived" : rentalType
        );
      },
    },
    {
      queryKey: ["get-top-club-members"],
      queryFn: async () => {
        return await fetchMembers(userId, 1, "");
      },
      enabled: !viewClubMemberModal && isRoleMarina,
    },
    {
      queryKey: ["get-waiting-list"],
      queryFn: async () => {
        return await fetchWaitingList(userId, 1, "", "");
      },
      enabled: isRoleMarina,
    },

    {
      queryKey: ["get-finance-graph", graphType],
      queryFn: async () => {
        return await financeGraphData(userId, graphType);
      },
    },
    {
      queryKey: ["get-total-numbers"],
      queryFn: async () => {
        return await getAssetCount(userId);
      },
    },
    {
      queryKey: ["fetch-docks-list", params.get("id")],
      queryFn: async () => {
        return await fetchDocks(userId, params.get("id"));
      },
      enabled: isModalOpen && isRoleMarina,
    },
    {
      queryKey: ["get-tasks"],
      queryFn: async () => {
        return await getTasks(userId);
      },
    },
    {
      queryKey: ["get-top-assets", assetType],
      queryFn: async () => {
        return await getAssets(userId, assetType, 1, "");
      },
      enabled: isRoleRental,
    },
  ]);

  const { myAssets } = Assets?.data ?? { data: { myassets: [] } };
  const { isLoading: assetsLoading, isFetching: assetsfetching } = Assets;
  const { data: tasks } = Tasks;
  const getTotalTasks = Object.keys(tasks ?? {}).filter(
    (key) => tasks[key] !== null
  ).length;

  const tasksDone = Object.values(tasks ?? {}).filter(
    (value) => value === true
  ).length;
  const {
    isLoading: docksLoading,
    data: docksData,
    error: docksError,
    refetch: docksRefetch,
    isFetching: docksFetching,
  } = docksList;

  const filterDocksData = docksData?.map((dock) => {
    return { key: dock.id, ...dock };
  });
  const { refetch: refetchWaitingList } = WaitingList;
  const seasonalDocksData = SeasonalDocks?.data;
  const topSeasonalDocks = seasonalDocksData?.slice(0, 3);
  const rentalsData = Rentals?.data?.rentals;
  const topRentalsData = rentalsData?.slice(0, 3);
  const membersData = ClubMembers?.data?.members;
  const topClubMembersData = membersData?.slice(0, 3);
  const waitingData = WaitingList?.data?.members;
  const topWaitingListData = waitingData?.slice(0, 3);
  const topAssets = myAssets?.slice(0, 3);
  const { data: totalNumbers } = countAsset;
  const { refetch: clubMemberRefetchForDashboard } = ClubMembers;
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setParams({ ...paramsObject, id: "" });
  };
  const assignDockId = (id, name) => {
    setParams({ ...paramsObject, id });
    setAssignName(name);
    if (!isModalOpen) {
      setIsModalOpen(true);
    }
  };
  const addAsset = (type) => {
    localStorage.setItem("addAssetType", type);
    resetToInitialValues();
    localStorage.removeItem("my-images");
    localStorage.removeItem("values");
  };
  const handleModal = (bool, id) => {
    if (bool === true) {
      setParams({ ...paramsObject, id });
    }
    setViewClubMemberModal(bool);
  };

  let tableCardsData = [
    {
      id: 0,
      buttons: [
        ...(isRoleRental
          ? [
              {
                id: 1,
                text: t("addfirstRU"),
                type: "rental-object",
                handleClick: () => {
                  handleNavigate(`/${lang}/assets/all-assets`);
                },
              },
              {
                id: 1,
                text: t("waitforRR"),
                type: "rental-request",
                handleClick: () => {
                  handleNavigate(`/${lang}/rentals/requests?type=pending`);
                },
              },
            ]
          : [
              {
                id: 1,
                type: "club-members",
                text: t("addfirstCM"),
                handleClick: () => {
                  handleNavigate(`/${lang}/club-members`);
                },
              },
              {
                id: 2,
                text: t("addfirstRU"),
                type: "rentals",
                handleClick: () => {
                  handleNavigate(`/${lang}/assets/all-assets`);
                },
              },
              {
                id: 3,
                text: t("addfirstWL"),
                type: "wait-list",
                handleClick: () => {
                  handleNavigate(`/${lang}/wait-list`);
                },
              },
              {
                id: 4,
                text: t("waitforRR"),
                type: "rental-request",
                handleClick: () => {
                  handleNavigate(
                    `/${lang}/rentals/requests?type=pending&page=1`
                  );
                },
              },
              // {
              //   id: 6,
              //   text: t("setupaccountingintegration"),
              //   type: "account-integration",
              //   handleClick: () => {
              //     handleNavigate("/settings");
              //   },
              // },
              {
                id: 7,
                text: t("Addyourfirstseasondockspot"),
                type: "season-dock",
                handleClick: () => {
                  handleNavigate(`/${lang}/seasonaldocks`);
                },
              },
            ]),
      ],
    },
    ...(role === "marina"
      ? [
          {
            id: 1,
            heading: t("docks"),
            option: isRoleRental ? "disabled" : false,
            options: [
              { value: "guestDocks", label: t("guestDocks") },
              { value: "seasonaldocks", label: t("seasonalDocks") },
            ],
            handleFilter: (filter) => {
              setParams({ ...paramsObject, asset: filter });
            },
            defaultValue: assetType,
            type: "seasonalDocks",
            columns: [
              ...(params.get("asset") === "seasonaldocks"
                ? [
                    {
                      title: t("dock") + " #",
                      dataIndex: "name",
                      width: "25%",
                      render: (_, { dock_number }) => {
                        return (
                          <div className="text-[16px] font-roobert-sb">
                            {dock_number}
                          </div>
                        );
                      },
                    },
                  ]
                : [
                    {
                      title: t("dock") + " #",
                      dataIndex: "name",
                      width: "25%",
                      render: (_, { name }) => {
                        return (
                          <div className="text-[16px] font-roobert-sb">
                            {name}
                          </div>
                        );
                      },
                    },
                  ]),

              {
                title: t("renter"),
                dataIndex: "renter",
                width: "25%",
              },
              {
                title: t("status"),
                dataIndex: "status",
                width: "25%",
                render: (_, { status }) => {
                  let color =
                    status === "active"
                      ? "#D9F2DD"
                      : status === "RentedOut"
                      ? "#FFC4B7"
                      : "#FFC4B7";
                  return (
                    <Tag
                      color={color}
                      key={status}
                      className="!rounded-full my-1 leading-[28px] text-center  w-[98px]"
                    >
                      <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                        {status === "active" || status === "confirmed"
                          ? t("active")
                          : status === "RentedOut"
                          ? "Rented Out"
                          : t("ended")}
                      </div>
                    </Tag>
                  );
                },
              },
            ],
            overview:
              localStorage.getItem("lang") === "en"
                ? t("docks") + " " + t("overview")
                : t("overview") + " " + t("dock"),
            darkBtnIcon: <DockBtnIcon />,
            lightBtn1Text: t("addDock"),
            lightBtn2Text: t("ExportInformation"),
            handleNavigateToImport: () => {
              if (!isSeasonalDocks) {
                addAsset("guestDock");
              }
              handleNavigate(
                isSeasonalDocks
                  ? `/${lang}/import/season-dock`
                  : `/${lang}/map?step=1`
              );
            },
            handleNavigateToExport: () =>
              handleNavigate(
                isSeasonalDocks
                  ? `/${lang}/export/season-dock`
                  : `/${lang}/export/guest-docks`
              ),
            viewMore: () =>
              handleNavigate(
                !isSeasonalDocks
                  ? `/${lang}/assets/guest-docks`
                  : `/${lang}/seasonaldocks`
              ),
          },
        ]
      : []),
    {
      id: 2,
      heading: t("rentals"),
      options: [
        { value: "all", label: t("all") + " " + t("rentals") },
        { value: "ongoing", label: t("ongoing") },
        { value: "upcoming", label: t("upcoming") },
        { value: "request", label: t("requests") },
        { value: "archive", label: t("archive") },
      ],
      handleFilter: (filter) => setParams({ ...paramsObject, rental: filter }),
      defaultValue: params.get("rental") || "all",
      type: "rentals",
      columns: [
        {
          title: t("assetName"),
          dataIndex: "property.name",
          width: "25%",
          render: (_) => {
            return <div className="text-[16px] font-roobert-sb">{_}</div>;
          },
        },
        {
          title: t("renter"),
          dataIndex: "user.tenantName",
          width: "25%",
        },
        {
          title: "Status",
          dataIndex: "status",
          width: "25%",
          render: (_, { state }) => {
            let color =
              state === "ongoing"
                ? "#D9F2DD"
                : state === "request"
                ? "#F8F5DA"
                : state === "upcoming"
                ? "#CF9FFF"
                : "#FFC4B7";
            return (
              <Tag
                color={color}
                key={state}
                className="!rounded-full my-1 leading-[28px] text-center  w-[98px]"
              >
                <div className="text-[16px]  h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                  {state === "ongoing"
                    ? t("ongoing")
                    : state === "request"
                    ? t("request")
                    : state === "upcoming"
                    ? t("upcoming")
                    : t("ended")}
                </div>
              </Tag>
            );
          },
        },
      ],
      overview:
        localStorage.getItem("lang") === "en"
          ? t("rentals") + " " + t("overview")
          : t("overview") + " " + t("rentals"),
      darkBtnIcon: <RentalsOverview />,
      lightBtn1Text: t("GotoRentals"),
      lightBtn2Text: t("ExportInformation"),
      handleNavigateToImport: () =>
        handleNavigate(`/${lang}/rentals/all-rentals`),
      handleNavigateToExport: () =>
        handleNavigate(`/${lang}/export/rentals?filter=all`),
      viewMore: () => handleNavigate(`/${lang}/rentals/all-rentals`),
    },
    ...(role === "marina"
      ? [
          {
            id: 3,
            heading: t("Clubmembers"),
            option: "disabled",
            type: "clubmembers",
            columns: [
              {
                title: t("memberNumber") + " #",
                width: "25%",
                render: (_, { customer_id }) => {
                  return (
                    <div className="text-[16px] font-roobert-sb">
                      {customer_id}
                    </div>
                  );
                },
              },
              {
                title: t("name"),
                dataIndex: "customer_name",
                width: "25%",
              },
              {
                title: t("details"),
                dataIndex: "4",
                width: "25%",
                render: (_, { id }) => {
                  return (
                    <ViewDetails
                      handleClick={() => {
                        handleModal(true, id);
                      }}
                    />
                  );
                },
              },
            ],
            overview:
              localStorage.getItem("lang") === "en"
                ? t("Clubmembers") + " " + t("overview")
                : t("overview") + " " + t("clubMember"),
            darkBtnIcon: <MembersOverview />,
            lightBtn1Text: t("addClubmember"),
            lightBtn2Text: t("ExportInformation"),
            handleNavigateToImport: () =>
              handleNavigate(`/${lang}/import/club-members`),
            handleNavigateToExport: () =>
              handleNavigate(`/${lang}/export/club-members`),
            viewMore: () => handleNavigate(`/${lang}/club-members`),
          },
          {
            id: 4,
            heading: t("Waitlist"),
            option: "disabled",
            type: "waitingList",
            columns: [
              {
                title: t("Waitlist") + " #",
                dataIndex: "id",
                width: "25%",
                render: (_, { customer_id }) => {
                  return (
                    <div className="text-[16px] font-roobert-sb">
                      {customer_id}
                    </div>
                  );
                },
              },
              {
                title: t("name"),
                dataIndex: "customer_name",
                width: "25%",
              },
              {
                title: t("CustomerNumber"),
                dataIndex: "customer_contact",
                width: "25%",
              },
              {
                title: t("assignDock"),
                dataIndex: "4",
                // width: '25%',
                render: (_, { id, customer_name }) => {
                  return (
                    <div className="flex items-center justify-center my-1 ">
                      <ButtonDark
                        styleClasses={"white-Btn !text-[12px]"}
                        text={t("assignDock")}
                        handleClick={() => assignDockId(id, customer_name)}
                      />
                    </div>
                  );
                },
              },
            ],
            overview:
              localStorage.getItem("lang") === "en"
                ? t("Waitlist") + " " + t("overview")
                : t("overview") + " " + t("Waitlist"),
            darkBtnIcon: <WaitlistOverview />,
            lightBtn1Text: t("Addtowaitlist"),
            lightBtn2Text: t("ExportInformation"),
            handleNavigateToImport: () =>
              handleNavigate(`/${lang}/import/waiting-list`),
            handleNavigateToExport: () =>
              handleNavigate(`/${lang}/export/waiting-list`),
            viewMore: () => handleNavigate(`/${lang}/wait-list`),
          },
        ]
      : []),
    ...(isRoleRental
      ? [
          {
            id: 1,
            heading: t("rentalObjects"),
            options: [
              { value: "boat", label: t("boats") },
              { value: "kayak", label: t("kayak") },
              { value: "jet_ski", label: t("jetski") },
              { value: "boat_trailer", label: "Boat trailer" },
            ],
            handleFilter: (filter) => {
              setParams({ ...paramsObject, asset: filter });
            },
            defaultValue: assetType,
            type: "rental-objects",
            columns: [
              {
                title: t("assetName"),
                dataIndex: "name",
                width: "25%",
                render: (_, { name }) => {
                  return (
                    <div className="text-[16px] font-roobert-sb">{name}</div>
                  );
                },
              },
              {
                title: t("price"),
                dataIndex: "rate_per_hour",
                width: "25%",
              },
              {
                title: "Status",
                dataIndex: "status",
                width: "25%",
                render: (_, { status }) => {
                  let color =
                    status === "active" ||
                    status === "available" ||
                    status === "confirmed"
                      ? "#D9F2DD"
                      : "#FFC4B7";
                  return (
                    <Tag
                      color={color}
                      className="!rounded-full h-[40px] leading-[28px] text-center  w-[98px]"
                    >
                      <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                        {status === "available"
                          ? t("available")
                          : status === "active" || status === "confirmed"
                          ? t("active")
                          : t("ended")}
                      </div>
                    </Tag>
                  );
                },
              },
            ],
            overview:
              localStorage.getItem("lang") === "en"
                ? t("rentalObjects") + " " + t("overview")
                : t("overview") + " " + t("rentalObjects"),
            darkBtnIcon: "",
            lightBtn1Text:
              assetType === "kayak"
                ? t("addKayak")
                : isJetski
                ? t("addJetski")
                : isBoat
                ? t("addBoat")
                : t("add") + " " + t("BoatTrailer"),
            lightBtn2Text: t("ExportInformation"),
            handleNavigateToImport: () => {
              addAsset(assetType);
              handleNavigate(`/${lang}/map?step=1`);
            },
            handleNavigateToExport: () =>
              handleNavigate(`/${lang}/export/${assetType}`),
            viewMore: () => handleNavigate(`/${lang}/assets/all-assets`),
          },
        ]
      : []),
    {
      id: 5,
      heading: t("finance&revenue"),
      options: [
        { value: "monthly", label: t("monthly") },
        { value: "yearly", label: t("yearly") },
      ],
      defaultValue: "monthly",
      handleFilter: (filter) => {
        setParams({ ...paramsObject, graph: filter });
      },
    },
  ];

  let orderedTableCardsData = [];
  if (organizedKeys) {
    organizedKeys.forEach((key) => {
      const matchingItem = tableCardsData.find(
        (item) => item.id === Number(key)
      );
      if (matchingItem) {
        orderedTableCardsData.push(matchingItem);
      }
    });
  } else {
    tableCardsData = orderedTableCardsData;
  }

  const getTopValues = (type) => {
    if (type === "seasonalDocks") {
      return topSeasonalDocks;
    }
    if (type === "rentals") {
      return topRentalsData?.map((rental) => {
        return resolvedFlattened(rental);
      });
    }
    if (type === "clubmembers") {
      return topClubMembersData;
    }
    if (type === "waitingList") {
      return topWaitingListData;
    }
    if (type === "rental-objects") {
      return topAssets;
    }
  };

  const onDragEnd = (result) => {
    return onDrag(result, orderedTableCardsData, setOrganizedKeys);
  };
  const showCountedData = (type) => {
    const {
      requests,
      season_dock,
      club_members,
      waiting_member,
      kayak,
      jet_ski,
      boat,
      guest_docks,
      boat_trailer,
    } = totalNumbers ?? {
      requests: 0,
      season_dock: 0,
      club_members: 0,
      waiting_member: 0,
      kayak: 0,
      jet_ski: 0,
      boat: 0,
      guest_docks: 0,
      boat_trailer: 0,
    };

    switch (type) {
      case "seasonalDocks":
        return `${isSeasonalDocks ? season_dock : guest_docks} ${t("docks")}`;
      case "rentals":
        return `${requests} ${t("requests")}`;
      case "clubmembers":
        return `${club_members} ${t("members")}`;
      case "waitingList":
        return `${waiting_member} ${t("people")}`;
      case "rental-objects":
        return `${t(
          isJetski
            ? jet_ski + " " + t("jetski")
            : assetType === "kayak"
            ? kayak + " " + t("kayak")
            : isBoat
            ? boat + " " + t("boat")
            : assetType === "boat_trailer"
            ? boat_trailer + " Boat trailer"
            : ""
        )} `;

      default:
        return "";
    }
  };

  const showError = (type) => {
    switch (type) {
      case "seasonalDocks":
        return SeasonalDocks?.error;
      case "rentals":
        return Rentals?.error;
      case "clubmembers":
        return ClubMembers?.error;
      case "waitingList":
        return WaitingList?.error;
      default:
        return false; // or handle the default case as per your requirements
    }
  };
  const showLoading = (type) => {
    switch (type) {
      case "seasonalDocks":
        return SeasonalDocks?.isLoading || SeasonalDocks?.isFetching;
      case "rentals":
        return Rentals?.isLoading || Rentals?.isFetching;
      case "clubmembers":
        return ClubMembers?.isLoading || ClubMembers?.isFetching;
      case "waitingList":
        return WaitingList.isLoading || WaitingList?.isFetching;
      default:
        return false; // or handle the default case as per your requirements
    }
  };

  const getDisabledValue = (type) => {
    if (type === "club-members" && tasks?.club_member) {
      return true;
    }
    if (type === "rentals" && tasks?.rental_object) {
      return true;
    }
    if (type === "wait-list" && tasks?.waiting_list) {
      return true;
    }
    if (type === "rental-request" && tasks?.rental_request) {
      return true;
    }
    if (type === "account-integration" && tasks?.setup_account_integration) {
      return true;
    }
    if (type === "rental-object" && tasks?.rental_object) {
      return true;
    }
    if (type === "season-dock" && tasks?.season_dock) {
      return true;
    }
  };

  return {
    orderedTableCardsData,
    setOrganizedKeys,
    getTopValues,
    financeGraph,
    onDragEnd,
    totalNumbers,
    showCountedData,
    showLoading,
    handleAssignDockModal,
    assignDockModal,
    assignName,
    filterDocksData,
    docksError,
    docksFetching,
    docksRefetch,
    docksLoading,
    refetchWaitingList,
    isModalOpen,
    handleCloseModal,
    t,
    clubMemberRefetchForDashboard,
    tasks,
    getDisabledValue,
    getTotalTasks,
    tasksDone,
    showError,
  };
};

export default useGetDataForDashboard;
