import React, { useEffect } from "react";
import Label from "../CommonComponents/Label";
import FormInput from "../CommonComponents/FormInput";
import { Form } from "antd";
import ButtonDark from "../CommonComponents/ButtonDark";
import useGetDataForSignup from "../../hooks/getData/useGetDataForSignup";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { TABS } from "../../constants/constants";
import PlaceSearchInput, { PlacesAutocomplete } from "./PlaceSearchInput";
import "@reach/combobox/styles.css";

const FirstStep = ({ activeProgress }) => {
  const { initialValues, finishStepOne, activeTab } = useGetDataForSignup();
  const [form] = useForm();
  const isMarinaProgress = activeProgress === "marina";
  const { t } = useTranslation();
  const { marina } = TABS;
  useEffect(() => {
    form.resetFields();
  }, [activeTab, form]);

  const validateNumberNotZero = (_, value) => {
    if (value === "0") {
      return Promise.reject("Number must be greater than 0");
    }
    return Promise.resolve();
  };

  return (
    <Form
      name="basic"
      onFinish={finishStepOne}
      autoComplete="off"
      initialValues={initialValues}
      form={form}
    >
      <div className="grid grid-cols-2 gap-x-3 mt-[20px] ">
        <div className="">
          <Label text={t("organizationName") + "*"} />
          <FormInput
            placeHolder={t("skrivorganizationName")}
            name={"organization_name"}
            type={"text"}
            rules={[
              {
                required: true,
                message: t("enterOrganizationName"),
              },
            ]}
          />
        </div>
        <div className="">
          <Label text={t("organizationNumber") + "*"} />
          <FormInput
            placeHolder={t("skrivorganizationNumber")}
            label=""
            name={"organization_number"}
            type={"text"} // Change the type to "text"
            rules={[
              {
                required: true,
                message: t("enterOrganizationNumber"),
              },
              {
                pattern: /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/, // Regular expression to allow numbers and symbols
                message: t("enterSpecialCharacters"),
              },
            ]}
          />
        </div>
        {isMarinaProgress && (
          <div className="">
            <Label text={t("MarinaName") + "*"} />
            <FormInput
              placeHolder={t("SkrivMarinaName")}
              name={"name"}
              type={"text"}
              rules={[
                {
                  required: true,
                  message: t("enterMarinaName"),
                },
              ]}
            />
          </div>
        )}

        <div className="">
          <Label
            text={`${
              activeTab === marina ? t("marinaAddress") : t("companyAddress")
            }*`}
          />

          <PlacesAutocomplete
            name={"address"}
            placeHolder={t("skrivAddress")}
            rules={[
              {
                required: true,
                message:
                  activeTab === "marina"
                    ? t("enterMarinaAddress")
                    : t("enterCompanyAddress"),
              },
            ]}
            form={form}
          />
        </div>
        {isMarinaProgress && (
          <div className="">
            <Label text={t("Numberofguestdocks") + "*"} />
            <FormInput
              placeHolder={t("enterGuestDock")}
              name={"number_of_guest_dock"}
              type={"number"}
              rules={[
                {
                  required: true,
                  message: t("enterNoOfGuestDocks"),
                },
                { validator: validateNumberNotZero },
              ]}
            />
          </div>
        )}
        {!isMarinaProgress && (
          <div className="">
            <Label text={t("Numberofrentalunits") + "*"} />
            <FormInput
              placeHolder={t("enterRU")}
              name={"number_of_dock_or_rental"}
              type={"number"}
              rules={[
                {
                  required: true,
                  message: t("enterRentalUnits"),
                },
                { validator: validateNumberNotZero },
              ]}
            />
          </div>
        )}
        {isMarinaProgress && (
          <div className="">
            <Label text={t("Numberofpermanentdocks") + "*"} />
            <FormInput
              placeHolder={t("enterPermanentDocks")}
              name={"number_of_dock_or_rental"}
              type={"number"}
              rules={[
                {
                  required: true,
                  message: t("enterNoOfDocks"),
                },
                { validator: validateNumberNotZero },
              ]}
            />
          </div>
        )}
        {!isMarinaProgress && (
          <div className="">
            <Label text={t("Numberofdocks")} />
            <FormInput
              placeHolder={t("enterDocks")}
              name={"number_of_guest_dock"}
              type={"number"}
            />
          </div>
        )}
      </div>
      <div className="text-white mt-[20px]  flex justify-center">
        <div className="text-center">
          <ButtonDark
            styleClasses={
              "bg-dark-blue text-[#FFFFFF] h-[50px] 2xl:h-[60px] w-[150px] px-[50px] py-[10px] !rounded-[50px]"
            }
            text={t("next")}
            type={"submit"}
          />
        </div>
      </div>
    </Form>
  );
};

export default FirstStep;
