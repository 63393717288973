import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showToastMessage = (message, type) => {
  if (type === "success") {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else if (type === "error") {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export default showToastMessage;
