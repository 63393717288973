import React from "react";
import ButtonDark from "../CommonComponents/ButtonDark";

const DiscountOffer = ({ isDiscount, setIsDiscount, t }) => {
  return (
    <div className="text-center font-roobert-rg mt-[20px]">
      <h1 className="text-[22px] 2xl:text-[24px]">{t("discountOfferText1")}</h1>
      <p className="text-[14px] 2xl:text-[16px] mt-[10px]">
        {t("discountOfferText2")}
      </p>
      <div className="flex gap-x-[12px] mt-[20px] justify-center items-center">
        <ButtonDark
          text={t("yes")}
          styleClasses={`${
            isDiscount ? "bg-[#ACDCE7]" : ""
          } white-Btn h-[40px] w-[100px]`}
          handleClick={() => {
            setIsDiscount(true);
          }}
        />
        <ButtonDark
          text={t("no")}
          styleClasses={`${
            !isDiscount ? "bg-[#ACDCE7]" : ""
          } white-Btn h-[40px]  w-[100px]`}
          handleClick={() => {
            setIsDiscount(false);
          }}
        />
      </div>
    </div>
  );
};

export default DiscountOffer;
