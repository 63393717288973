import React, { useEffect } from "react";
import ChatHeader from "./ChatHeader";
import ChatBubble from "./ChatBubble";
import useGetDataForChats from "../../hooks/useGetDataForChats";
import MessageInputBox from "./MessageInputBox";
import { orderBy, query } from "firebase/firestore";
import { getChat, getMessages, getNestedCollection } from "../../API/chats";
import ChatBookingsHeader from "./ChatBookingsHeader";
import Spinner from "../CommonComponents/Spinner";
import ProposedDatesModal from "./ProposedDatesModal";
import Dialog from "../CommonComponents/Modal/Dialog";
import ChatAssetHeader from "./ChatAssetHeader";
const ChatScreen = () => {
  const {
    isLoading,
    loading,
    setLoading,
    isChatExisted,
    notExistedChat,
    messages,
    setMessages,
    chatId,
    chat,
    setChat,
    currentUser,
    sectionRef,
    dateRange,
    setDateRange,
    handleViewRental,
    isBookingUpcomingOrOngoing,
    isOwnerChat,
    openProposedDates,
    handleCloseProposedDatesModal,
    sendProposedDates,
    handleBookNowAndProposedDates,
    userName,
    messageLoading,
    data,
  } = useGetDataForChats();
  useEffect(() => {
    setMessages([]);
    setLoading(true);
    const messagesRef = getNestedCollection(chatId, "chats", "messages");
    const Query = query(messagesRef, orderBy("createdAt"));
    const unsubscribe = getMessages(Query, setMessages, setLoading);
    if (!isChatExisted) {
      setChat(notExistedChat[0]);
    } else {
      getChat(chatId, setChat);
    }
    return () => {
      unsubscribe();
    };
  }, [chatId, notExistedChat, isChatExisted, setMessages, setLoading, setChat]);

  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollTop = sectionRef.current.scrollHeight;
    }
  }, [messages, sectionRef]);

  if (loading || isLoading) {
    return (
      <div className="h-[100vh] w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <Dialog
        isModalOpen={openProposedDates}
        onCancelModal={handleCloseProposedDatesModal}
        background="rgb(215, 238, 243)"
        width={600}
        component={
          <ProposedDatesModal
            handleCloseProposedDatesModal={handleCloseProposedDatesModal}
            dateRange={dateRange}
            setDateRange={setDateRange}
            isOpen={openProposedDates}
            sendProposedDates={sendProposedDates}
            propertyId={chat?.asset_id}
            propertyType={chat?.asset_type}
            messageLoading={messageLoading}
          />
        }
      />
      <div className="h-full font-roobert-sb flex flex-col">
        <ChatHeader
          userImage={
            currentUser?.uid === chat?.tenant_id
              ? chat?.owner_pic
              : chat?.tenant_pic
          }
          userName={userName}
        />
        {isOwnerChat && isBookingUpcomingOrOngoing && (
          <ChatBookingsHeader bookings={data?.bookingArray ?? []} />
        )}
        {!isBookingUpcomingOrOngoing && (
          <ChatAssetHeader
            isOwnerChat={isOwnerChat}
            isBookingUpcomingOrOngoing={isBookingUpcomingOrOngoing}
            assetName={chat?.asset_name ?? "--"}
            handleBookNowAndProposedDates={handleBookNowAndProposedDates}
            handleViewRental={handleViewRental}
          />
        )}
        <section
          ref={sectionRef}
          className="flex-1 flex pt-3 flex-col space-y-4 px-3 md:px-5 xl:px-8 overflow-y-auto"
        >
          {currentUser &&
            messages.map((message, i) => {
              return (
                <ChatBubble
                  key={i}
                  text={message.text}
                  time={message.createdAt.seconds}
                  isMe={message.author.id === currentUser.uid}
                  userImage={
                    message?.author?.id === chat?.owner_id
                      ? chat?.owner_pic
                      : chat?.tenant_pic
                  }
                  metadata={message.metadata}
                  isOwnerChat={isOwnerChat}
                />
              );
            })}
        </section>

        <MessageInputBox
          chatId={chatId}
          senderId={currentUser ? currentUser.uid : null}
          isExisting={isChatExisted}
          chatInfo={notExistedChat[0]}
        />
      </div>
    </>
  );
};

export default ChatScreen;
