import React from "react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const DateRangePicker = ({ visibleDateRange, handleDateChange }) => {
  return (
    <Space direction="vertical" size={12}>
      {/* RangePicker with value and onChange props */}
      <RangePicker
        value={[
          dayjs(visibleDateRange?.endDate),
          dayjs(visibleDateRange?.startDate),
        ]}
        showTime={false}
        format="YYYY/MM/DD"
        onChange={handleDateChange}
        allowClear={false}
      />
    </Space>
  );
};

export default DateRangePicker;
