export const Large = "large";
export const UNDEFINED_ID = "undefined";

const pathname = window.location.pathname;
const structuredPath = pathname.split("/");
export const languageType = structuredPath[1];
export const defaultLanguage = languageType || "no";
export const langOptions = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "no",
    label: "Norsk",
  },
  {
    value: "se",
    label: "Swedish",
  },
  {
    value: "de",
    label: "German",
  },
];
export const mapStyles = {
  height: "90vh",
  width: "100%",
};
export const placesLibrary = ["places"];

export const customMapStyles = `
  .gm-style-cc,
    .gm-bundled-control-maps-data { 
      display: none !important;
    }
  `;
export const disabled = "disabled";
export const pending = "pending";
export const enabled = "enabled";
export const failed = "failed";

export const TABS = {
  marina: "marina",
  rental: "rental",
};
export const URLS = {
  no: "https://www.ship-ohoi.no/vilkar-marina",
  se: "https://www.ship-ohoi.no/se/villkor-marina",
  en: "https://www.ship-ohoi.no/en/terms-marina",
};

export const ASSET_TYPES = {
  "guest-docks": "guest_dock",
  "boat-trailer": "boat_trailer",
  boat: "boat",
  dock: "dock",
  jet_ski: "jet_ski",
  kayak: "kayak",
};
export const ASSET_TYPES_FOR_ADDING_ASSETS = {
  "guest-docks": "guest_dock",
  "boat-trailer": "boat_trailer",
  boat: "boat",
  dock: "dock",
  jet_ski: "jet_ski",
  kayak: "kayak",
  boat_trailer: "boat-trailer",
};

export const declineOptions = [
  {
    id: 1,
    text: "declineReason1",
  },
  {
    id: 2,
    text: "declineReason2",
  },
  {
    id: 3,
    text: "declineReason4",
  },
  {
    id: 4,
    text: "declineReason3",
  },
  {
    id: 5,
    text: "declineReason5",
  },
];
