import React, { useState } from "react";
import { Form, Table } from "antd";
import useGetDataForImports from "../../../hooks/getData/useGetDataForImports";
import ButtonDark from "../ButtonDark";
import { transformedData } from "../../../utils/transformData";

const EditableTable = ({ t }) => {
  const [data, setData] = useState([
    {
      id: 1,
    },
  ]);
  const onDeleteRow = (id) => {
    const newData = data?.filter((row) => row.id !== id);
    setData(newData);
  };

  const { defaultColumns, mutate } = useGetDataForImports({
    onDeleteRow,
  });

  const addRow = () => {
    const count = data.length + 1;
    setData([
      ...data,
      {
        id: count,
      },
    ]);
  };

  const onFinish = (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => value !== "")
    );
    const newArray = transformedData(filteredValues);
    mutate(newArray);
  };

  return (
    <div>
      <ButtonDark
        text={t("addRow")}
        handleClick={addRow}
        styleClasses={"dark-Btn h-[40px] my-[20px]"}
      />
      <Form name="basic" onFinish={onFinish} autoComplete="off">
        <Table
          columns={defaultColumns.map((column, i) => ({
            ...column,
            key: i,
            render: (text, record, index) => (
              <Form.Item
                name={column.dataIndex + record.id}
                rules={column.rules}
                dependencies={column.dependencies}
              >
                {column.render(text, record, index)}
              </Form.Item>
            ),
          }))}
          dataSource={data}
          pagination={false}
          locale={{
            emptyText: t("noData"),
          }}
        />
        <ButtonDark
          text={t("save&proceed")}
          type={"submit"}
          styleClasses={"dark-Btn h-[40px] mt-[30px]"}
        />
      </Form>
    </div>
  );
};

export default EditableTable;
