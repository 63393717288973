import React from "react";

const WaitlistOverview = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
    >
      <g clipPath="url(#clip0_2020_66558)">
        <path
          d="M27.5562 26.8327H28.0562V26.3327V12.2536C28.3023 12.1713 28.5434 12.0752 28.7784 11.9658V26.4963H28.7784L28.7785 26.5048C28.7808 26.6404 28.7564 26.7752 28.7066 26.9014C28.6568 27.0276 28.5827 27.1427 28.4884 27.2402C28.3941 27.3377 28.2815 27.4157 28.1571 27.4697L28.3561 27.9284L28.1571 27.4697C28.0338 27.5232 27.9013 27.5522 27.7669 27.5549H3.23438C3.10004 27.5522 2.96752 27.5232 2.84425 27.4697L2.64518 27.9284L2.84425 27.4697C2.71982 27.4157 2.60724 27.3377 2.51295 27.2402C2.41866 27.1427 2.3445 27.0275 2.29472 26.9014C2.24494 26.7752 2.2205 26.6404 2.22281 26.5048H2.22288V26.4963V7.22463H2.22295L2.22281 7.21613C2.2205 7.0805 2.24494 6.94574 2.29472 6.81956C2.3445 6.69337 2.41866 6.57823 2.51295 6.48071C2.60724 6.38318 2.71982 6.30519 2.84425 6.25118C2.96754 6.19768 3.10007 6.16875 3.23441 6.16602H5.52843V6.88824H3.4451H2.9451V7.38824V26.3327V26.8327H3.4451H27.5562Z"
          fill="white"
          stroke="white"
        />
        <path
          d="M6.89062 12.5566H8.61285V14.2789H6.89062V12.5566Z"
          fill="white"
        />
        <path
          d="M12.0547 12.5566H13.7769V14.2789H12.0547V12.5566Z"
          fill="white"
        />
        <path
          d="M17.2227 12.5566H18.9449V14.2789H17.2227V12.5566Z"
          fill="white"
        />
        <path
          d="M22.3906 12.5566H24.1128V14.2789H22.3906V12.5566Z"
          fill="white"
        />
        <path
          d="M6.89062 16.8613H8.61285V18.5836H6.89062V16.8613Z"
          fill="white"
        />
        <path
          d="M12.0547 16.8613H13.7769V18.5836H12.0547V16.8613Z"
          fill="white"
        />
        <path
          d="M17.2227 16.8613H18.9449V18.5836H17.2227V16.8613Z"
          fill="white"
        />
        <path
          d="M22.3906 16.8613H24.1128V18.5836H22.3906V16.8613Z"
          fill="white"
        />
        <path
          d="M6.89062 21.166H8.61285V22.8882H6.89062V21.166Z"
          fill="white"
        />
        <path
          d="M12.0547 21.166H13.7769V22.8882H12.0547V21.166Z"
          fill="white"
        />
        <path
          d="M17.2227 21.166H18.9449V22.8882H17.2227V21.166Z"
          fill="white"
        />
        <path
          d="M22.3906 21.166H24.1128V22.8882H22.3906V21.166Z"
          fill="white"
        />
        <path
          d="M8.61111 9.11155C8.83949 9.11155 9.05852 9.02082 9.22001 8.85933C9.3815 8.69784 9.47222 8.47882 9.47222 8.25043V3.08377C9.47222 2.85539 9.3815 2.63636 9.22001 2.47487C9.05852 2.31338 8.83949 2.22266 8.61111 2.22266C8.38273 2.22266 8.1637 2.31338 8.00221 2.47487C7.84072 2.63636 7.75 2.85539 7.75 3.08377V8.25043C7.75 8.47882 7.84072 8.69784 8.00221 8.85933C8.1637 9.02082 8.38273 9.11155 8.61111 9.11155Z"
          fill="white"
        />
        <path
          d="M19.3759 5.66602H11.1953V7.38824H19.617C19.4592 6.82768 19.3781 6.24834 19.3759 5.66602Z"
          fill="white"
        />
        <path
          d="M25.8329 9.97244C28.2108 9.97244 30.1385 8.04478 30.1385 5.66688C30.1385 3.28899 28.2108 1.36133 25.8329 1.36133C23.455 1.36133 21.5273 3.28899 21.5273 5.66688C21.5273 8.04478 23.455 9.97244 25.8329 9.97244Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2020_66558">
          <rect
            width="31"
            height="31"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WaitlistOverview;
