import React from "react";
import { useUserDataAndNavigation } from "../hooks/useUserDataAndNavigation";
import GoogleMapContainer from "../components/MapComponent";
import AddAssetsForm from "../components/Assets/AddAssetsForm";
import { useParams } from "react-router-dom";
import AddGuestDocks from "../components/Maps/AddGuestDocks";
import AddDockDetail from "../components/Assets/AddDockDetail";

const AddAsset = () => {
  const { params, t } = useUserDataAndNavigation();
  const step = params.get("step") ? Number(params.get("step")) : 1;
  const { assetType } = useParams();
  const isGuestDock = assetType === "guest_dock";
  const numberOfDocks =
    Number(JSON.parse(localStorage.getItem("values"))?.number_of_guest_dock) ||
    0;
  const docksArray = Array.from(
    { length: Number(numberOfDocks) },
    (_, index) => index
  );
  const activeDockNumber = Number(params.get("activeDock")) || 1;
  const isActive = params.get("active") === "true";
  return (
    <div className=" w-full add-asset relative px-5 h-screen overflow-y-auto">
      {step === 1 && (
        <GoogleMapContainer
          isGuestDock={false}
          notGuestDock={true}
          t={t}
          assetType={assetType}
        />
      )}
      {step === 2 && !isGuestDock && (
        <div className="">
          <AddAssetsForm step={step} assetType={assetType} />
        </div>
      )}
      {step === 2 && isGuestDock && (
        <div className="">
          <AddGuestDocks />
        </div>
      )}
      {step === 3 && isGuestDock && !isActive && (
        <div className="">
          <GoogleMapContainer
            isGuestDock={true}
            notGuestDock={false}
            t={t}
            assetType={assetType}
            activeDockNumber={activeDockNumber}
            dockNumber={numberOfDocks}
          />
        </div>
      )}
      {step === 3 && isGuestDock && !isActive && (
        <div className="rounded-full  max-h-[300px] overflow-y-auto px-[40px] py-[20px] gap-[5px] left-[20px] flex flex-wrap absolute bottom-5 bg-[#FFFFFF]">
          {docksArray.map((index) => (
            <div
              className={`w-full ${
                activeDockNumber === index + 1 ? "bg-[#6DB9D0]" : "bg-[#D7EEF3]"
              } rounded-full h-[40px] !w-[40px] flex justify-center items-center`}
              key={index}
            >
              {index + 1}
            </div>
          ))}
        </div>
      )}

      {step === 3 && isGuestDock && isActive && (
        <div className="">
          <AddDockDetail
            total={numberOfDocks}
            t={t}
            activeDockNumber={activeDockNumber}
          />
        </div>
      )}
    </div>
  );
};

export default AddAsset;
