export const checkUniqueInputs = async (getFieldsValue, setFields) => {
  const allFieldValues = await getFieldsValue();
  const customerIdsObject = {};
  for (const key in allFieldValues) {
    if (allFieldValues.hasOwnProperty(key) && key.startsWith("customer_id")) {
      customerIdsObject[key] = allFieldValues[key];
    }
  }
  const valueToPropertiesMap = {};
  for (const key in customerIdsObject) {
    if (customerIdsObject.hasOwnProperty(key)) {
      const value = customerIdsObject[key];

      if (valueToPropertiesMap[value]) {
        valueToPropertiesMap[value].push(key);
      } else {
        valueToPropertiesMap[value] = [key];
      }
    }
  }
  for (const value in valueToPropertiesMap) {
    const properties = valueToPropertiesMap[value];
    if (properties.length > 1) {
      properties.forEach((fieldKey) => {
        setFields({
          [fieldKey]: {
            value: allFieldValues[fieldKey],
            errors: ["Customer ID must be unique!"],
          },
        });
      });
      return Promise.reject(new Error("Customer ID must be unique!"));
    }
  }
};
