import React from "react";

const SearchButton = ({ handleSearchClick, text }) => {
  return (
    <button
      onClick={handleSearchClick}
      className="border w-[120px] h-[40px] font-roobert-rg   justify-center  text-[22px] text-[#FFFFFF] relative flex items-center pl-[8px] 2xl:pl-[12px] pr-[10px] space-x-[7.5px] 2xl:space-x-[15px] rounded-full border-[#385E62] bg-dark-blue"
    >
      {text}
    </button>
  );
};

export default SearchButton;
