import React from "react";
import PaymentCard from "./PaymentCard";
import Spinner from "../CommonComponents/Spinner";
import { Collapse } from "antd";
import CollapseIcon from "../../assets/icons/CollapseIcon";
import SuccessIcon from "../../assets/icons/SuccessIcon";
import { organizeDataByMonthYear } from "../../utils/organizeDataByMonthOrYear";

const ViewPaymentOrEarnedList = ({
  heading,
  type,
  data,
  isLoading,
  isFetching,
  error,
}) => {
  if (isLoading || isFetching) {
    return <Spinner />;
  }
  if (error) {
    return error;
  }

  const result = organizeDataByMonthYear(data?.withdrawalDetails);
  return (
    <div className="h-[80vh] overflow-y-scroll bg-[#E2F3F7] text-dark-blue rounded-[10px] flex flex-col py-[30px] items-center">
      <h1 className="text-center text-[28px] 2xl:text-[35px] font-roobert-rg">
        {heading}
      </h1>
      {type === "pending" ? (
        <div className="flex items-center mt-[40px] gap-[20px] justify-center  w-[70%] flex-wrap">
          {data?.withdrawalDetails?.map((withDraw) => {
            return (
              <PaymentCard
                key={withDraw?.booking_id}
                currency={data?.currency}
                data={withDraw}
              />
            );
          })}
        </div>
      ) : (
        <div className="w-[70%] mt-[40px]">
          {result?.map((res, i) => {
            return (
              <Collapse
                className="w-full"
                expandIconPosition="end"
                expandIcon={(data) => (
                  <CollapseIcon isActive={data?.isActive} />
                )}
                key={i}
                items={[
                  {
                    key: "1",
                    label: (
                      <h1 className="text-[20px] font-roobert-rg text-dark-blue">
                        {res.month}
                      </h1>
                    ),
                    children: (
                      <div className="flex flex-col gap-y-[10px]">
                        {res?.data?.map((item) => {
                          return (
                            <div className="px-[13px] flex gap-x-[14px] items-center py-[20px] bg-[#FFFFFF] rounded-[12px]">
                              <SuccessIcon />
                              <div className="flex w-full justify-between ">
                                <div className="">
                                  <h1 className="text-[20px] font-roobert-rg text-dark-blue">
                                    Transferred to account
                                  </h1>
                                  <h2 className="text-[14px] font-roobert-rg text-dark-blue">
                                    Transferred: October 9th, 2023, 15:09
                                  </h2>
                                </div>
                                <p className="text-[16px] font-roobert-rg text-dark-blue">
                                  28 NOK
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ),
                  },
                ]}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ViewPaymentOrEarnedList;
