import { useEffect, useState } from "react";
import {
  deleteClubMember,
  editMarinaMember,
  fetchMember,
  fetchMembers,
} from "../../API/marina";
import useQueryHook from "../useQueryHook";
import useMutationHook from "../useMutationHook";
import { Form, message } from "antd";
import { useLocationStore } from "../../store/store";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import useConstants from "./useConstants";
import { getClubMembersColumns } from "../../constants/clubMemberConstants";
import { useLocation } from "react-router-dom";
import useAssignDock from "./useAssignDock";
const GetDataForMarinaMembers = ({ dashboard }) => {
  const [form] = Form.useForm();
  const [viewClubMemberModal, setViewClubMemberModal] = useState(false);
  const { setEdit, setIsModalOpen, isModalOpen } = useLocationStore();

  const { assign, seasonalDockId } = useLocation().state ?? false;
  const { pierColumn, t } = useConstants();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [assignName, setAssignName] = useState("");
  const [assignDockModal, setAssignDockModal] = useState(false);
  const handleAssignDockModal = (bool) => {
    setAssignDockModal(bool);
  };

  const {
    paramsObject,
    params: searchParams,
    setParams: setSearchParams,
    userId,
  } = useUserDataAndNavigation();
  const { page, search } = useConstants();

  const { isLoading, data, error, refetch, isFetching } = useQueryHook(
    ["getting-Members", page, search],
    async () => {
      return fetchMembers(userId, page, search);
    },
    !dashboard
  );

  const filterMembers = data?.members?.map((member) => {
    return { key: member.id, ...member };
  });

  const {
    isLoading: memberLoading,
    data: memberData,
    error: memberError,
    refetch: memberRefetch,
    isFetching: memberFetching,
  } = useQueryHook(
    ["fetch-member"],
    async () => {
      return await fetchMember(userId, searchParams.get("id"));
    },
    viewClubMemberModal
  );

  const handleModal = (bool, id) => {
    if (bool === true) {
      setSearchParams({ ...paramsObject, id });
    }
    setViewClubMemberModal(bool);
  };

  const onSuccess = (Data) => {
    const { message: response } = Data?.data;
    message.success(response);
    refetch();
    memberRefetch();
    setEdit(false);
  };
  const onError = (error) => {
    const { data } = error?.response;
    message.error(data?.message);
  };

  const { mutate: mutateEdit } = useMutationHook(
    ["edit-member"],
    async (Data) => {
      return await editMarinaMember(Data, userId, searchParams.get("id"));
    },
    onSuccess,
    onError
  );
  useEffect(() => {
    if (memberData) {
      const { customer_name, customer_email, customer_contact, customer_id } =
        memberData;
      form.setFieldsValue({
        customer_name,
        customer_email,
        customer_contact,
        customer_id,
      });
    }
  }, [memberData, form]);

  const onFinishEdit = (values) => {
    mutateEdit(values);
  };
  const handleCloseMemberDetails = () => {
    setViewClubMemberModal(false);
    setEdit(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSuccessDelete = ({ message: response }) => {
    if (viewClubMemberModal) {
      handleCloseMemberDetails();
    }
    refetch();
    setSelectedRowKeys([]);
    message.success(response);
  };
  const onErrorDelete = (Error) => {
    message.error(Error);
  };
  const { mutate } = useMutationHook(
    ["delete-club-member"],
    async (Data) => {
      return await deleteClubMember(userId, Data);
    },
    onSuccessDelete,
    onErrorDelete
  );

  const handleDeleteWaitingMember = () => {
    mutate({
      memberIds:
        selectedRowKeys.length >= 1 ? selectedRowKeys : searchParams.get("id"),
    });
  };
  const assignDockId = (id, name) => {
    setSearchParams({ ...paramsObject, id });
    setAssignName(name);
    if (!isModalOpen && !assign) {
      setIsModalOpen(true);
    } else {
      setSearchParams({ ...paramsObject, dockId: seasonalDockId, id });
      handleAssignDockModal(true);
    }
  };
  const columns = getClubMembersColumns(handleModal, pierColumn, assignDockId);
  const { updateDock, isLoading: updateDockLoading } = useAssignDock({
    type: "clubMember",
    refetch,
    setIsModalOpen: setAssignDockModal,
  });

  return {
    columns,
    searchParams,
    setSearchParams,
    isLoading,
    data,
    error,
    refetch,
    isFetching,
    search,
    viewClubMemberModal,
    setViewClubMemberModal,
    handleModal,
    filterMembers,
    memberData,
    memberLoading,
    mutateEdit,
    form,
    setEdit,
    onFinishEdit,
    handleCloseMemberDetails,
    selectedRowKeys,
    setSelectedRowKeys,
    onSelectChange,
    handleDeleteWaitingMember,
    t,
    isModalOpen,
    assignName,
    assignDockModal,
    handleAssignDockModal,
    updateDock,
    updateDockLoading,
    memberError,
    memberFetching,
    setIsModalOpen,
    paramsObject,
    seasonalDockId,
    assign,
  };
};

export default GetDataForMarinaMembers;
