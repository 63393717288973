import React from "react";
import ButtonDark from "../CommonComponents/ButtonDark";
import { useTranslation } from "react-i18next";

const PayoutAlertModal = ({ handleCancelModal, handleProceed }) => {
  const { t } = useTranslation();
  return (
    <div className="p-[20px] bg-[#E2F3F7] rounded-[10px] h-[50vh] flex flex-col justify-center items-center  text-secondary">
      <h1 className="text-center text-[28px] font-roobert">
        {t("PayoutAlert")}
      </h1>
      <p className="text-center mt-2">{t("PayoutAlertDesc")}.</p>
      <div className="flex gap-x-[10px] items-center">
        <ButtonDark
          handleClick={handleProceed}
          styleClasses="bg-[#FFFFFF] h-[40px] mt-5 text-secondary"
          text={t("proceed")}
        />
        <ButtonDark
          handleClick={handleCancelModal}
          styleClasses="bg-[#FFFFFF] h-[40px] mt-5 text-secondary"
          text={t("cancel")}
        />
      </div>
    </div>
  );
};

export default PayoutAlertModal;
