import React from "react";

const AcceptCircle = () => {
  return (
    <svg
      width="29"
      height="25"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.2891"
        cy="14.2761"
        r="13.2422"
        stroke="#134357"
        strokeWidth="2"
      />
      <path
        d="M8.46094 13.6288L13.6399 18.8077L20.1136 10.7156"
        stroke="#134357"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AcceptCircle;
