import SendIcon from "../../assets/icons/SendIcon";
import { sendMessage } from "../../API/chats";
import { useTranslation } from "react-i18next";
import { Form, message as antdMessage } from "antd";
import useUserInfo from "../../hooks/useUserInfo";
import useChatsStore from "../../store/chatStore";
import { useForm } from "antd/es/form/Form";

function MessageInputBox({ chatId, senderId, isExisting, chatInfo }) {
  const { currentUser } = useUserInfo();
  const { setIsChatExisted, setNotExistedChat } = useChatsStore();
  const { t } = useTranslation();
  const [form] = useForm();
  const onSubmit = async ({ message }) => {
    if (message === undefined || message === "" || message?.trim() === "")
      return;
    // Check if the message contains "vipps" or "vipp"
    if (
      message.toLowerCase().includes("vipps") ||
      message.toLowerCase().includes("vipp")
    ) {
      antdMessage.error(t("textRestriction"));
      return;
    }
    return await sendMessage(
      currentUser,
      chatId,
      message,
      senderId,
      isExisting,
      setIsChatExisted,
      chatInfo,
      setNotExistedChat,
      () => {
        form.resetFields();
      }
    );
  };
  return (
    <div className="w-full bg-white py-6 xl:py-9 border-t-[1.5px] border-border mt-2">
      <Form
        onFinish={onSubmit}
        form={form}
        autoComplete="off"
        className="w-full container xl:max-w-5xl 2xl:max-w-6xl px-6 sm:mx-auto flex items-center justify-between gap-6"
      >
        <Form.Item className="w-full" name="message">
          <input
            type="text"
            placeholder={`${t("Writeamessage")}...`}
            name="message"
            className="outline-none w-full  flex-1 bg-transparent text-kprimary placeholder:text-[#A3A3A3] caret-kprimart text-sm md:text-base"
          />
        </Form.Item>
        <button
          type="submit"
          className="active:opacity-70  outline-none disabled:pointer-events-none disabled:opacity-50"
        >
          <SendIcon className="h-5 w-5 md:h-6 md:w-6" />
        </button>
      </Form>
    </div>
  );
}

export default MessageInputBox;
