import React from "react";

const CollapseIcon = ({ isActive }) => {
  return (
    <>
      {isActive ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          className="mt-[20px]"
        >
          <path
            d="M16.0695 9.38984L9.10798 0.794668C8.93023 0.574523 8.67424 0.45692 8.42018 0.448008C8.16651 0.444795 7.90344 0.551016 7.71598 0.761032L0.417066 8.99958C-0.0447895 9.51987 0.556979 10.3046 1.17495 9.98014L8.28963 6.8708L15.2724 10.3275C15.8778 10.6768 16.5116 9.92747 16.0691 9.38413L16.0695 9.38984Z"
            fill="#07344A"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.62533 8.40313L8.58684 16.9983C8.76459 17.2184 9.02058 17.336 9.27465 17.345C9.52831 17.3482 9.79139 17.242 9.97885 17.0319L17.2778 8.79339C17.7396 8.27309 17.1378 7.48834 16.5199 7.81283L9.40519 10.9222L2.42241 7.46544C1.817 7.11618 1.18326 7.8655 1.62573 8.40884L1.62533 8.40313Z"
            fill="#07344A"
          />
        </svg>
      )}
    </>
  );
};

export default CollapseIcon;
