import React from "react";

const ArrowNextCircle = () => {
  return (
    <svg
      width="39"
      height="43"
      viewBox="0 0 39 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4988 6.91207C11.5633 6.91207 5.13035 13.2339 5.13035 21.0322C5.13035 28.8305 11.5633 35.1523 19.4988 35.1523C27.4342 35.1523 33.8672 28.8305 33.8672 21.0322C33.8672 13.2339 27.4342 6.91207 19.4988 6.91207Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.4906 28.9131L25.3024 21.7117C25.5215 21.5337 25.6394 21.2586 25.6563 21.0158C25.6563 20.7569 25.5383 20.498 25.3024 20.32L16.4906 13.1185C15.9346 12.6654 15.1259 13.2804 15.4797 13.9115L18.9168 21.032L15.4797 28.1525C15.1259 28.7675 15.9346 29.3986 16.4906 28.9455L16.4906 28.9131Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowNextCircle;
