import React from "react";
import useQueryHook from "./useQueryHook";
import {
  exportAssets,
  exportMarinaMembers,
  exportRentals,
  exportSeasonDocks,
  exportWaitingList,
} from "../API/marina";
import { useParams, useSearchParams } from "react-router-dom";

const useExportCalls = () => {
  const { data: enabled } = useParams();
  const userId = localStorage.getItem("userId");
  const [params] = useSearchParams();
  const {
    data: rentalsData,
    isLoading,
    isFetching,
    refetch,
  } = useQueryHook(
    ["export-renatls"],
    async () => {
      return await exportRentals(userId, params.get("filter"));
    },
    enabled === "rentals"
  );

  const {
    data: clubMembersData,
    isLoading: clubMembersLoading,
    isFetching: clubMembersFetching,
    refetch: clubMembersRefetch,
  } = useQueryHook(
    ["export-renatls"],
    async () => {
      return await exportMarinaMembers(userId);
    },
    enabled === "club-members"
  );

  const {
    data: seasonalDocksData,
    isLoading: seasonalDocksLoading,
    isFetching: seasonalDocksIsFetching,
    refetch: seasonalDocksRefetch,
  } = useQueryHook(
    ["export-season-docks", params.get("type")],
    async () => {
      return await exportSeasonDocks(userId, params.get("type"));
    },
    enabled === "season-dock"
  );
  const {
    data: waitingListData,
    isLoading: waitingListLoading,
    isFetching: waitingListFetching,
    refetch: waitingListRefetch,
  } = useQueryHook(
    ["export-season-docks", params.get("type")],
    async () => {
      return await exportWaitingList(userId);
    },
    enabled === "waiting-list"
  );

  const {
    data: assetsData,
    isLoading: assetsLoading,
    isFetching: assetsFetching,
    refetch: assetsRefetch,
  } = useQueryHook(
    ["export-assets"],
    async () => {
      return await exportAssets(
        userId,
        enabled === "guest-docks"
          ? "guest_dock"
          : enabled === "boat-trailer"
          ? "boat_trailer"
          : enabled
      );
    },
    enabled === "boat" ||
      enabled === "jet_ski" ||
      enabled === "kayak" ||
      enabled === "guest-docks" ||
      enabled === "boat_trailer" ||
      enabled === "boat-trailer"
  );

  switch (enabled) {
    case "rentals":
      return {
        data: rentalsData,
      };
    case "club-members":
      return {
        data: clubMembersData,
      };
    case "season-dock":
      return {
        data: seasonalDocksData,
      };
    case "waiting-list":
      return {
        data: waitingListData,
      };
    case "boat":
    case "jet_ski":
    case "kayak":
    case "guest-docks":
    case "boat_trailer":
    case "boat-trailer":
      return {
        data: assetsData,
      };
    default:
      break;
  }
};

export default useExportCalls;
