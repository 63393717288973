import React from "react";
import Layout from "../Layout/Layout";
import MapComponent from "../components/MapComponent";
import PageHeaders from "../components/CommonComponents/PageHeaders";
import ChooseCategory from "../components/Maps/ChooseCategory";
import Instructions from "../components/Maps/Instructions";
import AddJetski from "../components/Maps/AddJetski";
import AddGuestDocks from "../components/Maps/AddGuestDocks";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import AddDockDetail from "../components/Assets/AddDockDetail";
import { addAssetStore } from "../store/store";
import GuestDocksAddedSuccessful from "../components/Maps/GuestDocksAddedSuccessful";
import useGetDataForMap from "../hooks/getData/useGetDataForMap";
const Map = () => {
  const { activeDockNumber, active, setActive } = addAssetStore();
  const { number_of_guest_dock } = localStorage.getItem("values")
    ? JSON.parse(localStorage.getItem("values"))
    : {};
  const {
    isLoading,
    error,
    data,
    firstStep,
    secondStep,
    thirdStep,
    fourthStep,
    fifthStep,
    sixthStep,
    seventhStep,
    eighthStep,
    isGuestDock,
    notGuestDock,
    t,
    setParams,
    params,
  } = useGetDataForMap();

  const docksArray = Array.from(
    { length: Number(number_of_guest_dock) },
    (_, index) => index
  );

  const addAssetType = localStorage.getItem("addAssetType");

  // *********************
  // return
  // *********************
  return (
    <Layout page={t("map")}>
      <div className=" pb-[33px] overflow-hidden px-[25px]">
        <PageHeaders heading={t("map")} />
        {fourthStep && isGuestDock && (
          <div className="my-[5px] flex items-center justify-between">
            <h1 className="text-[20px] 2xl:text-[22px] leading-[35px] text-dark-blue font-roobert-rg">
              {t("exactLocation")}
            </h1>
            <ButtonDark
              styleClasses={`white-Btn h-[40px]`}
              text={`${t(
                "Registered"
              )} ${activeDockNumber}/${number_of_guest_dock} ${t("docks")} `}
            />
          </div>
        )}
        <div className=" relative  overflow-hidden  ">
          <div className="h-[90vh] mt-[-50px]">
            <MapComponent
              setActive={setActive}
              isGuestDock={isGuestDock}
              notGuestDock={notGuestDock}
              t={t}
            />
            <div
              style={{
                background: "rgba(117, 117, 117, 0.4)",
              }}
              className={`h-[90vh] w-full ${
                thirdStep && addAssetType !== "guestDock"
                  ? "hidden"
                  : (fourthStep || thirdStep) && isGuestDock && !active
                  ? "hidden"
                  : ""
              } absolute top-[0px] z-2  flex justify-center   items-center`}
            >
              {firstStep && (
                <ChooseCategory t={t} params={params} setParams={setParams} />
              )}
              {thirdStep && isGuestDock && <AddGuestDocks />}
              {seventhStep && isGuestDock && <GuestDocksAddedSuccessful />}
              {eighthStep && isGuestDock && <GuestDocksAddedSuccessful />}
              {fourthStep && isGuestDock && active && (
                <AddDockDetail t={t} total={Number(number_of_guest_dock)} />
              )}
              {secondStep && (
                <Instructions t={t} params={params} setParams={setParams} />
              )}
              {fourthStep && notGuestDock && <AddJetski t={t} />}
              {/* {fifthStep && <ConnectToBank t={t} />} */}
              {/* {sixthStep && (
                <ConnectionSuccessful
                  t={t}
                  params={params}
                  setParams={setParams}
                />
              )} */}
            </div>
          </div>
          {fourthStep && isGuestDock && !active && (
            <div className="rounded-full max-h-[300px] overflow-y-auto px-[20px] py-[12px] gap-[5px] left-[20px] flex flex-wrap absolute bottom-5 bg-[#FFFFFF]">
              {docksArray.map((index) => (
                <div
                  className={`w-full ${
                    activeDockNumber === index + 1
                      ? "bg-[#6DB9D0]"
                      : "bg-[#D7EEF3]"
                  } rounded-full h-[40px] !w-[40px] flex justify-center items-center`}
                  key={index}
                >
                  {index + 1}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Map;
