export const mapAssetType = (assetType) => {
  const type =
    assetType === "Allassets"
      ? ""
      : assetType === "boatdocks"
      ? "dock"
      : assetType === "boats"
      ? "boat"
      : assetType === "kayaks"
      ? "kayak"
      : assetType === "jetskis"
      ? "jet_ski"
      : assetType === "boat-trailer"
      ? "boat_trailer"
      : "";
  return type;
};

export const getAssetTypeName = (assetType, t) => {
  const type =
    assetType === "Allassets"
      ? ""
      : assetType === "guestDock"
      ? t("guestDock")
      : assetType === "boat"
      ? t("boat")
      : assetType === "kayak"
      ? t("singleKayak")
      : assetType === "jet_ski"
      ? t("singleJetski")
      : assetType === "boat_trailer"
      ? t("boatTrailer")
      : "";
  return type;
};
