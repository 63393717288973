import React from "react";

const BankIconLarge = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 214 214"
      fill="none"
    >
      <g clipPath="url(#clip0_2020_74488)">
        <path
          d="M107 214C166.094 214 214 166.094 214 107C214 47.9055 166.094 0 107 0C47.9055 0 0 47.9055 0 107C0 166.094 47.9055 214 107 214Z"
          fill="#D7EEF3"
        />
        <path
          d="M163.33 77.9995H51.25"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M163.33 144.553H51.25"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M169.501 153.555H44.4961"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M51.5703 69.8971V64.5599L107.578 35.8809L163.586 65.0101V69.8971H51.5703Z"
          fill="white"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M62.4375 85.394H75.2981"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M68.8672 87.645V136.451"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M62.4375 136.451H75.2981"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M101.148 85.394H114.009"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M107.578 87.645V136.451"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M101.148 136.451H114.009"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M139.281 86.1016H152.142"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M145.711 88.3521V137.158"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M139.281 137.158H152.142"
          stroke="#134357"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_2020_74488">
          <rect width="214" height="214" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BankIconLarge;
