import i18next from "i18next";
import { dateFormat } from "../utils/dateFormat";
import ButtonDark from "../components/CommonComponents/ButtonDark";
const t = i18next.t;

export const selectOption = [
  {
    key: 1,
    label: t("allCustomer"),
    value: "",
  },
  {
    key: 2,
    label: t("oldest_newest"),
    value: "oldest",
  },
  {
    key: 3,
    label: t("biggest_lowest"),
    value: "biggest",
  },
  {
    key: 4,
    label: t("lowest_biggest"),
    value: "lowest",
  },
];

export const getWaitingListColumns = (assignDockId) => {
  return [
    {
      title: t("boatType"),
      dataIndex: "boat_type",
    },
    {
      title: t("boatWidth"),
      dataIndex: "boat_width",
    },
    {
      title: t("boatLength"),
      dataIndex: "boat_length",
    },
    {
      title: t("name"),
      dataIndex: "customer_name",
    },
    {
      title: t("email"),
      dataIndex: "customer_email",
    },
    {
      title: t("Phonenumber"),
      dataIndex: "customer_contact",
    },
    {
      title: t("reported"),
      dataIndex: "join_date",
      render: (_, { id, join_date }) => {
        return dateFormat(join_date);
      },
    },
    {
      title: t("assignDock"),
      dataIndex: "4",

      render: (_, { id, customer_name }) => {
        return (
          <div className="flex items-center justify-center my-1 ">
            <ButtonDark
              styleClasses={"white-Btn !text-[12px]"}
              text={t("assignDock")}
              handleClick={() => assignDockId(id, customer_name)}
            />
          </div>
        );
      },
    },
  ];
};
