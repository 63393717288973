import React from "react";
import ButtonDark from "../CommonComponents/ButtonDark";

const ChooseCategory = ({ t, params, setParams }) => {
  const handleProceed = () => {
    setParams({ ...params, step: 2 });
  };
  const addAssetType = localStorage.getItem("addAssetType");

  const items = [
    {
      id: 1,
      text: t("guestDock"),
      name: "guestDock",
    },
    {
      id: 2,
      text: t("boat"),
      name: "boat",
    },
    {
      id: 3,
      text: t("singleKayak"),
      name: "kayak",
    },
    {
      id: 4,
      text: t("singleJetski"),
      name: "jet_ski",
    },
    {
      id: 5,
      text: t("BoatTrailer"),
      name: "boat_trailer",
    },
  ];

  return (
    <div className="bg-[#E2F3F7] shadow-custom-lg  z-20 w-[30%] overflow-y-auto py-[40px] rounded-[20px] flex flex-col justify-center items-center">
      <h1 className="text-[28px] text-center 2xl:text-[35px] leading-[50px] font-roobert-rg">
        {t("chooseCategory")}
      </h1>
      <div className="flex flex-col w-[70%]   mt-[50px] gap-y-[10px]">
        {items.map((item) => {
          return (
            <ButtonDark
              text={item.text}
              id={item.id}
              styleClasses={`${
                addAssetType === item.name ? "light-Btn" : "white-Btn"
              } h-[40px]`}
            />
          );
        })}
      </div>
      <ButtonDark
        text={t("proceed")}
        styleClasses={"dark-Btn mt-[30px] h-[50px]"}
        handleClick={handleProceed}
      />
    </div>
  );
};

export default ChooseCategory;
