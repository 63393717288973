import dayjs from "dayjs";

export const convertTimeStamps = (timeStamps, t) => {
  const formattedDate = dayjs.unix(timeStamps);
  if (timeStamps) {
    return `${t(formattedDate.format("ddd"))} ${formattedDate.format("DD")}.${t(
      t(formattedDate.format("MMM"))
    )}`;
  }
};

export function formatTimeAgo(timestamp) {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  const timeDifference = currentTime - timestamp;

  if (timeDifference < 60) {
    return `0 minute ago`;
  } else if (timeDifference < 3600) {
    const minutes = Math.floor(timeDifference / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${String(date.getHours()).padStart(
      2,
      "0"
    )}:${String(date.getMinutes()).padStart(2, "0")}`;
    return formattedDate;
  }
}

export const getMonths = (month, type) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (type === "short") {
    return shortMonths[month];
  } else {
    return months[month];
  }
};

export const convertToTimeStamps = (time) => {
  const dateObject = new Date(time);
  const utcTimestamp = dateObject.getTime();
  const utcTimestampInSeconds = Math.floor(utcTimestamp / 1000);
  return utcTimestampInSeconds;
};
