import dayjs from "dayjs";

export const getDisabledDates = (bookings, bookingId) => {
  if (bookings?.length <= 0) return [];

  const disabledDates = [];

  for (let index = 0; index < bookings?.length; index++) {
    if (bookings[index].id !== bookingId) {
      const startDate = dayjs(
        new Date(Number(bookings[index]?.booked_from * 1000))
      );
      const endDate = dayjs(
        new Date(Number(bookings[index].booked_till * 1000))
      );
      let currentDate = startDate;
      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
      ) {
        disabledDates.push(currentDate.valueOf());
        currentDate = currentDate.add(1, "day");
      }
    }
  }

  return disabledDates;
};
