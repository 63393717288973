import axios from "axios";
import { useLocationStore } from "../store/store";
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`, // Replace with your actual base URL
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth");
    const lang = useLocationStore.getState().lang;

    if (token) {
      config.headers["Authorization"] = token;
      config.headers["platform"] = "marina-web-user";
      config.headers["app_language"] = lang;
    } else {
      delete config.headers["Authorization"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
