import { message } from "antd";
import useMutationHook from "../useMutationHook";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { assignDock } from "../../API/marina";

const useAssignDock = ({ type, refetch, setIsModalOpen }) => {
  const {
    paramsObject,
    setParams: setSearchParams,
    params: searchParams,
    userId,
  } = useUserDataAndNavigation();
  const onSuccess = ({ data }) => {
    const { message: successMessage } = data;
    message.success(successMessage);
    setSearchParams({ ...paramsObject, id: "" });
    refetch();
    setIsModalOpen(false);
  };

  const onError = (error) => {
    const { data } = error?.response;
    message.error(data?.message);
  };
  const { mutate: updateDock, isLoading } = useMutationHook(
    ["assign-dock"],
    async (Data) => {
      return assignDock(userId, searchParams.get("id"), Data, type);
    },
    onSuccess,
    onError
  );
  return {
    updateDock,
    isLoading,
  };
};

export default useAssignDock;
