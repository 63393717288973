import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locale/en.json";
import norTranslation from "./locale/nor.json";
import seTranslation from "./locale/se.json";
import deTranslation from "./locale/Ger.json";
import { languageType } from "../constants/constants";

i18n.use(initReactI18next).init({
  fallbackLng: languageType ? languageType : "no",
  // lng: "",
  resources: {
    en: {
      translations: enTranslation,
    },
    no: {
      translations: norTranslation,
    },
    se: {
      translations: seTranslation,
    },
    de: {
      translations: deTranslation,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

i18n.languages = ["en", "no", "se", "de"];

export default i18n;
