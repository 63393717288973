import React from "react";

const ArrowBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
    >
      <path
        d="M9.10678 0.264234L0.764933 7.52738C0.551256 7.71286 0.442843 7.97287 0.442983 8.22709C0.448804 8.48071 0.564325 8.73984 0.780883 8.9197L9.2741 15.9206C9.81051 16.3637 10.5733 15.7343 10.2271 15.1283L6.86635 8.12886L10.0723 1.02743C10.3997 0.409963 9.62832 -0.196696 9.10109 0.264834L9.10678 0.264234Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default ArrowBack;
