import { useMutation } from "react-query";

const useMutationHook = (key, mutationFtn, onSuccess, onError) => {
  const mutation = useMutation(key, mutationFtn, {
    onSuccess,
    onError,
  });
  return { mutate: mutation.mutate, isLoading: mutation.isLoading };
};

export default useMutationHook;
