import React from "react";
import { dateFormat } from "../../utils/dateFormat";

const PaymentCard = ({ currency, data }) => {
  return (
    <div className="rounded-[20px] px-[20px] pt-[10px] pb-[10px] w-full bg-[#FFFFFF] ">
      <div className="text-[24px] flex justify-between  gap-x-[40px] items-center font-roobert-sb">
        <h1 className="">Payment</h1>
        <h1 className="">
          {data?.client_profit} {currency}
        </h1>
      </div>
      <p className="text-[18px] mt-[10px] font-roobert-rg">
        Payment: {dateFormat(data?.withdrawal_date)}
      </p>
      <p className="text-[18px] mt-[10px] font-roobert-rg">
        Rental ended: {dateFormat(data?.booked_till)}
      </p>
    </div>
  );
};

export default PaymentCard;
