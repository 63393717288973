import React from "react";
import Sidebar from "../components/CommonComponents/Sidebar";
import Navbar from "../components/CommonComponents/Navbar";

const Layout = ({ sidebar, children, backToDashboard, page }) => {
  return (
    <>
      <div className=" flex ">
        {!sidebar && (
          <div className="w-[240px] 2xl:w-[270px]">
            <Sidebar />
          </div>
        )}
        <div className="w-full">
          <Navbar backToDashboard={backToDashboard ?? true} page={page} />
          {children}
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {};

export default Layout;
