import React from "react";

const ClubMemberIcon = ({ location, path }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
    >
      <path
        d="M0.5 10.7368H13.5V12H0.5V10.7368ZM0.5 1.89474L3.75 4.10526L7 0L10.25 4.10526L13.5 1.89474V9.47368H0.5V1.89474ZM1.8 4.32063V8.21053H12.2V4.32063L9.977 5.83263L7 2.07158L4.023 5.83263L1.8 4.32063Z"
        fill={path === location ? "#134357" : "#FFFFFF"}
      />
    </svg>
  );
};

export default ClubMemberIcon;
