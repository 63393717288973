import React, { useState } from "react";
import ArrowDownDark from "../../assets/icons/ArrowDownDark";
import { useNavigate, useParams } from "react-router-dom";
import { useLocationStore } from "../../store/store";
import { useSearchParams } from "react-router-dom";

const MenuBox = ({ form, t }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setIsModalOpen, setEdit, setAddRenter } = useLocationStore();
  const [params] = useSearchParams();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();
  const { lang } = useParams();
  return (
    <div className="w-[300px] h-full rounded-3xl">
      <div
        className="bg-[#ACDCE7] cursor-pointer flex items-center justify-center rounded-t-3xl text-[18px] font-roobert-rg leading-[31px] h-[50px] gap-x-[15px]"
        onClick={toggleMenu}
      >
        {t("chooseNewRenter")} <ArrowDownDark />
      </div>
      {isOpen && (
        <ul className="bg-white w-full rounded-b-3xl">
          <li
            onClick={() => {
              setIsModalOpen(false);
              navigate(`/${lang}/seasonaldocks/wait-list`, {
                state: {
                  assign: true,
                  seasonalDockId: params.get("id"),
                },
              });
            }}
            className="h-[40px] cursor-pointer flex items-center justify-center text-[18px] font-roobert-l leading-[31px]"
          >
            {t("fromWaitlist")}
          </li>
          <li className="border-b cursor-pointer border-[#F0F0F0D6]"></li>
          <li
            onClick={() => {
              setIsModalOpen(false);
              navigate(`/${lang}/seasonaldocks/club-member`, {
                state: {
                  assign: true,
                  seasonalDockId: params.get("id"),
                },
              });
            }}
            className="h-[40px] cursor-pointer flex items-center justify-center text-[18px] font-roobert-l leading-[31px]"
          >
            From Club Members
          </li>
          <li className="border-b cursor-pointer border-[#F0F0F0D6]"></li>
          <li
            onClick={() => {
              setAddRenter(true);
              setEdit(true);
              form.resetFields();
            }}
            className="h-[40px] cursor-pointer flex items-center justify-center text-[18px] font-roobert-l leading-[31px]"
          >
            {t("manually")}
          </li>
        </ul>
      )}
    </div>
  );
};

export default MenuBox;
