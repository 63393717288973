import i18next from "../languages/config";
import { useNavigate } from "react-router-dom";
import { useLocationStore } from "../store/store";

const useChangeLanguage = () => {
  const navigate = useNavigate();
  const { setLanguage } = useLocationStore();
  const changeLanguage = (value) => {
    i18next.changeLanguage(value);
    const path = window.location.href;
    const sortedPath = path.split("/");
    sortedPath.splice(0, 3);
    sortedPath[0] = value;
    const newPath = sortedPath.join("/");
    setLanguage(value);
    navigate("/" + newPath, { replace: true });
  };
  return {
    changeLanguage,
  };
};

export default useChangeLanguage;
