import { Select } from "antd";
import React from "react";
import ArrowDownDark from "../../assets/icons/ArrowDownDark";
import { useParams } from "react-router-dom";

const SelectDropdown = ({
  options,
  defaultValue,
  handleChange,
  stylesClasses,
  arrowLight,
}) => {
  const { lang } = useParams();
  return (
    <Select
      defaultValue={defaultValue}
      key={lang}
      className={`!rounded-[30px] !px-[10px]   !flex ${stylesClasses}  !text-[16px] !border !border-dark-blue   font-roobert-sb h-[40px]`}
      onChange={(values) => handleChange(values)}
      bordered={false}
      suffixIcon={<ArrowDownDark arrowLight={arrowLight} />}
      options={options}
    />
  );
};

export default SelectDropdown;
