import { Form } from "antd";
import React from "react";
import FormInput from "../CommonComponents/FormInput";
import Label from "../CommonComponents/Label";
import useGetDataForMarinaMembers from "../../hooks/getData/useGetDataForMarinaMembers";
import Spinner from "../CommonComponents/Spinner";

const EditMember = ({ mutateEdit, form, t }) => {
  const { memberLoading, memberFetching, onFinishEdit } =
    useGetDataForMarinaMembers({ dashboard: true });

  if (memberLoading && memberFetching) {
    return <Spinner />;
  }

  return (
    <Form
      name="basic"
      form={form}
      onFinish={onFinishEdit}
      autoComplete="off"
      className="px-[60px] mt-[10px] h-[50vh]"
    >
      <h1 className="2xl:text-[35px] font-roobert-rg  text-[28px] leading-[50px]">
        {t("customerInfo")}
      </h1>
      <div className="grid grid-cols-2 gap-x-[100px]">
        <div className="">
          <Label text={t("memberNumber")} />
          <FormInput
            name={"customer_id"}
            type={"text"}
            styleClasses={"rounded-full"}
            rules={[
              {
                required: true,
                message: t("inputYourMemberNumber"),
              },
            ]}
          />
        </div>
        <div className="">
          <Label text={t("name")} />
          <FormInput
            name={"customer_name"}
            type={"text"}
            styleClasses={"rounded-full"}
            rules={[
              {
                required: true,
                message: t("inputYourName"),
              },
            ]}
          />
        </div>
        <div className="">
          <Label text={t("Phonenumber")} />
          <FormInput
            name={"customer_contact"}
            type={"number"}
            styleClasses={"rounded-full"}
            rules={[
              {
                required: true,
                message: t("inputYourNumber"),
              },
            ]}
          />
        </div>
        <div className="">
          <Label text={t("email")} />
          <FormInput
            name={"customer_email"}
            type="email"
            styleClasses={"rounded-full"}
            rules={[
              {
                required: true,
                message: t("inputYourEmail"),
              },
              {
                type: "email",
                message: t("enterValidEmail"),
              },
            ]}
          />
        </div>
      </div>
    </Form>
  );
};

export default EditMember;
