import React from "react";

const Eye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M13.5 8C10.5455 8 8.00455 10.0667 7 13C8.00455 15.9333 10.5455 18 13.5 18H14.15C14.0909 17.8 14.0909 17.6 14.0909 17.3333C14.0909 17.0667 14.0909 16.8667 14.15 16.6C13.9136 16.6 13.7364 16.6667 13.5 16.6667C11.2545 16.6667 9.24545 15.2667 8.3 13C9.24545 10.7333 11.2545 9.33333 13.5 9.33333C15.7455 9.33333 17.7545 10.7333 18.7 13C18.6409 13.1333 18.5227 13.2667 18.4636 13.4667C18.8773 13.6 19.2318 13.7333 19.5864 14C19.7636 13.6667 19.8818 13.3333 20 13C18.9955 10.0667 16.4545 8 13.5 8ZM13.5 11C12.4955 11 11.7273 11.8667 11.7273 13C11.7273 14.1333 12.4955 15 13.5 15C14.5045 15 15.2727 14.1333 15.2727 13C15.2727 11.8667 14.5045 11 13.5 11ZM17.6364 19V17.6667H15.2727V16.3333H17.6364V15L19.4091 17L17.6364 19Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default Eye;
