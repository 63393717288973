import React from "react";
import EditableTable from "../Table/EditableTable";
const CopyAndPaste = ({ t }) => {
  return (
    <div className="h-full font-roobert-rg flex flex-col items-center px-[40px]  mt-[70px]">
      <div className="">
        <h1 className="2xl:text-[35px]  text-left text-[28px] ">
          {t("copy&paste")} {t("contact")}
        </h1>
        <div className="import-table">
          <EditableTable t={t} />
        </div>
      </div>
    </div>
  );
};

export default CopyAndPaste;
