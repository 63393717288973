import React from "react";
import Drag from "../../assets/icons/Drag";
import AntdTable from "../CommonComponents/Table/Table";
import ViewMore from "../CommonComponents/ViewMore";
import IconBtn from "../../assets/icons/IconBtn";
import LightBtn from "../CommonComponents/LightBtn";
import { Draggable } from "react-beautiful-dnd";
import DashboardCardHeader from "./DashboardCardHeader";

const TableCard = ({
  item,
  index,
  data,
  showCountedData,
  showLoading,
  showError,
}) => {
  const { id } = item;

  return (
    <Draggable draggableId={id.toString()} key={index} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="mt-[30px] dashboard min-h-[350px]  h-auto  bg-light-blue flex rounded-[12px] shadow-custom"
        >
          <div
            {...provided.dragHandleProps}
            className="h-auto w-[21px] shadow-drag-btn rounded-l-[12px] flex justify-center items-center bg-transparent"
          >
            <Drag />
          </div>
          <div className="flex w-full">
            <div className="bg-[#FFFFFF] shadow-custom-lg w-[70%] 2xl:w-[65%]  pr-[25px]  pt-[20px]">
              <DashboardCardHeader item={item} />
              <div className="2xl:mt-[20px]  2xl:mb-[25px] mb-[10px]">
                <AntdTable
                  columns={item?.columns}
                  data={data}
                  loading={showLoading(item.type)}
                  error={showError(item.type)}
                />
                <ViewMore handleClick={item.viewMore} />
              </div>
            </div>
            <div className="2xl:w-[30%] w-[40%] px-[25px] 2xl:px-[42px] pt-[20px]">
              <h1 className="2xl:text-[35px] text-[28px] xl:whitespace-nowrap  text-dark-blue text-start font-roobert-rg leading-[50px]">
                {item?.overview}
              </h1>

              <div className="w-full mt-[15px] 2xl:mt-[35px] flex flex-col gap-y-[20px]">
                <IconBtn
                  text={showCountedData(item.type)}
                  icon={item?.darkBtnIcon}
                />
                <LightBtn
                  text={item?.lightBtn1Text}
                  handleClick={item.handleNavigateToImport}
                />
                <LightBtn
                  text={item?.lightBtn2Text}
                  handleClick={item.handleNavigateToExport}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TableCard;
