export const handleSearchChange = (
  e,
  setSearch,
  searchParams,
  setSearchParams
) => {
  setSearch(e.target.value);
  if (e.target.value === "") {
    const paramsObject = Object.fromEntries(searchParams);
    setSearchParams({ ...paramsObject, page: 1, search: "" });
  }
};

export const handleSearchClick = (search, setSearchParams, searchParams) => {
  if (search !== "") {
    const paramsObject = Object.fromEntries(searchParams);
    setSearchParams({ ...paramsObject, page: 1, search });
  }
};
