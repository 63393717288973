import React from "react";
import SettingHeader from "./SettingHeader";
import { Form } from "antd";
import FormInput from "../CommonComponents/FormInput";

const CustomSettingCard = ({
  heading,
  subHeading,
  priceTerm,
  editAccountDetails,
  rules,
  name,
  t,
}) => {
  const onFinish = (values) => {
    editAccountDetails({
      type: "pinCode",
      pinCode: values?.pin_code,
    });
  };

  return (
    <div className="bg-[#E2F3F7] rounded-[20px] pb-[20px] 2xl:pb-[46px]">
      <Form name="basic" onFinish={onFinish} autoComplete="off">
        <SettingHeader t={t} heading={heading} />
        <div className="pl-[40px] pr-[20px]">
          <h1 className=" text-[20px] font-roobert-rg 2xl:text-[22px] leading-[35px]">
            {subHeading}
          </h1>

          <div className="mt-[10px] relative">
            <FormInput
              name={name}
              rules={rules}
              styleClasses={"rounded-full w-[50%]"}
            />
            <span className="absolute left-[55%] top-[10px]">{priceTerm}</span>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CustomSettingCard;
