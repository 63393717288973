import { Button } from "antd";
import React from "react";

const IconBtn = ({ text, icon, styleClasses, handleClick }) => {
  return (
    <Button
      onClick={handleClick}
      className={`bg-[#DEDEDE] ${styleClasses}   px-[21px] font-normal text-dark-blue 3xl:text-[22px] hover:!text-dark-blue text-[20px] rounded-full !h-auto font-roobert-rg flex items-center outline-none shadow-none gap-x-[7px]`}
    >
      {icon} {text}
    </Button>
  );
};

export default IconBtn;
