import React from "react";

const MapPlus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
    >
      <path
        d="M45.3885 30.9024H32.9824V43.3084C32.9824 44.673 31.8659 45.7896 30.5012 45.7896C29.1366 45.7896 28.02 44.673 28.02 43.3084V30.9024H15.614C14.2494 30.9024 13.1328 29.7858 13.1328 28.4212C13.1328 27.0565 14.2494 25.94 15.614 25.94H28.02V13.5339C28.02 12.1693 29.1366 11.0527 30.5012 11.0527C31.8659 11.0527 32.9824 12.1693 32.9824 13.5339V25.94H45.3885C46.7531 25.94 47.8697 27.0565 47.8697 28.4212C47.8697 29.7858 46.7531 30.9024 45.3885 30.9024Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default MapPlus;
