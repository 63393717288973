import useMutationHook from "../useMutationHook";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { addImagesToFirebase } from "../../utils/addImages";
import { message } from "antd";
import { useState } from "react";
import axiosInstance from "../../API/axiosConfig";
import { searchPlaceName } from "../../utils/searchPlaceName";
import { ASSET_TYPES_FOR_ADDING_ASSETS } from "../../constants/constants";
import { useParams } from "react-router-dom";
const useGetDataForAddingAssets = () => {
  const { userId, parse, stringify, lang, params, navigate, t, role } =
    useUserDataAndNavigation();
  const [selectedImages, setSelectedImages] = useState([]);
  const [removeImage, setRemoveImage] = useState(false);

  const [isDiscount, setIsDiscount] = useState(false);
  const [isAutoApproveRequest, setIsAutoApproveRequest] = useState(false);

  const [thumbnailImages, setThumbnailImages] = useState([]);

  const [formData, setFormData] = useState({
    occupancy: "",
    horse_power: "",
    size_in_feet: "",
    rate_per_day: "",
    rate_per_week: "",
    rate_per_month: "",
    rate_per_season: "",
    rate_per_hour: "",
    description: "",
    name: "",
    weight_in_kg: "",
  });

  const [imagesUploadError, setImagesUploadError] = useState(null);
  const [screen, setScreen] = useState(1);
  const { assetType } = useParams();
  const [loading, setLoading] = useState(false);
  const onSuccess = (response) => {
    const { data } = response.data;
    localStorage.removeItem("my-images");
    localStorage.removeItem("thumbnail-images");
    if (data?.is_exceeded) {
      navigate("/subscription", {
        state: {
          number_of_dock_or_rental: data?.number_of_dock_or_rental,
          number_of_guest_dock: data?.number_of_guest_dock,
          company_type: role,
          type: "asset",
          returnUrl: "/map?step=4",
        },
      });
    } else {
      navigate(`/${lang}/assets/${ASSET_TYPES_FOR_ADDING_ASSETS[assetType]}`);
      message.success(
        `${ASSET_TYPES_FOR_ADDING_ASSETS[assetType]} added successfully!`
      );
    }
    setLoading(false);
  };
  const onError = (error) => {
    const { data } = error.response;
    setLoading(false);
    message.error(data.message);
  };
  const { mutate } = useMutationHook(
    ["add-asset"],
    async (Data) => {
      return await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/${userId}/${assetType}`,
        Data
      );
    },
    onSuccess,
    onError
  );

  const onFinish = async (values, isDiscount) => {
    if (selectedImages === null || selectedImages.length < 1) {
      return setImagesUploadError(t("atLeastOneImage"));
    }
    if (screen === 1) {
      setFormData((prevData) => ({
        ...prevData,
        ...values,
      }));
      setScreen(2);
    } else {
      setLoading(true);

      try {
        const imagesUrls = await addImagesToFirebase(
          selectedImages.map((img) => img.file)
        );
        const thumbnailImagesUrl = await addImagesToFirebase(
          thumbnailImages.map((img) => img.file)
        );
        const latLng = JSON.parse(localStorage.getItem("latlng"));
        const {
          occupancy,
          horse_power,
          size_in_feet,
          description,
          name,
          weight_in_kg,
        } = formData;
        const {
          rate_per_day,
          rate_per_week,
          rate_per_month,
          rate_per_season,
          rate_per_hour,
        } = values;
        const numericValues = {
          occupancy: Number(occupancy),
          horse_power: Number(horse_power),
          size_in_feet: Number(size_in_feet),
          rate_per_day: Number(rate_per_day),
          rate_per_month: Number(rate_per_month > 0 ? rate_per_month : 0),
          rate_per_week: Number(rate_per_week > 0 ? rate_per_week : 0),
          rate_per_hour: Number(rate_per_hour),
          rate_per_season: Number(rate_per_season > 0 ? rate_per_season : 0),
        };
        const place_name = await searchPlaceName(latLng.lat, latLng.lng);
        const updatedValues = {
          ...numericValues,
          type: assetType,
          images: imagesUrls,
          thumbnail_images: thumbnailImagesUrl,
          lat: latLng.lat,
          lng: latLng.lng,
          key_details: "",
          is_discount: isDiscount,
          description,
          name,
          weight_in_kg,
          place_name,
        };
        console.log(updatedValues);
        mutate(updatedValues);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return {
    mutate,
    onFinish,
    loading,
    imagesUploadError,
    setImagesUploadError,
    screen,
    setScreen,
    navigate,
    selectedImages,
    setSelectedImages,
    removeImage,
    setRemoveImage,
    isDiscount,
    setIsDiscount,
    thumbnailImages,
    setThumbnailImages,
    isAutoApproveRequest,
    setIsAutoApproveRequest,
  };
};

export default useGetDataForAddingAssets;
