import React from "react";
import Layout from "../Layout/Layout";
import Graph from "../components/Dashboard/Graph";
import BarChartComponent from "../components/CommonComponents/BarChartComponent";
import PageHeaders from "../components/CommonComponents/PageHeaders";
import SelectDropdown from "../components/CommonComponents/Select";
import AverageCard from "../components/Dashboard/AverageCard";
import PieChartGraph from "../components/CommonComponents/PieChartGraph";
import useGetDataForReports from "../hooks/getData/useGetDataForReports";
import { organizeDataByYears } from "../utils/fixYearOrder";
const Reports = () => {
  const {
    handleRevenueChange,
    searchParams,
    financeGraph,
    rentalsGraph,
    t,
    revenueType,
    rentalType,
    percentageData,
    role,
  } = useGetDataForReports();
  // *********************
  // return
  // *********************
  return (
    <Layout page={t("reports")}>
      <div className="pl-[25px] pr-[50px] pb-[33px]">
        <PageHeaders heading={t("reports")} exportData={false} />
      </div>
      <div className="pl-[25px] 2xl:mt-[42px] mt-[20px] pr-[39px]">
        <div className="rounded-[30px] pl-[49px] pt-[25px]  bg-[#FFFFFF]">
          <div className="flex mb-[30px] justify-between items-center pr-[49px]">
            <h1 className="2xl:text-[35px] text-[28px] leading-[50px] font-roobert-rg">
              {t("finance&revenue")}
            </h1>
            <SelectDropdown
              options={[
                { label: t("monthly"), value: "monthly" },
                { label: t("yearly"), value: "yearly" },
              ]}
              handleChange={handleRevenueChange}
              defaultValue={searchParams.get("revenue") || "monthly"}
            />
          </div>
          <AverageCard
            average={financeGraph?.data?.total_average}
            currency={financeGraph?.data?.currency}
          />
          <div className="h-[400px] 3xl:h-[500px] max-2xl:h-[300px] mt-[10px]">
            <Graph
              lineColor="#046997"
              data={financeGraph?.data?.graphData}
              lineDotsColor="#CF8900"
              left={0}
              type={revenueType}
            />
          </div>
        </div>
        <div
          className={`grid  ${
            role === "marina" ? "grid-cols-2" : ""
          } mt-[20px] gap-x-[23px]`}
        >
          <div className="rounded-[30px]  my-[33px]  pl-[49px] bg-[#FFFFFF] pt-[30px]">
            <h1 className="2xl:text-[35px] text-[28px] leading-[50px] font-roobert-rg">
              {t("rentalsreport")}
            </h1>
            <div className="h-[400px] 3xl:h-[500px] max-2xl:h-[300px] mt-[10px]">
              <BarChartComponent
                data={organizeDataByYears(rentalsGraph?.data?.graphData)}
                type={rentalType}
              />
            </div>
          </div>
          {role === "marina" && (
            <div className="rounded-[30px]   my-[33px]  bg-[#FFFFFF] pt-[30px]">
              <h1 className="2xl:text-[35px] pl-[49px] text-[28px] leading-[50px] font-roobert-rg">
                {t("docks")}
              </h1>
              <div className=" flex justify-center mt-[-30px]  overflow-y-hidden">
                <PieChartGraph data={percentageData ?? []} />
              </div>
              <div className="pb-[30px] w-full flex flex-col gap-y-[20px] justify-center items-center">
                <div className="flex w-[300px]  items-center gap-x-[5px]">
                  <div className="h-[15px] w-[15px] rounded-full bg-[#7BB9D3]"></div>
                  <div className="flex justify-between w-full">
                    <p className="text-[16px] font-roobert-rg text-dark-blue">
                      {t("seasonalDocks")}
                    </p>
                    <p className="text-[16px] font-roobert-rg text-dark-blue">
                      {percentageData?.seasonDocks ?? 0}%
                    </p>
                  </div>
                </div>
                <div className="flex w-[300px]  items-center gap-x-[5px]">
                  <div className="h-[15px] w-[15px] rounded-full bg-[#D7EEF3]"></div>
                  <div className="flex justify-between w-full">
                    <p className="text-[16px] font-roobert-rg text-dark-blue">
                      {t("guestDocks")}
                    </p>
                    <p className="text-[16px] font-roobert-rg text-dark-blue">
                      {percentageData?.guestDocks ?? 0}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Reports;
