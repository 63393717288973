import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/icons/BackIcon";
import ProfileAvatar from "../../assets/images/ProfileAvatar.jpg";

function ChatHeader({ userImage, userName }) {
  const navigate = useNavigate();

  return (
    <header className="flex items-center gap-x-[10px]">
      <button
        onClick={() => navigate(-1)}
        className="md:hidden  active:opacity-75"
      >
        <BackIcon className="h-9 w-9" />
      </button>
      <div className="bg-white w-full py-2 px-3 md:px-5 xl:px-8 text-sm flex items-center gap-3 text-kprimary">
        <Avatar src={userImage ? userImage : ProfileAvatar} />
        <h4>{userName}</h4>
      </div>
    </header>
  );
}

export default ChatHeader;
