import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { storage } from "../config/firebaseConfig";
import showToastMessage from "./message";

export const handleImageUpload = (event, selectedImages, setSelectedImages) => {
  const files = event.target.files;
  if (selectedImages.length < 4) {
    const imageArray = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setSelectedImages([...selectedImages, ...imageArray]);
    event.target.value = null;
  } else {
    showToastMessage("You can only upload up to 4 images.", "error");
  }
};

export const handleImageRemove = (index, selectedImages, setSelectedImages) => {
  const updatedImages = [...selectedImages];
  updatedImages.splice(index, 1);
  setSelectedImages(updatedImages);
};

export const addImagesToFirebase = async (selectedImages) => {
  let Arr = [];
  try {
    for (let i = 0; i < selectedImages.length; i++) {
      const res = await fetch(selectedImages[i]);
      const blob = await res.blob();
      const imageRef = ref(storage, `annotation_pictures/${Date.now()}${i}`);
      await uploadBytes(imageRef, blob);
      const downloadUrl = await getDownloadURL(imageRef);
      Arr.push(downloadUrl);
    }
    return Arr;
  } catch (error) {
    showToastMessage(error.message, "error");
  }
};
