import React, { useState } from "react";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import { useUserDataAndNavigation } from "../hooks/useUserDataAndNavigation";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  createSubscription,
  getSubscriptionInfo,
  updateSubscriptionOnContinue,
} from "../API/marina";
import { message } from "antd";
import SubscriptionCardData from "../components/Subscription/SubscriptionCardData";
import Spinner from "../components/CommonComponents/Spinner";

const Subscription = () => {
  const { t, navigate, userId } = useUserDataAndNavigation();
  const { state } = useLocation();
  const {
    number_of_dock_or_rental,
    company_type,
    number_of_guest_dock,
    type,
    returnUrl,
  } = state ?? {};

  const [subscriptionData, setSubscriptionData] = useState(null);

  const { data, isLoading: subcscriptionLoading } = useQuery({
    queryKey: ["get-subscription"],
    queryFn: async () => {
      const data = await getSubscriptionInfo({
        number_of_dock_or_rental,
        company_type,
        number_of_guest_dock,
      });
      return data?.data?.data;
    },
    enabled: type !== "asset",
  });
  const { monthly_price, yearly_price } = data ?? {};

  const handleConfirmLogout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("");
    localStorage.removeItem("userId");
    localStorage.removeItem("organizationName");
    localStorage.removeItem("addAssetsStore");
    localStorage.removeItem("addAssetsStore");
    navigate("/signin");
  };

  const onSuccess = (Data) => {
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      window.location.href = "/";
    }
  };

  const onError = (error) => {
    message.error(error?.message);
  };

  const { mutate, isLoading } = useMutation({
    mutationKey: ["create-subscription"],
    mutationFn: async (data) => {
      return await createSubscription(userId, data);
    },
    onSuccess,
    onError,
  });

  const {
    mutate: updateSubscriptionContinue,
    isLoading: updateSubscriptionContinueLoading,
  } = useMutation({
    mutationKey: ["update-continue-subscription"],
    mutationFn: async (data) => {
      return await updateSubscriptionOnContinue(userId, data);
    },
    onSuccess,
    onError,
  });
  const continueSubscription = () => {
    if (type === "asset") {
      updateSubscriptionContinue({
        amount: yearly_price ?? 0,
        monthly_price,
      });
    } else {
      mutate({
        amount: yearly_price ?? 0,
        monthly_price,
      });
    }
  };
  if (subcscriptionLoading) {
    return <Spinner />;
  }
  return (
    <div className="h-screen bg-white w-full flex text-dark-blue font-roobert-rg justify-center items-center">
      <div className="w-[60%] py-[20px] max-lg:w-[80%] h-[95vh] border flex  flex-col gap-y-[30px] justify-center items-center rounded-[20px] bg-[#E2F3F7]">
        <h1 className="text-[28px] 2xl:text-[35px] px-[20px] text-center">
          {type === "asset" ? t("upgradeSubscription") : t("7dayTrial")}
        </h1>
        <h1 className="text-[22px] 2xl:text-[24px] text-center">
          {type === "asset" ? t("upgradeSubscription1") : t("trialFinished")}.
        </h1>
        <p className="text-[20px] 2xl:text-[22px] px-[20px] text-center">
          {type === "asset"
            ? t("upgradeSubscription2")
            : t("acceptTheSubscription") + "."}
        </p>
        <div className="rounded-[10px] flex flex-col items-center justify-center gap-y-[10px] p-[30px] text-center bg-[#FFFFFF]">
          {type !== "asset" ? (
            <SubscriptionCardData monthly_price={monthly_price} t={t} />
          ) : (
            <></>
          )}
        </div>
        <div className="flex items-center gap-x-[20px]">
          <ButtonDark
            styleClasses={`h-[40px] w-[160px] white-Btn ${
              !subscriptionData && type === "asset" ? "!bg-gray-300" : ""
            }`}
            text={t("continue")}
            handleClick={continueSubscription}
            loading={isLoading || updateSubscriptionContinueLoading}
            disabled={!subscriptionData && type === "asset"}
          />
          <ButtonDark
            styleClasses={"h-[40px] w-[160px] white-Btn"}
            text={t("cancel")}
            handleClick={() => {
              if (type === "asset") {
                navigate("/assets/all-assets");
              } else {
                handleConfirmLogout();
              }
            }}
          />
        </div>
        <p className="text-[16px]">
          {type === "asset"
            ? t("upgradeSubscription3")
            : t("invoicedAccordingly")}
          .
        </p>
      </div>
    </div>
  );
};

export default Subscription;
