import React from "react";

const Bank = () => {
  return (
    <svg
      width="98"
      height="98"
      viewBox="0 0 98 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2020_71044)">
        <path
          d="M49 98C76.062 98 98 76.062 98 49C98 21.938 76.062 0 49 0C21.938 0 0 21.938 0 49C0 76.062 21.938 98 49 98Z"
          fill="#D7EEF3"
        />
        <path
          d="M69.1085 39.8086H28.9805"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M69.1085 63.6367H28.9805"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M71.3181 66.8594H26.5625"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M29.0938 36.9074V34.9965L49.1463 24.7285L69.1988 35.1577V36.9074H29.0938Z"
          fill="white"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M32.9844 42.4551H37.5889"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M35.2891 43.2617V60.7357"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M32.9844 60.7363H37.5889"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M46.8438 42.4551H51.4482"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M49.1484 43.2617V60.7357"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M46.8438 60.7363H51.4482"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M60.4961 42.709H65.1006"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M62.8008 43.5137V60.9877"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M60.4961 60.9883H65.1006"
          stroke="#134357"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_2020_71044">
          <rect width="98" height="98" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Bank;
