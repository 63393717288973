import { useQuery } from "react-query";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { fetchAssets } from "../../API/marina";
import { useState } from "react";

const useGetDataForMap = () => {
  const { userId, params, t, setParams, paramsObject } =
    useUserDataAndNavigation();
  const { isLoading, error, data } = useQuery({
    queryKey: ["map-assets"],
    queryFn: () => fetchAssets(userId),
    keepPreviousData: true,
  });
  const step = params.get("step") ?? 1;
  const instructed = params.get("instructed") ?? false;
  const [showInstruction, setShowInstruction] = useState(
    step === 1 && instructed !== "true"
  );
  const onHandleInstructions = (bool) => {
    setShowInstruction(bool);
    setParams({ ...paramsObject, instructed: "true" });
  };
  const acceptLocation = (newPosition) => {
    const newParams = {
      ...paramsObject,
      step: 2,
      lat: newPosition.lat,
      lng: newPosition.lng,
    };
    setParams(newParams);
    localStorage.setItem(
      "latlng",
      JSON.stringify({
        lat: newPosition.lat,
        lng: newPosition.lng,
      })
    );
  };

  const acceptDocksLocation = (newPosition, setActive) => {
    setParams({
      ...paramsObject,
      lat: newPosition.lat,
      lng: newPosition.lng,
      active: "true",
    });
  };

  const handleLoad = (map, mapRef, setOptions) => {
    mapRef.current = map;
    setOptions({
      fullscreenControl: false,
      zoomControl: false,
      streetViewControl: false,
      mapTypeId: "hybrid",
      mapTypeControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
        mapTypeIds: ["roadmap", "hybrid"],
      },
      mapTypeControl: false,
      minZoom: 3,
      rotateControl: false,
    });
  };
  return {
    isLoading,
    error,
    data,
    t,
    params,
    setParams,
    acceptLocation,
    acceptDocksLocation,
    handleLoad,
    onHandleInstructions,
    showInstruction,
  };
};

export default useGetDataForMap;
