function isFlattened(obj) {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      return false;
    }
  }
  return true;
}

export const resolvedFlattened = (obj) => {
  if (isFlattened(obj)) {
    return obj;
  }
  return flattenObject(obj);
};

const flattenObject = (obj, prefix = "") =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const prefixedKey = `${prefix}${key}`;
    if (typeof value === "object" && value !== null) {
      return { ...acc, ...flattenObject(value, `${prefixedKey}.`) };
    }
    return { ...acc, [prefixedKey]: value };
  }, {});
