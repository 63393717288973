import React from "react";
import ArrowBack from "../../assets/icons/ArrowBack";
import ArrowNext from "../../assets/icons/ArrowNext";
import { Link } from "react-router-dom";
const BackToDashboardButton = ({ page }) => {
  return (
    <div className="mt-[15px] mx-[25px]    text-dark-blue font-roobert-rg ">
      <Link to={"/"} className="flex items-center">
        <ArrowBack />
        <div className="text-[22px] mx-[10px]">Dashboard</div>
        <ArrowNext />
        <div className="text-[22px] leading-[35px] ml-[10px]">{page}</div>
      </Link>
    </div>
  );
};

export default BackToDashboardButton;
