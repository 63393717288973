import { Form, message } from "antd";
import React, { useRef, useState } from "react";
import FormInput from "../CommonComponents/FormInput";
import Label from "../CommonComponents/Label";
import EditImages from "../CommonComponents/EditImages";

const EditUserDetails = ({
  images,
  form,
  onFinish,
  addRenter,
  dockLength,
  dockWidth,
  t,
}) => {
  const fileInputRef = useRef(null);
  const firebaseImages = addRenter
    ? []
    : images?.map((image) => {
        return { firebase: true, ...image };
      });

  const [addImages, setAddImages] = useState(firebaseImages ?? []);
  const handleClick = () => {
    if (addImages.length >= 4) {
      message.error("Only Four Images you can add");
    } else {
      fileInputRef.current.click();
    }
  };

  const deleteImage = (id) => {
    setAddImages((prevImages) => {
      return prevImages.filter((image) => image.images_id !== id);
    });
  };

  const handleFileChange = (event) => {
    const { files } = event.target;
    const selectedFile = files[0];
    if (selectedFile) {
      const newImage = {
        images_id: addImages.length + 1,
        image: URL.createObjectURL(selectedFile),
      };
      setAddImages((prevImages) => [newImage, ...prevImages]);
    }
    event.target.value = null;
  };

  return (
    <div className="pl-[70px] pb-[70px] edit-user pr-[55px]">
      <Form
        name="basic"
        onFinish={(values) => onFinish(values, addImages)}
        autoComplete="off"
        form={form}
      >
        <h1 className="2xl:text-[35px] text-[28px] mt-[5px] ">
          {t("customerInfo")}
        </h1>

        <div className="grid grid-cols-2 gap-x-3 mt-[50px]  ">
          <div className="">
            <Label styleClasses={"!text-[20px]"} text={t("memberNumber")} />
            <FormInput name={"customer_id"} type={"text"} />
          </div>
          <div className="">
            <Label styleClasses={"!text-[20px]"} text={t("name")} />
            <FormInput name={"customer_name"} type={"text"} />
          </div>
          <div className="">
            <Label styleClasses={"!text-[20px]"} text={t("Phonenumber")} />
            <FormInput name={"customer_contact"} type={"number"} />
          </div>

          <div className="">
            <Label styleClasses={"!text-[20px]"} text={t("email")} />
            <FormInput
              placeHolder="Enter email"
              name={"customer_email"}
              type={"email"}
            />
          </div>
        </div>
        <h1 className="2xl:text-[35px] text-[28px] mt-[5px] ">
          {t("boatInfo")}
        </h1>
        <div className="grid grid-cols-2 gap-x-3 mt-[50px]  ">
          <div className="">
            <Label styleClasses={"!text-[20px]"} text={t("boatType")} />
            <FormInput name={"boat_type"} type={"text"} />
          </div>
          <div className="">
            <Label
              styleClasses={"!text-[20px]"}
              text={t("length") + " " + "(meters)"}
            />
            <FormInput
              name={"boat_length"}
              type={"number"}
              rules={[
                () => ({
                  validator(_, value) {
                    if (value <= dockLength) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t("BoatLengthshouldbelessthanorequaltoDockLength")
                      )
                    );
                  },
                }),
              ]}
            />
          </div>
          <div className="">
            <Label
              styleClasses={"!text-[20px]"}
              text={t("width") + " " + "(meters)"}
            />
            <FormInput
              name={"boat_width"}
              type={"number"}
              rules={[
                () => ({
                  validator(_, value) {
                    if (value <= dockWidth) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t("BoatWidthshouldbelessthanorequaltoDockWidth")
                      )
                    );
                  },
                }),
              ]}
            />
          </div>
        </div>
        <EditImages
          deleteImage={deleteImage}
          addImages={addImages}
          handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
          t={t}
          handleClick={handleClick}
        />
      </Form>
    </div>
  );
};

export default EditUserDetails;
