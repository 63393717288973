import React from "react";
import SettingHeader from "./SettingHeader";
import { Form } from "antd";
import FormInput from "../CommonComponents/FormInput";

const AccountSetting = ({ t }) => {
  const onFinish = (values) => {};
  const onFinishFailed = (errorInfo) => {};
  return (
    <div className=" bg-[#E2F3F7] h-auto rounded-[20px] pb-[20px] 2xl:pb-[46px]">
      <SettingHeader heading={t("singleAccount")} t={t} />
      <div className="pl-[40px] pr-[20px]">
        <h1 className=" text-[20px] font-roobert-rg 2xl:text-[22px] leading-[35px]">
          {t("appearsInExportRentals")}
        </h1>
        <p className="font-roobert-rg leading-[28px] text-[18px] 2xl:mt-[30px] mt-[20px]">
          {t("accountsDesc")}
        </p>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="mt-[10px]">
            <FormInput
              styleClasses="rounded-full w-[50%]"
              disabled={true}
              value="3605"
            />
          </div>
          <p className="font-roobert-rg leading-[28px] text-[18px] 2xl:mt-[30px] mt-[20px]">
            {t("accountsDesc2")}
          </p>
          <div className="mt-[10px]">
            <FormInput
              styleClasses="rounded-full w-[50%]"
              disabled={true}
              value="3610"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AccountSetting;
