import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import Table from "../components/CommonComponents/Table/Table";
import useGetDataForAssets from "../hooks/getData/useGetDataForAssets";
import PageHeaders from "../components/CommonComponents/PageHeaders";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import AllAssets from "../components/Assets/AllAssets";
import SeasonalDocks from "./SeasonalDocks";
import EditAssets from "../components/Assets/EditAssets";
import SDSDetails from "../components/SeasonalDockSpots/SDSDetails";
import useGetDataForSeasonalDocks from "../hooks/getData/useGetDataForSeasonalDocks";
import SearchButton from "../components/CommonComponents/SearchButton";
import { handleSearchChange, handleSearchClick } from "../utils/handleSearch";
import SearchBar from "../components/CommonComponents/SearchBar";
import { useAssetEditStore } from "../store/useAssetEditStore";
import Dialog from "../components/CommonComponents/Modal/Dialog";
const Assets = () => {
  let {
    orderedTableCardsData,
    assetTypeObj,
    selectedRowKeys,
    onSelectChange,
    filterAssets,
    total_pages,
    assetsType,
    t,
    isModalOpen,
    location,
    handleModal,
    form,
    topAssets,
    refetch,
    refetchTopAssets,
    dropDownProps,
    searchParams,
    setParams,
    isLoading,
    isFetching,
    error,
    lang,
    assetsRefetch,
    isOpen,
  } = useGetDataForAssets();

  const { handleClose } = useGetDataForSeasonalDocks({ enabled: false });
  const [searchValue, setSearchValue] = useState("");
  const { setScreen } = useAssetEditStore();
  const assetId = searchParams.get("id");

  if (assetsType !== "permanent-docks") {
    return (
      <Layout page={t("rentalObjects")}>
        <Dialog
          isModalOpen={isModalOpen}
          onCancelModal={handleClose}
          component={
            <SDSDetails
              form={form}
              handleClose={handleClose}
              refetch={assetsRefetch}
            />
          }
          styles={"seasonaldocks"}
        />
        <Dialog
          isModalOpen={isOpen}
          onCancelModal={() => {
            handleModal(false);
            setScreen(1);
          }}
          component={
            <EditAssets
              onCancelModal={() => {
                handleModal(false);
                setScreen(1);
              }}
              refetch={refetch}
              refetchTopAssets={refetchTopAssets}
            />
          }
          styles={"club-members"}
        />
        <div className="px-[25px] pb-[40px]">
          <PageHeaders
            heading={t("rentalObjects")}
            dropDown={location === `/${lang}/assets/guest-docks` ? true : false}
            dropDownProps={dropDownProps}
            exportData={location !== `/${lang}/assets/all-assets`}
            exportUrl={`/${lang}/export/${assetsType}`}
          />
          <div>
            {location === `/${lang}/assets/all-assets` ? (
              <AllAssets
                isLoading={topAssets?.isLoading || topAssets?.isFetching}
                orderedTableCardsData={orderedTableCardsData}
                error={topAssets?.error}
              />
            ) : (
              <div className="rounded-[12px] pb-[40px] pt-[33px] 2xl:mt-[50px] mt-[40px] bg-[#FFFFFF] my-table">
                <div className="px-[30px] pb-[20px] w-full flex items-center justify-between">
                  <div className="flex items-center">
                    <h1 className="text-dark-blue text-[28px] 2xl:text-[35px]">
                      {assetTypeObj?.heading}
                    </h1>
                    <div className="flex gap-x-[10px] pl-[25px]">
                      <SearchBar
                        value={searchValue}
                        placeholder={t("search") + " " + assetTypeObj?.heading}
                        handleSearchChange={(e) =>
                          handleSearchChange(
                            e,
                            setSearchValue,
                            searchParams,
                            setParams
                          )
                        }
                        handleSubmit={() =>
                          handleSearchClick(
                            searchValue,
                            setParams,
                            searchParams
                          )
                        }
                      />
                      <SearchButton
                        text={t("search")}
                        handleSearchClick={() =>
                          handleSearchClick(
                            searchValue,
                            setParams,
                            searchParams
                          )
                        }
                      />
                    </div>
                  </div>
                  <ButtonDark
                    text={assetTypeObj?.buttonText}
                    styleClasses="h-[40px] light-Btn"
                    handleClick={assetTypeObj?.addAsset}
                  />
                </div>
                <div className="pt-[15px]">
                  <Table
                    columns={assetTypeObj?.columns}
                    selection={true}
                    data={filterAssets}
                    loading={isLoading || isFetching}
                    pagination={true}
                    error={error}
                    onSelectChange={onSelectChange}
                    selectedRowKeys={selectedRowKeys}
                    totalPages={total_pages * 20}
                    pageSize={20}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  } else {
    return <SeasonalDocks />;
  }
};
export default Assets;
