import { useQuery } from "react-query";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { checkSubscription } from "../API/marina";
import { defaultLanguage } from "../constants/constants";
import IntercomComponent from "../components/Intercom/IntercomComponent";

const ProtectedRoute = ({ children }) => {
  const userAuthToken = localStorage.getItem("auth");
  const location = useLocation().pathname;

  const { data: subscriptionData } = useQuery(
    ["check-trial", location],
    () => checkSubscription(localStorage.getItem("userId")),
    {
      enabled: !!userAuthToken,
    }
  );
  if (subscriptionData?.is_expired) {
    return (
      <Navigate
        to={`/${defaultLanguage}/subscription`}
        state={{
          number_of_dock_or_rental: subscriptionData?.number_of_dock_or_rental,
          number_of_guest_dock: subscriptionData?.number_of_guest_dock,
          company_type: subscriptionData?.company_type,
        }}
      />
    );
  }

  if (userAuthToken && !subscriptionData?.is_expired) {
    return children ? (
      children
    ) : (
      <>
        <IntercomComponent />
        <Outlet />
      </>
    );
  }

  if (!userAuthToken) {
    return <Navigate to={`/${defaultLanguage}/signin`} />;
  }
};

export default ProtectedRoute;
