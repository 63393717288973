import { Form, Input } from "antd";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export const PlacesAutocomplete = ({ name, rules, form, placeHolder }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({});

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => {
    setValue(description, false);
    form.setFieldsValue({ [name]: description });
    clearSuggestions();
  };

  const handleSelectAndSetValue = (suggestion) => {
    handleSelect(suggestion);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={() => handleSelectAndSetValue(suggestion)}
          className="cursor-pointer border-b py-2"
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div className="relative">
      <Form.Item name={name} rules={rules} className={`!rounded-[30px]`}>
        <Input
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={placeHolder}
          className="h-[40px] !placeholder:text-[#A3A3A3] pl-[10px] w-full text-[14px] font-roobert-rg text-black !rounded-[30px]  border-none outline-none shadow-input"
        />
      </Form.Item>
      {status === "OK" && (
        <ul className="w-full absolute z-50 top-[43px] bg-[#FFFFFF] rounded-[10px] h-[150px] overflow-y-auto p-2 text-darl-blue font-roobert-rg text-[16px]">
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};
