import React from "react";

const DashboardIcon = ({ path, location }) => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      className="mt-[1px]"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M1.98325 0C1.47569 0 0.968193 0.178822 0.580908 0.536313C-0.193664 1.25129 -0.193608 2.41034 0.580908 3.1253C1.18339 3.68143 2.07715 3.80471 2.80895 3.49561L4.2944 4.86673C3.70163 5.36974 3.07704 5.84377 2.42852 6.29529L3.26203 7.06464C3.78034 6.48887 4.31208 5.92884 4.86241 5.39109L9.5546 9.72228C8.1202 10.7552 6.21058 11.0972 4.22 10.8093L4.26496 10.2642L1.48358 9.74225L4.12317 12L4.17767 11.336C6.57346 12.2802 9.09145 12.2199 10.855 11.0359L12.5997 11.5802L12.0057 9.95611C13.2399 8.33134 13.2897 6.03817 12.2798 3.85555L13 3.80524L10.5541 1.36949L11.1195 3.93689L11.71 3.89542C12.0221 5.73377 11.6501 7.49681 10.5299 8.82122L5.83248 4.48603C6.41556 3.96657 7.02023 3.47303 7.65243 3.01193L6.81901 2.2426C6.34825 2.85947 5.83203 3.43424 5.27828 3.97446L3.78508 2.59614C4.12203 1.92008 3.98918 1.09341 3.3857 0.536313C2.99836 0.178822 2.49086 0 1.98325 0ZM1.98325 0.690311C2.29959 0.690362 2.61605 0.801149 2.85739 1.02395C3.3401 1.46952 3.34008 2.19214 2.85739 2.63766C2.37471 3.08324 1.59273 3.08324 1.11004 2.63766C0.627333 2.19217 0.627306 1.46952 1.11004 1.02397C1.35133 0.801149 1.66693 0.690311 1.98325 0.690311Z"
        fill={path === location ? "#134357" : "#FFFFFF"}
      />
    </svg>
  );
};

export default DashboardIcon;
