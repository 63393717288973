import React, { useState } from "react";
import { useQuery } from "react-query";
import { getTransactions } from "../../API/marina";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";

const useGetDataForPayouts = () => {
  const [earnedOrPending, setEarnedOrPending] = useState({
    type: null,
    open: false,
  });
  const { userId } = useUserDataAndNavigation();
  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ["get-transactions"],
    queryFn: async () => {
      return await getTransactions(
        userId,
        earnedOrPending?.type === "earning"
          ? "withdrawal"
          : earnedOrPending?.type
      );
    },
    enabled: earnedOrPending?.open,
  });

  return {
    earnedOrPending,
    setEarnedOrPending,
    data,
    isLoading,
    isFetching,
    error,
  };
};

export default useGetDataForPayouts;
