import React from "react";
import { Steps } from "antd";
import Step1Icon from "../../assets/icons/Step1Icon";
import Step2Icon from "../../assets/icons/Step2Icon";
import Step3IconLight from "../../assets/icons/Step3IconLight";
import Step2IconLight from "../../assets/icons/Step2IconLight";
import Step3Icon from "../../assets/icons/Step3Icon";
import useGetDataForSignup from "../../hooks/getData/useGetDataForSignup";
import { Link } from "react-router-dom";
const { Step } = Steps;
const ProgressSteps = ({ t, params }) => {
  const { steps, current, lang } = useGetDataForSignup();

  return (
    <>
      <Steps current={params.get("current")} className="max-w-[300px] h-auto">
        <Step icon={<Step1Icon />} />
        <Step icon={current >= "1" ? <Step2Icon /> : <Step2IconLight />} />
        <Step icon={current === "2" ? <Step3Icon /> : <Step3IconLight />} />
      </Steps>
      <div className="flex  max-w-[300px] mt-[10px]">
        <div
          className={`ml-[-15px] text-[14px] font-roobert-rg ${
            current >= 0 ? "text-[#18374F]" : "text-[#A5C5CC]"
          }`}
        >
          {t("organization")}
        </div>
        <div
          className={`ml-[54px] text-[14px] ${
            current >= 1 ? "text-[#18374F]" : "text-[#A5C5CC]"
          } font-roobert-rg`}
        >
          {t("contact")}
        </div>
        <div
          className={`ml-[84px] text-[14px] ${
            current >= 2 ? "text-[#18374F]" : "text-[#A5C5CC]"
          } font-roobert-rg`}
        >
          Plan
        </div>
      </div>
      <div>
        {steps[current].content}

        {current <= 1 && (
          <Link to={`/${lang}/signin`}>
            <p className="text-[16px]  text-center mt-[30px] leading-[28px]  text-dark-blue">
              {t("haveanaccount")}{" "}
              <span className="font-roobert-sb">{t("singleSignin")}</span>
            </p>
          </Link>
        )}
      </div>
    </>
  );
};

export default ProgressSteps;
