export const sortData = (data) => {
  return data?.slice().sort((a, b) => a.period - b.period);
};

export const filterData = (sortData, type, translateAverage, monthNames) => {
  return sortData?.map((subData) => {
    if (type === "monthly") {
      return {
        ...subData,
        [translateAverage]: subData.average,
        period: monthNames[subData.period - 1],
      };
    } else {
      return subData;
    }
  });
};
