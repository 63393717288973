export const deleteParam = (prevParams, param) => {
  const params = new URLSearchParams(prevParams);
  const queryParams = {};
  for (const [key, value] of params) {
    queryParams[key] = value;
  }
  delete queryParams[param];
  const newParams = new URLSearchParams(queryParams);

  return newParams.toString();
};
