import React from "react";

const ZoomInZoomOut = ({ handleZoomOut, handleZoomIn, mainMap }) => {
  return (
    <div
      style={{
        userSelect: "none",
      }}
      className="h-[80px]  bg-dark-blue  text-white w-[40px] rounded-full"
    >
      <div
        onClick={handleZoomIn}
        className={`${
          mainMap === "main" ? "pt-[5px]" : "pt-[7px]"
        } text-[20px] flex h-[40px] justify-center pt-[7px] cursor-pointer`}
      >
        +
      </div>
      <div className="bg-white w-[80%] ml-[4px] mt-[3px]">
        <hr />
      </div>
      <div
        onClick={handleZoomOut}
        className={`${
          mainMap === "main" ? "mt-[-7px]" : ""
        } text-[22px] flex h-[40px] justify-center cursor-pointer`}
      >
        -
      </div>
    </div>
  );
};

export default ZoomInZoomOut;
