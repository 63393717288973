import axiosInstance from "./axiosConfig";
import showToastMessage from "../utils/message";
import { ASSET_TYPES, defaultLanguage } from "../constants/constants";
import { message } from "antd";

export const signup = async (data) => {
  return axiosInstance.post(`/marinaOrCompany/signup`, data, {
    headers: {
      platform: "marina-web-user",
    },
  });
};

export const handleSignin = async (Data) => {
  const { data } = await axiosInstance.get(
    `/signIn?email=${Data.email}&firebase_id=${Data.userId}`,
    {
      headers: {
        platform: "marina-web-user",
      },
    }
  );
  return data;
};

export const checkSubscription = async (userId) => {
  try {
    const { data } = await axiosInstance.get(`/${userId}/marinaSubscription`, {
      headers: {
        platform: "marina-web-user",
        app_language: defaultLanguage,
      },
    });
    return data.data;
  } catch (error) {
    message.error(error?.response?.data?.message);
  }
};

export const createSubscription = async (userId, Data) => {
  const { data } = await axiosInstance.post(
    `/${userId}/marinaSubscription`,
    Data
  );
  return data.data;
};

export const getSubscriptionInfo = async (data) => {
  return await axiosInstance.get(
    `marinaOrCompany/getProduct?number_of_dock_or_rental=${data?.number_of_dock_or_rental}&company_type=${data?.company_type}&number_of_guest_dock=${data?.number_of_guest_dock}`,
    {
      headers: {
        platform: "marina-web-user",
        app_language: defaultLanguage,
      },
    }
  );
};
export const fetchMembers = async (userId, page, newSearch) => {
  const data = await axiosInstance.get(
    `${userId}/marinaMembers?page_no=${page}&search=${newSearch}`
  );
  return data.data.data;
};
export const fetchMember = async (userId, memberId) => {
  const { data } = await axiosInstance.get(
    `${userId}/marinaMembers/${memberId}`
  );
  return data.data;
};

export const editMarinaMember = async (Data, userId, memberId) => {
  const data = axiosInstance.patch(`${userId}/marinaMembers/${memberId}`, Data);
  return data;
};

export const deleteClubMember = async (userId, Data) => {
  try {
    const data = await axiosInstance.patch(
      `${userId}/marinaMembers/delete`,
      Data
    );
    return data.data;
  } catch (error) {
    showToastMessage(error, "error");
  }
};
export const importWaitingList = async (userId, Data) => {
  const data = axiosInstance.post(`${userId}/waitingList`, {
    membersData: Data,
  });
  return data;
};
export const fetchWaitingList = async (userId, page, type, search) => {
  const data = await axiosInstance.get(
    `${userId}/waitingList?page_no=${page}&type=${type}&search=${search}`
  );
  return data.data.data;
};
export const fetchWaitingMember = async (userId, memberId) => {
  const data = await axiosInstance.get(`${userId}/waitingList/${memberId}`);
  return data.data.data;
};

export const editWaitingMember = async (Data, userId, memberId) => {
  const data = axiosInstance.patch(`${userId}/waitingList/${memberId}`, Data);
  return data;
};
export const deleteWaitingMember = async (userId, Data) => {
  try {
    const data = await axiosInstance.patch(`${userId}/waitingList`, Data);
    return data.data;
  } catch (error) {
    showToastMessage(error, "error");
  }
};

export const fetchRentals = async (userId, page, newSearch, type) => {
  const data = await axiosInstance.get(
    `${userId}/properties/marinaRentalRequests?page_no=${page}&search=${newSearch}&filter=${type}`
  );
  return data.data.data;
};
export const accceptRentalRequest = async (userId, rentalId) => {
  const data = await axiosInstance.put(`${userId}/booking/${rentalId}/accept`);
  return data.data;
};
export const rejectRentalRequest = async (userId, Data) => {
  const data = await axiosInstance.put(`${userId}/booking/${Data?.id}`, Data);
  return data.data;
};

export const exportRentals = async (userId, type) => {
  const { data } = await axiosInstance.get(
    `${userId}/properties/marinaRentalRequests/export?filter=${type}`
  );
  return data.data;
};

export const exportWaitingList = async (userId) => {
  const { data } = await axiosInstance.get(`${userId}/waitingList/export`);
  return data.data;
};

export const exportMarinaMembers = async (userId) => {
  const { data } = await axiosInstance.get(`${userId}/marinaMembers/export`);
  return data.data;
};

export const getSeasonsDocks = async (userId, page, filter, type, search) => {
  const { data } = await axiosInstance.get(
    `${
      process.env.REACT_APP_API_URL
    }/${userId}/seasonDockRenter?page_no=${page}&type=${
      filter === undefined ? "" : filter
    }&order_by=${type}&search=${search}`
  );
  return data?.data;
};

export const fetchDocks = async (userId, selectedRow, type) => {
  const { data } = await axiosInstance.get(
    `${userId}/seasonDockRenter/status/${selectedRow}?type=${type ?? ""}`
  );
  return data?.data;
};

export const assignDock = async (userId, id, Data, type) => {
  return await axiosInstance.put(
    `${userId}/seasonDockRenter/assign/${Data.dockId}/${id}?type=${
      type === "clubMember" ? "club_member" : "" ?? ""
    }`
  );
};

export const importClubMembers = async (userId, Data) => {
  const data = axiosInstance.post(`${userId}/marinaMembers`, {
    membersData: Data,
  });
  return data;
};

export const seasonDockDetails = async (userId, dockId) => {
  const { data } = await axiosInstance.get(
    `${userId}/seasonDockRenter/${dockId}`
  );
  return data?.data;
};
export const editSeasonDock = async (userId, Data, id) => {
  const { data } = await axiosInstance.put(
    `${userId}/seasonDockRenter/${id}`,
    Data
  );
  return data;
};

export const importSeasonDocks = async (userId, Data) => {
  const data = axiosInstance.post(`${userId}/seasonDockRenter`, {
    dockRentingData: Data,
  });
  return data;
};
export const deleteSeasonDock = async (userId, dockId) => {
  const data = axiosInstance.patch(
    `${userId}/seasonDockRenter/delete/${dockId}`
  );
  return data;
};
export const exportSeasonDocks = async (userId, type) => {
  const { data } = await axiosInstance.get(
    `${userId}/seasonDockRenter/record/export?type=${type ?? ""}`
  );
  return data.data;
};
export const exportAssets = async (userId, type) => {
  const { data } = await axiosInstance.get(
    `${userId}/properties/marinaAssets/export?type=${type}`
  );
  return data.data;
};
export const customerSupport = async (userId, Data) => {
  const data = await axiosInstance.post(`${userId}/support`, Data);
  return data;
};

export const goToPayouts = async (Data, flow) => {
  const { data } = await axiosInstance.get(
    `${Data.userId}/account?rentingFlow=${flow}`
  );
  return data?.data;
};

export const checkPayoutAccount = async (userId) => {
  const { data } = await axiosInstance.get(`${userId}/account/bankDetails`);
  return data;
};

export const getAssets = async (userId, type, page, search) => {
  const assetType = ASSET_TYPES[type] || type;
  const { data } = await axiosInstance.get(
    `${userId}/properties/getMarinaOwnerAsset?page_no=${page}&type=${assetType}&search=${search}`
  );
  return data.data;
};

export const addGuestDocks = async (userId, Data) => {
  const data = await axiosInstance.post(`${userId}/marinaGuestDock`, Data);
  return data;
};

export const getTopAssets = async (userId) => {
  const { data } = await axiosInstance.get(
    `${userId}/properties/getMarinaAssetMain`
  );
  return data.data;
};
export const getGuestDocks = async (userId, page, guestDockType) => {
  const data = await axiosInstance.get(
    `${userId}/marinaGuestDock?page_no=${page}&order_by=${guestDockType}`
  );
  return data.data.data;
};
export const financeGraphData = async (userId, revenueType) => {
  const { data } = await axiosInstance.get(
    `${userId}/booking/property/marinaFinanceGraph?type=${revenueType}`
  );
  return data.data;
};

export const fetchRentalGraph = async (userId, rentalType) => {
  const { data } = await axiosInstance.get(
    `${userId}/booking/property/marinaRentalGraph?type=${rentalType}`
  );
  return data.data;
};
export const getAssetCount = async (userId) => {
  const { data } = await axiosInstance.get(
    `${userId}/properties/marinaAssetsCount`
  );
  return data.data;
};
export const getAssetDetails = async (userId, id, type) => {
  const { data } = await axiosInstance.get(
    `${userId}/properties/${id}/assetDetailByMarina?type=${
      type === "guest-dock" ? "guest_dock" : type
    }`
  );
  return data.data;
};
export const editGuestDock = async (userId, id) => {
  const { data } = await axiosInstance.get(
    `${userId}/properties/${id}/assetDetailByMarina?type=guest_dock`
  );
  return data.data;
};
export const editAsset = async (userId, assetId, assetType, Data) => {
  const data = await axiosInstance.put(
    `${userId}/${assetType}/${assetId}`,
    Data
  );
  return data;
};

export const editGuestDocks = async (userId, assetId, Data) => {
  const data = axiosInstance.put(`${userId}/marinaGuestDock/${assetId}`, Data);
  return data;
};
export const getPercentageOfDocks = async (userId) => {
  const data = await axiosInstance.get(
    `${userId}/seasonDockRenter/docks/graphData`
  );
  return data?.data?.data;
};

export const getTasks = async (userId) => {
  const data = await axiosInstance.get(`marinaOrCompany/${userId}/walkthrough`);
  return data.data.data;
};
export const editPinCode = async (userId, pinCode) => {
  const data = await axiosInstance.post(`${userId}/pinCode`, {
    pin_code: pinCode,
  });
  return data.data;
};
export const deleteUser = async (userId, Data) => {
  const data = await axiosInstance.patch(
    `${userId}/marinaOrCompanyUser/delete`,
    Data
  );
  return data.data;
};
export const getUsers = async (userId) => {
  const data = await axiosInstance.get(`${userId}/marinaOrCompanyUser`);
  return data?.data?.data;
};
export const addOrEditUserAccount = async (userId, Data) => {
  const data = await axiosInstance.post(`${userId}/marinaOrCompanyUser`, Data);
  return data.data;
};
export const getGuestDocksRequests = async (userId) => {
  const data = await axiosInstance.get(`${userId}/guestDock/request`);
  return data?.data?.data;
};
export const acceptOrRejectRequests = async (userId, Data) => {
  const data = await axiosInstance.patch(`${userId}/guestDock/request`, Data);
  return data?.data;
};
export const getPayouts = async (userId) => {
  const { data } = await axiosInstance.get(`${userId}/payout`);
  return data?.data;
};

export const changeBankAccount = async (userId, key) => {
  const { data } = await axiosInstance.put(
    `${userId}/account?stripe_account_id=${key}`
  );
  return data?.data;
};

export const updateSubscriptionOnContinue = async (userId, editedData) => {
  const { data } = await axiosInstance.put(
    `${userId}/marinaSubscription`,
    editedData
  );
  return data?.data;
};
export const getTransactions = async (userId, type) => {
  const { data } = await axiosInstance.get(
    `${userId}/payout/payoutTransactions?transaction_type=${type}`
  );
  return data?.data;
};
export const getInvoicedInformation = async (userId) => {
  const { data } = await axiosInstance.get(
    `marinaOrCompany/${userId}/getMarina`
  );
  return data?.data;
};
export const updateInvoicedInformation = async (userId, Data) => {
  const { data } = await axiosInstance.put(`marinaOrCompany/${userId}`, Data);
  return data?.data;
};

export const fetchAssets = async (userId) => {
  const { data } = await axiosInstance.get(`/${userId}/properties`);
  return data.data;
};

export const getMyProfile = async (userId) => {
  const { data } = await axiosInstance.get(`/${userId}/my_profile`);
  return data.data;
};

export const getAutoValues = async (userId, type, size) => {
  const res = await axiosInstance.get(
    `/${userId}/properties/suggestedPrices?type=${type}&size_in_feet=${size}`
  );
  const response = res?.data?.data ?? {};
  return response;
};

export const getCurrency = async (userId) => {
  const res = await axiosInstance.get(`/${userId}/currency`);
  return res?.data?.data ?? {};
};
