import React from "react";

const WaitListIcon = ({ location, path }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
    >
      <path
        d="M0.99999 0.705078L1 15.7492"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeLinecap="round"
      />
      <path
        d="M13 0.705078L13 15.7492"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeLinecap="round"
      />
      <path
        d="M12.5 15.75L1.5 15.75"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeLinecap="square"
      />
      <line
        x1="13"
        y1="0.75"
        x2="1"
        y2="0.749999"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeLinecap="round"
      />
      <path
        d="M5.49902 5.60156H10.499"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeLinecap="round"
      />
      <path
        d="M5.49902 8.9082H10.499"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeLinecap="round"
      />
      <path
        d="M5.49902 12.2168H10.499"
        stroke={path === location ? "#134357" : "#FFFFFF"}
        strokeLinecap="round"
      />
      <path
        d="M4.09941 5.62909C4.09941 5.93122 3.83078 6.17615 3.49941 6.17615C3.16804 6.17615 2.89941 5.93122 2.89941 5.62909C2.89941 5.32696 3.16804 5.08203 3.49941 5.08203C3.83078 5.08203 4.09941 5.32696 4.09941 5.62909Z"
        stroke={path === location ? "#134357" : "#FFFFFF"}
      />
      <path
        d="M4.10039 8.91815C4.10039 9.22028 3.83176 9.46521 3.50039 9.46521C3.16902 9.46521 2.90039 9.22028 2.90039 8.91815C2.90039 8.61602 3.16902 8.37109 3.50039 8.37109C3.83176 8.37109 4.10039 8.61602 4.10039 8.91815Z"
        stroke={path === location ? "#134357" : "#FFFFFF"}
      />
      <path
        d="M4.20488 12.1838C4.20488 12.4859 3.93625 12.7308 3.60488 12.7308C3.27351 12.7308 3.00488 12.4859 3.00488 12.1838C3.00488 11.8816 3.27351 11.6367 3.60488 11.6367C3.93625 11.6367 4.20488 11.8816 4.20488 12.1838Z"
        stroke={path === location ? "#134357" : "#FFFFFF"}
      />
    </svg>
  );
};

export default WaitListIcon;
