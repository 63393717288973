import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
export const handleImageChange = (
  event,
  setImage,
  setOpenCropImageModal,
  type
) => {
  const reader = new FileReader();
  let imageFile = event.target.files && event.target.files[0];

  if (imageFile) {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = imageFile.name.split(".").pop().toLowerCase();
    if (type && type === "guestDock") {
      const maxSize = 1024 * 1024; // 1 MB
      if (imageFile.size > maxSize) {
        message.error("Image size exceeds 1 MB. Please choose a smaller file.");
        return;
      }
    }
    if (allowedExtensions.includes(fileExtension)) {
      reader.onloadend = () => {
        setImage({
          id: uuidv4(),
          file: reader.result,
          isFirebase: false,
        });
        setOpenCropImageModal(true);
      };
      reader.readAsDataURL(imageFile);
    } else {
      message.error(
        "Invalid file type. Please choose a JPG, JPEG, or PNG file."
      );
    }
  }
};

export const handleRemoveImage = (
  index,
  selectedImages,
  stringify,
  parse,
  myImages,
  setSelectedImages,
  id
) => {
  if (index >= 0 && index < selectedImages.length) {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.filter((_, i) => i !== index)
    );
    const thumnailImages = parse(localStorage.getItem("thumbnail-images"));
    const updatedThumbnailImage = thumnailImages
      ? thumnailImages?.filter((image) => image.parentId !== id)
      : [];
    localStorage.setItem("thumbnail-images", stringify(updatedThumbnailImage));
    localStorage.setItem(
      "my-images",
      stringify(parse(myImages).filter((_, i) => i !== index))
    );
  }
};

export const handleRemoveImageForEditAsset = (
  index,
  selectedImages,
  setSelectedImages,
  id,
  thumbnailImages,
  setThumbnailImages,
  removeImages,
  setRemoveImages
) => {
  const removedImages = selectedImages.filter((img) => img?.id === id);
  const removedThumbnailImages = thumbnailImages.filter(
    (img) => img?.parentId === id
  );
  setSelectedImages(selectedImages.filter((img) => img?.id !== id));
  setThumbnailImages(thumbnailImages.filter((img) => img?.parentId !== id));
  setRemoveImages([
    ...removeImages,
    ...removedImages,
    ...removedThumbnailImages,
  ]);
};
