import { message } from "antd";

export const searchPlaceName = async (lat, lng) => {
  try {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${encodeURIComponent(
      Number(lat)
    )},${encodeURIComponent(Number(lng))}&key=${process.env.REACT_APP_API_KEY}`;
    const response = await fetch(apiUrl);
    if (!response.ok) {
      message.error(`Geocoding failed with status ${response.status}`, "error");
    }
    const data = await response.json();
    if (data.status === "OK") {
      const cityComponent = data.results[0].address_components.find(
        (component) => component.types.includes("locality")
      );
      return cityComponent ? cityComponent.long_name : null;
    } else {
      message.error("Geocoding failed with an unknown status.", "error");
    }
  } catch (error) {
    message.error(error.message, "error");
  }
};
