import axios from "axios";
import { useState, createContext, useContext } from "react";
import showToastMessage from "../utils/message";
import { setLocalStorageItems } from "../utils/setLocalStorageItems";

const AuthContext = createContext();

const initialState = {
  userName: "",
  userEmail: "",
  userPhone: "",
  isRegister: "",
  userToken: "",
};
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(initialState);

  const loginAuth = (data) => {
    const {
      isRegister,
      token,
      id,
      user_name,
      user_email,
      user_phone,
      organization,
      role,
    } = data;
    if (isRegister) {
      if (token) {
        setLocalStorageItems({
          auth: token,
          organizationName: organization,
          role,
          userId: id,
        });
      }
      if (!localStorage.getItem("onBoarding")) {
        localStorage.setItem("onBoarding", true);
      }
      setUser({
        userName: user_name,
        userEmail: user_email,
        userPhone: user_phone,
        isRegister: isRegister,
      });
    }
  };

  const registrationAuth = async (
    values,
    mutate,
    setFieldError,
    setLoading
  ) => {
    const { companyName, phone, registrationEmail, location } = values;

    if (companyName && phone && location && registrationEmail) {
      try {
        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            location
          )}&key=${process.env.REACT_APP_API_KEY}`
        );
        var locationLatLng = await res.data.results[0].geometry.location;
      } catch (error) {
        setLoading(false);
        if (
          error.message ===
          "Cannot read properties of undefined (reading 'geometry')"
        ) {
          setFieldError("location", "*Enter correct location");
        }
        showToastMessage(error.message, "error");
      }
      if (locationLatLng) {
        mutate({
          company_name: companyName,
          contact_no: phone,
          lat: locationLatLng.lat,
          lng: locationLatLng.lng,
          email: registrationEmail,
        });
      }
    }
  };
  return (
    <AuthContext.Provider
      value={{ user, setUser, loginAuth, registrationAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
