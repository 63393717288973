import { ConfigProvider, TimePicker } from "antd";
import { DateRange } from "react-date-range";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { getMonths } from "../../utils/dateUtils";
import { Colors } from "../../constants/chatsConstants";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ButtonDark from "../CommonComponents/ButtonDark";
import { getAntdLocales, getLocale } from "../../utils/getLocale";
const Calendar = ({
  btnText,
  dateRange,
  setDateRange,
  startTime,
  endTime,
  handleContinue,
  isLoading,
  disableDates = [],
  mainHeading,
  text,
}) => {
  const { lang } = useParams() || "no";
  const { t } = useTranslation();
  return (
    <div>
      <div
        className="w-full p-5 flex justify-center flex-col items-center"
        id="booking"
      >
        <div className="mb-5 ">
          <h1 className="text-center text-[24px] text-kprimary font-roobert">
            {mainHeading ?? t("suggestionText1")}
          </h1>
          <p className="text-center text-kprimary font-roobert text-[18px]">
            {text ?? t("suggestionText2")}
          </p>
        </div>
        <div className="w-[70%]">
          <DateRange
            direction="vertical"
            rangeColors={[Colors.strokeColor]}
            ranges={[{ ...dateRange, key: "selection" }]}
            onChange={(rangesByKey) => {
              setDateRange(rangesByKey.selection);
            }}
            disabledDates={disableDates?.map(
              (timestamp) => new Date(timestamp)
            )}
            className="!rounded-[10px]"
            showDateDisplay={false}
            minDate={new Date()}
            locale={getLocale(lang)}
          />
        </div>
      </div>

      <h3 className="text-center text-lg mt-2">{t("Time")}</h3>
      <div
        id="bookingTimer"
        className="flex items-center justify-center gap-3 mt-5"
      >
        <div
          id="bookingTimer"
          className="flex calendar-timer flex-col items-center"
        >
          <p className="text-sm -mb-1">
            {dayjs(dateRange.startDate).date()}{" "}
            {t(getMonths(dayjs(dateRange.startDate).month(), "short"))}
          </p>
          <ConfigProvider locale={getAntdLocales(lang)}>
            <TimePicker
              bordered={false}
              suffixIcon={null}
              format="HH:mm"
              size="large"
              inputReadOnly
              value={dayjs(startTime)}
              onChange={(val) => {
                setDateRange({
                  ...dateRange,
                  startDate: val?.toDate(),
                });
              }}
            />
          </ConfigProvider>
        </div>

        <span className="text-lg self-end mb-4">{t("To")}</span>

        <div
          id="bookingTimer"
          className="flex calendar-timer flex-col items-center"
        >
          <p className="text-sm -mb-1">
            {dayjs(dateRange.endDate).date()}{" "}
            {t(getMonths(dayjs(dateRange.endDate).month(), "short"))}
          </p>
          <ConfigProvider locale={getAntdLocales(lang)}>
            <TimePicker
              bordered={false}
              suffixIcon={null}
              format="HH:mm"
              value={dayjs(endTime)}
              size="large"
              inputReadOnly
              onChange={(val) => {
                setDateRange({
                  ...dateRange,
                  endDate: val?.toDate(),
                });
              }}
            />
          </ConfigProvider>
        </div>
      </div>

      <div className="flex justify-center mt-7">
        <ButtonDark
          loading={isLoading}
          handleClick={handleContinue}
          text={t(btnText)}
          styleClasses={"bg-dark-blue h-[40px] text-[#FFFFFF]"}
        />
      </div>
    </div>
  );
};

export default Calendar;
