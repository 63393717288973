import { generateCroppedImage } from "./getCroppedImage";
import { v4 as uuidv4 } from "uuid";

export const handleCrop = async (
  image,
  croppedArea,
  onCancel,
  setSelectedImages,
  setThumbnailImages,
  userId,
  selectedImages,
  thumbnailImages,
  type
) => {
  if (croppedArea) {
    const baseUrl = await generateCroppedImage(image.file, croppedArea);
    if (!baseUrl) onCancel();
    const parentId = uuidv4();
    setSelectedImages([
      ...selectedImages,
      {
        id: parentId,
        file: image.file,
      },
    ]);
    setThumbnailImages([
      ...thumbnailImages,
      {
        id: uuidv4(),
        file: baseUrl,
        isFirebase: false,
        parentId,
      },
    ]);
    if (type === "guestDock") {
      localStorage.setItem(
        "my-images",
        JSON.stringify([
          ...selectedImages,
          {
            id: parentId,
            file: image.file,
          },
        ])
      );
      localStorage.setItem(
        "thumbnail-images",
        JSON.stringify([
          ...thumbnailImages,
          {
            id: parentId,
            file: baseUrl,
            isFirebase: false,
            parentId,
          },
        ])
      );
    }
    onCancel();
  }
};

export const getAssetModelLabel = (assetType, t) => {
  let label;

  switch (assetType) {
    case "jet_ski":
      label = t("singleJetski") + " " + t("model");
      break;
    case "boat":
      label = t("boat") + " " + t("model");
      break;
    case "boat_trailer":
      label = t("BoatTrailer") + " " + t("name");
      break;
    case "kayak":
      label = t("singleKayak") + " " + t("model");
      break;
    default:
      break;
  }
  return label;
};
