import React from "react";

const PrevIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8.5"
        cy="8.5"
        r="8.5"
        transform="rotate(-180 8.5 8.5)"
        fill="#FFFDF6"
      />
      <path
        d="M10.2196 3.80471L5.13953 8.0294C5.01325 8.13383 4.94526 8.29523 4.93555 8.43763C4.93555 8.58953 5.00354 8.74143 5.13953 8.84586L10.2196 13.0706C10.5402 13.3364 11.0064 12.9756 10.8024 12.6054L8.8209 8.42814L10.8024 4.25091C11.0064 3.89015 10.5402 3.5199 10.2196 3.78572V3.80471Z"
        fill="#134357"
      />
    </svg>
  );
};

export default PrevIcon;
