import { useState } from "react";
import { useQuery } from "react-query";
import {
  fetchWaitingList,
  deleteWaitingMember,
  assignDock,
} from "../../API/marina";
import { useLocationStore } from "../../store/store";
import useMutationHook from "../useMutationHook";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import useConstants from "./useConstants";
import useFetchDocksList from "./useFetchDocksList";
import { getWaitingListColumns } from "../../constants/waitingListCostants";
import useAssignDock from "./useAssignDock";
const GetDataForWaitingMembers = () => {
  const {
    userId,
    params: searchParams,
    setParams: setSearchParams,
    paramsObject,
    t,
    lang,
    navigate,
  } = useUserDataAndNavigation();
  const { assign, seasonalDockId } = useLocation().state ?? false;
  const { page, search } = useConstants();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { setIsModalOpen, isModalOpen } = useLocationStore();
  const [assignName, setAssignName] = useState("");
  const [assignDockModal, setAssignDockModal] = useState(false);

  const handleAssignDockModal = (bool) => {
    setAssignDockModal(bool);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const { isLoading, data, error, refetch, isFetching } = useQuery({
    queryKey: ["waiting-list", page, searchParams.get("type"), search],
    queryFn: () =>
      fetchWaitingList(userId, page, searchParams.get("type"), search),
    staleTime: Infinity,
  });

  const {
    isLoading: docksLoading,
    data: docksData,
    error: docksError,
    refetch: docksRefetch,
    isFetching: docksFetching,
    filterDocksData,
  } = useFetchDocksList({ isModalOpen });

  const filterData = data?.members.map((member) => {
    return { ...member, key: member?.id };
  });

  const handleOptionsChange = (value) => {
    setSearchParams({ ...paramsObject, type: value, page: 1 });
  };

  const { updateDock, isLoading: updateDockLoading } = useAssignDock({
    type: "",
    refetch,
    setIsModalOpen: setAssignDockModal,
  });

  const assignDockId = (id, name) => {
    setSearchParams({ ...paramsObject, id });
    setAssignName(name);
    if (!isModalOpen && !assign) {
      setIsModalOpen(true);
    } else {
      setSearchParams({ ...paramsObject, dockId: seasonalDockId, id });
      handleAssignDockModal(true);
    }
  };

  const columns = getWaitingListColumns(assignDockId);
  const onSuccess = (Data) => {
    const { message: response } = Data;
    message.success(response);
    setSelectedRowKeys([]);
    refetch();
  };

  const onError = (error) => {
    message.error(error);
  };

  const { mutate } = useMutationHook(
    ["delete-waiting-member"],
    async (Data) => {
      return await deleteWaitingMember(userId, Data);
    },
    onSuccess,
    onError
  );

  const deleteUser = () => {
    mutate({
      memberIds: selectedRowKeys,
    });
  };

  return {
    setSearchParams,
    columns,
    isLoading,
    data,
    error,
    refetch,
    isFetching,
    searchParams,
    filterData,
    onSelectChange,
    selectedRowKeys,
    setIsModalOpen,
    docksData,
    filterDocksData,
    paramsObject,
    docksLoading,
    isModalOpen,
    docksRefetch,
    docksFetching,
    docksError,
    handleOptionsChange,
    deleteUser,
    assign,
    seasonalDockId,
    updateDock,
    assignName,
    t,
    assignDockModal,
    handleAssignDockModal,
    lang,
    navigate,
    updateDockLoading,
  };
};

export default GetDataForWaitingMembers;
