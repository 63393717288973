import React, { useState } from "react";
import ViewDetails from "../../components/CommonComponents/ViewDetails";
import { Tag, message } from "antd";
import useMutationHook from "../useMutationHook";
import {
  addOrEditUserAccount,
  deleteUser,
  editPinCode,
  getUsers,
} from "../../API/marina";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { useMutation, useQueries } from "react-query";
const useGetDataForSettings = () => {
  const { userId, t, params } = useUserDataAndNavigation();
  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddUserModal = (bool) => {
    setOpenAddUserModal(bool);
  };
  const onSuccess = (Data) => {
    message.success(Data.message);
    usersRefetch();
    handleAddUserModal(false);
    setSelectedRowKeys([]);
  };
  const onError = (error) => {
    const { data } = error?.response;
    message.error(data?.message);
  };
  const { mutate: editAccountDetails } = useMutationHook(
    ["edit-details"],
    (Data) => {
      return editPinCode(userId, Data.pinCode);
    },
    onSuccess,
    onError
  );
  const {
    mutate: deleteUsers,
    isLoading: deleteUserLoading,
    isFetching: deleteUserFetching,
  } = useMutation(
    ["delete-users"],
    (Data) => {
      return deleteUser(userId, Data);
    },
    {
      onSuccess,
      onError,
    }
  );
  const handleDelete = () => {
    deleteUsers({
      userIds: selectedRowKeys,
    });
  };
  const {
    mutate: addOrEditUser,
    isLoading: addUserLoading,
    isFetching: addUserFetching,
  } = useMutation(
    ["add-edit-users"],
    (Data) => {
      return addOrEditUserAccount(userId, Data);
    },
    {
      onSuccess,
      onError,
    }
  );
  const [Users] = useQueries([
    {
      queryKey: ["get-users"],
      queryFn: async () => {
        return await getUsers(userId);
      },
    },
  ]);

  let {
    data: users,
    isError: usersError,
    isLoading: usersIsLoading,
    isFetching: usersIsFetching,
    refetch: usersRefetch,
  } = Users;
  const filterUsers = users?.map((obj) => {
    return { key: obj.id, ...obj };
  });
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Type",
      dataIndex: "Type",
    },
    {
      title: "Invoice Number",
      dataIndex: "Invoice Number",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        let color = status === "paid" ? "#D9F2DD" : "#FFC4B7";
        return (
          <Tag
            color={color}
            key={status}
            className="!rounded-full h-[40px] leading-[28px] text-center  w-[98px]"
          >
            <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
              {status === "paid" ? "Paid" : "Un paid"}
            </div>
          </Tag>
        );
      },
    },
    {
      title: "Details",
      dataIndex: "4",
      // width: '25%',
      render: (_, { id }) => {
        return (
          <ViewDetails
          // handleClick={handleClick}
          />
        );
      },
    },
  ];

  const userAccountsColumns = [
    {
      title: t("name"),
      dataIndex: "user_name",
    },
    {
      title: t("role"),
      dataIndex: "user_role",
    },
    {
      title: t("email"),

      dataIndex: "user_email",
    },
    {
      title: t("Phonenumber"),
      dataIndex: "user_phone",
    },
  ];

  const onFinishAddOrEditUser = (values) => {
    addOrEditUser({
      ...values,
    });
  };

  return {
    columns,
    userAccountsColumns,
    editAccountDetails,
    filterUsers,
    usersError,
    usersIsFetching,
    usersIsLoading,
    handleAddUserModal,
    openAddUserModal,
    onFinishAddOrEditUser,
    onSelectChange,
    selectedRowKeys,
    t,
    addUserLoading,
    addUserFetching,
    handleDelete,
    deleteUserFetching,
    deleteUserLoading,
    userId,
    params,
  };
};

export default useGetDataForSettings;
