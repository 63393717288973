import React, { useState } from "react";
import Layout from "../Layout/Layout";
import { Form } from "antd";
import Label from "../components/CommonComponents/Label";
import FormInput from "../components/CommonComponents/FormInput";
import TextArea from "../components/CommonComponents/TextArea";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import useMutationHook from "../hooks/useMutationHook";
import { customerSupport } from "../API/marina";
import { message } from "antd";
import { useForm } from "antd/es/form/Form";
import { useUserDataAndNavigation } from "../hooks/useUserDataAndNavigation";
const Support = () => {
  const { t, userId, lang } = useUserDataAndNavigation();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const onSuccess = ({ data }) => {
    const { message: response } = data;
    form.resetFields();
    message.success(response);
    setLoading(false);
  };
  const onError = (error) => {
    message.error("Something went wrong try again later.");
    setLoading(false);
  };
  const { mutate } = useMutationHook(
    ["customer-support"],
    async (Data) => {
      return await customerSupport(userId, Data);
    },
    onSuccess,
    onError
  );

  const onFinish = (values) => {
    setLoading(true);
    mutate(values);
  };

  return (
    <Layout page={t("support")}>
      <div className="px-[25px] mt-[20px] pb-[33px] text-dark-blue ">
        <h1 className="text-[28px] 2xl:text-[32px] font-roobert-rg tracking-[0.96px]">
          {t("support")}
        </h1>
        <p className="text-[20px] font-roobert-l mt-[15px]  leading-[35px]">
          {t("supportText")}
        </p>
        {lang === "en" && (
          <>
            {" "}
            <p className="text-[20px] font-roobert-l mt-[30px]  leading-[35px]">
              We recommend that you look through our Support guides before you
              send us your question. <br /> You might find the answer to your
              question there :)
            </p>
            <p className="text-[20px] font-roobert-l mt-[30px]  leading-[35px]">
              You can find our support guide here.
            </p>{" "}
          </>
        )}
        <div className="">
          <Form name="basic" form={form} onFinish={onFinish} autoComplete="off">
            <div className="grid grid-cols-1 gap-x-3 mt-[30px]  ">
              <div className="">
                <Label styleClasses={"!text-[20px]"} text={t("name")} />
                <FormInput
                  name={"name"}
                  type={"text"}
                  styleClasses={"max-w-[450px] rounded-full"}
                  rules={[
                    {
                      required: true,
                      message: t("enterName"),
                    },
                  ]}
                />
              </div>
              <div className="">
                <Label styleClasses={"!text-[20px]"} text={t("email")} />
                <FormInput
                  name={"email"}
                  type={"email"}
                  styleClasses={"max-w-[450px] rounded-full"}
                  rules={[
                    {
                      required: true,
                      message: t("enterEmail"),
                    },
                  ]}
                />
              </div>
              <div className="">
                <Label styleClasses={"!text-[20px]"} text={t("topic")} />
                <FormInput
                  name={"subject"}
                  type={"text"}
                  styleClasses={"max-w-[450px] rounded-full"}
                  rules={[
                    {
                      required: true,
                      message: t("enterTopic"),
                    },
                  ]}
                />
              </div>
              <div className="pr-[340px]">
                <Label styleClasses={"!text-[20px]"} text={t("message")} />
                <TextArea
                  rows={7}
                  name={"description"}
                  styleClasses={""}
                  rules={[
                    {
                      required: true,
                      message: t("enterdescription"),
                    },
                  ]}
                />
              </div>
              <div className=" text-right pr-[340px]">
                <ButtonDark
                  styleClasses={"dark-Btn 2xl:h-[50px] h-[40px]"}
                  text={"Send " + t("message")}
                  type={"submit"}
                  loading={loading}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
