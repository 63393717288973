import React from "react";

const SubscriptionCardData = ({ subscriptionData, t, monthly_price }) => {
  return (
    <>
      {monthly_price ? (
        <>
          <h1 className="text-[28px] 2xl:text-[35px] ">{t("pricing")}</h1>

          <p className="text-[17px] 2xl:text-[18px] font-roobert-rg">
            {monthly_price} NOK {t("perMonth")}
          </p>
          <p className="text-[17px] 2xl:text-[18px] font-roobert-rg">
            *{t("billedAnnually")}
          </p>
          <p className="text-[17px] 2xl:text-[18px] font-roobert-rg">
            {t("10%free")}
          </p>
        </>
      ) : (
        <p className="">{t("noPlanAvailable")}</p>
      )}
    </>
  );
};

export default SubscriptionCardData;
