import { useTranslation } from "react-i18next";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import NorwayIcon from "../assets/icons/NorwayIcon";
import Sweden from "../assets/images/Sweden.png";
import Germany from "../assets/images/Germany.png";
export const useUserDataAndNavigation = () => {
  const [params, setParams] = useSearchParams();
  const paramsObject = Object.fromEntries(params);
  const navigate = useNavigate();
  const { parse, stringify } = JSON;
  const location = useLocation().pathname;
  const role = localStorage.getItem("role");
  let userId = localStorage.getItem("userId");
  const initialValues = localStorage.getItem("values")
    ? parse(localStorage.getItem("values"))
    : "";
  const { t } = useTranslation();
  const { lang } = useParams() || "no";
  const monthNames = [
    t("january"),
    t("february"),
    t("march"),
    "April",
    t("may"),
    t("june"),
    t("july"),
    "August",
    "September",
    t("october"),
    "November",
    t("December"),
  ];
  const handleNavigate = (path) => {
    navigate(path);
  };

  const countries = [
    {
      value: "Norway",
      label: t("norway"),
      svgIcon: <NorwayIcon />,
    },
    {
      value: "Sweden",
      label: t("sweden"),
      imgSrc: Sweden,
    },
    {
      value: "Germany",
      label: t("germany"),
      imgSrc: Germany,
    },
  ];

  const renderOptions = (options) => {
    return options.map(({ value, label, imgSrc, svgIcon }) => {
      return {
        value: value,
        label: (
          <div className="flex items-center gap-x-[12px] text-[14px] font-roobert-rg">
            {imgSrc && (
              <img
                src={imgSrc}
                className="h-[20px] rounded-[5px] w-[28px]"
                alt=""
              />
            )}
            {svgIcon}
            <span>{label}</span>
          </div>
        ),
      };
    });
  };

  const countryOptions = renderOptions(countries);
  return {
    params,
    setParams,
    userId,
    initialValues,
    parse,
    stringify,
    monthNames,
    t,
    paramsObject,
    navigate,
    location,
    handleNavigate,
    role,
    lang,
    countryOptions,
  };
};
