import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewDetails from "../../components/CommonComponents/ViewDetails";
import { Form, Tag } from "antd";
import { useQueries } from "react-query";
import {
  editGuestDock,
  getAssetDetails,
  getAssets,
  getTopAssets,
} from "../../API/marina";
import { addAssetStore, useLocationStore } from "../../store/store";
import { resolvedFlattened } from "../../utils/flatObj";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";
import { onDrag } from "../../utils/onDrag";
import useConstants from "./useConstants";
import { mapAssetType } from "../../utils/assetType";
import { useAssetEditStore } from "../../store/useAssetEditStore";

const GetDataForAssets = () => {
  const {
    location,
    userId,
    t,
    navigate,
    setParams,
    paramsObject,
    params: searchParams,
    handleNavigate,
    role,
    lang,
  } = useUserDataAndNavigation();
  const { assetsType } = useParams();
  const {
    assetsDropdownOption,
    page,
    search,
    emailColumn,
    priceColumn,
    sizeInFeet,
    horsePowerColumn,
    pierColumn,
    renterColumn,
  } = useConstants();
  const { resetToInitialValues } = addAssetStore();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { isModalOpen, setIsModalOpen } = useLocationStore();
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [form] = Form.useForm();
  const { isOpen, setIsOpen } = useAssetEditStore();
  const [Assets, topAssets] = useQueries([
    {
      queryKey: ["get-assets", location, page, search],
      queryFn: async () => {
        return await getAssets(userId, assetsType, page, search);
      },
      enabled: assetsType !== "all-assets" && assetsType !== "permanent-docks",
      cacheTime: 0,
    },
    {
      queryKey: ["get-top-assets", location],
      queryFn: async () => {
        return await getTopAssets(userId);
      },
      enabled: assetsType === "all-assets",
      cacheTime: 0,
    },
  ]);

  const { data, isLoading, isFetching, error } = Assets;
  const { data: recentAssets, refetch: assetsRefetch } = topAssets;
  const { boat, guestDock, kayak, jet_ski, seasonDock, boat_trailer } =
    recentAssets
      ? recentAssets
      : {
          boat: [],
          guestDock: [],
          kayak: [],
          jet_ski: [],
          seasonDock: [],
          boat_trailer: [],
        };

  const { myAssets, total_pages } = data ?? {
    total_pages: 1,
    myAssets: [],
  };

  const filterAssets = myAssets?.map((asset) => ({ key: asset.id, ...asset }));

  const type = mapAssetType(assetsType);

  const handleModal = (bool, id, type) => {
    if (bool === true) {
      setParams({ ...paramsObject, id, type });
    }
    if (bool === false) {
      setParams({ ...paramsObject, id: "", type: "" });
    }
    setIsOpen(bool);
  };
  const viewDockDetails = (id) => {
    setParams({ ...paramsObject, id });
    setIsModalOpen(true);
  };
  const addAsset = (type) => {
    resetToInitialValues();
    localStorage.removeItem("my-images");
    localStorage.removeItem("thumbnail-images");
    localStorage.removeItem("values");
    navigate(`/${lang}/add-asset/${type}`);
  };
  const dropDownProps = {
    options: assetsDropdownOption,
    defaultValue: "all",
    handleChange: (option) => setParams({ ...paramsObject, type: option }),
  };

  let rentalObjects = [
    {
      id: 1,
      heading: t("guestDocks"),
      type: "guestDocks",
      buttonText: t("addGuesDock"),
      addAsset: () => addAsset("guest_dock"),
      viewMore: () => handleNavigate(`/${lang}/assets/guest-docks`),
      columns: [
        {
          title: t("dock"),
          dataIndex: "name",
          render: (_, { name }) => {
            return <div className="text-[16px] font-roobert-sb">{name}</div>;
          },
        },
        pierColumn,
        renterColumn,
        emailColumn,
        {
          title: t("Phonenumber"),
          dataIndex: "phone",
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (_, { status }) => {
            let color =
              status === "active" ||
              status === "available" ||
              status === "confirmed"
                ? "#D9F2DD"
                : "#FFC4B7";
            return (
              <Tag
                color={color}
                key={status}
                className="!rounded-full h-[40px] leading-[28px] text-center  w-[98px]"
              >
                <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                  {status === "active" || status === "confirmed"
                    ? t("active")
                    : status === "available"
                    ? t("available")
                    : t("ended")}
                </div>
              </Tag>
            );
          },
        },
        priceColumn,
        {
          title: t("edit"),
          dataIndex: "4",
          render: (_, { id, type }) => {
            return (
              <ViewDetails
                handleClick={() => {
                  handleModal(true, id, "guest-dock");
                }}
                text={t("editDetails")}
              />
            );
          },
        },
      ],
    },
    {
      id: 2,
      heading: t("boats"),
      type: "boats",
      buttonText: t("addBoat"),
      addAsset: () => addAsset("boat"),
      viewMore: () => handleNavigate(`/${lang}/assets/boat`),
      columns: [
        {
          title: t("boat") + " " + t("model"),
          dataIndex: "name",
        },

        sizeInFeet,

        horsePowerColumn,
        {
          title: "Status",
          dataIndex: "status",
          render: (_, { status }) => {
            let color =
              status === "active" ||
              status === "available" ||
              status === "confirmed"
                ? "#D9F2DD"
                : "#FFC4B7";
            return (
              <Tag
                color={color}
                className="!rounded-full h-[40px] leading-[28px] text-center  w-[98px]"
              >
                <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                  {status === "available"
                    ? t("available")
                    : status === "active" || status === "confirmed"
                    ? t("active")
                    : t("ended")}
                </div>
              </Tag>
            );
          },
        },
        priceColumn,
        {
          title: t("edit"),
          dataIndex: "4",
          render: (_, { id, type }) => {
            return (
              <ViewDetails
                handleClick={() => {
                  handleModal(true, id, type);
                }}
                text={t("editDetails")}
              />
            );
          },
        },
      ],
    },
    {
      id: 3,
      heading: t("kayak"),
      buttonText: t("addKayak"),
      type: "kayaks",
      addAsset: () => addAsset("kayak"),
      viewMore: () => handleNavigate(`/${lang}/assets/kayak`),
      columns: [
        {
          title: t("singleKayak") + " " + t("model"),
          dataIndex: "name",
        },
        sizeInFeet,
        {
          title: "Status",
          dataIndex: "status",
          render: (_, { status }) => {
            let color =
              status === "active" ||
              status === "available" ||
              status === "confirmed"
                ? "#D9F2DD"
                : "#FFC4B7";
            return (
              <Tag
                color={color}
                className="!rounded-full h-[40px] leading-[28px] text-center  w-[98px]"
              >
                <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                  {status === "available"
                    ? t("available")
                    : status === "active" || status === "confirmed"
                    ? t("active")
                    : t("ended")}
                </div>
              </Tag>
            );
          },
        },
        priceColumn,
        {
          title: t("edit"),
          dataIndex: "4",
          render: (_, { id, type }) => {
            return (
              <ViewDetails
                handleClick={() => {
                  handleModal(true, id, type);
                }}
                text={t("editDetails")}
              />
            );
          },
        },
      ],
    },
    ...(role !== "rental"
      ? [
          {
            id: 4,
            heading: t("seasonalDocks"),
            buttonText: t("addSeasonalDock"),
            type: "seasonalDocks",
            viewMore: () => handleNavigate(`/${lang}/assets/permanent-docks`),
            addAsset: () => handleNavigate(`/${lang}/import/season-dock`),
            columns: [
              {
                title: t("dock"),
                dataIndex: "dock_number",
              },
              pierColumn,
              {
                title: t("name"),
                dataIndex: "customer_name",
              },
              {
                title: t("email"),
                dataIndex: "customer_email",
              },
              {
                title: t("Phonenumber"),
                dataIndex: "customer_contact",
              },
              {
                title: t("boatType"),
                dataIndex: "boat_type",
              },
              priceColumn,
              {
                title: "Status",
                dataIndex: "status",
                render: (_, { status }) => {
                  let color =
                    status === "available" || status === "confirmed"
                      ? "#D9F2DD"
                      : "#FFC4B7";
                  return (
                    <Tag
                      color={color}
                      className="!rounded-full border-none h-[40px] leading-[28px] text-center  w-[98px]"
                    >
                      <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                        {status === "RentedOut"
                          ? "Rented out"
                          : t("unavailable")}
                      </div>
                    </Tag>
                  );
                },
              },

              {
                title: t("details"),
                dataIndex: "4",
                render: (_, { id }) => {
                  return (
                    <ViewDetails handleClick={() => viewDockDetails(id)} />
                  );
                },
              },
            ],
          },
        ]
      : []),

    {
      id: 5,
      heading: t("jetski"),
      buttonText: t("addJetski"),
      type: "jetskis",
      addAsset: () => addAsset("jet_ski"),
      viewMore: () => handleNavigate(`/${lang}/assets/jet_ski`),
      columns: [
        {
          title: t("singleJetski") + " " + t("model"),
          dataIndex: "name",
        },
        sizeInFeet,
        {
          title: "Status",
          dataIndex: "status",
          render: (_, { status }) => {
            let color =
              status === "active" ||
              status === "available" ||
              status === "confirmed"
                ? "#D9F2DD"
                : "#FFC4B7";
            return (
              <Tag
                color={color}
                className="!rounded-full h-[40px] leading-[28px] text-center  w-[98px]"
              >
                <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                  {status === "available"
                    ? t("available")
                    : status === "active" || status === "confirmed"
                    ? t("active")
                    : t("ended")}
                </div>
              </Tag>
            );
          },
        },
        priceColumn,
        {
          title: t("edit"),
          dataIndex: "4",
          render: (_, { id, type }) => {
            return (
              <ViewDetails
                handleClick={() => {
                  handleModal(true, id, type);
                }}
                text={t("editDetails")}
              />
            );
          },
        },
      ],
    },
    {
      id: 6,
      heading: t("BoatTrailer"),
      buttonText: t("add") + " " + t("BoatTrailer"),
      type: "boat-trailer",
      addAsset: () => addAsset("boat_trailer"),
      viewMore: () => handleNavigate(`/${lang}/assets/boat-trailer`),
      columns: [
        {
          title: t("BoatTrailer") + " " + t("model"),
          dataIndex: "name",
        },
        sizeInFeet,
        {
          title: t("capacity"),
          dataIndex: "weight_in_kg",
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (_, { status }) => {
            let color =
              status === "active" ||
              status === "available" ||
              status === "confirmed"
                ? "#D9F2DD"
                : "#FFC4B7";
            return (
              <Tag
                color={color}
                className="!rounded-full h-[40px] leading-[28px] text-center  w-[98px]"
              >
                <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
                  {status === "available"
                    ? t("available")
                    : status === "active" || status === "confirmed"
                    ? t("active")
                    : t("ended")}
                </div>
              </Tag>
            );
          },
        },
        {
          title: t("price"),
          dataIndex: "rate_per_hour",
        },
        {
          title: t("edit"),
          dataIndex: "4",
          render: (_, { id, type }) => {
            return (
              <ViewDetails
                handleClick={() => {
                  handleModal(true, id, type);
                }}
                text={t("editDetails")}
              />
            );
          },
        },
      ],
    },
  ];

  let assetTypeObj =
    location === `/${lang}/assets/guest-docks`
      ? rentalObjects[0]
      : location === `/${lang}/assets/boat`
      ? rentalObjects[1]
      : location === `/${lang}/assets/kayak`
      ? rentalObjects[2]
      : location === `/${lang}/assets/permanent-docks`
      ? rentalObjects[3]
      : location === `/${lang}/assets/jet_ski`
      ? role === "marina"
        ? rentalObjects[4]
        : rentalObjects[3]
      : location === `/${lang}/assets/boat-trailer`
      ? role === "marina"
        ? rentalObjects[5]
        : rentalObjects[4]
      : "";
  const { organizedAssetKeys, setOrganizedAssetKeys } = useLocationStore();
  const orderedTableCardsData = [];
  if (organizedAssetKeys) {
    organizedAssetKeys.forEach((key) => {
      const matchingItem = rentalObjects.find(
        (item) => item.id === Number(key)
      );
      if (matchingItem) {
        orderedTableCardsData.push(matchingItem);
      }
    });
  } else {
    rentalObjects = orderedTableCardsData;
  }

  const typeOfAsset = (type) => {
    const assetData =
      type === "boats"
        ? boat.slice(0, 5)
        : type === "guestDocks"
        ? guestDock
        : type === "seasonalDocks"
        ? seasonDock
        : type === "kayaks"
        ? kayak.slice(0, 5)
        : type === "jetskis"
        ? jet_ski.slice(0, 5)
        : type === "boat-trailer"
        ? boat_trailer?.slice(0, 5)
        : [];

    return assetData?.map((asset) => {
      return resolvedFlattened(asset);
    });
  };

  const handleDragEnd = (result) => {
    onDrag(result, orderedTableCardsData, setOrganizedAssetKeys);
  };
  const { refetch } = Assets;
  const { refetch: refetchTopAssets } = topAssets;

  return {
    type,
    orderedTableCardsData,
    assetTypeObj,
    setOrganizedAssetKeys,
    Assets,
    selectedRowKeys,
    onSelectChange,
    filterAssets,
    total_pages,
    typeOfAsset,
    assetsType,
    t,
    isModalOpen,
    setIsModalOpen,
    handleDragEnd,
    location,
    handleModal,
    form,
    topAssets,
    refetch,
    refetchTopAssets,
    dropDownProps,
    isOpen,
    setIsOpen,
    assetsRefetch,
    searchParams,
    setParams,
    isLoading,
    isFetching,
    error,
    lang,
  };
};
export default GetDataForAssets;
