import { message, Tag } from "antd";
import React from "react";
import { useLocationStore } from "../../store/store";
import useConstants from "./useConstants";
import useAssignDock from "./useAssignDock";
const useGetDataForDockList = ({ refetch, type }) => {
  const { setIsModalOpen } = useLocationStore();
  const { pierColumn, t } = useConstants();

  const { updateDock, isLoading } = useAssignDock({
    type,
    refetch,
    setIsModalOpen,
  });

  const handleSave = (selectedDock, setSelectedDock) => {
    if (selectedDock !== null) {
      setSelectedDock([]);
      updateDock({
        dockId: selectedDock,
      });
      return;
    } else {
      message.error("Please select one dock");
    }
  };

  const dockListColumns = [
    {
      title: t("dock"),
      dataIndex: "id",
    },
    pierColumn,
    {
      title: t("length"),
      dataIndex: "dock_length",
    },
    {
      title: t("width"),
      dataIndex: "dock_width",
      key: "width",
    },
    {
      title: t("currentRenter"),
      dataIndex: "current_renter",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        let color =
          status === "Available"
            ? "#F8F5DA"
            : status === "Active"
            ? "#D9F2DD"
            : "";
        return (
          <Tag
            color={color}
            key={status}
            className="!rounded-full my-1 leading-[28px] text-center  w-[98px]"
          >
            <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
              {status === "Active" ? t("active") : t("available")}
            </div>
          </Tag>
        );
      },
    },
    {
      title: t("boatFits"),
      dataIndex: "boat_fits",
      key: "boat_fits",
      render: (_, { boat_fits }) => {
        return (
          <div className="flex justify-center items-center">
            {boat_fits === "Yes" ? t("yes") : t("no")}
          </div>
        );
      },
    },
  ];

  return {
    dockListColumns,
    handleSave,
    isLoading,
  };
};

export default useGetDataForDockList;
