import React, { useState } from "react";
import EditImageCard from "../SeasonalDockSpots/EditImageCard";
import ButtonDark from "./ButtonDark";
import { UNDEFINED_ID } from "../../constants/constants";
import CropImageModal from "./CropImageModal";
import {
  handleImageChange,
  handleRemoveImageForEditAsset,
} from "../../utils/handleImageChangeAndRemove";
import { useAssetEditStore } from "../../store/useAssetEditStore";

const EditImages = ({
  addImages,
  handleClick,
  t,
  fileInputRef,
  editAsset,
  setAddImages,
  removeImages,
  setRemoveImages,
}) => {
  const [openCropImageModal, setOpenCropImageModal] = useState(false);
  const [image, setImage] = useState(null);
  const { thumbnailImages, setThumbnailImages } = useAssetEditStore();
  return (
    <>
      <CropImageModal
        isOpen={openCropImageModal}
        onCancel={() => {
          setImage(null);
          setOpenCropImageModal(false);
        }}
        image={image}
        setSelectedImages={setAddImages}
        setThumbnailImages={setThumbnailImages}
        selectedImages={addImages}
        thumbnailImages={thumbnailImages}
        type="edit"
      />
      <div
        className={`grid  grid-cols-2 gap-x-[30px] space-x-[30px]  my-[20px]`}
      >
        <div className="">
          <div className="">
            {addImages?.length > 0 && (
              <EditImageCard
                size={"large"}
                src={addImages[0]?.file}
                deleteImage={() => {
                  handleRemoveImageForEditAsset(
                    1,
                    addImages,
                    setAddImages,
                    addImages[0]?.id,
                    thumbnailImages,
                    setThumbnailImages,
                    removeImages,
                    setRemoveImages
                  );
                }}
                id={addImages[0]?.id ?? addImages[0]?.id ?? UNDEFINED_ID}
              />
            )}
          </div>
          <div className="text-center mt-[29px]">
            {!editAsset && (
              <ButtonDark
                styleClasses={"light-Btn  h-[50px] px-[46px]"}
                text={t("uploadNewPictures")}
                handleClick={handleClick}
              />
            )}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) =>
                handleImageChange(
                  e,
                  setImage,
                  setOpenCropImageModal,
                  addImages,
                  t
                )
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-[10px]">
          {addImages?.map((img, index) =>
            index > 0 ? (
              <EditImageCard
                src={img?.file}
                deleteImage={() => {
                  handleRemoveImageForEditAsset(
                    index,
                    addImages,
                    setAddImages,
                    img?.id,
                    thumbnailImages,
                    setThumbnailImages,
                    removeImages,
                    setRemoveImages
                  );
                }}
                key={img?.id}
                id={img?.id ? img?.id : "undefined"}
              />
            ) : null
          )}
        </div>
      </div>
      <div className="w-full flex justify-center">
        {editAsset && (
          <ButtonDark
            styleClasses={"light-Btn  h-[50px]"}
            text={t("uploadNewPictures")}
            handleClick={handleClick}
          />
        )}
      </div>
    </>
  );
};

export default EditImages;
