import React from "react";
import FormInput from "../CommonComponents/FormInput";
import { useTranslation } from "react-i18next";
import Facilities from "../Maps/Facilities";

const EditFacilities = ({ checkedList, setCheckedList }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Facilities
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        t={t}
      />
      <div className="text-dark-blue mt-[40px]">
        <h1 className="text-[16px] font-roobert-rg leading-[28px]">
          {t("fixedPriceElectricity")}
        </h1>
        <div className="grid grid-cols-2 gap-x-[20px] mt-[10px]">
          <FormInput
            name={"electricity_rate_per_hour"}
            step="any"
            disabled={!checkedList.electricity}
            placeHolder={t("rate_per_hour") + "*"}
            styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
            type={"number"}
            rules={[
              {
                required: checkedList.electricity,
                message:
                  t("pleaseEnter") +
                  " " +
                  t("electricity") +
                  " " +
                  t("rate_per_hour") +
                  "!",
              },
            ]}
          />
          <FormInput
            name={"electricity_rate_per_day"}
            placeHolder={t("rate_per_day") + "*"}
            step="any"
            disabled={!checkedList.electricity}
            styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
            type={"number"}
            rules={[
              {
                required: checkedList.electricity,
                message:
                  t("pleaseEnter") +
                  " " +
                  t("electricity") +
                  " " +
                  t("rate_per_day") +
                  "!",
              },
            ]}
          />
        </div>
      </div>
      <div className="text-dark-blue mt-[40px]">
        <h1 className="text-[16px] font-roobert-rg leading-[28px]">
          {t("fixedPriceWater")}
        </h1>
        <div className="grid grid-cols-2 gap-x-[20px] mt-[10px]">
          <FormInput
            name={"water_rate_per_hour"}
            step="any"
            disabled={!checkedList.water}
            placeHolder={t("rate_per_hour") + "*"}
            type={"number"}
            styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
            required={checkedList.water}
            message={
              t("pleaseEnter") +
              " " +
              t("water") +
              " " +
              t("rate_per_hour") +
              "!"
            }
          />
          <FormInput
            name={"water_rate_per_day"}
            step="any"
            placeHolder={t("rate_per_day") + "*"}
            type={"number"}
            styleClasses={"rounded-full font-roobert-rg pl-[20px]"}
            disabled={!checkedList.water}
            required={checkedList.water}
            requiredMessage={
              t("pleaseEnter") +
              " " +
              t("water") +
              " " +
              t("rate_per_day") +
              "!"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default EditFacilities;
