import React from "react";

const Step3IconLight = () => {
  return (
    <svg
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="z-10"
    >
      <circle cx="23" cy="22.5" r="22" stroke="#A5C5CC" />
      <path
        d="M22.9443 28.628C20.7043 28.628 18.9443 27.172 18.9443 24.98H20.2883C20.2883 26.436 21.4403 27.396 22.9443 27.396C24.5123 27.396 25.5043 26.644 25.5043 25.316C25.5043 24.004 24.5123 23.3 22.9443 23.3H22.0483V22.1H22.9443C24.2723 22.1 25.0723 21.284 25.0723 20.164C25.0723 19.06 24.2083 18.404 22.8643 18.404C21.5843 18.404 20.7523 19.156 20.6723 20.484H19.3443C19.4243 18.452 20.8803 17.172 22.8803 17.172C24.9603 17.172 26.4483 18.404 26.4483 20.116C26.4483 21.252 25.7443 22.212 24.8323 22.612C26.0163 22.996 26.9123 23.988 26.9123 25.332C26.9123 27.476 25.0563 28.628 22.9443 28.628Z"
        fill="#A5C5CC"
      />
    </svg>
  );
};

export default Step3IconLight;
