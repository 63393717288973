import React from "react";

const MembersOverview = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M8.30398 4.2168C8.16232 4.21677 8.02308 4.25369 7.89983 4.32396C7.77658 4.39423 7.67352 4.49545 7.60071 4.61775L2.68277 12.8677C2.59547 13.0145 2.55567 13.185 2.56889 13.3556C2.5821 13.5262 2.64768 13.6884 2.75654 13.8198L15.0416 28.6582C15.1316 28.7712 15.2497 28.8583 15.3838 28.9108C15.5179 28.9632 15.6634 28.9792 15.8057 28.9572C15.9479 28.9351 16.0818 28.8757 16.194 28.785C16.3062 28.6943 16.3927 28.5755 16.4448 28.4404L21.9791 14.1168H28.3593L28.6052 13.8198C28.7141 13.6884 28.7797 13.5262 28.7929 13.3556C28.8061 13.185 28.7663 13.0145 28.679 12.8677L23.7611 4.61775C23.6883 4.49545 23.5852 4.39423 23.4619 4.32396C23.3387 4.25369 23.1995 4.21677 23.0578 4.2168H8.30398ZM20.2218 14.1168L15.6809 25.8648L11.1416 14.1168H20.2201H20.2218ZM4.83191 12.4668L8.76626 5.8668H12.0105L9.38756 12.4668H4.83191ZM9.38101 14.1168L13.0973 23.7346L5.13682 14.1168H9.38101ZM11.1515 12.4668L13.7744 5.8668H17.5825L20.2054 12.4668H11.1564H11.1515ZM21.9709 12.4668L19.348 5.8668H22.5922L26.5266 12.4668H21.9709ZM24.6955 22.3668C25.565 22.3668 26.399 22.0191 27.0138 21.4002C27.6287 20.7814 27.9741 19.942 27.9741 19.0668C27.9741 18.1916 27.6287 17.3522 27.0138 16.7333C26.399 16.1145 25.565 15.7668 24.6955 15.7668C23.8259 15.7668 22.992 16.1145 22.3771 16.7333C21.7623 17.3522 21.4168 18.1916 21.4168 19.0668C21.4168 19.942 21.7623 20.7814 22.3771 21.4002C22.992 22.0191 23.8259 22.3668 24.6955 22.3668ZM24.6955 30.6168C28.7938 30.6168 30.4331 28.546 30.4331 26.4918C30.4331 25.8354 30.174 25.2059 29.7129 24.7417C29.2517 24.2776 28.6263 24.0168 27.9741 24.0168H21.4168C20.7647 24.0168 20.1392 24.2776 19.6781 24.7417C19.2169 25.2059 18.9579 25.8354 18.9579 26.4918C18.9579 28.5543 20.5972 30.6168 24.6955 30.6168Z"
        fill="white"
      />
    </svg>
  );
};

export default MembersOverview;
