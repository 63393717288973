import React, { useState } from "react";
import Layout from "../Layout/Layout";
import Table from "../components/CommonComponents/Table/Table";
import useGetDataForWaitingMembers from "../hooks/getData/useGetDataForWaitingMembers";
import PageHeaders from "../components/CommonComponents/PageHeaders";
import ButtonDark from "../components/CommonComponents/ButtonDark";
import IconBtn from "../components/CommonComponents/IconBtn";
import CancelCircle from "../assets/icons/CancelCircle";
import DocksList from "../components/CommonComponents/DocksList";
import ApprovePopup from "../components/CommonComponents/ApprovePopup";
import SearchBar from "../components/CommonComponents/SearchBar";
import SearchButton from "../components/CommonComponents/SearchButton";
import { handleSearchChange, handleSearchClick } from "../utils/handleSearch";
import Dialog from "../components/CommonComponents/Modal/Dialog";
import { selectOption } from "../constants/waitingListCostants";
import {
  assignUserFromClubMemberListOrWaitlist,
  handleCloseModal,
} from "../constants/clubMemberConstants";

const WaitingList = () => {
  const {
    columns,
    data,
    filterData,
    selectedRowKeys,
    onSelectChange,
    filterDocksData,
    docksLoading,
    isModalOpen,
    setIsModalOpen,
    handleOptionsChange,
    deleteUser,
    assign,
    seasonalDockId,
    updateDock,
    assignName,
    refetch,
    t,
    assignDockModal,
    handleAssignDockModal,
    isFetching,
    isLoading,
    error,
    lang,
    navigate,
    searchParams,
    setSearchParams,
    updateDockLoading,
    paramsObject,
  } = useGetDataForWaitingMembers();
  const [searchValue, setSearchValue] = useState("");

  const showAssignDock = () => {
    if (selectedRowKeys.length === 1 && !assign) {
      setIsModalOpen(true);
      const paramsObject = Object.fromEntries(searchParams);
      setSearchParams({ ...paramsObject, id: selectedRowKeys[0] });
    }
    if (selectedRowKeys.length === 1 && assign) {
      updateDock({
        dockId: [seasonalDockId],
        waitListId: selectedRowKeys[0],
      });
    }
  };

  return (
    <Layout page={t("Waitlist")}>
      <Dialog
        isModalOpen={assignDockModal}
        onCancelModal={() => handleAssignDockModal(false)}
        component={
          <ApprovePopup
            onApprove={() =>
              assignUserFromClubMemberListOrWaitlist(updateDock, searchParams)
            }
            handleCloseModal={() => handleAssignDockModal(false)}
            isLoading={updateDockLoading}
          />
        }
      />
      <Dialog
        isModalOpen={isModalOpen}
        onCancelModal={() =>
          handleCloseModal(setIsModalOpen, setSearchParams, paramsObject)
        }
        component={
          <DocksList
            data={filterDocksData}
            handleCloseModal={handleCloseModal}
            docksLoading={docksLoading}
            assignName={assignName}
            refetch={refetch}
            t={t}
          />
        }
      />
      <div className="px-[25px] waitingList pb-[40px]">
        <PageHeaders
          heading={t("Waitlist") + " " + t("seasonalDocks")}
          exportData={true}
          importData={true}
          dropDown={true}
          importUrl={`/${lang}/import/waiting-list`}
          exportUrl={`/${lang}/export/waiting-list`}
          dropDownProps={{
            options: selectOption,
            defaultValue: "",
            handleChange: handleOptionsChange,
            // styleClasses:"!text-dark-blue   !px-[21px]  !text-[22px] h-[40px]",
          }}
        />
        <div className="  mt-[20px] bg-[#FFFFFF] pb-[40px] rounded-[40px] my-table">
          <div className="w-full pb-[40px]  flex pt-[30px] items-center justify-between gap-x-[24px] pr-[30px]">
            <div className="flex gap-x-[10px] pl-[45px]">
              <SearchBar
                value={searchValue}
                placeholder={t("search") + " " + t("Waitlist")}
                handleSearchChange={(e) =>
                  handleSearchChange(
                    e,
                    setSearchValue,
                    searchParams,
                    setSearchParams
                  )
                }
                handleSubmit={() =>
                  handleSearchClick(searchValue, setSearchParams, searchParams)
                }
              />
              <SearchButton
                text={t("search")}
                handleSearchClick={() =>
                  handleSearchClick(searchValue, setSearchParams, searchParams)
                }
              />
            </div>
            <div className="flex gap-x-[20px]">
              {selectedRowKeys.length >= 1 && (
                <IconBtn
                  icon={<CancelCircle />}
                  styleClasses={"bg-[#FFC4B7]   !h-[40px]  border-none"}
                  text={t("deleteUser")}
                  handleClick={deleteUser}
                />
              )}
              <ButtonDark
                styleClasses={`  ${
                  selectedRowKeys.length === 1 ? "light-Btn" : "bg-[#DEDEDE]"
                }  !text-dark-blue  px-[21px]   h-[40px]`}
                text={t("assignDock")}
                handleClick={showAssignDock}
              />
              <ButtonDark
                styleClasses={
                  "  light-Btn  !text-dark-blue   px-[21px]  h-[40px]"
                }
                handleClick={() => {
                  navigate(`/${lang}/import/waiting-list`);
                }}
                text={t("addNewCustomer")}
              />
            </div>
          </div>

          <Table
            columns={columns}
            selection={true}
            data={filterData}
            onSelectChange={onSelectChange}
            selectedRowKeys={selectedRowKeys}
            pagination={true}
            pageSize={20}
            totalPages={data?.total_pages * 20}
            loading={isFetching || isLoading}
            error={error}
          />
        </div>
      </div>
    </Layout>
  );
};

export default WaitingList;
