import React from "react";

const Label = ({ text, styleClasses }) => {
  return (
    <div
      className={`text-[14px] whitespace-nowrap ${styleClasses} mb-[10px] text-dark-blue font-roobert-rg leading-[19px]`}
    >
      {text}
    </div>
  );
};

export default Label;
