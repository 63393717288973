import React, { useState } from "react";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
import { message } from "antd";
import { auth } from "../../config/firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import useMutationHook from "../useMutationHook";
import FirstStep from "../../components/Signup/FirstStep";
import SecondStep from "../../components/Signup/SecondStep";
import ThirdStep from "../../components/Signup/ThirdStep";
import { getSubscriptionInfo, signup } from "../../API/marina";
import { useSignupStore } from "../../store/store";
import { TABS } from "../../constants/constants";
import { setLocalStorageItems } from "../../utils/setLocalStorageItems";

const useGetDataForSignup = () => {
  const { params, setParams, t, lang, countryOptions, navigate } =
    useUserDataAndNavigation();
  const { addSubscriptionData, addSignupValues, signupValues } =
    useSignupStore();
  const {
    country_name,
    billing_type,
    number_of_dock_or_rental,
    number_of_guest_dock,
  } = signupValues;
  const [country, setCountry] = useState(country_name || null);
  const [billing, setBilling] = useState(billing_type || "email");
  const [activeNum, setActiveNum] = useState(signupValues?.code || null);
  const { marina, rental } = TABS;
  const paramsObject = Object.fromEntries(params);
  const activeTab = params.get("active") ? params.get("active") : marina;
  const current = params.get("current") ? params.get("current") : 0;
  const handleTab = (key) => {
    const paramsObject = Object.fromEntries(params);
    const active = key === marina ? marina : rental;
    addSignupValues({});
    setParams({ ...paramsObject, active, current: 0 });
  };

  const steps = [
    {
      content: <FirstStep activeProgress={activeTab} />,
    },
    {
      content: <SecondStep />,
    },
    {
      content: <ThirdStep activeProgress={activeTab} />,
    },
  ];
  const initialValues = signupValues;
  const onSuccess = (response) => {
    const { data } = response.data;
    const { token, organization, role, id } = data;
    setLocalStorageItems({
      auth: token,
      organizationName: organization,
      role,
      userId: id,
    });
    addSignupValues({});
    navigate(`/${lang}/`);
  };
  const onError = (error) => {
    const { data } = error.response;
    message.error(data.message);
    if (current === 1) {
      setParams({
        ...paramsObject,
        current: 2,
      });
    }
  };
  const onSuccessSubscription = (response) => {
    const { data } = response.data;
    addSubscriptionData(data);
    setParams({
      ...paramsObject,
      current: 2,
    });
  };
  const { mutate } = useMutationHook(
    ["sign-up"],
    async (data) => {
      return await signup(data);
    },
    onSuccess,
    onError
  );

  const { mutate: getSubscription } = useMutationHook(
    ["get-subscription"],
    async (data) => {
      return await getSubscriptionInfo(data);
    },
    onSuccessSubscription,
    onError
  );

  const finishStepOne = (values) => {
    addSignupValues(values);
    setParams({
      ...paramsObject,
      current: Number(params.get("current")) + 1,
    });
  };
  const finishStepTwo = (values) => {
    getSubscription({
      company_type: activeTab,
      number_of_dock_or_rental: number_of_dock_or_rental,
      number_of_guest_dock: number_of_guest_dock,
    });
    const newValues = {
      ...signupValues,
      ...values,
      phone: values.phone,
      code: activeNum ?? lang === "se" ? `+46` : lang === "de" ? `+49` : `+47`,
      billing_type: billing,
      country_name:
        country ?? lang === "se"
          ? `Sweden`
          : lang === "de"
          ? `Germany`
          : "Norway",
    };
    addSignupValues(newValues);
  };
  const onFinish = async (values) => {
    try {
      const newValues = {
        ...signupValues,
        phone: signupValues?.code + signupValues?.phone,
        ...values,
      };
      const { password, email, confirmPassword, ...filteredData } = newValues;
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { uid } = userCredentials.user;
      mutate({
        ...filteredData,
        email: email,
        firebase_id: uid,
        company_type: params.get("active") ? params.get("active") : marina,
      });
    } catch (error) {
      const { code, message: response } = error;
      if (code === "auth/weak-password") {
        message.error(
          "Password is too weak. Please choose a stronger password."
        );
      } else {
        message.error(response);
      }
    }
  };

  const changeCountry = (value) => {
    setCountry(value);
  };
  const changeBilling = (value) => {
    setBilling(value);
  };
  const options = [
    {
      label: t("email"),
      value: "email",
    },
    {
      label: "EHF",
      value: "ehf",
    },
  ];

  return {
    activeNum,
    setActiveNum,
    params,
    setParams,
    t,
    handleTab,
    onFinish,
    paramsObject,
    steps,
    initialValues,
    finishStepOne,
    finishStepTwo,
    countryOptions,
    options,
    changeBilling,
    changeCountry,
    billing,
    activeTab,
    current,
    lang,
    country_name,
  };
};

export default useGetDataForSignup;
