import { create } from "zustand";

const useChatsStore = create((set) => ({
  isChatExisted: true,
  rentalDetails: {},
  setRentalDetails: (data) => set({ rentalDetails: data }),
  setIsChatExisted: (bool) => set({ isChatExisted: bool }),
  notExistedChat: [],
  setNotExistedChat: (data) => set({ notExistedChat: data }),
}));

export default useChatsStore;
