import React from "react";
import BankIconLarge from "../../assets/icons/BankIconLarge";
import ButtonDark from "../CommonComponents/ButtonDark";

const GetStartedPayout = ({ t, handleCreateAccount, mutateLoading }) => {
  return (
    <div className="bg-[#FFF]   mt-[20px]  text-dark-blue shadow-custom-lg z-20 w-full   overflow-y-auto py-[20px] rounded-[20px]">
      <h1 className="text-[28px] 2xl:text-[35px] font-roobert-rg px-[50px] text-center 2xl:mt-[40px] leading-[50px]">
        {t("connectToBank")}
      </h1>
      <div className="flex justify-center my-[10px]">
        <BankIconLarge />
      </div>
      <div className="flex justify-center  flex-col items-center">
        <p className="text-center font-roobert-rg text-[22px]">
          {t("connectToBank1")}
        </p>
        <ButtonDark
          text={t("Getstarted")}
          styleClasses={"dark-Btn mt-[20px] h-[50px]"}
          handleClick={handleCreateAccount}
          loading={mutateLoading}
        />
      </div>
    </div>
  );
};

export default GetStartedPayout;
