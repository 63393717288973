import React from "react";
import useQueryHook from "../useQueryHook";
import { useSearchParams } from "react-router-dom";
import { fetchDocks } from "../../API/marina";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";

const useFetchDocksList = ({ isModalOpen, type }) => {
  const searchParams = useSearchParams()[0];
  const { userId } = useUserDataAndNavigation();
  const { isLoading, data, error, refetch, isFetching } = useQueryHook(
    ["fetch-docks-list", searchParams.get("id")],
    async () => {
      return await fetchDocks(userId, searchParams.get("id"), type);
    },
    isModalOpen
  );
  const filterDocksData = data?.map((dock) => {
    return { key: dock.id, ...dock };
  });
  return {
    filterDocksData,
    isLoading,
    data,
    error,
    refetch,
    isFetching,
  };
};

export default useFetchDocksList;
