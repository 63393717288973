import { Form } from "antd";
import React from "react";
import Label from "../CommonComponents/Label";
import FormInput from "../CommonComponents/FormInput";
import ButtonDark from "../CommonComponents/ButtonDark";
import useGetDataForSignup from "../../hooks/getData/useGetDataForSignup";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";
import { URLS } from "../../constants/constants";

const AddPassword = () => {
  const { onFinish } = useGetDataForSignup();
  const { lang, t } = useUserDataAndNavigation();
  const link = URLS[lang] || URLS.en;
  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      className="w-[70%] max-xl:w-full h-full flex flex-col   mt-[60px]"
    >
      <div className="">
        <Label text={t("password") + "*"} />
        <FormInput
          placeHolder={"*************"}
          name={"password"}
          type={"password"}
          rules={[
            {
              required: true,
              message: t("enterPassword"),
            },
            {
              min: 8,
              message: t("passwordLimit"),
            },
          ]}
        />
      </div>
      <div className="">
        <Label text={t("confirmPassword") + "*"} />
        <FormInput
          placeHolder={"*************"}
          name={"confirmPassword"}
          type={"password"}
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: t("enterConfirmPassword"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("passwordMatch")));
              },
            }),
          ]}
        />
      </div>
      <ButtonDark
        styleClasses={"dark-Btn h-[50px] mt-[40px] xl:w-[75%] "}
        text={`${t("Getstarted")} 🎉`}
        type={"submit"}
      />
      <p className="text-[16px] w-full text-center mt-[20px] font-roobert-rg text-dark-blue">
        {t("signupaggreement")} &nbsp;
        <a href={link} target="_" className="underline">
          {t("terms&condition")}
        </a>
      </p>
    </Form>
  );
};

export default AddPassword;
