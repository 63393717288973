import { Form } from "antd";
import React from "react";
import SDSDetailsHeader from "../../components/SeasonalDockSpots/SDSDetailsHeader";
import FormInput from "../CommonComponents/FormInput";
import Label from "../CommonComponents/Label";
import { useTranslation } from "react-i18next";
import ButtonDark from "../CommonComponents/ButtonDark";
const AddUserModal = ({
  onFinish,
  handleClose,
  addUserLoading,
  addUserFetching,
  type,
  handleDelete,
  deleteUserFetching,
  deleteUserLoading,
}) => {
  const { t } = useTranslation();
  return (
    <div className="p-[10px] px-[20px] h-[50vh] rounded-[10px] bg-[#E2F3F7]">
      {type === "delete" && (
        <>
          <SDSDetailsHeader handleClose={() => handleClose(false)} />
          <div className="h-full w-full flex justify-center items-center">
            <div className="">
              <h1 className="text-center text-[28px] 2xl:text-[35px] text-dark-blue">
                {t("sureToDeleteUser")}?
              </h1>
              <div className="text-center mt-[20px] flex gap-x-[10px] justify-center items-center">
                <ButtonDark
                  text={t("yes")}
                  styleClasses={"light-Btn h-[40px]  w-[100px]"}
                  handleClick={handleDelete}
                  loading={deleteUserFetching || deleteUserLoading}
                />
                <ButtonDark
                  text={t("no")}
                  styleClasses={"white-Btn h-[40px] w-[100px]"}
                  handleClick={() => handleClose(false)}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {type !== "delete" && (
        <Form name="basic" onFinish={onFinish} autoComplete="off">
          <SDSDetailsHeader
            handleClose={() => handleClose(false)}
            edit={true}
            addRenter={true}
            isLoading={addUserFetching}
            isFetching={addUserLoading}
            onDone={onFinish}
          />

          <div className="grid grid-cols-2 gap-x-3 mt-[50px]  ">
            <div className="">
              <Label styleClasses={"!text-[20px]"} text={t("name")} />
              <FormInput
                name={"user_name"}
                placeHolder="Enter name"
                type={"text"}
                styleClasses={"!rounded-full"}
              />
            </div>
            <div className="">
              <Label styleClasses={"!text-[20px]"} text={t("Phonenumber")} />
              <FormInput
                name={"user_phone"}
                placeHolder="Enter phone#"
                type={"number"}
                styleClasses={"!rounded-full"}
              />
            </div>

            <div className="">
              <Label styleClasses={"!text-[20px]"} text={t("email")} />
              <FormInput
                placeHolder="Enter email"
                name={"user_email"}
                type={"email"}
                styleClasses={"!rounded-full"}
              />
            </div>
            <div className="">
              <Label styleClasses={"!text-[20px]"} text={t("role")} />
              <FormInput
                placeHolder="Enter Role"
                name={"user_role"}
                type={"text"}
                styleClasses={"!rounded-full"}
              />
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default AddUserModal;
