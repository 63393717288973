import React, { useState } from "react";
import CancelCircle from "../../assets/icons/CancelCircle";
import { Large } from "../../constants/constants";

const EditImageCard = ({ src, size, deleteImage, id }) => {
  const [showEdit, setShowEdit] = useState(false);
  const showEditOption = (bool) => {
    setShowEdit(bool);
  };

  const changeEditImageSize =
    size === Large ? "h-[210px] w-[380px]" : "h-[112px] w-[150px]";
  return (
    <div
      onMouseEnter={() => showEditOption(true)}
      onMouseLeave={() => showEditOption(false)}
      className={`${changeEditImageSize} relative`}
    >
      <img
        src={src}
        className={` ${
          size === "large" ? "h-[210px] w-[380px]" : "h-[112px] w-[150px]"
        }  rounded-[10px] shadow-img  opacity-[0.95]   `}
        alt=""
      />
      {showEdit && (
        <div
          className={`${
            size === "large" ? "h-[210px] w-[380px]" : "h-[112px] w-[150px]"
          } flex justify-end absolute rounded-[10px] pt-2 pr-3 shadow-img top-0  bg-slate-400 opacity-50`}
        >
          <div onClick={() => deleteImage(id)} className="cursor-pointer">
            <CancelCircle />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditImageCard;
