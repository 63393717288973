import { useTranslation } from "react-i18next";
import { useQueries } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
  fetchRentalGraph,
  financeGraphData,
  getPercentageOfDocks,
} from "../../API/marina";
import { useUserDataAndNavigation } from "../useUserDataAndNavigation";

const useGetDataForReports = () => {
  const {
    t,
    userId,
    params: searchParams,
    setParams: setSearchParams,
    role,
  } = useUserDataAndNavigation();
  let revenueType = searchParams.get("revenue")
    ? searchParams.get("revenue")
    : "monthly";

  let rentalType = searchParams.get("rentals")
    ? searchParams.get("rentals")
    : "monthly";
  const handleRevenueChange = (value) => {
    const paramsObject = Object.fromEntries(searchParams);
    setSearchParams({ ...paramsObject, revenue: value });
  };
  const [financeGraph, rentalsGraph, PercentageOfDocks] = useQueries([
    {
      queryKey: ["fetchFinanceGraphData", searchParams.get("revenue")],
      queryFn: async () => {
        return await financeGraphData(userId, revenueType);
      },
    },
    {
      queryKey: ["fetchRentalsGraphData"],
      queryFn: async () => {
        return await fetchRentalGraph(userId, "yearly");
      },
    },
    {
      queryKey: ["get-percentage-of-docks"],
      queryFn: async () => {
        return await getPercentageOfDocks(userId);
      },
      enabled: role === "marina",
    },
  ]);
  const { data: percentageData } = PercentageOfDocks ?? { data: [] };

  const filterFinanceGraph = (option) => {
    const paramsObject = Object.fromEntries(searchParams);
    option === "Yearly" || option === "Årlig"
      ? setSearchParams({ ...paramsObject, revenue: "yearly" })
      : setSearchParams({ ...paramsObject, revenue: "monthly" });
  };

  const filterBarChart = (option) => {
    const paramsObject = Object.fromEntries(searchParams);
    option === "Yearly" || option === "Årlig"
      ? setSearchParams({ ...paramsObject, rentals: "yearly" })
      : setSearchParams({ ...paramsObject, rentals: "monthly" });
  };

  return {
    financeGraph,
    rentalsGraph,
    handleRevenueChange,
    searchParams,
    setSearchParams,
    filterBarChart,
    filterFinanceGraph,
    revenueType,
    rentalType,
    t,
    percentageData,
    role,
  };
};

export default useGetDataForReports;
