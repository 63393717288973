import React from "react";

const NorwayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
    >
      <g clipPath="url(#clip0_2020_65907)">
        <path
          d="M7.72222 3.61133H3.38889C2.62271 3.61133 1.88791 3.91569 1.34614 4.45746C0.804364 4.99924 0.5 5.73404 0.5 6.50022L0.5 10.8336H7.72222V3.61133ZM23.6111 3.61133H12.0556V10.8336H26.5V6.50022C26.5 5.73404 26.1956 4.99924 25.6539 4.45746C25.1121 3.91569 24.3773 3.61133 23.6111 3.61133ZM7.72222 22.3891H3.38889C2.62271 22.3891 1.88791 22.0847 1.34614 21.543C0.804364 21.0012 0.5 20.2664 0.5 19.5002V15.1669H7.72222V22.3891ZM23.6111 22.3891H12.0556V15.1669H26.5V19.5002C26.5 20.2664 26.1956 21.0012 25.6539 21.543C25.1121 22.0847 24.3773 22.3891 23.6111 22.3891Z"
          fill="#EF2B2D"
        />
        <path
          d="M10.9722 3.61133H8.846L8.82794 11.9169H0.5V14.0836H8.82361L8.80556 22.3891H10.9722V14.0836H26.5V11.9169H10.9722V3.61133Z"
          fill="#002868"
        />
        <path
          d="M10.9722 22.3891H12.0556V15.1669H26.5V14.0836H10.9722V22.3891ZM12.0556 3.61133H10.9722V11.9169H26.5V10.8336H12.0556V3.61133ZM8.80556 3.61133H7.72222V10.8336H0.5V11.9169H8.80556V3.61133ZM0.5 14.0836V15.1669H7.72222V22.3891H8.80556V14.0836H0.5Z"
          fill="#EEEEEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2020_65907">
          <rect
            width="26"
            height="26"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NorwayIcon;
