import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { useState } from "react";
import ButtonDark from "../CommonComponents/ButtonDark";
import { useJsApiLoader } from "@react-google-maps/api";
import { placesLibrary } from "../../constants/constants";
import { useLocation } from "react-router-dom";

const SearchLocation = ({
  panTo,
  notGuestDock,
  isGuestDock,
  acceptLocation,
  acceptDocksLocation,
  t,
  newPosition,
  setActive,
}) => {
  const [newAddress, setNewAddress] = useState("");
  const { isLoaded: isGoogleMapsLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
    libraries: placesLibrary,
  });
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
    },
  });

  const handleInput = (e) => {
    const { value } = e.target;
    setNewAddress(value);
    setValue(value);
  };
  const setSearchValue = newAddress.length >= 1 ? newAddress : value;
  const handleSelect = async (address) => {
    clearSuggestions();
    try {
      setNewAddress(address);
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };
  const { pathname } = useLocation();

  return (
    <>
      {isGoogleMapsLoaded && (
        <div
          className={
            pathname.split("/")[2] === "add-asset"
              ? "add-asset-search flex items-center"
              : "search"
          }
        >
          <Combobox onSelect={handleSelect}>
            <ComboboxInput
              value={setSearchValue}
              className="-z-10 relative"
              onChange={handleInput}
              placeholder={t("selectLocation")}
            />
            <ComboboxPopover>
              <ComboboxList>
                {status === "OK" &&
                  data.map(({ id, description }) => (
                    <ComboboxOption key={id} value={description} />
                  ))}
              </ComboboxList>
            </ComboboxPopover>
          </Combobox>
          {notGuestDock && (
            <div className="absolute  right-[5%] top-[70px]">
              <ButtonDark
                text={t("approveLocation")}
                styleClasses={"dark-Btn h-[50px] z-10"}
                handleClick={() => acceptLocation(newPosition)}
              />
            </div>
          )}
          {isGuestDock && (
            <div className="absolute right-[5%] z-1  top-[70px]">
              <ButtonDark
                text={t("approveLocation")}
                styleClasses={"light-Btn h-[50px] z-10"}
                handleClick={() => acceptDocksLocation(newPosition, setActive)}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default SearchLocation;
