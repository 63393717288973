import React from "react";
import { useTranslation } from "react-i18next";
const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    // Customize the tooltip content here
    return (
      <div className=" bg-[#ffffff] p-[20px]  border shadow-lg">
        <p className="text-[12px] leading-[12px]">{`${t(
          "period"
        )}: ${label}`}</p>
        <p className="text-[12px] leading-[12px] mt-[5px]">{`${t("average")}: ${
          payload[0].value
        }`}</p>
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
