import React from "react";
import Modal from "react-modal";
import AlertCircle from "../assets/icons/AlertCircle";
import { useTranslation } from "react-i18next";

const LogoutModal = ({ isOpen, onRequestClose, onLogout }) => {
  const { t } = useTranslation();

  return (
    <Modal
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Logout Modal"
      className="w-[300px] lg:w-[35%]  outline-none relative top-[25%] z-20 bg-white text-dark-blue border-t border-slate-200  drop-shadow-md rounded-lg  m-auto"
    >
      <div className=" bg-white rounded-lg py-8 flex flex-col items-center justify-center">
        <AlertCircle />
        <h2 className="font-carter my-4 text-[22px] tracking-wide">
          {t("confirmlogout")} !
        </h2>
        <p className=" mb-6 text-[16px] text-[#6DAAC6]">
          {t("logoutquestion")}
        </p>
        <div className="flex item-center justify-end text-[16px] gap-x-10">
          <button
            className=" hover:bg-gray-300 text-dark-blue font-semibold px-8 py-2 rounded-lg "
            onClick={onRequestClose}
          >
            {t("cancel")}
          </button>
          <button
            className="bg-[#BC3535] hover:bg-red-600 text-white px-8 py-2 rounded-lg"
            onClick={onLogout}
          >
            {t("logout")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
