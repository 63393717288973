import React from "react";
import ArrowNextCircle from "../../assets/icons/ArrowNextCircle";

const PayoutCard = ({
  payouts,
  styleClasses,
  text,
  amount,
  type,
  handleClickPayout,
}) => {
  return (
    <div
      onClick={() => handleClickPayout(type, true)}
      className={`${styleClasses} font-roobert-rg cursor-pointer rounded-[20px] pt-[10px] text-[#FFFFFF] 2xl:pb-[54px] pb-[30px] pl-[25px] pr-[18px]`}
    >
      <div className="w-full flex justify-end   items-center">
        <ArrowNextCircle />
      </div>
      <h3 className="text-[22px] 2xl:text-[24px]  leading-[31px]">{text}</h3>
      <h1 className="text-[28px] mt-[12px] 2xl:text-[35px] leading-[50px] ">
        {amount ?? ""} {payouts?.currency ?? ""}
      </h1>
    </div>
  );
};

export default PayoutCard;
