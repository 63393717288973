import React from "react";

const Step1Icon = () => {
  return (
    <svg
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="22.5" r="22.5" fill="#ACDCE7" />
      <path
        d="M24.5778 17.3V28.5H23.2178V19.636C23.0418 19.892 22.6418 20.02 22.2098 20.02H21.1378V18.788H22.0498C22.7538 18.788 23.1538 18.484 23.2338 17.3H24.5778Z"
        fill="black"
      />
    </svg>
  );
};

export default Step1Icon;
