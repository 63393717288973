import Table from "../CommonComponents/Table/Table";
import React from "react";
import ViewDetails from "./ViewDetails";
import { useTranslation } from "react-i18next";
import ButtonDark from "./ButtonDark";
import { Tag } from "antd";
const PreviousInvoices = ({ padding }) => {
  const { t } = useTranslation();
  const invoiceColumns = [
    {
      title: t("date"),
      dataIndex: "date",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: t("invoiceNumber"),
      dataIndex: "invoicenumber",
    },
    {
      title: t("amount"),
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // width: '25%',
      render: (_, { status }) => {
        return (
          <Tag
            color={status === "paid" ? "#D9F2DD" : "#FFC4B7"}
            key={status}
            className="!rounded-full my-1 leading-[28px] text-center  w-[98px]"
          >
            <div className="text-[16px] h-full flex items-center justify-center  font-roobert-rg text-dark-blue ">
              {status}
            </div>
          </Tag>
        );
      },
    },
    {
      title: t("details"),
      dataIndex: "4",
      // width: '25%',
      render: (_, { id }) => {
        return <ViewDetails />;
      },
    },
  ];

  const invoicesData = [
    {
      key: 1,
      id: 1,
      date: "01.02.23",
      type: "Håkon Mathiesen",
      invoicenumber: "202",
      amount: "609 kr",
      status: "paid",
    },
    {
      key: 2,
      id: 2,
      date: "01.02.23",
      type: "Håkon Mathiesen",
      invoicenumber: "202",
      amount: "609 kr",
      status: "unpaid",
    },
  ];
  return (
    <div
      className={`bg-[#FFFFFF] mt-[40px]  2xl:mt-[50px] pt-[32px] w-[100%]  rounded-t-[30px] ${
        padding ? "xl:p-[30px]" : ""
      }`}
    >
      <div className="flex justify-between my-[20px] items-center">
        <h1 className=" text-[22px] leading-[31px] font-roobert-rg text-dark-blue">
          {t("previousInvoices")}
        </h1>
        <div className="flex items-center gap-x-[20px]">
          <ButtonDark text={t("import")} styleClasses={"dark-Btn h-[40px]"} />
          <ButtonDark text={t("export")} styleClasses={"dark-Btn h-[40px]"} />
        </div>
      </div>
      <div className="pb-[30px] my-table ">
        <Table columns={invoiceColumns} data={invoicesData} selection={true} />
      </div>
    </div>
  );
};

export default PreviousInvoices;
