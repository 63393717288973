import React from "react";

const Step2Icon = () => {
  return (
    <svg
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="22.5" r="22.5" fill="#ACDCE7" />
      <path
        d="M19.5314 20.932C19.4514 18.644 20.9234 17.172 23.2274 17.172C25.3394 17.172 26.8594 18.484 26.8594 20.516C26.8594 21.972 25.9634 23.204 24.2514 24.052L22.6514 24.868C21.7394 25.332 20.7154 26.18 20.7154 27.268H26.9234V28.5H19.3874V27.524C19.3874 25.524 20.9554 24.292 22.1074 23.716L23.8194 22.836C25.0194 22.228 25.4514 21.46 25.4514 20.484C25.4514 19.204 24.5074 18.372 23.2274 18.372C21.7874 18.372 20.8754 19.396 20.9394 20.932H19.5314Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default Step2Icon;
