// import { handleCrop } from "@/utils/addAssetsUtils";
import { Modal } from "antd";
import { useState } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import ButtonDark from "./ButtonDark";
import useConstants from "../../hooks/getData/useConstants";
import { handleCrop } from "../../utils/addAssetUtils";

const CropImageModal = ({
  isOpen,
  onCancel,
  image,
  setSelectedImages,
  setThumbnailImages,
  selectedImages,
  thumbnailImages,
  type = "add",
}) => {
  const [crop, setCrop] = useState({ x: 50, y: 4 });
  const [zoom, setZoom] = useState(1);
  const { userId } = useConstants();

  const [croppedArea, setCroppedArea] = useState();
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const { t } = useTranslation();
  return (
    <Modal
      styles={{
        content: {
          height: "100vh",
          position: "relative",
        },
      }}
      open={isOpen}
      footer={false}
      centered
      onCancel={onCancel}
    >
      <div
        onClick={onCancel}
        className="w-9 h-9 cursor-pointer absolute right-3 top-3 border-2 border-black rounded-full flex justify-center items-center
      "
      >
        X
      </div>
      <div className="h-[90vh] flex items-center flex-col justify-center overflow-hidden w-full ">
        <h3 className=" font-roobert text-kprimary text-[18px] mt-5">
          {t("cropImageText1")}
        </h3>
        <div className=" h-[50vh]  mt-10 w-full  relative">
          <Cropper
            image={image?.file}
            crop={crop}
            aspect={8 / 2}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            showGrid={false}
            zoom={zoom}
            onZoomChange={setZoom}
            cropSize={{ width: 250, height: 100 }}
          />
        </div>
        <ButtonDark
          handleClick={() =>
            handleCrop(
              image,
              croppedArea,
              onCancel,
              setSelectedImages,
              setThumbnailImages,
              userId,
              selectedImages,
              thumbnailImages,
              type
            )
          }
          styleClasses="text-white  !bg-dark-blue h-[50px] mt-10"
          text={t("cropImageText2")}
        />
      </div>
    </Modal>
  );
};

export default CropImageModal;
