import React from "react";

const CustomPrevButton = ({ onClick, style, className }) => {
  return (
    <svg
      width="69"
      height="77"
      viewBox="0 0 69 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ ...style }}
      className={className}
    >
      <path
        d="M34.4992 64.6225C48.5388 64.6225 59.9202 53.3021 59.9202 39.3376C59.9202 25.3732 48.5388 14.0527 34.4992 14.0527C20.4595 14.0527 9.07812 25.3732 9.07812 39.3376C9.07812 53.3021 20.4595 64.6225 34.4992 64.6225Z"
        stroke="#18374F"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M39.8216 25.2226L24.2315 38.1182C23.8439 38.4369 23.6353 38.9296 23.6055 39.3643C23.6055 39.8279 23.8141 40.2916 24.2315 40.6104L39.8216 53.5059C40.8053 54.3174 42.2361 53.2162 41.6101 52.086L35.5291 39.3353L41.6101 26.5846C42.2361 25.4834 40.8053 24.3532 39.8216 25.1646L39.8216 25.2226Z"
        fill="#18374F"
      />
    </svg>
  );
};

export default CustomPrevButton;
