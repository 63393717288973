import React from "react";
import SettingHeader from "./SettingHeader";
import FormInput from "../CommonComponents/FormInput";
import { Form } from "antd";
import ButtonDark from "../CommonComponents/ButtonDark";

const QueueManagement = ({ t }) => {
  const onFinish = (values) => {
    // next();
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  return (
    <div className="bg-[#E2F3F7] h-full rounded-[20px] pb-[20px] 2xl:pb-[46px]">
      <SettingHeader t={t} heading={t("queueManagement")} />
      <div className="pl-[40px] pr-[20px] flex flex-col">
        <div className="">
          <h1 className=" text-[20px] font-roobert-rg 2xl:text-[22px] leading-[35px]">
            {t("queueManagement1")}
            <br /> {t("queueManagement2")}
          </h1>
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="mt-[10px] relative">
              <FormInput styleClasses={"rounded-full w-[50%]"} />
              <span className="absolute left-[55%] top-[10px]">
                NOK/{t("annually")}
              </span>
            </div>
          </Form>
        </div>
        <div className="2xl:mt-[150px] mt-[130px]">
          <ButtonDark
            text={t("getTheWidget")}
            styleClasses={"light-Btn h-[40px] w-[40%]"}
          />
        </div>
      </div>
    </div>
  );
};

export default QueueManagement;
