import React, { useState } from "react";
import Logo from "../../assets/icons/Logo";
import "../../App.css";
import { useParams } from "react-router-dom";
import LogoutIcon from "../../assets/icons/LogoutIcons";
import LogoutModal from "../LogoutModal";
import useGetDataForSidebar from "../../hooks/getData/useGetDataForSidebar";
import MenuItems from "./MenuItems";

const Sidebar = () => {
  const {
    SideBarMenuForMarina,
    location,
    handleConfirmLogout,
    isModalOpen,
    setIsModalOpen,
    t,
    bottomItems,
  } = useGetDataForSidebar();

  const [closeActiveMenuChilds, setCloseActiveMenuChilds] = useState(false);
  const { lang } = useParams();

  return (
    <div className="whitespace-nowrap  text-[#FFFFFF] sticky top-0 overflow-y-auto min-h-[600px] overflow-x-hidden h-screen bg-dark-blue">
      <div className="flex flex-col mt-[50px] w-full  ">
        <div className="pl-[16px]">
          <Logo />
        </div>
      </div>
      <h1 className="font-roobert-rg   w-[48%] 2xl:w-[43%] text-right   text-[14px] ">
        Marina O’Hoi
      </h1>
      <div className="flex flex-col justify-between h-auto">
        <div className="pt-[20px] h-[60vh] overflow-y-auto">
          {SideBarMenuForMarina.map((menu, i) => {
            return (
              <MenuItems
                menu={menu}
                lang={lang}
                key={i}
                location={location}
                closeActiveMenuChilds={closeActiveMenuChilds}
                setCloseActiveMenuChilds={setCloseActiveMenuChilds}
              />
            );
          })}
        </div>
        <div>
          <div className=" w-full  bottom-0 absolute flex flex-col justify-end">
            {bottomItems?.map((item) => {
              return (
                <MenuItems
                  menu={item}
                  key={item.id}
                  lang={lang}
                  location={location}
                  closeActiveMenuChilds={closeActiveMenuChilds}
                  setCloseActiveMenuChilds={setCloseActiveMenuChilds}
                />
              );
            })}
            <button
              onClick={() => setIsModalOpen(true)}
              className="flex gap-x-[10px] pl-[15%]  justify-start  hover-effect hover:text-dark-blue hover:bg-white w-full   ml-[15px]  items-center py-[10px]  text-[16px] 2xl:text-[20px] font-roobert-rg  "
            >
              <LogoutIcon />
              {t("logout")}
            </button>
          </div>

          <LogoutModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            onLogout={handleConfirmLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
