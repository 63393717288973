import { Button } from "antd";
import React from "react";

const LightBtn = ({ text, handleClick }) => {
  return (
    <Button
      //   type='primary'
      onClick={handleClick}
      className="light-Btn h-[50px] rounded-[50px] font-roobert-l  flex justify-center items-center w-full"
    >
      {text}
    </Button>
  );
};

export default LightBtn;
