import React from "react";
import SDSDetailsHeader from "./SDSDetailsHeader";
import MenuBox from "../CommonComponents/MenuBox";
import Carousel from "../Carousel/Carousel";
import EditUserDetails from "./EditUserDetails";
import PreviousInvoices from "../CommonComponents/PreviousInvoices";
import { useLocationStore } from "../../store/store";
import useGetDataForEditSeasonalDock from "../../hooks/getData/useGetDataForEditSeasonalDock";
import { useTranslation } from "react-i18next";
const SDSDetails = ({ handleClose, form, refetch }) => {
  const {
    dockFetching,
    dockData,
    dockError,
    dockLoading,
    onFinish,
    onDone,
    handleEdit,
    onDeleteUser,
  } = useGetDataForEditSeasonalDock({ form, refetch });
  const { edit, addRenter } = useLocationStore();
  const { t } = useTranslation();
  if (dockError) {
    return dockError;
  }
  const {
    boat_type,
    boat_length,
    boat_width,
    customer_id,
    customer_name,
    customer_email,
    customer_contact,
    images,
    dock_length,
    dock_width,
  } = dockData ?? {
    boat_type: "",
    boat_length: "",
    boat_width: "",
    customer_id: "",
    customer_name: "",
    customer_email: "",
    customer_contact: "",
    images: [],
  };

  return (
    <>
      <div className="px-[50px]  bg-[#E2F3F7] pt-[26px]">
        <SDSDetailsHeader
          handleClose={handleClose}
          handleEdit={handleEdit}
          onDone={onDone}
          onDeleteUser={onDeleteUser}
          edit={edit}
          showEdit={true}
          addRenter={addRenter}
        />
        {!edit && (
          <div className="mt-[40px]   w-full flex gap-x-[55px]">
            <MenuBox form={form} t={t} />
            <ul className="whitespace-nowrap mt-[-10px]">
              <li className="text-[28px]  font-roobert-rg ">
                {t("customerInfo")}
              </li>
              <li className="mt-[5px] text-[16px] font-roobert-rg">
                {t("memberNumber")}: {customer_id}
              </li>
              <li className="mt-[5px] text-[16px] font-roobert-rg">
                {t("name")}: {customer_name}
              </li>
              <li className="mt-[5px] text-[16px] font-roobert-rg">
                {t("email")}: {customer_email}
              </li>
              <li className="mt-[5px] text-[16px] font-roobert-rg">
                {t("Phonenumber")}: {customer_contact}
              </li>
            </ul>
            <ul className="whitespace-nowrap mt-[-10px]">
              <li className="text-[28px]  font-roobert-rg ">{t("boatInfo")}</li>
              <li className="mt-[5px] text-[16px] font-roobert-rg">
                {t("boatType")}: {boat_type}
              </li>
              <li className="mt-[5px] text-[16px] font-roobert-rg">
                {t("boatLength")}:{" "}
                {boat_length > 0 ? boat_length + "meter" : ""}
              </li>
              <li className="mt-[5px] text-[16px] font-roobert-rg">
                {t("boatWidth")}: {boat_width > 0 ? boat_width + "meter" : ""}
              </li>
            </ul>
          </div>
        )}
        {!edit && images.length === 0 && (
          <div className="mt-[20px] h-[100px] flex justify-center items-center bg-dark-blue 2xl:mt-[40px]">
            <h1 className="text-white">{t("noImagesToShow")}</h1>
          </div>
        )}
        {!edit && images?.length > 0 && (
          <div className="mt-[20px]">
            <Carousel data={images} />
          </div>
        )}
      </div>
      {!edit && <PreviousInvoices padding={true} />}
      {edit && (
        <EditUserDetails
          form={form}
          dockLength={dock_length}
          dockWidth={dock_width}
          images={images}
          onFinish={onFinish}
          addRenter={addRenter}
          t={t}
        />
      )}
    </>
  );
};

export default SDSDetails;
